<script setup lang="ts">

import { getDateLabels } from '@/utils/dateUtils'

import { useAppointments } from '@/store/items/appointment';
import type { AppointmentItem } from '@/store/items/interfaces/appointment'
import { useAgenda } from '@/store/pages/agenda'
import { useWorkdays } from '@/store/items/workday';

// Store initialization
const store = useAgenda();
const workdays = useWorkdays()
const appointments = useAppointments()

// Define props with types
const props = defineProps<{
  selected: number | null;
}>();

// Define emits with type safety
const emit = defineEmits<{
  (event: 'selectWorkday', id: number): void;
}>();

// Helper function to count appointments on a given date
function countAppts(dateStr: string): number {
  const userDate = new Date(dateStr);
  const userDay = userDate.getUTCDate();
  const userMonth = userDate.getUTCMonth();
  const userYear = userDate.getUTCFullYear();

  const appointmentItems = appointments.items.filter((appt: AppointmentItem) => {
    const apptDate = new Date(appt.begin);
    return (
      apptDate.getUTCDate() === userDay &&
      apptDate.getUTCMonth() === userMonth &&
      apptDate.getUTCFullYear() === userYear
    );
  });

  return appointmentItems.length;
}

</script>

<template>
<div class="flex flex-row justify-between w-fit gap-4 p-4 select-none">
  <div
    v-for="obj in workdays.items"
    :key="obj.id"
    class="flex flex-col relative h-20 w-20 lg:w-24 lg:h-24 items-center justify-between border border-gray-400 cursor-pointer hover:bg-gray-100"
    :class="{'border-pink-600': obj.id === selected}"
    @click="$emit('selectWorkday', obj.id)"
  > 
    <div 
      class="text-xs border-b w-full text-center text-white py-0.5"
      :class="[{'bg-pink-600': obj.id === selected}, {'bg-gray-700': obj.id !== selected}]"
    >
      {{ getDateLabels(obj.date).weekday.short }}
    </div>
    <div class="flex flex-col text-center">
      <div class="text-xs border-b pb-0.5 text-gray-500">{{ getDateLabels(obj.date).month }}</div>
      <div M
        class="font-bold text-sm lg:text-xl"
        :class="[
          { 'text-cyan-600': countAppts(obj.date) },
          { 'text-gray-400': obj.duration === 0 },
          { 'text-pink-600': obj.id === selected }
        ]"
      >
        {{ getDateLabels(obj.date).date }}
      </div>
    </div>
    <div class="h-2"></div>
  </div>
</div>
</template>