<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'
import { useThreads } from '@/store/items/thread'

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import ReadThread from '@/components/common/email/ReadThread.vue';

const threads = useThreads()

const props = defineProps<{
    category: number
}>()

const items = computed(() => threads.forProject(props.category))

const selected = ref<boolean>(false)
function closeSelected() {
    selected.value = false
}
async function selectItem(pk: number) {
    await threads.fetchThread(pk)
    threads.selected = pk
    selected.value = true
}
onMounted(() => {
    threads.loadItems({'category': props.category})
})

</script>

<template>

<div v-if="!selected" class="flex flex-col flex-grow">
    <div 
      v-for="item in items"
      class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
      @click="selectItem(item.id)"
    >
        {{ item.messages[0].subject }}
    </div>
</div>

<div v-else>
    <ReadThread 
        :showFilterOpts="false"
        @closeThread="closeSelected"
    />
</div>


</template>