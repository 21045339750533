export function formatTimeDifference(startDate: Date, endDate: Date): string {
    const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInDays > 0) {
      return formatTimeInPast(diffInDays, 'day');
    } else if (diffInHours > 0) {
      return formatTimeInPast(diffInHours, 'hour');
    } else if (diffInMinutes > 0) {
      return formatTimeInPast(diffInMinutes, 'minute');
    } else {
      return formatTimeInPast(diffInSeconds, 'second');
    }
  }

export function formatTimeInPast(value: number, unit: string): string {
  return `${value} ${unit}${value !== 1 ? 's' : ''} ago`;
}

export function javascriptNonsenseForUserTime(): string {
  const date = new Date();

  // Get the timezone offset in minutes
  const timezoneOffset = -date.getTimezoneOffset();
  const offsetSign = timezoneOffset >= 0 ? '+' : '-';
  const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');

  // Format the date to ISO string (local time without timezone info)
  const localTime = date.toISOString().slice(0, 19).replace('T', 'T');

  // Combine the local time with the timezone offset
  return `${localTime}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export function getUserLocalTime(): Date {
  const userTimezoneOffset = new Date().getTimezoneOffset() * 60000; // Offset in milliseconds
  return new Date(Date.now() - userTimezoneOffset);
}

export function convertMinutesIntoHrsMinutesVerbose(minutes: number): string {
  if (minutes <= 0) {
    return "--";
  }

  const hrs = Math.floor(minutes / 60);
  const mins = minutes % 60;

  const parts = [];
  if (hrs > 0) {
    parts.push(`${hrs} hr`);
  }
  if (mins > 0) {
    parts.push(`${mins} min`);
  }

  return parts.join(" & ");
}

export function getHrsMinutesRaw(minutes: number): { hours: number; minutes: number } {
  return {
    hours: Math.floor(Math.max(0, minutes) / 60),
    minutes: Math.max(0, minutes) % 60,
  };
}

export function getHrsMinutes(minutes: number): string {
  if (minutes <= 0) {
    return "--";
  }

  const { hours, minutes: mins } = getHrsMinutesRaw(minutes);

  const parts = [];
  
  if (hours > 0) {
    parts.push(`${hours} hr`);
  }
  if (mins > 0) {
    parts.push(`${mins} min`);
  }

  return parts.join(" & ");
}

export function convertToLocalTime(date: Date): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  ); // Create a local `Date` without any timezone adjustments
};

export function makeReadable(dateStr: string | Date) {
  const dateObj = new Date(dateStr);
  const readableString = dateObj.toLocaleString("en-US", {
    dateStyle: "full",
    timeStyle: "short",
  });
  return readableString
}