<script setup lang="ts">
import { useContacts } from "@/store/items/contact"
import { useGroups } from "@/store/items/group"
import { useProjects } from "@/store/items/project"

import type { AppointmentItem } from "@/store/items/interfaces/appointment"

const props = defineProps<{
  appointment: AppointmentItem
  position: number
}>()

const contacts = useContacts()
const groups = useGroups()
const projects = useProjects()

function getTime(): string {
  const date = new Date(props.appointment.begin)
  const dateMask: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'short', day: 'numeric' }
  let str = date.toLocaleDateString(undefined, dateMask) + " @ "
  str += date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' })
  return str
}

function countSegments(): number {
  const begin = new Date(props.appointment.begin)
  const end = new Date(props.appointment.end)
  const length = Math.floor((Math.abs(end.getTime() - begin.getTime()) / 1000) / 60)
  return Math.round(length / 30)
}

function getClass(): string {
  let classStr = "absolute text-white font-xs px-2 rounded w-full drop-shadow-md p-1 flex "
  if (countSegments() > 1) {
    classStr += "flex-col"
  } else {
    classStr += "flex-row gap-1"
  }
  return classStr
}

function getColor(): string {
  return projects.getItem(props.appointment.category).color
}

function getStyle(): string {
  const height = 22 * countSegments()
  const top = -1.5 + (-1 * props.position)
  const left = 125 * props.position
  let str = `height: ${height}px; top: ${top}px; z-index: ${props.position}; left: ${left}px;`
  str += `background: ${getColor()};`
  return str
}

function startDrag(evt: DragEvent): void {
  evt.dataTransfer!.dropEffect = 'move'
  evt.dataTransfer!.effectAllowed = 'move'
  evt.dataTransfer!.setData('pk', String(props.appointment.id))
}
</script>

<template>
  <div
    @dragstart="startDrag($event)"
    draggable="true"
    :class="getClass()" :style="getStyle()"
  >
    <div class="font-medium flex flex-row gap-1">
      {{ appointment.label }}<span v-if="appointment.participant_names">with {{ appointment.participant_names }}</span>
    </div>
  </div>
</template>
