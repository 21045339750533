<script>
export default {
  name: "Status",
  props: {
    data: Number, // Integer indicating initial status value.
    labelClass: String, // Styles for Label
  },
  emits: [
    "updateField", // Returns: {field: "Field Name", value: "Field Contents"}
  ],
  setup(props, context) {
    const statusOptions = [
      [1, "Actionable"],
      [2, "Needs Reply"],
      [3, "Suspended"],
      [5, "Done"],
    ];
    const activeStrings = {
      0: "hidden",
      1: "bg-emerald-600 text-white hover:bg-emerald-600",
      2: "bg-yellow-700 text-white hover:bg-yellow-700",
      3: "bg-rose-800 text-white hover:bg-rose-800",
      4: "hidden",
      5: "bg-cyan-800 text-white hover:bg-cyan-800",
    };

    const classStrings = {
      0: "hidden",
      1: "hover:bg-gray-100",
      2: "hover:bg-gray-100",
      3: "hover:bg-gray-100",
      4: "hidden",
      5: "hover:bg-gray-100",
    };


    function update(value) {
      context.emit("updateField", { field: "status", value: value });
    }

    function getStatusClass(option) {
      let str = 'chip '
      if (option === props.data) {
        str += activeStrings[parseInt(option)];
      } else {
        str += classStrings[parseInt(option)]
      }
      return str;
    }

    return { update, statusOptions, getStatusClass };
  },
};
</script>

<template>
  <div class="flex flex-col max-w-full">
    <label class="form-label" :class="labelClass"> Status </label>
    <div class="flex flex-row justify-center gap-2 items-center">
      <div
        v-for="(option, index) in statusOptions"
        @click="update(option[0])"
        :class="getStatusClass(option[0])"
        class="w-96"
        :key="index"
      >
        {{ option[1] }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.hidden {
  display:none
}
</style>
