<script setup lang="ts">
import DisplayNote from './DisplayNote.vue';
import ListNotes from './ListNotes.vue';

import { useNotes } from '@/store/items/note';
import { ref, computed, watch } from 'vue';

// Define the store type if it's available
const store = useNotes();

// Define props with their types
const props = defineProps<{
    project: number;
    loaded: number[];
}>();

function createNewNote(): void {
    store.clearSelected();
    store.editorIsOpen = true;
}

function openEditForm(pk: number): void {
    store.setSelected(pk);
    store.editorIsOpen = true;
}

watch(
    () => props.project,
    () => {
        store.editorIsOpen = false;
    }
);
</script>

<template>
    <div class="h-full w-full flex flex-row">
        <div class="flex flex-col w-1/2 border-r p-5">
            <div class="flex flex-row gap-6 w-full text-sm items-center mb-5">
                <div class="text-xl text-teal-700">Notes</div>
            </div>
            <ListNotes
                :project="project"
                :loaded="loaded"
                @openEditForm="openEditForm"
                @createNewNote="createNewNote"
            ></ListNotes>
        </div>
        <div v-if="store.editorIsOpen === true" class="flex flex-col w-1/2 min-h-0 flex-grow">
            <DisplayNote
                :project="project"
                :showActions="true"
            ></DisplayNote>
        </div>
    </div>
</template>