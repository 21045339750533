<script setup>
import {ref} from 'vue'
const emit = defineEmits(['runSearch'])

const searchText = ref("")

function changeText() {
    emit('runSearch', searchText.value)
}

function clearSearch() {
    searchText.value = ""
    changeText()
}

</script>

<template>
<div class="">
    <div class="relative mx-auto text-gray-600">
        
        <input 
            @input="changeText"
            v-model="searchText"
            class="border-2 bg-gray-200 border-gray-200 h-8 px-2 pr-16 rounded-xl text-sm focus:outline-none"
             name="search" placeholder="Search" 
        />
        
        <div class="absolute right-3 top-1/2 transform -translate-y-1/2">
            <svg v-if="!searchText.length" class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                width="512px" height="512px">
                <path
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
            <div v-else @click="clearSearch()" class="relative top-0.5 left-1 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-gray-600 h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>
        </div>
    </div>
</div>
    
</template>