<script lang="ts" setup>

import { defineProps, defineEmits, ref, watch, computed, reactive } from 'vue';
import { useRoutines } from '@/store/items/routine';

import DurationField from '@/components/common/formFields/forTask/Duration.vue';
import EnthusiasmField from '@/components/common/formFields/forTask/Enthusiasm.vue';
import type { RoutineItem } from '@/store/items/interfaces/routine';
import LeadTimeField from '@/components/common/formFields/forRoutine/LeadTimeField.vue';

// Define the props
interface Props {
  project: number;
}

const props = defineProps<Props>();

// Define the emitted events
type Emit = (event: 'close') => void;
const emit: Emit = defineEmits(['close']);

// Store and form setup
const store = useRoutines();
const form = reactive(store.proposed);

const formValid = computed(() => {
  return !!form.label && !!form.start_day;
});

// Errors state
const errors = ref({
  label: false,
  start_day: false,
  final_day: false,
  distance: false,
  interval: false,
  lead_time: false,
  minutes_allotted: false,
  enthusiasm: false,
});

// Error setting function
function setErrors(): void {
  errors.value.label = !form.label;
  errors.value.start_day = !form.start_day;
}

// Save function
function saveData(): void {
  if (!formValid.value) {
    setErrors();
    return;
  }

  if (form.distance === 'QUARTERLY') {
    form.interval *= 3;
    form.distance = 'MONTHLY';
  }
  form.category = props.project;
  store.save();
  emit('close');
}

interface UpdateFieldData<K extends keyof RoutineItem> {
  field: K;
  value: RoutineItem[K];
}

function updateField<K extends keyof RoutineItem>(data: UpdateFieldData<K>): void {
  form[data.field] = data.value;
}

// Watchers
watch(
  () => form.interval,
  (newVal) => {
    if (newVal < 1) {
      form.interval = 1;
    }
  }
);

watch(
  () => form.minutes_allotted,
  (newVal) => {
    if (newVal < 1) {
      form.minutes_allotted = 5;
    }
  }
);
</script>

<template>

<div class="pt-4 flex flex-col gap-5">
    <div class="flex flex-col">         
        <div class="form-label">Label</div>
        <input autofocus v-model="form.label" type="text" :class="{'border-red-600': errors.label}"  class="form-field">            
    </div>
    <div class="flex flex-col">
        <div class="form-label">Beginning Date</div>
        <input type="date" :class="{'border-red-600': errors.start_day}" class="form-field" v-model="form.start_day">
    </div>
    <div class="flex flex-col">
        <div class="form-label">End Date (Optional)</div>
        <input type="date" class="form-field" v-model="form.final_day">
    </div>
    <div class="flex flex-col">
        <div class="form-label">Repeats Every</div>
    
        <div class="flex flex-row gap-3 items-center justify-start w-full text-sm ">
            <input min="1" v-model="form.interval" type="number" class="text-sm w-16 border-x-0 border-t-0 border-dotted focus:ring-0"/>
            <select v-model="form.distance" class="text-sm uppercase border-x-0 border-t-0 border-dotted focus:ring-0">
                <option value="DAILY">Days</option>
                <option value="WEEKLY">Weeks</option>
                <option value="MONTHLY">Months</option>
                <option value="QUARTERLY">Quarters</option>
            </select>
        </div>
    </div>

    <div class="flex flex-col">
        <div class="form-label">Lead Time</div>
        <LeadTimeField
            :leadTime="form.lead_time"
            :distance="form.distance"
            :interval="form.interval"
            @update:leadTime="(value) => (form.lead_time = value)"
        />
    </div>

    <DurationField
        :data="form.minutes_allotted"
        label="Estimated Work"
        @updateField="updateField"
    ></DurationField>

    <EnthusiasmField
        :data="form.enthusiasm"
        :classes="{label:''}"
        @updateField="updateField"
    ></EnthusiasmField>
    
    <div class="flex flex-row justify-end border-t pt-4">
        <div @click="saveData()" :class="{ 'btn-confirm': formValid, 'btn-disabled': !formValid }">Save</div>
    </div>

</div>

</template>