const detailActions = {
    async interactForDetail(action, model, data) {
        return this.customInteract(action, model, data);
      },
      setDetail(model, obj) {
        const target = this.getSelected()
        const targetIndex = target[model].findIndex((item) => item.id === obj.id);
        target[model][targetIndex] = obj;
      },
      async createDetail(model, payload) {
        const result = await this.interactForDetail('create', model, payload);
        const contact = this.getSelected()
        contact[model].push(result);
      },
      async overwriteDetail(model, payload) {
        const result = await this.interactForDetail('overwrite', model, payload);
        this.setDetail(model, result)
      },
      async saveDetail(model, payload) {
        if (payload.id) {
          await this.overwriteDetail(model, payload)
        } else {
          await this.createDetail(model, payload)
        }
      },
      async delDetail(pk, model) {
        await this.interactForDetail("delete", model, pk);
        const target = this.getSelected()
        const targetIndex = target[model].findIndex((item) => item.id === pk);
        target[model].splice(targetIndex, 1); 
      },
      async updateCategory(categoryID) {      
        const data = {
          'id': this.selected, 
          'category': [categoryID] // Contacts can be saved to multiple categories, so the backend expects a list.
        }
        const response = await this.interact('update', data)
        this.getItem(this.selected).category = response.category
      },     
  }
export { detailActions };