<script setup lang="ts">

import { getSearchResults } from '@/utils/searchUtils';
import type { TaskItem } from '@/store/items/interfaces/task';

import TaskForm from '@/components/common/forms/TaskForm.vue';
import TaskDetails from './TaskDetails/TaskDetails.vue';
import ListedTask from '@/components/common/listedItems/ListedTask.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';
import FilterBar from '@/components/common/filters.vue';
import SearchBar from '@/components/common/search.vue';

import { useTasks } from '@/store/items/task';
import { ref, computed, onMounted, watch } from 'vue';

const store = useTasks();

const props = defineProps<{
  project: number;
}>();

const emit = defineEmits<{
  (e: 'toggleMode'): void;
}>();

const formIsOpen = ref(false);
const formPosition = ref<number | null>(null);
const locationName = ref<string>('root')
const openedTask = computed<number | null>(() =>
  store.opened['root'][store.opened['root'].length - 1]
);

function openNormalForm(): void {
  store.clearProposed();
  store.proposed.category = props.project;
  formIsOpen.value = true;
  store.formOwner = 'root';
}

function openSubtaskForm(pk: 'new' | number, position?: number | null): void {

  if (pk === 'new') {
    // Clear store if it hasn't been set up in another component.
    if (!position || position===null ) {
      store.clearProposed(); 
      formPosition.value = null;
    } else if (position || position === 0) {
      formPosition.value = position;
    }    
  } else {
    store.proposed = store.getItem(pk);
  }
  store.formOwner = 'root';
  store.proposed.category = props.project;
  formIsOpen.value = true;
}

const formLabel = computed(() => {
  let label = store.proposed.phase ? `New Task for Phase ${formPosition.value}` : 'New Task';
  if (store.proposed.parent) {
    const parentTask = store.getItem(store.proposed.parent);
    label += ` of: ${parentTask.label}`;
  }
  return label;
});

function closeForm(): void {
  formIsOpen.value = false;
}

const searchStr = ref('');
function setSearch(input: string): void {
  searchStr.value = input;
}

interface FilterOption {
  label: string;
  id: number;
}

const filterOptions: FilterOption[] = [
  { label: 'All Tasks', id: 0 },
  { label: 'Actionable', id: 1 },
  { label: 'Needs Reply', id: 2 },
  { label: 'On Hold', id: 3 },
  { label: 'Done', id: 5 },
];

const selectedFilter = ref(1);

function setFilter(input: number): void {
  selectedFilter.value = input;
}

const items = computed(() => {
  // Filter tasks for the current project, exclude tasks with status 4 (e.g., deleted), and without a parent.
  let unsortedTasks = store
    .forProject(props.project)
    .filter((task: TaskItem) => !task.parent && task.status !== 4);

  // Apply filter if selectedFilter is greater than 0.
  if (selectedFilter.value > 0) {
    unsortedTasks = unsortedTasks.filter((task: TaskItem) => task.status === selectedFilter.value);
  }

  // Search tasks by label using `searchStr`.
  unsortedTasks = getSearchResults(unsortedTasks, searchStr.value, 'label');

  // Sort tasks by urgency.
  return unsortedTasks.slice().sort((a, b) => a.urgency - b.urgency);
});

function openTask(pk: number) : void {
  if (!store.opened['root']) {
    store.opened['root'] = [];
  }
  store.opened['root'].push(pk);
}

onMounted((): void => {
  // Label Pipe allows tasks to be generated from text (e.g., from note or email).
  if (store.labelPipe) {
    openNormalForm();
    store.proposed.label = store.labelPipe;
    store.labelPipe = '';
  }
});

watch(
  () => store.formOwner,
  (newOwner) => {
    if (newOwner !== 'root' && formIsOpen.value) {
      closeForm();
    }
  }
);

</script>


<template>
  <div v-if="formIsOpen" class="w-full flex-col bg-white px-7 py-5 overflow-y-auto">
      <div class="flex flex-row border-b pb-2 text-sm justify-between text-gray-400 items-center">
          <div class=" text-gray-400">{{ formLabel }}</div>
          <CloseItem @click="closeForm"></CloseItem>
      </div>
      <TaskForm 
          :position="'root'"
          @close="closeForm"
      ></TaskForm>
  </div>
  <div v-else class="w-full flex flex-col h-full bg-white">
      <div class="flex flex-row justify-between border-b items-center p-3">
            <SearchBar
              @runSearch="setSearch"
          ></SearchBar>
          <div 
              @click="emit('toggleMode')"
              class="text-xs select-none uppercase flex flex-row justify-end text-gray-100 border p-1 px-2 rounded cursor-pointer bg-teal-700 hover:bg-teal-600"
          >
              <span >Show Routines </span>
          </div>
      </div>
      <div class="flex flex-row items-center text-sm gap-4 py-3 px-5 border-b justify-between">
          <FilterBar
              :items="filterOptions"
              :selected="selectedFilter"
              @setFilter="setFilter"
          ></FilterBar>
          <div class="">
              <div @click="openNormalForm()" class="btn-bold">Add Task</div>
          </div>
      </div>

      <div v-if="store.opened['root'].length" class="flex flex-col gap-3 p-6 overflow-y-auto">
          <TaskDetails
              :pk="openedTask!"
              :goalID="'root'"
              :parentLocation="'root'"
              @openForm="openSubtaskForm"
              @close="closeForm"
          ></TaskDetails>
      </div>
      
      <div v-else-if="items.length" class="flex flex-col p-6 gap-2 h-full overflow-y-auto">
          <ListedTask
              v-for="item in items"
              :pk="item.id"
              location="root"
              @actionA="openTask"
              @actionB="openTask"
          ></ListedTask>
      </div>
      <div v-else class="flex flex-col gap-3 p-6 items-center">
          <div class="text-gray-500 select-none">No Tasks for Project</div>
      </div>
  </div>
</template>