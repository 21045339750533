<script setup>

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import { ref, watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
    id: Number,
    data: String,
    sectionPK: Number,
    entryPK: Number
})

const emit = defineEmits(['createItem', 'updateItem', 'deleteItem'])

const id = ref(null)
const section = ref(null)
const entry = ref(null)
const data = ref("")
const backup = ref("")
const saving = ref(false)
const polling = ref(null);
const quill = ref("")

const clearData = () => {
    data.value = null
    id.value = null
    backup.value = null
}

const setData = () => {
    if (props.id) {
        id.value = props.id
        data.value = backup.value = props.data
    } else {
        id.value = null
        data.value =  backup.value = "\n"
    }
    section.value = props.sectionPK
    entry.value = props.entryPK
};

function containsOnlyTags(htmlString) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(htmlString, 'text/html');
  var textContent = doc.body.textContent.trim();
  return textContent === '';
}

const saveNote = async () => {
    if (id.value && (!data.value || containsOnlyTags(data.value)) && !saving.value) {
        saving.value = true
        await emit('deleteItem', id.value, section.value, entry.value);
        clearData()
        saving.value = false
    } else if (!saving.value && data.value && !containsOnlyTags(data.value) && data.value !== backup.value) {
        let result;
        saving.value = true;

        if (id.value) {
            result = await emit('updateItem', id.value, data.value);
        } else {
            result = await emit('createItem', data.value, section.value, entry.value);
            if (id.value === props.id && !data) {
                data.value = result
            }
        }

        if (id.value === props.id) {
            backup.value = data.value;
        }
        saving.value = false;
    }
};

const startPolling = () => {
    if (!polling.value) {
        polling.value = setInterval(async () => {
            await saveNote();
        }, 7000);
    }
};

const stopPolling = () => {
    if (polling.value) {
        clearInterval(polling.value);
        polling.value = null;
    }
};

onMounted(() => {
    startPolling();
});

onBeforeUnmount(() => {
    saveNote();
    stopPolling();
});


watch(() => props.id, async () => {
    await saveNote();
    setData();
});

setData()


</script>

<template>
    <div class="bg-white rounded shadow flex flex-col h-full flex-grow">
    <QuillEditor       
        ref="quill"
        v-model:content="data"
        theme="snow"
        contentType="html"
        toolbar="minimal"
    />
    </div>
</template>
