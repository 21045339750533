<script setup lang="ts">

import SelectOption from '@/components/common/buttons/SelectOption.vue';

import { useProjects } from '@/store/items/project'
import { useBinders } from '@/store/items/binder';
import { useThreads } from '@/store/items/thread';

import { reactive, ref, computed, watchEffect } from 'vue';

const props = defineProps<{
    filterAll: boolean
    useSubject: boolean
}>()

const emit = defineEmits(['setFilterAll', 'setUseSubject']);

const threads = useThreads()
const projects = useProjects()
const binders = useBinders()

const selected = reactive({
    project: null as number | null,
    binder: null as number | null,
});

const modes = [
    { label: 'All Messages', value: true },
    { label: 'Selected', value: false }
]

function setMode(option: boolean) {
    emit('setFilterAll', option)
}

const addOption = ref<"project" | "binder" | null>(null);
const subjectOption = ref(false);

async function saveNewProject() {
    const newObj = await projects.create()
    selected.project = newObj.id
    addOption.value = null
}

async function saveNewBinder() {
    binders.proposed.category =  selected.project
    const newObj = await binders.create()
    selected.binder = newObj.id
    addOption.value = null
}

const availableBinders = computed(() => {
    return binders.items.filter((item) => item.category === selected.project)
})

binders.loadItems()

watchEffect(() => {
    if (threads.filterData.subject.length) {
        emit('setUseSubject', true)      
    } else {
        emit('setUseSubject', false)        
    }
})

watchEffect(() => {
    if (selected.project) {
        threads.filterData.category = selected.project
        selected.binder = null
    }
})

watchEffect(() => {
    if (selected.binder) {
        threads.filterData.category = selected.binder
    }
})

watchEffect(() => {
    if (!props.filterAll) {
        threads.filterData.createFilter = false
        emit('setUseSubject', false)
    }
})

watchEffect(() => {
    if (!props.useSubject) {
        threads.filterData.subject = ''
    }
})

</script>

<template>
<div class="w-1/2 flex flex-col gap-4 border p-4" style="height: 400px">
    <div class="border-b select-none">Sort:</div>
    <div class="flex flex-row gap-4 justify-center select-none"> 
        <SelectOption
            :options="modes"
            :selected="filterAll"
            @select='setMode'
        ></SelectOption>
    </div>
    <div class="border-b select-none">into:</div>
    <div class="flex flex-row gap-4 justify-center select-none">
        <div v-if="addOption == 'project'" class="flex flex-col">            
            <input v-model="projects.proposed.name" type="text" id="addProject" class="form-input" autofocus>
            <div class="flex flex-row justify-end text-xs pt-0.5 cursor-pointer gap-1">
                <div @click="addOption = null" class="text-red-500 hover:text-red-600">Cancel</div>
                <div @click="saveNewProject()" class="text-green-600 hover:text-green-700">Save</div>
            </div>
        </div>
        <div v-else class="flex flex-col">
            <select v-model="selected.project" class="form-select">
                <option :value="null" selected disabled>Select Project</option>
                <option v-for="item in projects.items" :value="item.id">{{ item.name }}</option>
            </select>
            <div @click="addOption = 'project'" class="flex flex-row justify-end text-xs text-blue-500 pt-0.5 cursor-pointer hover:text-blue-600">
                +Add project
            </div>
        </div>
        
        <div v-if="addOption === 'binder'" class="flex flex-col">            
            <input v-model="binders.proposed.name" type="text" id="addBinder" class="form-input" autofocus>
            <div class="flex flex-row justify-end text-xs pt-0.5 cursor-pointer gap-1">
                <div @click="addOption = null" class="text-red-500 hover:text-red-600">Cancel</div>
                <div @click="saveNewBinder()" class="text-green-600 hover:text-green-700">Save</div>
            </div>
        </div>
        <div v-else class="flex flex-col">
            <select v-model="selected.binder" :disabled="!selected.project" class="form-select">
                <option :value="null">No Binder</option>
                <option v-for="item in availableBinders" :value="item.id">{{ item.name }}</option>
            </select>
            <div 
              @click="addOption = 'binder'"
              class="flex flex-row justify-end text-xs pt-0.5"
              :class="[selected.project ? 'text-blue-500 hover:text-blue-600 cursor-pointer':'text-gray-500 cursor-not-allowed']"
            >
                +Add binder
            </div>            
        </div>
    </div>


    <div 
        v-if="filterAll"
       class="flex flex-row gap-1 items-center border-t pt-4 select-none"
       :class="{ 'text-gray-400': !filterAll }"
    >
        <input
            v-model="threads.filterData.createFilter"
            :disabled="!filterAll" 
            class="form-checkbox"
            id="useFilter"
            type="checkbox"
        /><label for="useFilter"></label>
        <div>Set As Filter for Future Messages</div>
    </div>
    <div 
        class="flex flex-row gap-1 items-center select-none"
        :class="{ 'text-gray-400': !filterAll }"  
        v-if="filterAll"  
    >
        <input
            :disabled="!filterAll" 
            :checked="useSubject"
            @change="event => emit('setUseSubject', event.target.checked)"
            class="form-checkbox"
            id="enableSubject"
            type="checkbox"
        /><label for="enableSubject"></label>
        <div>only if subject includes:</div>
    </div>
    <div v-if="filterAll" class="flex flex-row gap-4 justify-center">
        <input :disabled="!filterAll" v-model="threads.filterData.subject" type="text" class="form-field w-full border-gray-500">
    </div>
</div>

</template>