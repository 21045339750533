const formatActions = {
      getUrgencyClass(pk) {
        const item = this.getItem(pk);
        const givenDate = new Date(item.begin.split('.')[0]);
        const currentDate = new Date();
    
        const timeDifference = givenDate - currentDate;
        const minutesDifference = Math.abs(Math.floor(timeDifference / (1000 * 60)));
        const hoursDifference = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60)));
    
        if (timeDifference < 0) {
          return "border-gray-300 shadow-gray-300 bg-gray-100"
        } else if (hoursDifference < 1 && minutesDifference < 15) {
          return "border-red-500 shadow-red-500"
        } else if (hoursDifference < 2 ) {
          return "border-yellow-600 shadow-yellow-600"
        } else {
          return "border-gray-600 shadow-gray-600"
        }
    },  
}

export { formatActions };