<script setup lang="ts">
import Loader from '@/components/common/Loader.vue';

import { useTasks } from '@/store/items/task';
// import { useProjects } from '@/store/project.js';

// import ManageItems from './ManageItems.vue';

import TaskPanel from './TaskPanel/TaskPanel.vue';
import RoutinePanel from './RoutinePanel/RoutinePanel.vue';
import GoalPanels from './GoalPanels/GoalPanels.vue';

import { ref, computed, watch } from 'vue';

const props = defineProps<{
  project: number;
  loaded: number[];
}>();

const store = useTasks();
// const projectStore = useProjects();

const mode = ref<'Task' | 'Routine'>('Task');

function toggleMode(): void {
  mode.value = mode.value === 'Routine' ? 'Task' : 'Routine';
  store.opened['root'].length = 0;
}

// const projects = computed(() => {
//   return projectStore.items
//     .filter((obj: { project: number | null }) => obj.project === null)
//     .map(({ id, name, project }: { id: number; name: string; project: number | null }) => ({ id, name, project }));
// });

watch(
  () => props.project,
  () => {
    store.opened['root'].length = 0;
  }
);
</script>

<template>
    <div class="flex flex-row overflow-x-auto h-full">
        <div class="flex flex-col h-full w-[650px] min-h-0 flex-shrink-0 border-r p-5">
            <div class="flex flex-col h-full">
                <div class="flex flex-row gap-6 w-full text-sm items-center">
                    <div class="text-xl text-teal-700 ">General {{ mode }}s</div>
                </div>
                <div class="flex flex-col mt-5 w-full min-h-0">            
                    <div v-if="!loaded.includes(project)" class="w-full flex-col bg-white px-7 py-5 ">
                        <Loader></Loader>
                    </div>
                    <div v-else class="w-full flex flex-col min-h-0 bg-white">

                        <TaskPanel
                            v-if="mode==='Task'"
                            :project="project"
                            @toggleMode="toggleMode"
                        ></TaskPanel>

                        <RoutinePanel 
                            v-else
                            :project="project"
                            @toggleMode="toggleMode"
                        />
                        
                    </div>                    
                </div>
            </div>        
        </div>
            <GoalPanels 
                :project="project"
            />
    </div>
</template>