<script setup lang='ts'>

import { useRouter } from 'vue-router'

import { ref } from 'vue'

const router = useRouter()

const slidePosition = ref(0)

function moveSlide(direction: string) {
    if (direction === 'left' && slidePosition.value > 0) {
        slidePosition.value -= 1
    } else if (direction === 'right' && slidePosition.value < 4) {
        slidePosition.value += 1
    }
}

</script>

<template>

<div class="flex flex-row bg-white h-full w-full">
    <div class="flex flex-col h-full w-1/2 bg-white overflow-auto border-t p-6">
        
        <div class="text-teal-600 flex flex-row text-4xl">On-It.pro</div>
        <div class="text-teal-800 text-sm">Personal Project Organizer</div>

        <div class="flex flex-col py-6"> 
            <div class="text-lg">Flexible</div>
            <div class="text-sm text-gray-500">
                On-it.pro was born out of frustration with rigid “productivity” apps that demand excessive upfront input and planning. Instead, On-it.pro lets you freely assign tasks to your workday—no strict scheduling required—break them down on the fly, and effortlessly ‘reset’ your project if life disrupts your plan. It's designed to lighten your mental load, instead of adding to it, so you can adapt your plans as life happens—without losing momentum.
            </div>        
        </div>

        <div class="flex flex-col py-6"> 
            <div class="text-lg">Powerful</div>
            <div class="text-sm text-gray-500">
                On-it.pro streamlines your daily workflow so you can focus on your work instead of the process of juggling your projects. Set up pipelines to automatically sort incoming emails, use meeting templates for quick agenda planning, and let the task auto-scheduler recommend a balanced weekly workload—factoring in deadlines, required time, and even your enthusiasm for your various responsibilities. Get your work done with minimal time spent sorting through mental clutter.
            </div>        
        </div>
        
        <div class="flex flex-col py-6"> 
            <div class="text-lg">Coherent</div>
            <div class="text-sm text-gray-500">
                Built by an entrepreneur for entrepreneurs, On-it.pro keeps each project in its own working environment, with the information you need exactly where you need it. Tasks, emails, meetings, contacts, notes, and external resources are all manageable within these environments using their own context-aware interfaces. A binder system allows for further archiving of items you want to reference later.
            </div>        
        </div>

        <div class="flex flex-row justify-around pt-4">
            <div class="btn btn-confirm" @click="router.replace('/request-access/')">Get Beta Access</div>
        </div>
    </div>
    <div class="flex flex-col border-l border-t h-full w-1/2 justify-between">
        <div v-if="slidePosition === 0" class="flex flex-col">
            <div class="ml-12 mt-4 m-8 shadow-xl border-2 border-teal-100 w-[500px]">
                <img 
                    src="/images/Screen_A1.png" 
                    alt="Splash Preview" 
                    class="splash-preview" 
                />
            </div>
            <div class="absolute bottom-36 right-0 m-4 ml-8 shadow-xl border-teal-500 border-2 w-[500px]">
                <img 
                    src="/images/Screen_A2.png" 
                    alt="Splash Preview" 
                    class="splash-preview" 
                />
            </div>
            <div class="flex flex-row items-center p-12 pb-2 text-lg text-gray-600">
                Divide and Conquer: Decompose any task on the fly to turn big challenges into a series of small wins.
            </div>
        </div>
        <div v-else-if="slidePosition === 1" class="flex flex-col">
            <div class="flex flex-row items-center p-12 pb-2 text-lg text-gray-600">
                Plan in Seconds, Work with Intention - Stay on top of projects by setting your goals for the day with just a few clicks. 
            </div>
            <div class="ml-12 mt-4 m-8 shadow-xl border-2 border-teal-100 w-[620px]">
                <img 
                    src="/images/Screen_B.png" 
                    alt="Splash Preview" 
                    class="splash-preview" 
                />
            </div>
        </div>
        <div v-else-if="slidePosition === 2" class="flex flex-col">
            <div class="ml-12 mt-4 m-8 shadow-xl border-2 border-teal-100 w-[620px]">
                <img 
                    src="/images/Screen_C.png" 
                    alt="Splash Preview" 
                    class="splash-preview" 
                />
            </div>
            <div class="flex flex-row items-center p-12 pb-2 text-lg text-gray-600">
                Email Precision: Group incoming emails by project and cut out clutter instantly.
            </div>
        </div>
        <div v-else-if="slidePosition === 3" class="flex flex-col">
            <div class="flex flex-row items-center p-12 pb-2 text-lg text-gray-600">
                One Single Hub, Infinite Clarity: Access your notes, resources, contacts, meetings and more in just one click.”
            </div>
            <div class="ml-12 mt-4 m-8 shadow-xl border-2 border-teal-100 w-[620px]">
                <img 
                    src="/images/Screen_D.png" 
                    alt="Splash Preview" 
                    class="splash-preview" 
                />
            </div>
        </div>
        <div v-else-if="slidePosition === 4" class="flex flex-col">
            <div class="flex flex-row gap-6 items-center">
                <div class="ml-12 mt-4 m-8">
                    <img 
                        src="/images/profile.jpeg" 
                        alt="Splash Preview" 
                        class="splash-preview  rounded-full" 
                    />
                </div>
                <div class="flex flex-grow text-4xl text-rose-600 border-b-4 mr-12">Meet the Dev</div>
            </div>
            <div class="flex flex-row items-center p-12 pb-2 text-lg text-gray-600">
                Hi, I’m Adam Starrh. As a lifelong entrepreneur, I’ve spent the past 15 years helping to manage everything from a large food processing and marketing firm in India to a community non-profit in my hometown. Along the way, I taught myself to code and have been involved in several other boards and ventures as well. Juggling such a diverse mix of projects pushed me to develop a system that could seamlessly handle multiple, very different initiatives at once. This project is the result—a practical solution born from real-world experience managing complexity and striving for excellence throughout.
            </div>
        </div>
        <div class="flex flex-row w-full px-12 gap-2 mb-6">
            <div 
                @click="moveSlide('left')" 
                :class="[
                    'w-1/2',
                    'text-white',
                    'p-2',
                    'flex',
                    'flex-row',
                    'gap-2',
                    'justify-center',
                    'select-none',
                    slidePosition === 0 ? 'bg-gray-500' : 'bg-teal-700 hover:bg-teal-800 cursor-pointer',
                ]"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z" />
                </svg>
                Back
            </div>
            <div             
                @click="moveSlide('right')" 
                :class="[
                    'w-1/2',
                    'select-none',
                    'text-white',
                    'p-2',
                    'flex',
                    'flex-row',
                    'gap-2',
                    'justify-center',
                    slidePosition === 4 ? 'bg-gray-500' : 'bg-teal-700 hover:bg-teal-800 cursor-pointer',
                ]"
            >
                Next
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
                </svg>
            </div>
        </div>
    </div>
</div>
</template>

