export function cleanURL(url: string): string | null {
  let cleaned = url;

  // Add protocol if missing
  if (!/^https?:\/\//i.test(cleaned)) {
      cleaned = "https://" + url;
  }

  try {
      // Validate the URL
      const validUrl = new URL(cleaned);
      return validUrl.href; // Return the fully validated URL string
  } catch (error) {
      // If the URL is invalid, return null or handle as needed
      return null;
  }
}