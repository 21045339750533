import { defineStore } from "pinia";
import { crudActions } from "./crudActions";
import { itemActions } from "./itemActions";
import { dragDropActions } from "./dragDropActions";

export const commonState = (model, template) => ({
  model: model, 
  items: [],
  selected: null, 
  proposed: {...template}, 
  blank: {...template},
  loadedProjectPKs: [],
  labelPipe: ""
});

export const commonGetters = {
  getItem: (state) => (id) => state.items.find((item) => item.id === id),
  getItems: (state) => (idSet) => state.items.filter((item) => idSet.includes(item.id)),
  getSet: (state) => (key, value) => state.items.filter((item) => item[key] === value),
  forProject: (state) => (project) => state.items.filter((item) => item.category === project),
  filterItems: (state) => (fld, value) => state.items.filter((obj) => obj[fld] === value),
  getSelected: (state) => () => state.items.find((i) => i.id === state.selected),
};

export const commonActions = {
  ...crudActions,
  ...itemActions,
  ...dragDropActions,
};

export function createStore({ model, template, customState = {}, customGetters = {}, customActions = {} }) {
  return defineStore(model, {
    state: () => ({
      ...commonState(model, template),
      ...customState,
    }),

    getters: {
      ...commonGetters,
      ...customGetters,
    },

    actions: {
      ...commonActions,
      ...customActions,
    },
  });
}