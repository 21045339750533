export const customActions = {
    isCustomRecipientValid() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        const validation = [
            {
            classes: "require-email",
            rule:  emailRegex
            }
        ]
        return validation.every(v => 
            typeof v.rule === "function" ? v.rule() : v.rule.test(this.customRecipient.value)
        );
    },
    constructRecipientFromContact(contactItem, projectID, position='to') {
        const matchingPrimary = contactItem.email_addresses.find((i) => i.primary_for.includes(projectID))
        const finalEmail = matchingPrimary ? matchingPrimary.value : contactItem.email_addresses[0].value
        const newRecipient = {
            id: contactItem.id,
            name: contactItem.name,
            address: finalEmail,
            position: position
        }
        return newRecipient
    }
}