<script lang="ts" setup>
import { ref } from "vue";
import { useAgenda } from "@/store/pages/agenda";
import { useWorkdays } from "@/store/items/workday"
import type { WorkdayItem } from "@/store/items/interfaces/workday";
import CloseItem from "@/components/common/buttons/CloseItem.vue";

const store = useAgenda();
const workdays = useWorkdays()
const holidays = ref<string[]>([]);
const durations = ref<Record<string, number>>({});
const dates = ref<Record<string, Date>>({});

const props = defineProps<{
  slug: string;
}>()

const emit = defineEmits(["setView", "autoSchedule"]);

for (const day of workdays.items as WorkdayItem[]) {
  const date = new Date(day.date);
  dates.value[day.id] = date;

  let duration = 0;
  const yesterday = new Date().getTime() - 24 * 60 * 60 * 1000; // One day ago
  if (date.getTime() >= yesterday) {
    duration = day.duration / 60;
  }
  durations.value[day.id] = duration;
}

async function autoSchedule() {
  const data = {
    holidays: holidays.value,
    durations: durations.value,
    slug: props.slug
  };
  emit('autoSchedule', data);
}
</script>

<template>
  <div class="border border-gray-300 text-sm my-1">
    <div class="flex flex-row justify-between">
      <div class="p-2">Set up your work week:</div>
      <CloseItem @click="emit('setView', 'center', 'planner')" /> 
    </div>
    <form @submit.prevent="autoSchedule()" class="p-4 flex flex-col space-y-1">
      <div
        v-for="day in workdays.items"
        :key="day.id"
        class="flex flex-row bg-gray-100 items-center justify-between px-2"
      >
        <div class="flex flex-row gap-1 items-center">
          <input
            :id="day.id"
            type="checkbox"
            v-model="holidays"
            :value="day.id"
            class="form-checkbox"
            style="display: none"
          />
          <label :for="day.id"></label>

          <div class="text-light ml-2 py-2">{{ day.name }}</div>
        </div>

        <div class="flex flex-row border">
          <input
            type="number"
            v-model="durations[day.id]"
            class="px-2 border-b border-gray-400 hover:border-green-400 w-12 h-8"
          />
        </div>
      </div>

      <div class="flex justify-end relative top-1.5">
        <input
          type="submit"
          value="Set Schedule"
          class="p-2 cursor-pointer w-24 select-none rounded focus:outline-none bg-gray-600 hover:bg-gray-700 text-white text-sm"
        />
      </div>
    </form>
  </div>
</template>

<style scoped></style>
