const setterActions = {
    setDefaultLabel(label) {
        this.current = null
        this.defaults.label = label
      },
      updatePhases(pk, parentPK, targetPhase) {
        const hasOtherObjectsWithSamePhase = this.forParent(parentPK).some(obj => obj.phase === targetPhase && obj.id !== pk);
        if (!hasOtherObjectsWithSamePhase) {
          this.forParent(parentPK).forEach(obj => {
            if (obj.phase > targetPhase && obj.id !== pk) {
              obj.phase -= 1;
            }
          });
        }
      },
      removeLastOpenedTask() {
        store.opened[props.goal].splice(-1);
      }
}
export { setterActions}