<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { DatePicker } from "v-calendar"

const emit = defineEmits<{
  (event: 'update:selected', date: Date): void
}>()

const props = defineProps<{
  selected: Date
}>()

const userSelected = ref<Date>(props.selected)

function updateSelected(): void {
  emit('update:selected', new Date(userSelected.value))
}

function convertDateTZ(date: Date): Date {
  const formattedDate = new Date(date)
  const finalDate = new Date(
    formattedDate.getTime() + formattedDate.getTimezoneOffset() * 60000
  )
  return finalDate
}

function formatDate(date: Date): string {
  return date.toDateString()
}

function addOneDay(): void {
  userSelected.value = new Date(userSelected.value.setDate(userSelected.value.getDate() + 1))
  updateSelected()
}

function subtractOneDay(): void {
  userSelected.value = new Date(userSelected.value.setDate(userSelected.value.getDate() - 1))
  updateSelected()
}

const formatted = computed(() => userSelected.value.toDateString())

watch(() => userSelected.value, () => {
  updateSelected()
})
</script>

<template>
  <DatePicker v-model="userSelected">
    <template v-slot="{ inputValue, togglePopover, updateValue, showPopover }">   
      <div class="flex flex-row items-center cursor-pointer select-none">
        <div @click="subtractOneDay" class="hover:bg-gray-100 p-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </div>
        <div @click="togglePopover()" class="p-2 border-x text-teal-600 hover:bg-gray-100 w-36 flex flex-row justify-around">
          <span>{{ formatted }}</span>
        </div>
        <div @click="addOneDay" class="hover:bg-gray-100 p-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      </div>
    </template>
  </DatePicker>
</template>
