<script setup lang="ts">

import SelectContacts from './SelectContacts/SelectContacts.vue'
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ref, computed, reactive, watch, onMounted } from "vue";
import { mergeArrays } from "@/utils/arrayUtils";
import type { ContactItem } from "@/store/items/interfaces/contact";
import { useThreads } from "@/store/items/thread";
import { useDirector } from "@/store/pages/director";
import { useContacts } from "@/store/items/contact";
import { useGroups } from "@/store/items/group";
import { useProjects } from "@/store/items/project";

import type { ThreadParticipant } from "@/store/items/interfaces/thread";
import { add } from 'date-fns';

export interface ComposeInitial {
  id: number | null;
  account: number;
  subject: string;
  body: string;
  category: number;
}

const quill = ref<InstanceType<typeof QuillEditor> | null>(null);

const props = defineProps<{
  initial: ComposeInitial;
  frameClass?: string;
}>();

const threads = useThreads();
const director = useDirector();
const contacts = useContacts();
const groups = useGroups()
const projects = useProjects()

const body = ref(props.initial.body);
const subject = ref(
  props.initial.id ? `Re: ${props.initial.subject || ""}` : ""
);
// const showAllContacts = ref(!props.initial.id);
// const newContact = ref("");
// const newContactOption = ref("to");

// const tags = ref<string[]>([]);

const projectContacts = computed(() => {
  return contacts.forProject(projects.selected!)
});

const recipients = ref<ThreadParticipant[]>([]);

function updateRecipient(index: number, value: "to" | "cc" | "bcc" | "disabled") {
    recipients.value[index].position = value
}

function addRecipient(contact: ThreadParticipant) {
  recipients.value.push(contact)
}

// watch(
//   () => props.thread,
//   () => {
//     if (props.thread) {
//       recipients.email = props.msgContacts.map((obj) => ({ ...obj }));
//       subject.value = props.initialSubject || "";
//     }
//   }
// );


const submitEmail = (send: boolean): void => {
  if (threads.isCustomRecipientValid()) {
    recipients.value.push({
      id: null,
      address: threads.customRecipient.value,
      position: threads.customRecipient.position,
      isCustom: true
    })
  }
  const data = {
    project: projects.selected,
    thread: threads.selected,
    draft: null,
    subject: subject.value,
    body: body.value,
    recipients: recipients.value,
    send
  };
  threads.processDraft(data);
};

// const setTransferData = async (): Promise<void> => {
//   const data = threadStore.transfer.data;
//   const model = data.model;
//   const store = directorStore.extensions[model];
//   const user = JSON.parse(localStorage.getItem("user") || "{}");
//   const pk = parseInt(data.id);
//   const obj = store.getItem(pk);

//   if (model === "thread") {
//     return;
//   }

//   if (model === "task") {
//     const capsuleStore = directorStore.extensions["capsule"];
//     await capsuleStore.loadItems(pk);
//     obj.notes = capsuleStore
//       .getForTask(pk)
//       .filter((c) => c.designation === "note");
//     obj.lessons = capsuleStore
//       .getForTask(pk)
//       .filter((c) => c.designation === "lesson");
//   }

//   let body = `<h5>${user.first_name} is sharing the following ${model} with you via <a href='https://www.on-it.pro'>on-it.pro</a>:</h5>`;
//   body += setDraft(model, obj);
//   text.value = body;

//   if (!subject.value) {
//     subject.value = setSubject(model, obj);
//   }

//   threadStore.transfer.target = null;
//   threadStore.transfer.data = { id: null, model: null };
// };

function addNewRecipient(contact: ContactItem, position: "to" | "cc" | "bcc"): void {
    const newRecipient = threads.constructRecipientFromContact(contact, projects.selected!, position)
    recipients.value.push(newRecipient)
}

onMounted(() => {
  const existingThread = threads.getSelected();
  if (existingThread) {
    const latestMessage = existingThread.messages.at(-1); // Correct way to get the last item in an array
    if (!latestMessage) return;

    // Handle the sender first
    if (latestMessage.from_contact) {
      const contact = contacts.getItem(latestMessage.from_contact)
      addNewRecipient(contact, "to");
    } else if (latestMessage.sender_address) {
        // Add sender as a "to" recipient when no from_contact is available
        recipients.value.push({
          id: null,
          address: latestMessage.sender_address,
          position: 'to'
        });
        console.log(recipients)
    }
    // Handle `to_contacts`, `cc_contacts`, and `bcc_contacts`
    latestMessage.to_contacts?.forEach(contactId => addNewRecipient(contacts.getItem(contactId), "to"));
    latestMessage.to_contacts?.forEach(contactId => addNewRecipient(contacts.getItem(contactId), "cc"));
    latestMessage.to_contacts?.forEach(contactId => addNewRecipient(contacts.getItem(contactId), "bcc"));
    // if (threadStore.transfer.target) {
    //   setTransferData();
    // }
  }
});
</script>
<template>
  <div :class="frameClass" class="overflow-auto">
    <h2 class="py-3 font-light text-center text-lg border-b">
      Compose
      <span v-if="!initial.id">New Message</span>
      <span v-else>Reply</span>
    </h2>
    <SelectContacts
      :recipients="recipients"
      @addRecipient="addRecipient"
      @updateRecipient="updateRecipient"
    ></SelectContacts>
    <div class="flex flex-col">
      <div class="flex flex-row justify-between">
        <input
          v-model="subject"
          class="p-2 m-5 border border-gray-400 focus:outline-none focus:border-blue-400 w-full"
          placeholder="Subject"
        />
      </div>
      <div class="p-5" style="height: 300px">
        <QuillEditor       
              ref="quill"
              v-model:content="body"
              theme="snow"
              contentType="html"
              toolbar="minimal"
          />
      </div>
      <div class="flex flex-row justify-end pr-6 gap-2">
        <div class="btn-dark">Cancel</div>
        <div @click="submitEmail(false)" class="btn-outline">Save</div>
        <div @click="submitEmail(true)" class="btn-confirm">Send</div>
      </div>
    </div>
  </div>
</template>

<style>
.vue-tags-input .ti-tag {
  background: #3b82f6;
  color: white;
}
.vue-tags-input .ti-tag.cc {
  background: #d97706;
}
.vue-tags-input .ti-tag.bcc {
  background: #4b5563;
}
</style>