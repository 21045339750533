<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import ReadMessage from "@/components/common/email/ReadMessage.vue";
import PreviewMessage from "./PreviewMessage.vue";
import Archive from "@/components/common/Archive.vue";
import CloseItem from "@/components/common/buttons/CloseItem.vue";
import { useThreads } from "@/store/items/thread";
import { useProjects } from "@/store/items/project";
import { useTasks } from "@/store/items/task";
import { useContacts } from "@/store/items/contact";
import { useMessages } from "@/store/items/message";

import type { MessageItem } from "@/store/items/interfaces/message";

const props = defineProps({
  showFilterOpts: Boolean,
})

const emit = defineEmits(["trashThread", "closeThread", 'resolveThread', 'muteThread', 'setComposeThread'])

const threads = useThreads();
const projects = useProjects();
const tasks = useTasks();
const messages = useMessages();
const contacts = useContacts();


const currentThread = computed(() => threads.getSelected())
const showArchiveMenu = ref(false)
const data = computed(() => threads.messages.data);
const viewed = ref<number>(data.value.length - 1);

const selectedMessage = computed(() =>data.value[viewed.value]);
// const threadID = computed(() => threads.selected);
const project = projects.getSelected();
// const displayAllPreviews = ref(false);
const isMuted = computed(() => currentThread.value!.status === 3);
const selectedContact = ref<number|null>(null)
const filterMenu = ref<boolean>(false)
function toggleFilterMenu() {
  filterMenu.value = !filterMenu.value;
}

function closeArchiveMenu(isArchived: boolean) {
  showArchiveMenu.value = false
  if (isArchived) {
    emit('closeThread')
  }
}

// const projectContacts = computed(() => contacts.forProject(projects.selected!))

// function revealPreviews() {
//   displayAllPreviews.value = true;
// }

// async function trashThread() {
//   await threads.del(threadID.value!);
//   threads.removeItems([threadID.value!]);
//   emit("closeThread");
// }

async function muteThread() {
  const thread = threads.getSelected();
  threads.muteBulk([thread!.id]);
}

// function toggleContact(pk: number) {
//   selectedContact.value = selectedContact.value === pk ? null : pk
// }

async function convertToTask() {
  const message = selectedMessage.value
  tasks.proposed = {
    parent_message: message!.id,
    label: message!.subject,
    category: projects.selected,
    status: 1,
  };
  await tasks.create();
  await threads.resolveBulk([threads.selected!])
  emit('closeThread')
}

function deleteThread() {
  threads.deleteBulk([threads.selected!])
  emit('closeThread')
}


// const getContactsForMessage = (rfcid: string) => {
//   console.log(rfcid)
//   const thread = threadStore.getSelected()
//   const message = thread!.messages.find((m) => m.rfcid === rfcid);
//   const sender = contactStore.items.find((c) => c.id === message!.sender);
//   sender.addressed = "to";
//   let output = [sender];
//   for (let i of ["to", "cc"]) {
//     let emails = threadStore.emails.filter((c) =>
//       message[i].includes(c.id)
//     );
//     emails = emails.map((e) => ({ ...e, addressed: "to" }));
//     output = output.concat(emails);
//   }
//   return output;
// };

const getSubjectForMessage = (rfcid: string) => {
  const thread = threads.getSelected();
  const message = thread!.messages.find((m) => m.rfcid === rfcid);
  return message!.subject;
};

const processDraft = (data: MessageItem) => {
  threads.processDraft(data);
  emit("closeThread");
};

const initialReplyData = ref('')

// watch(threadStore.transfer.target, (target) => {
//   if (target) {
//     compose.value = threadStore.current
//   }
// })

onMounted(() => {
  // if (threadStore.transfer.target) {
  //   compose.value = threadStore.current
  // }
})

// if (data.value.length === 1) {
//   selectedMessage.value = data.value[0]
// }


</script>

<template>
  <div class="flex flex-col flex-grow min-h-0">
    <div class="flex flex-row justify-between border-b">
      <div class="p-3 text-gray-500">
        <CloseItem @click="emit('closeThread')" />
      </div>
      <div class="flex flex-row pr-10 gap-2 pt-4">
        <svg @click="emit('resolveThread')" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer icon action" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>
        <svg @click="muteThread()" xmlns="http://www.w3.org/2000/svg" :class="{ active: isMuted }" class="h-6 w-6 cursor-pointer icon action" fill="none" viewBox="0 0 24 24" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" /></svg>
        <svg v-if="showFilterOpts" @click="toggleFilterMenu" class="w-6 h-6 cursor-pointer icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd"></path></svg>
        <svg @click="deleteThread()" class="w-6 h-6 cursor-pointer icon action caution" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path> </svg>
        <svg @click="showArchiveMenu = !showArchiveMenu" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 cursor-pointer icon action"><path stroke-linecap="round" stroke-linejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" /></svg>

      </div>
    </div>
    <div v-if="showArchiveMenu">
      <div class="p-4 text-lg">
        <div class="font-thin">Select a binder for: </div>
        <div>{{  data[0].subject }}</div>
      </div>
      <Archive
        :project="projects.selected!"
        :store="threads"
        @close="closeArchiveMenu"
      ></Archive>
    </div>
    <div v-else class="flex flex-col px-10 min-h-0">
      <div class="flex flex-col py-4">
        <div class="flex flex-row justify-between">
          <div class="text-2xl">{{ data[0].subject }}</div>
          <div class="flex flex-row gap-3"> 
            <div @click="convertToTask()" class="flex flex-row border p-2 px-3 btn-bold">
              <div class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3" />
                </svg>
              </div>
              <div class="">
                Add to Tasks
              </div>
            </div>
            <div @click="$emit('setComposeThread')" class="flex flex-row border p-2 px-3 btn-special">
              <div class="pr-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                </svg>
              </div>
              <div class="">
                Reply
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-2 mt-2"> 
          <div v-for="item in threads.participants" class="flex flex-col">
            <div @click=""  class="border rounded-full flex flex-row p-0.5 px-1 items-center text-sm cursor-pointer hover:bg-gray-100"> 
              <div class="border-r p-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              </div>
              <div class="p-1 pb-0.5">{{ item.name }}</div>        
            </div>
           <div v-if="selectedContact === item.contactPK" class="absolute mt-8 ml-4 border border-pink-500 bg-white p-4">
              <div v-if="typeof item.contactPK === 'string' && item.contactPK.includes('temp')">
                <div class="text-lg">{{ item.name }}</div>
                <div class="text-gray-600">{{ item.address }}</div>
                <div class="text-sm flex flex-row justify-end mt-2">
                  <div class="btn-confirm p-2 py-1 rounded uppercase"> add contact</div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div v-if="data.length > 1" class="flex flex-col min-h-0 overflow-auto divide-y border-y"> 
        <div v-for="(message, index) in data.slice().reverse()">
          <PreviewMessage
            v-if="message !== selectedMessage"
            :message="message"
            @click="viewed = index"
          />
          <ReadMessage
          v-else
          :message="selectedMessage"
          :showFilterOpts="showFilterOpts"
          :contacts="contacts.items"
          @setReply="setCompose"
          @convertToTask="convertToTask"
        />
        </div>
      </div>
      <div v-else class="flex flex-col min-h-0 overflow-auto divide-y border-y">
        <ReadMessage
          :message="selectedMessage"
          :showFilterOpts="showFilterOpts"
          :contacts="contacts.items"
          @setReply="setCompose"
          @convertToTask="convertToTask"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon {
  color: #d4d4d4;
  margin-bottom: 1px;
}
.icon.active {
  color: #e7a72f;
}
.icon:hover,
.icon.selected {
  color: #5f84b1;
}
.icon.active:hover {
  color: #e7a427;
}
.icon.caution:hover,
.icon.caution.selected {
  color: #964c4c;
}
iframe {
  max-width: 100% !important;
  width: auto !important;
  height: auto !important;
}
</style>