<script setup lang="ts">
import DeleteItem from '@/components/common/buttons/DeleteItem.vue';
import EditItem from '@/components/common/buttons/EditItem.vue';
import CompleteItem from '@/components/common/buttons/CompleteItem.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';
import FocusItem from '@/components/common/buttons/FocusItem.vue';
import type { TaskItem } from '@/store/items/interfaces/task';
import { useTasks } from '@/store/items/task';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const store = useTasks();
const router = useRouter()

const props = defineProps<{
    obj: TaskItem
}>();

const item = computed(() => store.getItem(props.obj.id));

const emit = defineEmits<{
    (e: 'openForm', pk: 'new' | number, position: number | null): void;
    (e: 'closeTask'): void;
    (e: 'showArchiveMenu') : void;
}>();

function deleteTask(pk: number): void {
    const phase = item.value.phase;
    store.del(pk);
    // store.removeLastOpenedTask()
    if (phase && props.obj.parent) {
        store.updatePhases(pk, props.obj.parent, phase);
    }
    emit('closeTask');
}

function openEditTaskForm(): void {
    emit("openForm", item.value.id, null);
}

function setFocus() {
    router.push(`/focus/${props.obj.id}`); 
}

async function handleStatusUpdate() {
    store.proposed = props.obj;
    if (store.proposed.status !== undefined && store.proposed.status < 5) {
        store.proposed.status = 5;
    } else {
        store.proposed.status = 1;
    }
    await store.save();
}

async function completeTask(): Promise<void> {
    handleStatusUpdate();
    store.clearProposed();
    if (props.obj.status === 5) {
        emit('closeTask');
    }
}
</script>

<template>
    <div class="flex flex-row justify-end gap-2 items-center">
        <DeleteItem :pk="obj.id" :onClick="deleteTask" />
        <EditItem :pk="obj.id" :onClick="openEditTaskForm" />
        <ArchiveItem v-if="!obj.parent" @click="emit('showArchiveMenu')" />
        <FocusItem :pk="obj.id" />
        <CompleteItem 
            v-if="obj.status"
            :pk="obj.id" 
            :status="obj.status" 
            :onClick="completeTask"
        />
    </div>
</template>