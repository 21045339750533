<script lang="ts" setup>

import { getHrsMinutes } from '@/utils/timeUtils'
import { ref, computed, onMounted, nextTick } from 'vue'

interface Props {
    currentTime: number
    targetValue: number | undefined
}

const emit = defineEmits<{
    (e: 'updateValue', value: number): void
    (e: 'toggleForm'): void
    (e: 'saveValue'): void
}>()


const props = defineProps<Props>()

const recordTime = ref<number>(0)
const timerIsOn = ref<boolean>(true)

const seconds = computed<number>(() => {
    return Math.floor((props.currentTime / 1000) % 60)
})

const minutes = computed<number>(() => {
    return Math.floor((props.currentTime / 1000 / 60) % 60)
})

const hours = computed<number>(() => {
    return Math.floor((props.currentTime / (1000 * 60 * 60)) % 24)
})

const days = computed<number>(() => {
    return Math.floor(props.currentTime / (1000 * 60 * 60 * 24))
})

const timer = (): void => {
    if (timerIsOn.value) {
        emit('updateValue', props.currentTime + 1000)
        setTimeout(timer, 1000)
    }
}

const formatTime = (value: number): string => {
    return value < 10 ? `0${value}` : `${value}`
}

const toggleTimer = async (): Promise<void> => {
    timerIsOn.value = !timerIsOn.value
    if (timerIsOn.value) {
        await nextTick(() => timer())
    } else {
        emit('saveValue')
    }
}

const getTimerClass = (): string => {
    if (timerIsOn.value) {
        if (recordTime.value !== 0 && props.currentTime > recordTime.value * 60000) {
            return 'overtime'
        } else {
            return 'active'
        }
    }
    return ''
}

timer()



onMounted(() => {
    if(props.targetValue) {
        recordTime.value = props.targetValue
    }
})

 
</script>

<template>
<div class="flex flex-row justify-between items-center w-full">
    <div
        id="timer"
        :class="getTimerClass()"
        class="flex flex-row items-center px-4 py-2 space-x-2 cursor-pointer"
    >
        <div @click="toggleTimer()" v-if="timerIsOn" class="text-teal-600 mb-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" /></svg>
        </div>    
        <div v-else @click="toggleTimer()" class="text-teal-600">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" > <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" ></path> </svg>
        </div>
        
        <div @click="$emit('toggleForm')" class="text-center" v-if="currentTime">
            <span v-if="hours"> {{ formatTime(hours) }}: </span>
            {{ formatTime(minutes) }} : {{ formatTime(seconds) }}
            <br />
        </div>
        <div @click="$emit('toggleForm')" v-else>00 : 00</div>
        
    </div>
    <div v-if="targetValue" class="flex flex-grow justify-end pr-2 text-gray-500 gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

        <div>
            {{ getHrsMinutes(targetValue) }}
        </div>
    </div>
</div>
</template>
