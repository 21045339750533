<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import type { CommonStore } from '@/store/items/interfaces/common'
import { useBinders } from '@/store/items/binder'

import CloseItem from './buttons/CloseItem.vue';

const binders = useBinders()

// Incoming store must have an item selected when this component is loaded.
const props = defineProps<{
    project: number,
    store: CommonStore
}>()

const emit = defineEmits(['close'])

const currentCategory = ref<number>(0); // "0" can mean top-level "project" or "root"
const selectedBinder = ref<number|null>(null);

const currentBinders = computed(() => {
  return binders.forProject(currentCategory.value);
});

/**
 * Build a breadcrumb array from the currentCategory up to the top (category=0).
 * Example path: 0 (root) -> 1 (Root Binder A) -> 3 (Child of A) -> 4 (Grandchild of A)
 */
const breadcrumbs = computed(() => {
  const trail: Array<{ id: number; name: string }> = [];
  let currentId = currentCategory.value;

  // If currentId = 0, that’s the top. We might label it "All Binders" or "Root".
  if (currentId === 0) {
    trail.push({ id: 0, name: 'All Binders' });
    return trail;
  }

  // Otherwise, walk up the chain until we reach the root
  while (true) {
    const currentBinder = binders.getItem(currentId);
    if (!currentBinder) break;

    trail.push({ id: currentBinder.id, name: currentBinder.name });
    if (currentBinder.category === 0) {
      // Once we hit a parent that’s 0, we can push "All Binders" as the top
      trail.push({ id: 0, name: 'All Binders' });
      break;
    }
    currentId = currentBinder.category
  }

  // The array is reversed (child -> parent), so reverse it to display root -> child
  return trail.reverse();
});


function openBinder(binderId: number) {
  currentCategory.value = binderId;
}

function goBack() {
  if (currentCategory.value === 0) return;
  const current = binders.getItem(currentCategory.value);
  if (current) {
    currentCategory.value = current.category;
  }
}


function goToCategory(categoryId: number) {
  currentCategory.value = categoryId;
}

function hasChildren(binderId: number): boolean {
  return binders.forProject(binderId).length > 0;
}

function confirmSelection() {
    if (selectedBinder.value) {
        props.store.updateCategory(selectedBinder.value)
        emit('close', true)
    }
}

async function loadAllDescendants(parentId: number) {
  const children = await binders.loadItems({ category: parentId });
  for (const child of children) {
    await loadAllDescendants(child.id);
  }
}

onMounted(async () => {
    loadAllDescendants(props.project)
    currentCategory.value = props.project
})

</script>

<template>
  <div class="p-4 max-w-xl mx-auto bg-white border">

     <div class="flex flex-row gap-2 items-center font-light text-lg">
        <!-- TITLE -->
        <div
            :class="{'text-blue-500 cursor-pointer': breadcrumbs.length}"
            @click=goToCategory(project)
        >
          Project Binders
        </div>

        <!-- BREADCRUMBS -->
        <span v-if="breadcrumbs.length"> &gt; </span>
        <div
            v-for="(crumb, i) in breadcrumbs"
            :key="crumb.id"
            class="text-lg"
            @click="goToCategory(crumb.id)"
        >
            {{ crumb.name }}
            <span v-if="i < breadcrumbs.length - 1"> &gt; </span>
        </div>
        <div class="ml-auto">
            <CloseItem @click="emit('close', false)"></CloseItem>
        </div>
        
    </div>

    <!-- BACK BUTTON -->
    <!-- <button
      class="px-3 py-1 bg-gray-200 rounded mr-2"
      :disabled="currentCategory === 0"
      @click="goBack"
    >
      Up One Level
    </button> -->

    <!-- BINDERS LIST: Show child binders of the currentCategory -->
    <div class="mt-3 flex flex-col gap-2">
      <div
        v-for="binder in currentBinders"
        :key="binder.id"
        class="rounded flex flex-row items-center gap-2"
      >
        <div 
          v-if="hasChildren(binder.id)"
          @click="openBinder(binder.id)"
          class="text-gray-600 cursor-pointer"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
        <div v-else class="h-5 w-5 text-3xl flex flex-row items-center justify-around text-gray-300 cursor-default">
            •
        </div>
        <div 
            @click="selectedBinder = binder.id"    
            class="pt-0.5 cursor-pointer"
            :class="{'text-teal-500' : selectedBinder === binder.id}"
            
         >{{ binder.name }}</div>
      </div>
    </div>

    <!-- CONFIRM THE CURRENT BINDER SELECTION -->
    <div class="mt-4 flex flex-row gap-2">
      <button
      :class="[
            'px-4 py-2',
            selectedBinder ? 'btn-confirm' : 'btn-disabled'
        ]"
        :disabled="!selectedBinder"
        @click="confirmSelection"
      >
        <span v-if="selectedBinder">Move to: {{ binders.getItem(selectedBinder).name}}</span>
        <span v-else>Select A Binder</span>
      </button>
      <button class="btn-outline" @click="emit('close', false)">
        Cancel
      </button>
    </div>
  </div>
</template>