<script setup lang="ts">
import { DatePicker } from "v-calendar";
import { formatDateForBackend } from "@/utils/dateUtils";
import { addOrRemoveFromArray } from "@/utils/arrayUtils";

import { useAppointments } from "@/store/items/appointment";
import { useGroups } from "@/store/items/group";
import { useProjects } from "@/store/items/project";

import SelectContacts from "./SelectContacts/SelectContacts.vue";
import Duration from "@/components/common/formFields/forTask/Duration.vue";
import ApptSelectRecurring from "@/components/common/formFields/ApptSelectRecurring.vue";

import { computed, reactive } from "vue";

// Props
const props = defineProps<{
  project: number;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();

const appts = useAppointments();
const groups = useGroups();
const projects = useProjects();

// Form
const form = reactive(appts.proposed);

// Validation
const isValidated = computed(() => !!(form.begin && form.label && form.duration));

function save() {
  if (!form.category){
    form.category = props.project
  }
  appts.save()
  emit("close");
}

async function deleteAppt() {
  await appts.del(form.id);
  emits("close");
}

function updateDuration(data: { field: string; value: number }) {
  form.duration = data.value;
}

// Selection Methods
function selectContact(pk: number) {
  form.participants = addOrRemoveFromArray(form.participants, pk);
  form.participant_groups = form.participant_groups.filter((groupPK: number) => {
    const group = groups.getItem(groupPK);
    return !group.members.includes(pk);
  });
}

function selectGroup(pk: number) {
  form.participant_groups = addOrRemoveFromArray(form.participant_groups, pk);
  const group = groups.getItem(pk);

  if (form.participant_groups.includes(pk)) {
    group.members.forEach((member: number) => {
      if (!form.participants.includes(member)) {
        form.participants.push(member);
      }
    });
  } else {
    group.members.forEach((member: number) => {
      const index = form.participants.indexOf(member);
      if (index !== -1) {
        form.participants.splice(index, 1);
      }
    });
  }
}

// function setFormOffset() {
//   if (form.begin) {
//     const formDate = new Date(form.begin);
//     const userTimezoneOffset = formDate.getTimezoneOffset();
//     form.begin = new Date(formDate.getTime() + userTimezoneOffset * 60000);
//   }
// }
// setFormOffset();
</script>

<template>
  <div class="flex flex-col gap-4 h-full">
    <!-- Topic -->
    <div class="flex flex-col">
      <div class="form-label">Topic</div>
      <input autofocus v-model="form.label" type="text" class="form-field" />
    </div>

    <!-- Project Selection -->
    <div v-if="!project" class="flex flex-col">
      <div class="form-label">Project</div>
      <select v-model="form.category" class="form-field">
        <option v-for="project in projects.items" :key="project.id" :value="project.id">
          {{ project.name }}
        </option>
      </select>
    </div>

    <!-- Date, Time, and Contacts -->
    <div class="flex flex-col w-full" style="height: 366px">
      <div class="flex flex-row gap-4 h-full">
        <!-- Date & Time -->
        <div class="flex flex-col">
          <div class="form-label">Date & Time</div>
          <DatePicker
            v-model="form.begin"
            :rules="{ minutes: { interval: 5 } }"
            :timezone="'utc'"
            mode="dateTime"
          />
        </div>

        <!-- Contacts -->
        <SelectContacts
          :project="project"
          :selected="{ contacts: form.participants, groups: form.participant_groups }"
          @selectContact="selectContact"
          @selectGroup="selectGroup"
        />
      </div>
    </div>

    <!-- Duration -->
    <div class="flex flex-col w-full">
      <Duration :data="form.duration" label="Duration" @updateField="updateDuration" />
    </div>

    <!-- Recurrence -->
    <div class="flex flex-col w-full">
      <ApptSelectRecurring
        :date="new Date(form.begin)"
        :recurrence_pattern="form.recurrence_pattern"
        :recurrence_days="form.recurrence_days"
        @update="(data: string) => {
            [form.recurrence_pattern, form.recurrence_days] = data.split('-');
        }"
      />
    </div>

    <!-- Actions -->
    <div class="flex flex-row justify-end gap-2">
      <button v-if="form.id" @click="deleteAppt" class="btn-caution">Delete</button>
      <button @click="save" :class="{ 'btn-disabled': !isValidated, 'btn-confirm': isValidated }">
        Save
      </button>
    </div>
  </div>
</template>

<style>
.vc-base-select select {
  border: none;
  padding: 0px 4px;
}
</style>