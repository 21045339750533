<script setup lang="ts">
import SearchBar from '@/components/common/search.vue';
import Loader from '@/components/common/Loader.vue';
import ListedNote from '@/components/common/listedItems/ListedNote.vue';

import type { NoteItem } from '@/store/items/interfaces/note';
import { useNotes } from '@/store/items/note';

import { getSearchResults } from '@/utils/searchUtils';

import { ref, computed } from 'vue';

const store = useNotes();


type GroupedItems = Record<string, NoteItem[]>;

const props = defineProps<{ 
  project: number; 
  loaded: number[]; 
}>();

const emit = defineEmits<{
  (event: 'openEditForm', noteId: number): void;
  (event: 'createNewNote'): void;
}>();

const searchStr = ref<string>('');

const setSearchStr = (val: string) => {
  searchStr.value = val;
};

const allItems = computed<NoteItem[]>(() => {
  const results = store.forProject(props.project);
  return getSearchResults(results, searchStr.value, 'text');
});

const recentItems = computed<NoteItem[]>(() => {
  const range = new Date();
  range.setDate(range.getDate() - 30);
  return allItems.value
    .filter(item => new Date(item.updated_at) >= range)
    .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
});

const groupedByMonth = computed<GroupedItems>(() => {
  const allItemsSet = new Set(allItems.value.map(item => item.id));
  const recentItemsSet = new Set(recentItems.value.map(item => item.id));

  const notRecentItems = allItems.value.filter(item => !recentItemsSet.has(item.id));

  const grouped: GroupedItems = {};

  notRecentItems.forEach(item => {
    const date = new Date(item.updated_at);
    const key = date.toLocaleString('default', { month: 'long' });

    if (date.getFullYear() === new Date().getFullYear()) {
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
    } else {
      const fullKey = `${key} ${date.getFullYear()}`;
      if (!grouped[fullKey]) {
        grouped[fullKey] = [];
      }
      grouped[fullKey].push(item);
    }
  });

  return grouped;
});

function formatDate(dateStr: string): string {
  return new Date(dateStr).toLocaleDateString();
}
</script>

<template>
    <div class="flex flex-col bg-white rounded shadow overflow-y-auto">
        <div class="flex flex-row justify-between border-b items-center pl-3">
            <SearchBar @runSearch="setSearchStr" />
            <div class="p-3">
                <div @click="$emit('createNewNote')" class="btn-bold">Add Note</div>
            </div>
        </div>
        <div v-if="!loaded.includes(project)" class="flex flex-col p-4">
            <Loader />
        </div>
        <div v-else-if="!allItems.length" class="flex flex-col gap-3 p-6 items-center">
            <div class="text-gray-500 select-none">No Notes for Project</div>
        </div>
        <div v-else class="flex flex-col p-4 overflow-y-auto">
            <div v-if="recentItems.length">
                <div class="bg-gray-200 p-1">Past 30 Days</div>
                <ListedNote
                    v-for="item in recentItems"
                    :key="item.id"
                    :note="item"
                    @openEditForm="$emit('openEditForm', $event)"
                />
            </div>
            <div
                v-for="(items, key) in groupedByMonth"
                :key="key"
                class="flex flex-col"
            >
                <div class="bg-gray-200 p-1">{{ key }}</div>
                <ListedNote
                    v-for="item in items"
                    :key="item.id"
                    :note="item"
                    @openEditForm="$emit('openEditForm', $event)"
                />
            </div>
        </div>
    </div>
</template>
