<script setup lang="ts">
import { useProjects } from '@/store/items/project';

import { onMounted, watch, reactive } from 'vue'

const projects = useProjects()

const props = defineProps<{
    value:number | null;
}>()

const emit = defineEmits(['updateField'])

const form = reactive({
  category: props.value !== null ? props.value : ''
})

function handleChange(e: Event) {
  const newVal = parseInt((e.target as HTMLSelectElement).value)
  const data = {field: 'category', value: newVal}
  emit('updateField', data)
}
watch(() => form.category, (newVal) => {
  const data = {field: 'category', value: Number(newVal)}
  emit('updateField', data)
})
onMounted(() => {
    projects.loadItems()
})
</script>

<template>
    <div class="flex flex-col">
      <div class="form-label">Project</div>
      <select :value="form.category" @change="handleChange" class="form-field">
        <option value="" disabled>Select A Project</option>
        <option v-for="project in projects.items" :key="project.id" :value="project.id">
          {{ project.name }}
        </option>
      </select>
    </div>
</template>

