<script>
import { ref, watch } from "vue";
import { DatePicker } from "v-calendar";

export default {
  name: "Deadline",
  props: {
    data: String,
    isChild: Boolean,
  },
  components: { DatePicker },
  setup(props, context) {
    const due_date = ref(null);
    const vCalFlag = ref(false);

    const formatDateTZ = (date) => {
      const formattedDate = new Date(date);
      const finalDate = new Date(
        formattedDate.getTime() + formattedDate.getTimezoneOffset() * 60000
      );
      return finalDate;
    };

    if (props.data !== null) {
      due_date.value = formatDateTZ(props.data).getTime();
    }

    const updateValue = async () => {
      let output = null;
      if (due_date.value !== null) {
        output = due_date.value.toISOString().substring(0, 10);
        vCalFlag.value = true;
      }
      await context.emit("updateField", { field: "due_date", value: output });
    };

    watch(
      () => due_date.value,
      async () => {
        updateValue();
      }
    );

    const deadlineOptions = {
      1: {
        label: "ASAP",
        classString: "bg-rose-800 text-white hover:bg-rose-800",
        daysAhead: 0,
        min: -10000,
      },
      2: {
        label: props.isChild ? "One Day" : "Tomorrow",
        classString: "bg-yellow-700 text-white hover:bg-yellow-700",
        daysAhead: 1,
        min: 1,
      },
      3: {
        label: props.isChild ? "One Week" : "This Week",
        classString: "bg-emerald-600 text-white hover:bg-emerald-600",
        daysAhead: 7,
        min: 2,
      },
      4: {
        label: props.isChild ? "One Month" : "This Month",
        classString: "bg-sky-800 text-white hover:bg-sky-800",
        daysAhead: 30,
        min: 8,
      },
      5: {
        label: "Whenever",
        classString: "bg-slate-700 text-white hover:bg-slate-700",
        daysAhead: null,
        min: null,
      },
    };

    function getDeadlineClass(option) {
      let str = 'chip '
      const daysAhead = Math.floor(
        (new Date(due_date.value).setHours(0, 0, 0, 0) -
          new Date().setHours(0, 0, 0, 0)) /
          (1000 * 60 * 60 * 24)
      );
      if (
        (deadlineOptions[option].daysAhead === null &&
          (due_date.value === "None" || due_date.value === null)) ||
        (deadlineOptions[option].daysAhead !== null &&
          daysAhead >= deadlineOptions[option].min &&
          daysAhead <= deadlineOptions[option].daysAhead)
      ) {
        str += deadlineOptions[option].classString;
      }
      return str;
    }

    function getDueDateClass() {
      if (!document.getElementsByClassName("deadline active").length) {
        return "text-amber-700 pl-2";
      }
    }

    function getValue(option) {
      let deadline = deadlineOptions[option].daysAhead;
      if (deadline !== null) {
        const today = new Date();
        // const todayTZ = new Date(
        //   today.getTime() - today.getTimezoneOffset() * 60000
        // );
        // deadline = new Date(todayTZ.setDate(todayTZ.getDate() + deadline));
        deadline = new Date(today.setDate(today.getDate() + deadline))

      }
      return deadline;
    }

    return {
      deadlineOptions,
      getDeadlineClass,
      getDueDateClass,
      getValue,
      due_date,
    };
  },
};
</script>

<template>
  <div class="flex flex-col select-none">
    <DatePicker v-model="due_date">
      <template v-slot="{ inputValue, togglePopover, updateValue }">
        <div class="flex flex-col">
          <span class="form-label">Deadline</span>
          <div class="flex flex-row gap-1 justify-center w-full items-center">
            <div
              v-for="(option, index) in deadlineOptions"
              @click="updateValue(getValue(index))"
              :class="getDeadlineClass(parseInt(index))"
              :key="index"
            >
              {{ option.label }}
            </div>
          </div>
          <div
            :class="getDueDateClass()"
            class="flex flex-row gap-1 items-baseline w-full justify-end py-1 text-sm"
          >
            <span class="text-black">Due Date:</span>
            <div class="flex items-center mr-1">
              <span
                v-if="inputValue && inputValue"
                @click="togglePopover()"
                class=" underline cursor-pointer"
                >{{ inputValue }}</span
              >
              <span
                v-else
                @click="togglePopover()"
                class="underline cursor-pointer"
                >Set Date</span
              >
            </div>
          </div>
        </div>
      </template>
    </DatePicker>
  </div>
</template>
