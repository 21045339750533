<script setup lang="ts">
</script>


<template>

<div class="flex flex-col flex-grow min-h-0">
    
    <div class="flex flex-row h-full w-full">

        <div class="flex flex-col h-full justify-between bg-gray-100 border-r w-48 ">

            <div class="flex flex-col text-lg overflow-scroll scrollbar scrollbar-thumb-gray-500 scrollbar-track-white divide-y border-y">
                <slot name="select-project"></slot>
            </div>

        </div> 

        <div class="h-full w-full flex flex-col min-w-0">
            <div class="flex flex-row">
                <slot name="project-nav"></slot>
            </div>
            
            <div class="flex min-h-0 flex-1 flex-row border-t">
                <slot name="panel"></slot>
            </div>    
        </div>
    </div>

</div>

</template>