<script setup lang="ts">

import AgendaFrameLayout from '@/layouts/AgendaLayouts/AgendaFrameLayout.vue';
import AgendaContentLayout from '@/layouts/AgendaLayouts/AgendaContentLayout.vue'
import NavHeader from '@/components/agenda/NavHeader/NavHeader.vue';
import SidePanel from '@/components/agenda/SidePanel/SidePanel.vue';
import Planner from '@/components/agenda/Planner/Planner.vue';
import AutoScheduleForm from '@/components/agenda/Planner/AutoScheduleForm.vue';
import TaskForm from '@/components/common/forms/TaskForm.vue';
import ViewTask from '@/components/agenda/ViewTask/ViewTask.vue';
import Schedule from '@/components/common/Schedule/Schedule.vue'
import AppointmentForm from '@/components/common/forms/AppointmentForm/AppointmentForm.vue'
import ClearSchedule from '@/components/agenda/ClearSchedule.vue'
import LoadingScreen from '@/components/common/LoadingScreen.vue'

import { getDateSlugFromStr, getDateSlugFromObj } from '@/utils/dateUtils';

import { ref, reactive, computed, onMounted, watch } from 'vue';
import { useAgenda } from "@/store/pages/agenda";
import { useRouter } from 'vue-router';
import { useRecords } from '@/store/items/record';
import { useAppointments } from '@/store/items/appointment';
import { useProjects } from '@/store/items/project';
import { useWorkdays } from "@/store/items/workday";
import CloseItem from '@/components/common/buttons/CloseItem.vue';

import type { Selections, View, ViewName } from '@/store/pages/interfaces/agenda';

const router = useRouter()
const store = useAgenda();
const records = useRecords();
const appointments = useAppointments()
const projects = useProjects()
const workdays = useWorkdays()

const slugStr = computed(() => router.currentRoute.value.params.slug )
const slugDate = computed(() => slugStr.value !== 'current' ? getDateSlugFromStr(slugStr.value as string) : getDateSlugFromObj(new Date()))

const selected = reactive<Selections>({
    task: null,
    project: null,
    workday: null,
});

const projectName = computed(() => {
    return selected.project ? projects.getItem(selected.project).name : ""
})

function selectItem(item: keyof Selections, pk: number | null) {
    selected[item] = pk;
}

const view = reactive<View>({
  center: 'planner',
  right: 'schedule'
});

const setView = (position: 'center' | 'right', label: ViewName) => {
  view[position] = label
}

const dataLoaded = ref(false)
const loadingText = ref('Loading Agenda')

async function autoSchedule(data) {
  loadingText.value = "Scheduling Your Week"
  dataLoaded.value = false
  await store.autoSchedule(data);
  setView('center', 'planner');
  dataLoaded.value = true;
}
const workday = computed(() => workdays.items.find(i => i.id === selected.workday))
const recordItems = computed(() => records.items.filter(i => i.workday === selected.workday))

const date = computed(() => {
  if (workday.value) {
    // Use the workday date as is, without timezone adjustment
    return new Date(workday.value.date + "T00:00");
  } else {
    // Adjust current date to local timezone
    const currentDate = new Date();
    const userTimezoneOffset = currentDate.getTimezoneOffset();
    return new Date(currentDate.getTime() - userTimezoneOffset * 60000);
  }
});

function updateSelected (dateStr: string) {
  const currentWorkday = workdays.items.find(i => i.date === dateStr)  
  if (currentWorkday) {
    selected.workday = currentWorkday.id
  }
}

const minutesPlanned = computed(() => {

  let minutes = recordItems.value.reduce(
  (n, { minutes_planned, minutes_worked, completed }) =>
    n + ((completed && minutes_worked > 0) ? minutes_worked : minutes_planned),
  0
);
  if (workday.value){
    appointments.appointmentsForDay(date.value).forEach(function (appt) {
      var difference =
        new Date(appt.end).getTime() - new Date(appt.begin).getTime();
      minutes += Math.round(difference / 60000);
    });
  }
  return minutes;
});

const loadAgenda = async () => {
  await store.loadData(slugDate.value);
}

function getCurrentDateString() {
  return date.value.toISOString().split('T')[0];
}

function getParsedDateString(slug: string) {
  return new Date(Date.parse(slug)).toISOString().split('T')[0]
}

onMounted(async () => {
  await loadAgenda()  

  const dateStr = slugStr.value === 'current' 
      ? getCurrentDateString()
      : getParsedDateString(slugStr.value as string);
      
  updateSelected(dateStr)
  dataLoaded.value = true
  await store.loadTasks()
})

watch(() => slugStr.value, async() => {
    view.center = 'planner'
    view.right = 'schedule'
    loadingText.value = "Loading Agenda"
    dataLoaded.value = false
    await loadAgenda()
    dataLoaded.value = true
    selected.workday = workdays.items[0].id
});

</script>

<template>
<LoadingScreen
   v-if="!dataLoaded"
   :text="loadingText"
 ></LoadingScreen>
<AgendaFrameLayout v-else>
    
    <template #header>
      <NavHeader
          :selected="selected"
          :dataLoaded="dataLoaded"
          @setView="setView"
          @selectItem="selectItem"
      ></NavHeader>
    </template>

    <template #sidePanel>
      <SidePanel
        :selected="selected"
        :minutesPlanned="minutesPlanned"
        :dataLoaded="dataLoaded"
        @setView="setView"
        @selectItem="selectItem"
      />
    </template>

    <!-- Main Content Slot -->
    <template #main>
      <AgendaContentLayout
        :view="view"
      >
        <!-- Pass Content to AgendaContentLayout Slots -->
        <template #planner> 
          <Planner
            :workdayID="selected.workday"
            :recordItems="recordItems"
            :minutesPlanned="minutesPlanned"
            @selectItem="selectItem"
            @setView="setView"
          />
        </template>
        
        <template #autoSch>
          <AutoScheduleForm
            @setView="setView"
            @autoSchedule="autoSchedule"
            :slug="slugStr"
          />
        </template>

        <template #newTask>
          <div class="flex flex-row gap-2 items-center justify-between">
            
            <div class="flex flex-row w-96 items-center gap-2 ">
              <div class="uppercase text-sm">New task for:</div>
              <div>{{ projectName }}</div>
            </div>
          
            <div class="flex flex-row w-full justify-end">
              <CloseItem @click="setView('center', 'planner')" />
            </div> 

          </div>
          <TaskForm
            :position="'root'"
            @close="setView('center', 'planner')" 
          />
        </template>
        <template #viewTask>
          <ViewTask 
            :pk="selected.task!"
            :workday="selected.workday!"
            @close="() => { setView('center', 'planner'); selected.task = null; }"
          />
        </template>

        <template #apptForm>
          <div class="flex flex-row w-full justify-end">
            <CloseItem @click="setView('right', 'schedule')" />
          </div> 
          <AppointmentForm
            :workday="selected.workday"
            :project="selected.project"
            @close="setView('right', 'schedule')"
          ></AppointmentForm>
        </template>
        <template #schedule>
          <div>
            <Schedule 
              :date="date"
              @openForm="setView('right', 'apptForm')"
            /></div>
        </template>
        <template #clearSchedule>
            <ClearSchedule
              :workday="workday!"
              @close="setView('center', 'schedule')"
            ></ClearSchedule>
        </template>
      </AgendaContentLayout>
    </template>
</AgendaFrameLayout>
</template>

<style scoped>

</style>