import { createStore } from "@/store/items/common/index.js"
import { dateTimeActions } from "./dateTimeActions";
import { setterActions } from "./setterActions";
import { agendaActions } from "./agendaActions";
import { formatActions } from "./formatActions";

const template = {
  label: "",
  begin: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 8, 0, 0, 0)), // 8:00 AM UTC
  duration: 30,
  participants: [],
  participant_groups: [],
  datesLoaded: [],
  task: null
};

const customState = {
  description: "",
  labelPipe: "",
  timezone: "UTC",
  agenda: [],
  clipboard: null,
};

// Define and export the store
export const useAppointments = createStore({
  model: "appointment", 
  template, 
  customState: customState,
  customActions: {
    ...dateTimeActions,
    ...setterActions,
    ...agendaActions,
    ...formatActions,
  },
});