<script setup lang="ts">

import { ref, computed } from 'vue';

import { useRecords } from '@/store/items/record';
import { useProjects } from '@/store/items/project';
import { useTasks } from '@/store/items/task';

import TasksForProject from './TasksForProject.vue';

const records = useRecords()
const projects = useProjects()
const tasks = useTasks()

const props = defineProps<{
    selected: {
        workday: number | null;
        task: number | null;
        project: number | null;
    };
    minutesPlanned: number;
    dataLoaded: boolean
}>();

const emit = defineEmits(['setView', 'selectItem']);

const filterOptions = [
    {'label': "Available", id: 1},
    {'label': "Scheduled", id: 0},
    {'label': "Needs Reply", id: 2},
    {'label': "On Hold", id: 3},
    {'label': "Done", id: 5}
]

const selectedFilter = ref(1)

function setSelectedFilter(id: number){
    selectedFilter.value = id
}

const recordItems = computed(() => {
    if (props.selected.workday === null) {
        return [];
    }
    return records.forWorkday(props.selected.workday);
});

const openTaskForm = (task: number| 'new', category: number) => {
    emit('selectItem', 'project', category);
    if (task === 'new') {
        tasks.clearProposed()
        tasks.proposed.category = category;
        emit('selectItem', 'task', null);
        emit('setView', 'center', 'newTask');
    } else {
        tasks.setSelected(task);
        emit('selectItem', 'task', task);
        emit('setView', 'center', 'viewTask');
    }
}

</script>

<template>
<div class="flex flex-col flex-grow gap-2">
    <div class="text-sm">
        <div class="flex flex-row justify-between pl-1">
            <div 
            v-for="item in filterOptions"
                :key="item.id"
                class="cursor-pointer select-none border p-1 px-2 rounded"
                v-bind:class="{'bg-teal-700 text-white': item.id === selectedFilter}"
                @click="setSelectedFilter(item.id)"
            >
            {{ item.label }}
        </div>
    </div>  
    </div>
    <div class="flex flex-col border min-h-0 " v-for="project in projects.items">
        <TasksForProject
          :project="project"
          :workday="selected.workday"
          :records="recordItems"
          :minutesPlanned="minutesPlanned"
          :statusFilter="selectedFilter"
          :dataLoaded="dataLoaded"
          @openTaskForm="openTaskForm"
        ></TasksForProject>
    </div>
</div>

</template>