<script setup lang="ts">
import { splitText } from '@/utils/textUtils';
import { computed } from 'vue';

import type { NoteItem } from '@/store/items/interfaces/note'

const props = defineProps<{
  note: NoteItem;
}>();

const emit = defineEmits<{
  (event: 'openEditForm', noteId: number): void;
}>();

function formatDate(dateStr: string): string {
  return new Date(dateStr).toLocaleDateString();
}

const previewSet = computed<string[]>(() => {
    return splitText(props.note.text);
});

</script>

<template>
  <div
    @click="$emit('openEditForm', note.id)"
    class="flex flex-col p-2 cursor-pointer hover:bg-gray-100"
  >
    <div class="flex flex-row justify-between">
      <div class="text-sm font-black">
        {{ previewSet[0] }}
      </div>
    </div>
    <div class="flex flex-row gap-3">
      <div class="text-sm text-gray-600">
        {{ formatDate(note.updated_at) }}
      </div>
      <div class="text-sm text-gray-400">
        {{ previewSet[1] }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.cursor-pointer {
  transition: background-color 0.2s ease-in-out;
}
.hover\:bg-gray-100:hover {
  background-color: #f5f5f5;
}
</style>
