<script setup lang="ts">

import { computed } from 'vue'

const props = defineProps<{
    name: string,
    email: string,
    selection: string
}>()

const emit = defineEmits(['select', 'saveOmitFilter'])

const cleanedName = computed(() => {
    return props.name === 'You' ? '' : props.name.replace(/['"]+/g, '')
})

</script>

<template>
    <div class="flex flex-row justify-center items-end gap-4">
        <div class="text-xl">{{ cleanedName }}</div>
        <div class="flex flex-row">
        <div>&lt;</div><div v-html="email"></div><div>&gt;</div>
        </div>
    </div>
    
    <div class="flex flex-row justify-between items-center gap-8 border-b w-full pb-4"> 
        <div></div>
        <div class="flex flex-row gap-4 items-center pt-2 select-none">
            <div>Are messages from this sender relevant to your work?</div>
            <div 
              @click="emit('select', 'no')" 
              :class="[selection === 'no' ? 'bg-red-700 text-white':'text-red-700']"
              class="border p-1 w-16 text-center  border-red-700 cursor-pointer select-none"
            >
            No
            </div>
            <div 
            @click="emit('select', 'yes')" 
            :class="[selection === 'yes' ? 'bg-emerald-800 text-white':'text-green-700']"
            class="border p-1 w-16 text-center border-green-700 cursor-pointer select-none"
            >
            Yes
            </div>
        </div>
        <div class="flex flex-row items-center w-12">
            <div 
            v-if="selection==='no'" 
            @click="emit('saveOmitFilter')"
            class="p-1 mr-3 border hover:bg-gray-100 cursor-pointer flex flex-row items-center mt-2"
            >
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </div>
        </div>                
    </div>

</template>