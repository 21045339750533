import { createStore } from "@/store/items/common/index.js";
import { crudActions } from "./actions/crudActions";

const template = {
  category: null,
  parent: null,
  distance: "MONTHLY",
  interval: 1,
  minutes_allotted: 30,
  enthusiasm: 0, 
  lead_time: 0,
};

const customStore = {
  defaults: {},
}

const customActions = {
  ...crudActions,

  setDefaultLabel(label) {
    this.defaults.label = label
  },

}

export const useRoutines = createStore({
  model: "routine",
  template,
  customStore,
  customActions,
})
