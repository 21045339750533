<script setup lang="ts">

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useTasks } from '@/store/items/task'

import Archive from '@/components/common/Archive.vue';
import ArchivedTask from './ArchivedTask.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';

const store = useTasks()

const props = defineProps<{
    project: number,
    category: number
}>()

const tasks = computed(() => store.forProject(props.category).filter(item => !item.parent))
const goals = computed(() => store.goalsForProject(props.category))

function closeSelected() {
    store.selected = null
}

function deleteItem(pk:number) {
    store.del(pk)
    closeSelected()
}

const showArchiveMenu = ref<boolean>(false)

function closeArchiveMenu(isArchived: boolean) {
    showArchiveMenu.value = false
    if (isArchived) {
        closeSelected()
    }
}

onMounted(() => {
    store.loadItems({'category': props.category})
})

onBeforeUnmount(() => {
    closeSelected()
})

const selectedItem = computed(() => store.getSelected())

</script>

<template>

<div v-if="!store.selected" class="flex flex-col flex-grow gap-4">
    <div v-if="goals.length" class="flex flex-col">
        <div class="text-sm border-b text-gray-600">Goals</div>
        <div 
            v-for="item in goals"
            class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
            @click="store.selected = item.id"
        >
          {{ item.label }}
        </div>
    </div>
    <div v-if="tasks.length" class="flex flex-col">
        <div class="text-sm border-b text-gray-600">Tasks</div>
        <div 
            v-for="item in tasks"
            class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
            @click="store.selected = item.id"
        >
          {{ item.label }}
        </div>
    </div>
</div>

<div v-else class="flex flex-col">
    
    <div class="flex flex-row justify-between pb-2">
      <div>{{ selectedItem!.label }}</div>
      <CloseItem @click="closeSelected" />
    </div>

    <Archive 
        v-if="showArchiveMenu"
        :project="project"
        :store="store"
        @close="closeArchiveMenu"
    />

    <ArchivedTask 
      v-else
      :item="selectedItem!"
      @delete="deleteItem"
      @showArchiveMenu="showArchiveMenu = true"
    ></ArchivedTask>

</div>


</template>