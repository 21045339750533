<script setup lang="ts">
import { format, startOfDay } from 'date-fns';
import { ref, computed } from 'vue';
import { useAppointments } from '@/store/items/appointment';
import { getSearchResults } from '@/utils/searchUtils';

import AppointmentSection from './AppointmentSection.vue'
import SearchBar from "@/components/common/search.vue";

const appts = useAppointments();

// Props and emits
const props = defineProps<{ project: number; loaded: number[] }>();
const emit = defineEmits(['openForm', 'openArchive']);

// Reactive search string
const searchStr = ref('');
const setSearchStr = (val: string) => (searchStr.value = val);

// Open forms
const openNewForm = () => {
  appts.clearProposed();
  emit('openForm');
};

const openEditForm = (pk: number) => {
  appts.setSelected(pk);
  emit('openForm');
};

const showArchiveMenu = (pk: number) => {
  emit('openArchive', pk)
}

// Filtered items based on project and search string
const items = computed(() => 
  getSearchResults(appts.forProject(props.project), searchStr.value, 'label')
);

// Date utilities
const currentDate = new Date();
const getStartOfDay = (date: Date) => new Date(date.setHours(5, 0, 0, 0));
const getStartOfNextWeek = (date: Date) => new Date(getEndOfThisWeek(date).getTime() + 86400000);
const getEndOfThisWeek = (date: Date) => new Date(date.setDate(date.getDate() + (7 - date.getDay())));
const getEndOfNextWeek = (date: Date) => new Date(getStartOfNextWeek(date).getTime() + 6 * 86400000);

// Appointment filters
const isWithinRange = (appointment: any, start: Date, end: Date) => {
  const endDate = toLocalEquivalent(appointment.end);
  return endDate >= start && endDate <= end;
};


function toLocalEquivalent(dateString: string): Date {
  const utcDate = new Date(dateString);
  const localDate = new Date();
  localDate.setFullYear(utcDate.getUTCFullYear());
  localDate.setMonth(utcDate.getUTCMonth());
  localDate.setDate(utcDate.getUTCDate());
  localDate.setHours(utcDate.getUTCHours(), utcDate.getUTCMinutes(), utcDate.getUTCSeconds(), utcDate.getUTCMilliseconds());
  return localDate;
}

// Recent appointments (e.g., those that started just a moment ago but within "recent" range).
const recentAppointments = computed(() => {
  const now = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(now.getDate() - 7);

  return items.value.filter(event => {
    const eventLocalDate = toLocalEquivalent(event.end);
    return eventLocalDate >= sevenDaysAgo && eventLocalDate < now;
  });
});

const appointmentsThisWeek = computed(() =>
  items.value.filter(appt => isWithinRange(appt, new Date(), getEndOfThisWeek(new Date())))
);

const appointmentsNextWeek = computed(() =>
  items.value.filter(appt => isWithinRange(appt, getStartOfNextWeek(new Date()), getEndOfNextWeek(new Date())))
);

const appointmentsBeyond = computed(() =>
  items.value.filter(appt => new Date(appt.begin) > getEndOfNextWeek(new Date()))
);

const expanded = ref<number | null>(null)
function expandItem(pk: number) {
    expanded.value = expanded.value === pk ? null : pk
}

</script>

<template>
  <div class="flex flex-row justify-between border-b items-center pl-3">
    <SearchBar @runSearch="setSearchStr" />
    <div class="p-3">
      <div @click="openNewForm" class="btn-bold">Add Meeting</div>
    </div>
  </div>
  <div v-if="!loaded.includes(project)">
    <Loader />
  </div>
  <div v-else class="flex flex-col p-4 overflow-y-auto">
    <AppointmentSection
      title="Recent"
      :appointments="recentAppointments"
      :project="project"
      :expanded="expanded"
      :inPast="true"
      @edit="showArchiveMenu"
      @expand="expandItem"
    />
    <AppointmentSection
      title="This Week"
      :appointments="appointmentsThisWeek"
      :project="project"
      :expanded="expanded"
      :inPast="false"
      @edit="openEditForm"
      @expand="expandItem"
    />
    <AppointmentSection
      title="Next Week"
      :appointments="appointmentsNextWeek"
      :project="project"
      :expanded="expanded"
      :inPast="false"
      @edit="openEditForm"
      @expand="expandItem"
    />
    <AppointmentSection
      title="2+ Weeks"
      :appointments="appointmentsBeyond"
      :project="project"
      :expanded="expanded"
      :inPast="false"
      @edit="openEditForm"
      @expand="expandItem"
    />
  </div>
</template>
