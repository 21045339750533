import { ref } from 'vue';
import { useAppointments } from '@/store/items/appointment';

export function useEntryDragAndDrop() {
  const draggedEntry = ref< number  | null>(null);
  const draggedIndex = ref<number | null>(null);
  const appts = useAppointments();

  function onEntryDragStart(event: DragEvent, entryIndex: number, entry: number) {
    draggedEntry.value = entry;
    draggedIndex.value = entryIndex;
    event.dataTransfer?.setData('text/plain', `${entryIndex}`);
  }

  function onEntryDrop(event: DragEvent, targetIndex: number, section: number, entry: number) {
    event.preventDefault();
    if (!draggedEntry.value) return;
    appts.updateEntryOrder(draggedIndex.value!, targetIndex, section, draggedEntry.value);

    draggedEntry.value = null; // Reset dragged entry
  }

  function onEntryDragOver(event: DragEvent) {
    event.preventDefault(); // Allow drop
  }

  return {
    draggedEntry,
    onEntryDragStart,
    onEntryDrop,
    onEntryDragOver,
  };
}