<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import { useAppointments } from '@/store/items/appointment';
import { parseToLocal } from '@/utils/dateUtils';

import 'vue-simple-calendar/dist/style.css';
// import 'vue-simple-calendar/dist/css/default.css';

// Import your store. We assume 'useAppointments' returns an object
// with 'items' and an 'interact' function.
const store = useAppointments();

// Track the currently visible date in the calendar.
// If you don't know what type the date is (string or Date), you can union them.
const showDate = ref<Date | string | null>(null);

// If needed, you can define a type for your store items, e.g.:
// interface Appointment {
//   id: number;
//   begin: string;
//   end: string;
//   label: string;
// }
// Then cast store.items as Appointment[] below, depending on how it's typed in your store.

const appointments = computed(() => store.items);

async function loadItems() {
  const response = await store.interact('fetch');
  store.items = response.results;
}

// Call loadItems() when component mounts
onMounted(() => {
  loadItems();
});

/**
 * Format a schedule time range from 'begin' to 'end' as "HH:MM AM/PM - HH:MM AM/PM"
 */
function getSchedule(begin: string | Date, end: string | Date) {
  const beginDate = new Date(begin);
  const endDate = new Date(end);
  const beginStr = beginDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  const endStr = endDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${beginStr} - ${endStr}`;
}

// Build the array of items for vue-simple-calendar
const showItems = computed(() => {
  const output: Array<{
    id: number;
    title: string;
    startDate: Date;
    endDate: Date;
    classes: string[];
    style: string;
  }> = [];

  if (store.items && store.items.length) {
    store.items.forEach((item: any) => {
      output.push({
        id: item.id,
        title: `${getSchedule(item.begin, item.end)}: ${item.label}`,
        startDate: parseToLocal(item.begin),
        endDate: parseToLocal(item.end),
        classes: ['flex', 'flex-row', 'p-2'],
        style: 'font-size: 12px; white-space: normal;',
      });
    });
  }
  return output;
});

/**
 * Handler to update our showDate ref, passed to the calendar's @input event.
 */
function setShowDate(d: Date | string) {
  showDate.value = d;
}

</script>

<template>
    <div id="frame" class="bg-white p-6">
          <calendar-view
            :showDate="showDate"
            :items="showItems"
            itemTop="1.9em"
            class="theme-default holiday-us-traditional holiday-us-official"
          >
              <template #header="{ headerProps }">
                  <calendar-view-header
                      :header-props="headerProps"
                      @input="setShowDate" />
              </template>
          </calendar-view>
    </div>
  </template>
  
  <style>
  #frame {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  </style>
  