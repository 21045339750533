
<script setup lang="ts">

import type { PhaseItem } from '@/store/items/interfaces/task';
import { ref } from 'vue';
import ListedTask from '@/components/common/listedItems/ListedTask.vue';
import PhaseDisplayHeader from './PhaseDisplayHeader.vue'
import PhaseForm from './PhaseForm/PhaseForm.vue'
import { useTasks } from '@/store/items/task'

const store = useTasks()

const props = defineProps<{
    index: number;
    phase: PhaseItem;
    taskStatus: number;
    goalID: string;
}>();

const emit = defineEmits(['openForm'])

function openNewTaskForm(){
    store.clearProposed()
    store.formOwner = props.phase.id
    store.proposed.phase = props.phase.id
    store.proposed.parent = props.phase.parent
    emit('openForm', 'new', props.phase.order)
}

const formIsOpen = ref<boolean>(false)

function openForm() {
    formIsOpen.value = !formIsOpen.value
}

function openTask(pk: number) {
    if (!store.opened[props.goalID]) {
        store.opened[props.goalID] = [];
    }
    store.opened[props.goalID].push(pk);
}

const styleClass = ref(props.taskStatus ? 'border-red-500' : '')

async function updatePhase(payload: { days_to_deadline: number | null; due_date: string | null }) {
    if (!payload.days_to_deadline && !payload.due_date) {
        styleClass.value = "border-red-500";
    } else {
        styleClass.value = "";
        const finalPayload = { ...payload, id: props.phase.id };
        await store.updatePhase(finalPayload);
        formIsOpen.value = false;
    }
}

function startDrag(evt: DragEvent, taskPK: number) {
    if (evt.dataTransfer) {
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('pk', taskPK.toString());
    }
}
</script>

<template>        
    <PhaseDisplayHeader
        :index="index"
        :phase="phase"
        :taskStatus="taskStatus"
        @openForm="openForm"
    />
   <div v-if="!formIsOpen" class="flex flex-col gap-2">
        <ListedTask
            v-for="task in store.items.filter(item => item.phase === phase.id)"
            :key="task.id"
            :pk="task.id"
            :location="`${goalID}`"
            :active="task.status === 1"
            @actionA="openTask"
            @actionB="openTask"
            draggable="true"
            @dragstart="startDrag($event, task.id)"
        />
        <div @click="openNewTaskForm()" class="text-sm p-2 text-gray-500 hover:text-gray-600 cursor-pointer hover:bg-gray-100 border">
            + Add Task
        </div>
    </div>
             
    <div v-else>
        <PhaseForm
            :styleClass="styleClass"
            :dueDate="phase.due_date"
            :daysToDeadline="phase.days_to_deadline"
            :label="phase.label"
            @submit="updatePhase"
            @close="formIsOpen = false"
        ></PhaseForm>
    </div>
</template>