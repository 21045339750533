<template>
  <div class="flex flex-col flex-grow min-h-0 font-sofia bg-gray-100">
    <div class="flex flex-row flex-grow min-h-0 ">
      <div class="flex flex-col w-3/4 flex-grow min-h-0 ">

        <!-- Header Section-->
        <div class="flex flex-row p-5 mt-5 ml-5 border-b justify-between items-center h-13 bg-white text-lg text-gray-600 rounded shadow">
          <slot name="header"></slot>
        </div>

        <div class="flex flex-row flex-grow min-h-0">

          <!-- Appointment Section -->
          <div class="flex flex-col w-1/3 p-5 gap-4 flex-grow min-h-0">
            <slot name="appointment"></slot>
          </div>

          <div class="flex flex-col flex-grow w-2/3 gap-4 py-5 min-h-0">
            <div class="p-6 flex flex-col flex-grow bg-white rounded gap-5 shadow min-h-0">
              <slot name="records"></slot>
            </div>
          </div>

        </div>
      </div>

      <div class="flex flex-col gap-4 w-1/4 p-5">

        <div class="p-5 bg-white flex flex-col flex-grow rounded shadow min-h-0">
          <slot name="projects"></slot>
        </div>
        
      </div>

    </div>
  </div>
</template>