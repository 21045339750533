<script setup lang="ts">

import TabbedSections from '@/layouts/TabbedSections.vue'
import AppointmentsView from './AppointmentsView/AppointmentsView.vue'

const props = defineProps<{
    project: number,
    task: number,
}>()

// Define the tabs with their labels and associated components
const tabs = [
    {
        label: 'Meetings',
        component: AppointmentsView,
        props: { project: props.project, task: props.task },

    },
];

</script>

<template>
    <TabbedSections :tabs="tabs" />
</template>