<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'
import { useNotes } from '@/store/items/note'

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import DisplayNote from '../../NotesView/DisplayNote.vue';

const store = useNotes()

const props = defineProps<{
    category: number
}>()

const items = computed(() => store.forProject(props.category))

const selected = computed(() => store.getSelected())

onMounted(() => {
    store.loadItems({'category': props.category})
})

</script>

<template>

<div v-if="!store.selected" class="flex flex-col flex-grow">
    <div 
      v-for="item in items"
      class="flex flex-col p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
      @click="store.selected = item.id"
    >
        <div class="font-black">{{ item.title }}</div>
    </div>
</div>

<div v-else class="flex flex-col gap-3 h-full">
    <div class="flex flex-row justify-between">
        <div class="font-black">{{ selected.title }}</div>
        <CloseItem @click="store.clearSelected()" />
    </div>
    <div class="flex h-full">
        <DisplayNote
          :project="category"
          :showActions="false"
        ></DisplayNote>
    </div>
    
</div>


</template>