import { addOrRemoveFromArray } from "@/utils/arrayUtils";
import { createStore } from "@/store/items/common/index.js";

const template = {
  'title': "",
  'url': ""
}

const customActions = {
  async toggleForCurrentTask(resourceID, taskID, categoryID) {
    const data = {
      id: resourceID,
      task: taskID,
      command: "toggle_task",
      project: categoryID,
    };
    await this.interact("update", data);
    const thisResource = this.items.find((item) => item.id === resourceID);
    thisResource.tasks = addOrRemoveFromArray(thisResource.tasks, taskID);
  }
}

export const useResources = createStore({
  model: "resource",
  template,
  customActions,
})
