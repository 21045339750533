import { mergeArrays } from '@/utils/arrayUtils'
import { useRecords } from '@/store/items/record'

const crudActions = {
    async create() {
        const data = await this.interact('create', this.proposed)
        this.items.push(data)
        const parent = this.items.find(i => i.id === data.parent)
        if (parent) {
            parent.children.push(data.id)
        }
        this.proposed = { ...this.blank }
        return data
    },
    async getChildren(pk) {
        const response = await this.customInteract("filter", "task-descendants", { pk: pk });
        this.items = mergeArrays(this.items, response.results, "id");
        return response.results;
    },
    async save() {
        let payload
        if (this.proposed.id) {
            const status = this.proposed.status
            payload = await this.overwrite()
            if (status == 5) {
                this.refreshRecords(this.proposed.id)
            }
        } else {
            payload = await this.create()
        }
        if (payload.parent || payload.children.length) {
            await this.refreshDescendants(payload.id)
            await this.refreshAncestors(payload.id)
        }
        this.setItem(payload.id, payload)
        return payload
    },
    async saveAsGoal() {
        this.proposed.is_goal = true
        const payload = await this.save()
        return payload
    },
    async del(pk) {
        if (this.getItem(pk).routine) {
            alert('This Task is managed by a Routine and therefore cannot be deleted. Please delete or modify the associated routine instead.')
        } else if (window.confirm(`Permanently delete this task?`)) {
            await this.interact("delete", pk);
            const targetIndex = this.items.findIndex((item) => item.id === pk);
            this.items.splice(targetIndex, 1);
            const records = useRecords()
            records.items = records.items.filter((item) => item.task !== pk);
        }
    },
    async refreshAncestors(pk) {
        const results = await this.customInteract('filter', 'task-ancestors', { pk: pk })
        this.items = mergeArrays(this.items, results.results, "id");
    },
    async refreshDescendants(pk) {
        const targetPK = this.returnTopAncestorPK(pk)
        const results = await this.customInteract('filter', 'task-descendants', { pk: targetPK })
        this.items = mergeArrays(this.items, results.results, "id");
    },
    async refreshRecords(pk) {
        const records = useRecords()
        records.items = records.items.filter(record => record.task == pk);
        records.loadItems({task: pk})
    },
    async setGoalDelay(pk, value) {
        const payload = {
            goal: pk,
            value: value
        }
        const data = await this.customInteract('update', 'set_goal_delay', payload)
        this.mergeItems(this.items, data.tasks)
        const goal = this.getItem(pk)
        goal.phases = mergeArrays(goal.phases, data.phases)
    },
    async createPhase(data) {
        const result = await this.customInteract('create', 'phase', data)
        const parent = this.getItem(data.parent)
        parent.phases.push(result)
        parent.final_deadline = result.parent_deadline
        return result
    },
    async updatePhase(data) {
        const result = await this.customInteract('update', 'phase', data)
        const parent = this.getItem(result.parent)
        const phaseIndex = parent.phases.findIndex(phase => phase.id === result.id);
        parent.phases[phaseIndex] = result;
        parent.final_deadline = result.parent_deadline
        return result
    }
}
export { crudActions };