import { createStore } from '@/store/items/common/index.js';
import { mergeArrays } from '@/utils/arrayUtils';

const template = { id: null, task: null, text: "", designation: "" };

const customState = {
  prompts: {
    'before-1': "What would the ideal output or result from your work on this task look like?",
    'before-2': "What are the most important factors contributing to achieving this ideal output?",
    'before-3': "Among these factors, which ones are within your control, and how do you plan to manage them?",
    'before-4': "For the factors that are outside your control, what strategies can you put in place to mitigate their impact?",
    'before-5': "What logical assumptions are required to make this task relevant to your broader goals?",
    'after-1': "What barriers prevented you from reaching your full potential?",
    "after-2": "What actions could you have taken to better set yourself up for success?",
    "after-3": "What lessons can you take from your work on this task?",
    "after-4": "Have you observed any new obstacle or barriers that will present significant delays or challenges to the resolution of this project's goals?"
  }
};

const customActions = {
  async loadItems(task) {
    const response = await this.interact("filter", { task: task });
    if (response.data.results.length) {
      this.items = mergeArrays(this.items, response.data.results, "id");
    }
    return response.data;
  }
};

export const useCapsules = createStore({
  model: "capsule",
  template,
  customState,
  customActions
}); 