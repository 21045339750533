<script setup lang="ts">

import type { TaskItem } from '@/store/items/interfaces/task';

import { computed } from 'vue';
import { useTasks } from '@/store/items/task';
import { getHrsMinutes } from '@/utils/timeUtils';

const props = defineProps<{
  obj: TaskItem;
}>();

const store = useTasks();

const workRemaining = computed(() => {
  if (props.obj.status === 5) {
    return "COMPLETED";
  } else {
    const minutesRemaining = store.getMinutesRemaining(props.obj.id);
    return getHrsMinutes(minutesRemaining);
  }
});

</script>

<template>
    <div class="flex flex-col">
        <div class="font-thin">Status:</div>
        <div>{{ obj.status_verbose }}</div>
    </div>
    <div class="">
        <div class="font-thin">Due Date:</div>
        <div>{{ props.obj.due_date || "--" }}</div>
    </div>
    <div class="">
        <div class="font-thin">Remaining:</div>
        <div>{{ workRemaining }}</div>
    </div>
</template>