<script setup lang="ts">

import { ref, nextTick } from 'vue'

import type { AgendaEntryItem } from '@/store/items/interfaces/appointment';

const props = defineProps<{
    items: AgendaEntryItem[]; // Adjust based on the structure of `items`
    sectionID: number;
    sectionIndex: number;
    isDragging: boolean;
}>();


const newItems = ref<Record<number, string>>({})

const isDraggingThisSection = ref(false)

const emit = defineEmits(['addItem', 'reposition', 'setDrag', 'deleteEntry'])

function addNew(sectionIndex: number) {
    const text = newItems.value[sectionIndex]?.trim(); // Trim whitespace
    if (!text) return;
    const trimmedText = text.slice (0, 64); // Limit to 64 characters
    emit('addItem', sectionIndex, trimmedText)
    newItems.value[sectionIndex] = "";
}

function refocus(target: HTMLElement) {
    nextTick().then(() => {
        target.focus()
    })
}

function handleKeyDown(event: KeyboardEvent, sectionIndex: number) {
    const inputElement = event.target as HTMLElement;
    if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        addNew(sectionIndex);
        refocus(inputElement);
    }
}

let draggedIndex: number | null = null;
let placeholder: HTMLElement | null = null;
function onDragStart(event: DragEvent, index: number): void {
    emit('setDrag', true);
    isDraggingThisSection.value = true;
    draggedIndex = index;

    if (event.dataTransfer) {
        event.dataTransfer.effectAllowed = 'move';
        const dragImage = document.getElementById(`section-${props.sectionID}-entry-${index}`);
        if (dragImage) {
            const clone = dragImage.cloneNode(true) as HTMLElement;
            clone.style.position = 'absolute';
            clone.style.top = '-9999px';
            document.body.appendChild(clone);
            event.dataTransfer.setDragImage(dragImage, 0, 0);
            event.target?.addEventListener('dragend', () => {
                document.body.removeChild(clone);
            }, { once: true });
        }
    }
}

function onDragEnd(): void {
    if (placeholder) placeholder.remove();
    draggedIndex = null;
    isDraggingThisSection.value = false;
}

function onDragOver(event: DragEvent, index: number): void {
    event.preventDefault();

    if (!props.isDragging || !isDraggingThisSection.value) return;

    const sectionElement = document.getElementById(`section-${props.sectionID}-entry-${index}`);
    if (placeholder && placeholder.nextSibling === sectionElement) {
        return;
    }

    if (placeholder) placeholder.remove();

    placeholder = document.createElement('div');
    placeholder.className = 'placeholder';
    placeholder.style.height = '23px';
    placeholder.style.backgroundColor = '#e0e0e0';
    placeholder.style.margin = '1px 0px 5px 24px';

    sectionElement?.parentNode?.insertBefore(placeholder, sectionElement);
}

function onDrop(event: DragEvent, index: number): void {
    if (draggedIndex === null || draggedIndex === index) {
        if (placeholder) placeholder.remove();
        return;
    }

    emit('reposition', index, draggedIndex, props.sectionIndex);

    draggedIndex = null;
    if (placeholder) placeholder.remove();
}

function deleteEntry(pk: number): void {
    emit('deleteEntry', props.sectionID, pk);
}


</script>

<template>


<div class="flex flex-col gap-1 m-1 interior-list">
    <div 
      v-for="(entry, index) in items" 
      @dragover.prevent="onDragOver($event, index)"
      @drop="onDrop($event, index)"
    >
        <div :id="'section-' + sectionID + '-entry-' + index" class="flex flex-row gap-1 items-center">
            <div
              :draggable="true" 
              @dragstart="onDragStart($event, index)" 
              @dragend="onDragEnd()"  
              class="text-slate-300 cursor-move"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                </svg>
            </div>
            <div class="flex flex-row w-full items-center">
              <div class="flex flex-grow">
                <input
                  v-model="entry.text" 
                  type="text" 
                  class="flex-grow text-sm text-gray-600 border border-dashed px-1 cursor-text py-0 border-gray-200 focus:ring-0 focus:border-teal-500"
                />
              </div>
              <div @click="deleteEntry(entry.id!)" class="text-gray-500 ml-0.5 cursor-pointer hover:text-red-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
        </div>  
    </div>            
    
    <div class="flex flex-row w-full items-center">
      <input 
          type="text" 
          :ref="'newItem'+sectionID"
          v-model="newItems[sectionIndex]"
          @change="addNew(sectionIndex)"
          @keydown="handleKeyDown($event, sectionIndex)"
          placeholder="+ Add"
          class="text-sm ml-6 flex-grow text-gray-600 placeholder:text-gray-400 border border-dashed px-1 cursor-text py-0 border-gray-200 focus:ring-0 focus:border-teal-500" 
      />
      <div>
        <div v-if="newItems[sectionIndex] && newItems[sectionIndex].length" @click="addNew(sectionIndex)" class="text-gray-500 ml-0.5 cursor-pointer hover:text-green-600">
          <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </div>
        <div v-else class="h-5 w-5 ml-0.5"></div>
      </div>
    </div>
</div>
</template>