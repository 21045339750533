import { defineStore } from "pinia";
import { useProjects } from "../items/project";

export const useWorkdesk = defineStore("workdesk", {
  state: () => ({
    tray: "default", // Trays must generally follow the pattern "view-model" ie. "manage-task" or "profile-message"
    prevTray: [],
    nextTray: [],
    loaded: ["task", "thread"],
    updated: null,
    deleted: null,
    refresh: null,
    responseData: null,
    active: null,
    filterOptions: {
      task: [
        [1, "Actionable"],
        [2, "Awaiting Response"],
        [3, "On Hold"],
        [5, "Recent"],
      ],
      thread: [
        [1, "Active"],
        [2, "Actionable"],
        [3, "Awaiting Reply"],
        [4, "Pinned"],
        [5, "Archived"],
      ],
      appointment: [
        [1, "Upcoming"],
        [2, "Recent"],
      ],
      resource: [],
      routine: [
        [1, "Active"],
        [2, "Suspended"],
      ],
      contact: [
        [1, "Individuals"],
        [2, "Groups"],
      ],
      binder: [],
      note: [],
    },
  }),

  actions: {
    getCurrentModel() {
      return this.tray.split("-")[1]; // Derived from the tray's "view-model" labeling pattern.
    },
    getCurrentProject() {
      return useProjects().current;
    },
    getFullCategoryName(project) {
      let name = project.name;
      if (project.project) {
        const parent = useProjects().getItem(project.project);
        name = this.getFullCategoryName(parent) + ":" + name;
      }
      return name;
    },
    categoryName() {
      const project = useProjects().getCurrent();
      return this.getFullCategoryName(project);
    },
    setTray(tray, pk) {
      this.prevTray.push({ view: this.tray, active: this.active });
      this.nextTray = [];
      this.active = pk;
      this.tray = tray;
    },
    revertTray() {
      if (this.prevTray.length) {
        this.nextTray.push({ view: this.tray, active: this.active });
        const prev = this.prevTray.pop();
        this.active = prev.active;
        this.tray = prev.view;
      }
    },
    restoreTray() {
      if (this.nextTray.length) {
        this.prevTray.push({ view: this.tray, active: this.active });
        const next = this.nextTray.pop();
        this.active = next.active;
        this.tray = next.view;
      }
    },
    getClassIsActive(model, pk) {
      let isActive = false;
      if (pk === this.active && this.getCurrentModel() === model) {
        isActive = true;
      }
      return isActive;
    },
    addCard(model, data) {
      const array = this[model].cards;
      const existing = array.find((item) => item.pk === data.pk);
      if (existing) {
        array.splice(array.indexOf(existing), 1, data);
      } else {
        array.push(data);
      }
    },
    removeCard(model, pk) {
      const array = this[model].cards;
      console.log(pk);
      let card = array.find((item) => item.pk === pk);
      if (!card) {
        card = array.find((item) => item.id === pk);
      }
      array.splice(array.indexOf(card), 1);
    },
  },
});
