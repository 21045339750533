<script setup lang="ts">



import type { ContactItem } from '@/store/items/interfaces/contact';
import type { ThreadParticipant } from '@/store/items/interfaces/thread';

import { ref, computed } from 'vue';

const props = defineProps<{
    recipients: ThreadParticipant[]
    projectContacts: ContactItem[]
    searchStr: string
    getRecipientClass: (email: string | ContactItem) => string;
}>();

const emit = defineEmits(['cycleContact', 'cycleAddress'])

const eligible = computed(() => props.projectContacts.filter(contact => 
    contact.email_addresses.length > 0 && 
    contact.name.toLowerCase().includes(props.searchStr.toLowerCase())
))


const exploded = ref<number[]>([])
const toggleExploded = (id: number) => {
    if (exploded.value.includes(id)) {
        exploded.value = exploded.value.filter(i => i !== id)
    } else {
        exploded.value.push(id)
    }
}

</script>


<template>
<div>
    <div
        v-if="eligible.length"
        class="w-full flex flex-row gap-1 py-2 flex-wrap"
    >
    <div
        class="flex flex-row gap-1"
        v-for="contact in eligible"
        :key="contact.id"
    >
        <div
          class="flex flex-row select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs"
          :class="!exploded.includes(contact.id) ? getRecipientClass(contact) : ''"
        >
        <div
            @click="$emit('cycleContact', contact.id)"
            class="p-2 py-1.5 cursor-pointer"
        >
            {{ contact.name }}
        </div>
        <div
            v-if="contact.email_addresses.length > 1"
            class="flex flex-row"
        >
            <svg
                v-if="!exploded.includes(contact.id)"
                @click="toggleExploded(contact.id)"
                class="w-4 h-4 mt-1.5 mr-1.5 border cursor-pointer hover:bg-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="{1.5}" stroke="currentColor"
            >
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
            <svg v-else @click="toggleExploded(contact.id)" class="w-4 h-4 mt-1.5 mr-1.5 border cursor-pointer hover:bg-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
            </svg>

            <div
              v-if="exploded.includes(contact.id)"
              class="flex flex-row"
            >
            <div
                v-for="email in contact.email_addresses"
                :key="email.id"
                @click="$emit('cycleAddress', email.value, contact.id)"
                :class="getRecipientClass(email.value)"
                class="p-0.5 m-0.5 select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs cursor-pointer"
            >
                {{ email.value }}
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    <div v-else class="italic py-3 text-sm">
         No eligible contacts found.
    </div>
</div>

</template>