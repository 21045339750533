<script setup lang="ts">
import type { TaskItem } from '@/store/items/interfaces/task'
import { useTasks } from '@/store/items/task'

const props = defineProps<{
    obj: TaskItem
}>();

const tasks = useTasks()

const emit = defineEmits(['cancel', 'openArchive'])


function archiveGoal() {
    emit('openArchive')
}

</script>


<template>
    <div class="w-full flex flex-col flex-grow gap-4 bg-white p-12 font-dm">
        <div class="flex flex-row gap-4 justify-center text-3xl text-gray-500 ">
            <div>🎉</div>
            <div>Congratulations!</div>
            <div>🎉</div>
        </div>
        <div class="flex flex-row gap-4 text-gray-500 justify-center">
            <div v-for="x in 4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                </svg>
            </div>
        </div>
        <div class="flex flex-col gap-14">
            <div class="font-light">Way to follow through! Consider taking this moment to:</div>
            <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-around text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z" />
                    </svg>
                </div>
                <div class="font-light"><strong class="font-black">Celebrate:</strong> Reflect on this accomplishment and acknowledge your success and ability to get big things done.</div>
            </div>

            <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-around text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg>
                </div>
                <div class="font-light"><strong class="font-black">Reflect:</strong> Look back on the project's history and imagine how this milestone might influence its trajectory.</div>
            </div>
            
            <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-around text-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                    </svg>
                </div>
                <div class="font-light"><strong class="font-black">Think Ahead:</strong> Consider where to go from here and how to channel this momentum effectively towards manifesting the project's vision.</div>
            </div>
            <div class="flex flex-row gap-4 text-gray-500 justify-center">
            <div v-for="x in 4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                </svg>
            </div>
        </div>
            
    </div>
        
    <div class="flex flex-col gap-2 items-center select-none">
        <div @click="emit('cancel')" class="border p-2 rounded text-teal-800 cursor-pointer text-center text-sm">Wait! One more task...</div>
        <div>-</div>
        <div @click="archiveGoal" class="btn-confirm">Archive Goal</div>
    </div>

    </div>
</template>