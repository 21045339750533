<script setup>
import Search from '@/components/common/search.vue';
import { useContacts } from '@/store/items/contact';
import { useAppointments } from '@/store/items/appointment'
import { ref, computed } from 'vue';

const props = defineProps({
    project: Number,
    appointmentPK: Number,
    participants: Array
});

const appointments = useAppointments()
const contacts = useContacts();
const selected = ref(null)

const searchStr = ref('');
function setSearchStr(text) {
    searchStr.value = text;
}

const participants = computed(() =>
    contacts.forProject(props.project).filter(item =>
        item.name.toLowerCase().includes(searchStr.value.toLowerCase()) && 
        props.participants.includes(item.id)
    )
);

const others = computed(() =>
    contacts.forProject(props.project).filter(item =>
        item.name.toLowerCase().includes(searchStr.value.toLowerCase()) && 
        !props.participants.includes(item.id)
    )
);

async function toggleContactForMeeting(pk) {
    appointments.proposed = appointments.getItem(props.appointmentPK);
    const index = appointments.proposed.participants.indexOf(pk);
    if (index > -1) {
        appointments.proposed.participants.splice(index, 1);
    } else {
        appointments.proposed.participants.push(pk);
    }
    appointments.save()
}


</script>

<template>
    <div class="flex flex-col min-h-0 border m-4 w-full">
        <div class="flex flex-row justify-between items-center p-3 border-b">
            <Search @runSearch="setSearchStr" />
        </div>
        <div v-if="!selected" id="contacts" class="p-4 overflow-auto">


            <div class="flex flex-row items-center">
                <h3 class="text-sm mb-2 border-b">Meeting Participants:</h3>
            </div>
            <li v-for="item in participants" :key="item.id" class="mb-1 flex flex-row items-center border rounded ">
                <div 
                  @click="toggleContactForMeeting(item.id)"
                  class="flex flex-row p-2 border-r h-10 w-10 items-center justify-around rounded-l cursor-pointer text-white bg-amber-400 hover:bg-amber-300"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" /></svg>                     
                </div>
                <div class="px-2 mt-0.5">{{ item.name }}</div>
            </li>


            <div class="flex flex-row items-center mt-5">
                <h3 class="text-sm mb-2 border-b">Other Contacts:</h3>
            </div>
            <li v-for="item in others" :key="item.id" class="mb-1 flex flex-row items-center border rounded">
                <div 
                  @click="toggleContactForMeeting(item.id)"
                  class="flex flex-row p-2 border-r h-10 w-10 items-center justify-around rounded-l cursor-pointer bg-teal-600 hover:bg-teal-700 text-white"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                </div>
                <div class="px-2 mt-0.5">{{ item.name }}</div>
            </li>


        </div>
    </div>
</template>