import { defineStore } from "pinia";
import { javascriptNonsenseForUserTime } from "@/utils/timeUtils";
import axios from "axios";

export const methodMap = {
  get: "get",
  fetch: "get",
  filter: "get",
  create: "post",
  delete: "delete",
  update: "patch",
  overwrite: "put",
  batch: "post",
  custom: "post",
  order: "post",
};

export const useApiCourier = defineStore("apiCourier", {
  state: () => ({
    notifications: {},
  }),

  actions: {
    // Core API request handler
    async request(method, url, data = null, params = null) {
      try {
        const response = await axios({
          method,
          url,
          data,
          params,
        });
        return response.data;
      } catch (error) {
        if (import.meta.env.MODE === "development") {
          console.error("API Request Error:", error);
          alert("There was an error with your submission.");
        }        
        throw error; // Rethrow for external error handling
      }
    },

    // High-level interaction method
    async interaction(option, modelOrCommand, data = null, page = null) {
      if (!methodMap.hasOwnProperty(option)) {
        throw new Error(`Unsupported action: ${option}`);
      }
      const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
      let endpoint = modelOrCommand;

      if (option === "batch") {
        endpoint += "-batch";
      }

      let url = `${apiEndpoint}/${endpoint}/`;

      if (data?.id) {
        url += `${data.id}/`;
      }

      if (page) {
        url += `?page=${page}`;
      }
      
      if (!data.date){
        if (data && typeof data === 'object') {
          data.date = javascriptNonsenseForUserTime(); // Add timestamp
        } else {
          url += `${data}/`;
        }
      }

      const method = methodMap[option];

      const isBodyMethod = ["post", "put", "patch"].includes(method);
      const params = option === "filter" ? data : null;
      const body = isBodyMethod ? data : null;
      return await this.request(method, url, body, params);
    },
  },
});