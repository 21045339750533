<script setup lang="ts">
import { useRouter } from "vue-router";
import { useDirector } from '@/store/pages/director'
import { computed } from 'vue';

import type { AppointmentItem } from "@/store/items/interfaces/appointment";

const director = useDirector();
const appts = director.extensions['appointment']
const contacts = director.extensions['contact'];
const groups = director.extensions['group']

const router = useRouter();

// Define props with explicit TypeScript types

const props = defineProps<{
  obj: AppointmentItem;
  project: number;
  expanded: number | null;
  inPast: boolean;
}>();

const contactsLoaded = computed(() => director.loadedForProjects['group'].includes(props.project))

// Define emits with explicit type
const emit = defineEmits<{
  (event: "expand", id: number): void;
  (event: "edit", id: number): void;
}>();

// Expand appointment options
function expandOptions() {
  emit("expand", props.obj.id);
}

// Delete the appointment
async function deleteAppt() {
  await appts.del(props.obj.id);
}
</script>

<template>
  <div class="flex flex-col">
    <div @click="expandOptions" class="flex flex-col p-2 cursor-pointer select-none hover:bg-gray-100">
      <div class="flex flex-row justify-between">
        <div>{{ obj.label }}</div>
        <div class="text-sm">{{ appts.displayDate(obj.id) }}</div>
      </div>
      <div v-if="contactsLoaded" class="flex flex-row justify-between">
        <div class="flex flex-row gap-1 text-sm text-gray-400 truncate">
          <div class="flex flex-row" v-for="(participant, index) in obj.participants" :key="'participant-' + index">
            {{ contacts.getItem(participant)?.name }}<span v-if="index < obj.participants.length - 1">, </span>
          </div>
          <div class="flex flex-row" v-for="(group, index) in obj.participant_groups" :key="'group-' + index">
            {{ groups.getItem(group)?.name }}<span v-if="index < obj.participant_groups.length - 1">, </span>
          </div>
        </div>
        <div class="text-sm">{{ appts.displayTime(obj.id) }}</div>
      </div>
    </div>
    <div v-if="expanded === obj.id" class="flex flex-row justify-center gap-6 p-3 items-center border-t bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 select-none">
      <div v-if="inPast" @click="emit('edit', obj.id)" class="text-white bg-amber-700 p-2 rounded cursor-pointer hover:bg-amber-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-amber-800"><path stroke-linecap="round" stroke-linejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" /></svg>
      </div>
      <div v-else @click="emit('edit', obj.id)" class="text-white bg-amber-700 p-2 rounded cursor-pointer hover:bg-amber-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/></svg>
      </div>
      <div @click="router.replace('/meeting/' + obj.id)" class="text-white bg-cyan-700 p-2 px-4 rounded flex flex-row items-center gap-2 cursor-pointer hover:bg-cyan-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 w-8"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/></svg>
        <div>Meeting Desk</div>
      </div>
      <div @click="deleteAppt" class="text-white bg-red-700 p-2 rounded cursor-pointer hover:bg-red-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/></svg>
      </div>
    </div>
  </div>
</template>
