<script setup lang="ts">
import { useProjects } from '@/store/items/project/index.js';

defineProps<{
  toggleSettingsView: () => void; 
}>();

const emit = defineEmits<{
  loadProject: (projectId: number) => void;
  openForm: () => void;
}>();

const store = useProjects();
</script>

<template>

<div class="py-4 text-center font-black">
    Projects
</div>
<div 
    v-for="obj in store.items"
    @click="$emit('loadProject', obj.id)"
>
    <div 
        class="border-l-4 bg-white border-gray-300 py-4 px-4 hover:shadow-inner hover:bg-gray-100 cursor-pointer"
        :class="{'border-teal-600 text-teal-600': store.selected === obj.id}"
    >
        {{ obj.name }}
    </div>
</div>
<div @click="emit('openForm')" class="border-l-4 border-l-white bg-white py-4 px-4 hover:shadow-inner hover:bg-gray-100 cursor-pointer font-thin">
    + New Project
</div>

</template>