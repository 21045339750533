import { createStore } from "@/store/items/common/index.js"
import { addOrRemoveFromArray } from "@/utils/arrayUtils";

const template = {
  name: "",
  members: [],
  project: null,
}

const customActions = {
  async toggleContactForGroup(member) {
    this.proposed = {...this.getSelected()}
    this.proposed.members = addOrRemoveFromArray(this.proposed.members, member);
    await this.overwrite();
  },
}

export const useGroups = createStore({
  model: "group",
  template, 
  customActions
})
