const defaultColors = [
    '#C62828FF',
    '#AD1457FF',
    '#6A1B9AFF',
    '#4527A0FF',
    '#3949ABFF',
    '#1565C0FF',
    '#0277BDFF',
    '#00838FFF',
    '#00695CFF',
    '#2E7D32FF',
    '#33691EFF',
    '#9E9D24FF',
    '#827717FF',
    '#F57F17FF',
    '#E65100FF',
    '#BF360CFF',
    '#6D4C41FF',
    '#455A64FF',
    '#616161FF',
    '#212121FF'
]

// Generate a random color in hsla format
export const getRandomColor = (): string => {
    const hue = Math.floor(Math.random() * 360); // Random hue between 0 and 359
    const saturation = Math.floor(Math.random() * 100); // Random saturation between 0 and 100%
    const lightness = Math.floor(Math.random() * 100); // Random lightness between 0 and 100%
    const alpha = Math.random().toFixed(2); // Random alpha between 0 and 1
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
};

export const getRandomDefaultColor = (): string => {
    const randomIndex = Math.floor(Math.random() * defaultColors.length);
    return defaultColors[randomIndex];
  };

  export const getUniqueDefaultColor = (usedColors): string[] => {
    // Filter out used colors from defaultColors
    const availableColors = defaultColors.filter((color) => !usedColors.includes(color));
  
    // If no colors are available, fall back to defaultColors (or handle as needed)
    if (availableColors.length === 0) {
      console.warn('No unique colors available. Reusing defaultColors.');
      return defaultColors[Math.floor(Math.random() * defaultColors.length)];
    }

    // Select a random color from the available colors
    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
    }

export const calculateSwatchMap = (usedColors: string[]): string[][] => {
    let availableColors: string[] = defaultColors.filter((color) => !usedColors.includes(color));
    if (availableColors.length === 0) {
        availableColors = defaultColors
    }
    const res: string[][] = [];
    for (let i = 0; i < availableColors.length; i += 4) {
        res.push(availableColors.slice(i, i + 4));
    }
    return res;
};