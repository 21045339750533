<script setup>

import {ref, computed, watch} from 'vue'

const props = defineProps({
    date: Date,
    recurrence_pattern: String,
    recurrence_days: String,
})

const emit = defineEmits(['update'])

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const givenDay = computed(() => props.date.getDay())
const givenPosition = computed(() => Math.ceil(props.date.getDate() / 7))

function getNumberWithSuffix(number) {
  const suffixes = ['st', 'nd', 'rd'];
  const remainderTen = number % 10;

  return (
    number +
    (suffixes[(remainderTen - 1) % 10] || 'th')
  );
}

const selectedOption = ref('')

const dayOfWeekOption = computed(() => `x-${givenDay.value}`)
const dayOfMonthOption = computed(() => `${givenPosition.value}-${givenDay.value}`)

function selectOption(option) {
    let output
    if (option === 'daily') {
        output = "x-0,1,2,3,4,5,6"
    } else if (option === 'weekly') {
      output = dayOfWeekOption.value
    } else if (option === 'monthly') {
        output = dayOfMonthOption.value
    } else {
        output = "-"
    }
    emit('update', output)
}

// Set initial form. Will need something more robust if the available options are changed.
if (props.recurrence_pattern) {
    if (props.recurrence_pattern === 'x') {
        if (props.recurrence_days.length > 1) {
            selectedOption.value = 'daily'
        } else {
            selectedOption.value = 'weekly'
        }
    } else if (props.recurrence_pattern.length) {
        selectedOption.value = 'monthly'
    }
}


watch(() => props.date, () => {
    selectOption(selectedOption.value)
});

watch(() => selectedOption.value, () => {
    selectOption(selectedOption.value)
});

</script>

<template>
<div class="flex flex-col gap-4">
    <select v-model="selectedOption">
        <option value="" selected>Doesn't Repeat</option>
        <option value="DAILY">Daily</option>
        <option value="WEEKLY">Every {{ daysOfWeek[givenDay] }}</option>
        <option value="MONTHLY">
            Monthly, on the {{ getNumberWithSuffix(givenPosition) }} {{ daysOfWeek[givenDay] }}
        </option>
    </select>
</div>

</template>