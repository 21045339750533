import Agenda from "./pages/AgendaPage.vue";
import Inbox from "./pages/InboxPage.vue";
import Dashboard from "@/pages/DashboardPage.vue";
import Calendar from '@/pages/CalendarPage.vue'
import Projects from "@/pages/ProjectsPage.vue";
import Login from "./Login.vue";
import Focus from "./pages/FocusPage.vue";
import Meeting from "./pages/MeetingPage.vue"
import Home from "./pages/SplashPage.vue"
import RequestAccess from "./pages/RequestAccessPage.vue"
import Feedback from "./pages/FeedbackPage.vue"

export default [
  { path: "/", component: Home, name: "home" },
  { path: "/login/", component: Login, name: "login" },
  { path: "/today/", component: Dashboard, name: "today" },
  { path: "/inbox/", component: Inbox, name: "inbox" },
  { path: "/projects/", component: Projects, name: "projects" },
  { path: "/focus/:task/", component: Focus, name: "focus" },
  { path: "/agenda/:slug/", component: Agenda, name: "agenda" },
  { path: "/meeting/:slug/", component: Meeting, name: "appointment" },
  { path: "/calendar/", component: Calendar, name: "calendar" },
  { path: "/feedback/", component: Feedback, name: "feedback" },
  { path: "/request-access/", component: RequestAccess, name: 'request-access'},
];
