import { ref } from 'vue';
import { useAppointments } from '@/store/items/appointment';

export function useSectionDragAndDrop() {
  const draggedSectionIndex = ref<number | null>(null);
  const appts = useAppointments();

  function onSectionDragStart(event: DragEvent, index: number) {
    draggedSectionIndex.value = index;
    event.dataTransfer?.setData('text/plain', index.toString());
  }

  function onSectionDrop(event: DragEvent, index: number, sectionPK: number, appointmentId: number) {
    event.preventDefault();
    if (draggedSectionIndex.value === null || draggedSectionIndex.value === index) return;
    appts.updateAgendaOrder(draggedSectionIndex.value, index, sectionPK, appointmentId);

    draggedSectionIndex.value = null; // Reset dragged index
  }

  function onSectionDragOver(event: DragEvent) {
    event.preventDefault(); // Allow drop
  }

  return {
    draggedSectionIndex,
    onSectionDragStart,
    onSectionDrop,
    onSectionDragOver,
  };
}