<script setup lang="ts">

import { computed } from 'vue'

import { useTasks } from '@/store/items/task'

const tasks = useTasks()

const props = defineProps<{
    pk: number
}>()

const taskItems = computed(() => tasks.items.filter(item => item.phase === props.pk))

</script>

<template>
    <div class="flex flex-col">
        <div 
            v-for="item in taskItems"
            class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
            @click="tasks.selected = item.id"
        >
            {{ item.label }}
        </div>
    </div>
</template>