<script setup lang="ts">

import { useRouter } from "vue-router";
import axios from "axios";

import { ref, computed } from "vue";
import { isValid } from "date-fns";

const router = useRouter()
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const userComplete = ref<boolean>(false)

const requestedEmail = ref<string>('')

const emailIsValid = computed(() => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(requestedEmail.value);
  }
)

function submitApplication() {
  if (emailIsValid.value) {
    axios.post(`${apiEndpoint}/request-access/`, {
        email: requestedEmail.value
    })
    userComplete.value = true
  }
}

</script>

<template>

<div class="max-w-xl mx-auto bg-white rounded-lg shadow-md p-8 space-y-6 mt-12">
  <h1 class="text-3xl font-bold text-center text-teal-700 border-b pb-2">
    Open Beta Access Request
  </h1>

  <section class="space-y-2">
    <h2 class="text-xl font-semibold text-teal-700">Heads up!</h2>
    <p class="text-gray-700">
      This product is still in early access beta, so you might run into some issues. Please report any bugs, issues, or suggestions using our 
      <a href="/feedback" class="underline text-teal-700">feedback form</a> to help us make it even better!
    </p>
  </section>

  <section class="space-y-2 text-gray-700">
    <h2 class="text-xl font-semibold text-teal-700">Quick Sign-Up</h2>
    <p class="">
      Can't wait to jump in? You can <a href="/login/" class="underline text-teal-700">create a new account</a> right away to get started with our core features—without the email sorting and management tools.
    </p>
    <p class="text-sm">
        <span class="text-black font-black ">Note:</span> To create an account now you must use our standard sign-up process, which doesn’t support “Login with Gmail.” To use Gmail login and our full email management features, please request full access below.
    </p>
  </section>

  <section class="space-y-2">
    <h2 class="text-xl font-semibold text-teal-700">Request Full Access</h2>
    <p class="text-gray-700">
      Want to experience the full power of On-it.pro? Enter your Gmail (or Google-hosted) address below, and I'll whitelist you and follow up as soon as possible.
    </p>
  </section>
  <section class="space-y-2" v-if="!userComplete">
    <input type="email" placeholder="Your Gmail Address" class="w-64" v-model="requestedEmail"/>
    <button @click="submitApplication" class="ml-2" :class="{'btn-confirm': emailIsValid, 'btn-disabled': !emailIsValid}">Submit</button>
  </section>

  <section class="space-y-2" v-else>
    <p class="text-gray-700">
      Thanks for your interest! I’ll send you an email when I’ve added you to the beta list. In the meantime, feel free to <a href="/login/" class="underline text-teal-700">create a new account</a> to get started with our core features.
    </p>
  </section>

</div>
    
</template>