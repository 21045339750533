<script setup lang="ts">

import axios from "axios";

import { useThreads } from "@/store/items/thread";

const threads = useThreads()

const callback = async (response: any) => {
  const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
  try {
    const res = await axios.post(`${apiEndpoint}/auth_google/`, {
      code: response.code,
      accountID: threads.refreshAccountId || null
    });
    console.log(res)
    threads.authReference[res.account] = true
    threads.refreshAccountId = null
  } catch (err) {
    console.error("Google sign-in error:", err);
  }
}
</script>

<template>
  <GoogleLogin :callback="callback" :authMode="'code'"/>
</template>