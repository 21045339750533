<script setup lang="ts">
import TabbedSections from '@/layouts/TabbedSections.vue';
import Resources from '@/components/common/lists/resources.vue';
import Contacts from './contacts.vue';
import Goals from './goals.vue';

const props = defineProps({
  project: Number,
  appointmentPK: Number,
  participants: Array,
});

// Define the tabs with their labels and associated components
const tabs = [
  {
    label: 'Resources',
    component: Resources,
    props: { project: props.project, participants: props.participants },
  },
  {
    label: 'Contacts',
    component: Contacts,
    props: { project: props.project, participants: props.participants, appointmentPK: props.appointmentPK },
  },
  {
    label: 'Goals',
    component: Goals,
    props: { project: props.project, appointmentPK: props.appointmentPK },
  },
];
</script>

<template>
  <TabbedSections :tabs="tabs" />
</template>