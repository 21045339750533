<script setup lang="ts">
import SearchBar from '@/components/common/search.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';
import Loader from '@/components/common/Loader.vue';
import BindersList from './BindersList.vue';
import ContentsMenu from './BinderContents/ContentsMenu.vue';

import { ref, computed, onMounted, nextTick, watch } from 'vue';

import { useBinders } from '@/store/items/binder';
import { useProjects } from '@/store/items/project';
import { getSearchResults } from '@/utils/searchUtils';

interface Props {
  project: number;
  loaded: number[];
}

const props = defineProps<Props>();

const binders = useBinders();
const projects = useProjects();

const selectedCategory = ref<number>(0);

const items = computed(() => binders.forProject(selectedCategory.value).filter((item) => item.name.includes(searchStr.value)));

const addFormIsOpen = ref<boolean>(false);
const addFormInput = ref<HTMLInputElement | null>(null);

function toggleAddForm(): void {
  addFormIsOpen.value = !addFormIsOpen.value;
  binders.proposed.category = selectedCategory.value;
  if (addFormIsOpen.value) {
    nextTick(() => {
      addFormInput.value?.focus();
    });
  }
}

const searchStr = ref<string>('');
function setSearchStr(str: string): void {
  searchStr.value = str;
}   

function submitAddForm(): void {
    binders.create();
  addFormIsOpen.value = false;
}

function openBinder(id: number): void {
  selectedCategory.value = id
}

// Build the ancestry array from the selected binder back up to the project root.
const breadcrumbs = computed(() => {
  const trail: Array<{ id: number; name: string }> = [];
  
  // Start by pushing the root project:
  const projectItem = projects.getItem(props.project);
  
  // Then walk through parent binders from selectedCategory upwards
  // until you reach the root (which might be the project).
  let currentId = selectedCategory.value;
  while (currentId && currentId !== props.project) {
    const binder = binders.getItem(currentId);
    if (binder) {
      trail.push({ id: binder.id, name: binder.name });
      currentId = binder.category!;
    } else {
      break
    }
  }
  trail.push({ id: projectItem.id, name: projectItem.name });
  return trail.reverse();
});

onMounted(() => {
  selectedCategory.value = props.project
});

watch(() => props.project, () => {
  selectedCategory.value = props.project
});

</script>

<template>
  <div class="h-full w-full flex flex-row">
    <div class="flex flex-col w-1/2 grow-0 border-r p-5">
      <div class="flex flex-col w-full text-sm mb-2">
        <div class="text-xl text-teal-700">Binders for:</div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-row w-full text-sm items-center ">
            <div class="flex flex-row items-center gap-2">
              <div
                v-for="(crumb, index) in breadcrumbs"
                :key="crumb.id"
                class="text-gray-800 text-lg"
                @click="openBinder(crumb.id)"
              >
                <span :class="{ 'cursor-pointer text-blue-600': index < breadcrumbs.length - 1 }">
                {{ crumb.name }}
                </span>
                <span v-if="index < breadcrumbs.length - 1"> > </span>
              </div>
            </div>
         </div>
        </div>
      </div>

      <div class="flex flex-col min-h-0 bg-white rounded shadow">
        <div class="flex flex-row justify-between items-center border-b pl-3">
          <SearchBar @runSearch="setSearchStr"></SearchBar>
          <div class="p-3">
            <div @click="toggleAddForm" class="btn-bold">
              <span v-if="!addFormIsOpen">Add Binder</span>
              <span v-else>Cancel Add</span>
            </div>
          </div>
        </div>

        <div v-if="addFormIsOpen" class="flex flex-col p-6 gap-0.5 border-y">
          <div class="form-label">Label for new binder:</div>
          <div class="flex flex-row border-2 border-teal-700 items-center">
            <input
              @keyup.enter="submitAddForm"
              v-model="binders.proposed.name"
              ref="addFormInput"
              type="text"
              class="form-field flex-grow border-none"
            />
            <div
              @click="submitAddForm"
              class="bg-teal-700 hover:bg-teal-600 text-white cursor-pointer h-10 w-10 flex flex-row items-center justify-around"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" /></svg>
            </div>
          </div>
        </div>

        <div v-if="!loaded.includes(project)">
          <Loader></Loader>
        </div>
        <div v-else-if="!items.length" class="flex flex-col gap-3 p-6 items-center">
          <div class="text-gray-500 select-none">No Binders Found</div>
        </div>
        <div v-else class="flex flex-col divide-y border-b overflow-y-auto"> 
          <BindersList :items="items" @openBinder="openBinder"></BindersList>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/2 grow-0 border-r p-5">
      <div v-if="breadcrumbs.length < 2" class="w-full h-full flex flex-row items-center justify-around text-xl italic text-gray-500">
        Select A Binder
      </div>
      <ContentsMenu
        v-else
        :project="project"
        :category="selectedCategory"
      >
      </ContentsMenu>
    </div>
  </div>
</template>
