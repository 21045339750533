import {javascriptNonsenseForUserTime, getUserLocalTime} from '@/utils/timeUtils'

import { defineStore } from "pinia";

import { useApiCourier } from "@/store/items/common/apiCourier";

import { useTasks } from "@/store/items/task";
import { useWorkdesk } from "@/store/pages/workdesk";
import { useContacts } from "@/store/items/contact";
import { useGroups } from "@/store/items/group";
import { useAppointments } from "@/store/items/appointment";
import { useThreads } from "@/store/items/thread";
import { useRoutines } from "@/store/items/routine";
import { useResources } from "@/store/items/resource";
import { useNotes } from "@/store/items/note";
import { useBinders } from "@/store/items/binder";
import { useCapsules } from "@/store/items/capsule";

export const useDirector = defineStore("director", {
  state: () => ({
    user: null,
    accounts: [],
    extensions: {
      task: useTasks(),
      thread: useThreads(),
      workdesk: useWorkdesk(),
      resource: useResources(),
      routine: useRoutines(),
      note: useNotes(),
      appointment: useAppointments(),
      contact: useContacts(),
      group: useGroups(),
      binder: useBinders(),
      capsule: useCapsules()
    },
    loadedForProjects: {
      task: [],
      thread: [],
      resource: [],
      routine: [],
      note: [],
      appointment: [],
      contact: [],
      group:[],
      binder: [],
    }, 
    panelSignal: null,
    loginSignal: null,
    cachedTime: getUserLocalTime(),
    focus: {
      taskPK: null,
      recordObj: null,
      workdayPK: null,
    },
  }),
  actions: {
    getStore(model) {
      return this.extensions[model];
    },
    async interact(command, keyword, data = {}) {
      const courier = useApiCourier()
      return await courier.interaction(command, keyword, data)
    },
    async loadItemsForProject(model, project) {
      const loadCache = this.loadedForProjects[model]
      if (Array.isArray(loadCache) && !loadCache.includes(project)) {
        const store = this.getStore(model);
        const data = {
          category: project,
          date: javascriptNonsenseForUserTime()
        };
        await store.loadItems(data)
        if (model === 'task') {
          this.loadItemsForProject('routine', project)
        } else if (model === 'contact') {
          this.loadItemsForProject('group', project)
        } else if (model === 'appointment') {
          this.loadItemsForProject('contact', project)
        }
        loadCache.push(project)
      }
    },
  },
});
