<script setup lang="ts">

import { ref } from 'vue'
import { useAppointments } from '@/store/items/appointment'

import type { AgendaSectionItem } from '@/store/items/interfaces/appointment'


const appts = useAppointments()

const props = defineProps<{
    index: number
    item: AgendaSectionItem
}>()

const editing = ref<number | null>(null);

function startEditing(sectionIndex: number) {
  editing.value = sectionIndex;
}

function finishEditing() {
  const section = props.item;
  appts.saveAgendaSection('overwrite', section)
  editing.value = null; // Stop editing
}

</script>

<template>
    <div class="text-gray-800 border-b cursor-pointer hover:text-teal-600 w-full">
      <template v-if="editing === index">
        <input
          v-model="item.label"
          @keyup.enter="() => finishEditing()"
          @blur="finishEditing()"
          class="border rounded p-1 w-full"
          type="text"
        />
      </template>
      <template v-else>
        <div @click="startEditing(index)">{{ item.label }}</div>
      </template>
    </div>
</template>