import { createStore } from "@/store/items/common/index.js";

import {detailActions} from './detailActions'

const template = {
  category: [], // pk
  name: "",
  email_addresses: [], // {value, label (home, work, etc), is_primary (T/F)}
  phone_set: [], // {value, label}
  date_set: [], // {value, label}
  url_set: [], // {value, label}
  note_set: [], // {value, label}
  address_set: [], // {value, label}
  group: null, // pk
};

const customGetters = {
  forProject: (state) => (project) => state.items.filter((item) => item.category.includes(project)),
  forGroup: (state) => (members) => state.items.filter((item) => members.includes(item.id)),
  getDetailItem: (state) => (pk, model, objID) => state.getItem(pk)[model].find((child) => child.id === objID),
}

export const useContacts = createStore({
  model: 'contact',
  template,
  customGetters: customGetters,
  customActions: {
    ...detailActions
  }
})
