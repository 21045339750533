import { createStore } from "@/store/items/common/index.js";

import { crudActions } from "./actions/crudActions";
import { displayActions } from "./actions/displayActions";
import { setterActions } from "./actions/setterActions";

const template = {
  id:"",
  label: "",
  details: "",
  status: 1,
  due_date: null,
  enthusiasm: 0,
  minutes_allotted: 30,
  parent: null,
  days_to_complete: 7,
  category: null,
};

const customState = {
  formOwner: null,
  opened: {'root': []},
  loadedDetailPKs: [],
  loadedGoalPKs: {
    forProjects: [],
    forGoals: [],
  },
  defaults: {},
}

const customGetters = {
  forProject: (state) => (project) => state.items.filter((item) => item.category === project && item.is_goal === false),
  forParent: (state) => (parentID) => state.items.filter((item) => item.parent === parentID),
  goalsForProject: (state) => (project) => state.items.filter((item) => item.category === project && item.is_goal === true),
}

export const useTasks = createStore({
  model: "task",
  template: template,
  customState: customState,
  customGetters: customGetters,
  customActions: {
    ...crudActions,
    ...displayActions,
    ...setterActions,
  }
})