<script>
export default {
  name: "Enthusiasm",
  emits: ["updateField"],
  props: {
    classes: Object,
    data: Number,
  },
  setup(props, context) {
    const enthusiasmOptions = [
      [-2, "😩"],
      [-1, "😕"],
      [0, "😐"],
      [1, "🙂"],
      [2, "😀"],
    ];

    function getEnthusiasmClass(option) {
      if (parseInt(option) === props.data) {
        return "active";
      }
    }

    function update(value) {
      context.emit("updateField", { field: "enthusiasm", value: value });
    }

    return { update, getEnthusiasmClass, enthusiasmOptions };
  },
};
</script>

<template>
  <div class="flex flex-col select-none">
    <label :class="classes.label" class="form-label mb-1">Enthusiasm</label>
    <div class="flex flex-row gap-10">
      <div
        v-for="(option, index) in enthusiasmOptions"
        :key="index"
        @click="update(option[0])"
        :class="getEnthusiasmClass(option[0])"
        class="face text-3xl border-gray-400 cursor-pointer"
      >
        {{ option[1] }}
      </div>
    </div>
  </div>
</template>


<style scoped>
.face {
  color: grey;
  filter: grayscale(100%);
}
.face:hover {
  filter: grayscale(50%);
}
.face.active {
  filter: none;
}
</style>