<script setup lang="ts">

import CloseItem from '@/components/common/buttons/CloseItem.vue';

import { useProjects } from '@/store/items/project';
import { useGroups } from '@/store/items/group';

const emit = defineEmits(['setView'])
const projects = useProjects()
const groups = useGroups()

async function createGroup() {
    if (projects.selected !== null) {
        groups.proposed.category = projects.selected
    }
    const savedItem = await groups.create()
    groups.selected = savedItem['id']
    emit('setView', 'default')  
}

</script>

<template>
    New Group
    
    <div class="bg-white rounded shadow p-5">
        <div class="flex flex-row justify-between border-b pb-3 mb-3">
            <div class="text-xl font-light">New Group</div>
            <CloseItem @click="emit('setView', 'default')"></CloseItem>
        </div>
        <div class="flex flex-col gap-4">

            <div class="flex flex-col gap-0.5">
                <div class="form-label">
                    Enter a Name for the Group
                </div>
                <input autofocus v-model="groups.proposed.name" type="text" class="form-field">
            </div>
            <div class="flex flex-row justify-end">
                <div @click="createGroup()" class="btn-confirm">Continue</div>
            </div>
        </div>
    </div>
</template>