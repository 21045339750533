
<script setup lang="ts">

import { ref, computed } from 'vue';
import { useTasks } from '@/store/items/task';

import GoalForm from './GoalForm.vue';
import GoalTray from './GoalTray/GoalTray.vue';

const props = defineProps<{
    project: number;
}>();

const tasks = useTasks();

const goals = computed(() => tasks.goalsForProject(props.project));

const showForm = ref<boolean>(false);

function openForm(goal: number | 'new'): void {
    if (goal === 'new') {
        tasks.clearSelected()
        tasks.proposed = { ...tasks.blank };
        tasks.proposed.category = props.project;
        tasks.proposed.is_goal = true;
    } else {
        tasks.setSelected(goal);
    }
    showForm.value = true;
}

function closeForm(): void {
    showForm.value = false;
    tasks.clearSelected()
}

</script>


<template>
<div v-for="goal in goals" class="flex flex-col flex-grow min-h-0">
    <div 
      v-if="showForm && tasks.selected === goal.id"
      class="flex flex-col gap-5 text-sm p-5 w-[650px] basis-6/12 flex-shrink-0 min-h-0"
    >
        <GoalForm 
           @close="closeForm()">
        </GoalForm>
        
    </div>
    <GoalTray
        v-else
        :key="goal.id"
        :obj="goal"
        :project="project"
        @openGoalForm="openForm"
    />
</div>

<div class="flex flex-col gap-6 text-sm p-5 w-[650px] overflow-y-auto basis-6/12 flex-shrink-0" ref="newGoalTrigger">
    <div v-if="showForm && !tasks.selected">
        <GoalForm 
          @close="closeForm()"
        ></GoalForm>
    </div>
    <div v-else class="flex flex-col">
        <div 
          @click="openForm('new')" 
          class="text-lg bg-gray-400 p-2 hover:bg-gray-500 text-white cursor-pointer select-none"
        >
          + Add New Goal
    </div>
    </div>
</div> 
</template>