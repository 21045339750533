<script setup lang="ts">

import type { TaskItem } from '@/store/items/interfaces/task'

import DeleteItem from '@/components/common/buttons/DeleteItem.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';
import FocusItem from '@/components/common/buttons/FocusItem.vue';
import PhaseContents from './PhaseContents.vue';

import { computed } from 'vue'

const props = defineProps<{
    item: TaskItem
}>()

const emit = defineEmits(['delete', 'showArchiveMenu'])

function deleteItem(pk: number) {
    emit('delete', pk)
}

const phases = computed(() => {
    return props.item.phases.slice().sort((a: { order: number }, b: { order: number }) => {
        return a.order - b.order;
    });
});


</script>


<template>
<div class="flex flex-col">
    <div class="flex flex-row gap-2 pt-4 text-sm text-gray-600 items-center">
        <div>Completed on:</div>
        <div class="text-gray-400">
            <span v-if=item.completion_date>{{ item.completion_date }}</span>
            <span v-else>Not Completed</span>
        </div>
    </div>
    <div class="flex flex-row gap-2 text-sm text-gray-600 items-center justify-center py-8 ">
        <ArchiveItem v-if="!item.parent" @click="$emit('showArchiveMenu')" />
        <DeleteItem :pk="item.id" :onClick="deleteItem" />
        <FocusItem :pk="item.id"></FocusItem>
    </div>
    <div v-for="phase in phases" class="flex flex-col gap-2">
        <div class="flex flex-row justify-between text-sm text-gray-500 border-b pb-1">
            <div>
                Phase {{ phase.order + 1 }}
            </div>
            <div>
                {{ phase.completion_date }}
            </div>
        </div>
        <PhaseContents
            :pk="phase.id"
        ></PhaseContents>
    </div>
</div>

</template>