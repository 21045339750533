<script setup lang='ts'>

import SearchBar from '@/components/common/search.vue';

import { useContacts } from '@/store/items/contact';
import { useProjects } from '@/store/items/project';
import { useGroups } from '@/store/items/group';

import { computed, ref } from 'vue';

import { getSearchResults } from '@/utils/searchUtils';

const contacts = useContacts();
const projects = useProjects();
const groups = useGroups();

const emit = defineEmits(['setView'])

const project = ref(projects.selected)
const searchStr = ref<string>('')
function setSearchStr(val: string) {
    searchStr.value = val
}

const items = computed(() => {
    let results = project.value ? contacts.forProject(project.value) : []
    return getSearchResults(results, searchStr.value, 'name')
})

function openContact(pk: number) {
    contacts.selected = pk
    emit('setView', 'manage')
}

const selectedGroup = computed(() => groups.getSelected())

const groupedContacts = computed(() => {
    if (!selectedGroup.value) {
        return [];
    }
    return items.value.filter(i => selectedGroup.value!.members.includes(i.id));
})


</script>

<template>

<div class="flex flex-col overflow-y-auto">
    <div class="flex flex-row justify-between items-center border-b p-3">
        <SearchBar
            @runSearch="setSearchStr"
        ></SearchBar>
    </div>
    <div class="bg-white flex flex-col w-full border-2 border-teal-400 ">
        <div v-for="contact in items" class="flex flex-row justify-between"> 
            <div @click="openContact(contact.id)" class="flex flex-col p-4 cursor-pointer border-t w-full hover:bg-gray-100">
                {{ contact.name }} 
            </div>
            <div class='flex flex-row'>
                <div v-if="!groupedContacts.includes(contact)" @click="groups.toggleContactForGroup(contact.id)" class="p-4 cursor-pointer hover:bg-teal-700 text-white border-l-2 border-y bg-teal-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </div>
                <div v-else @click="groups.toggleContactForGroup(contact.id)" class="p-4 cursor-pointer hover:bg-yellow-700 text-white border-l-2 border-y bg-yellow-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>
</template>