<template >
  <div class="w-full flex flex-row justify-around mt-10 pb-10">
    <div class="loadingio-spinner-double-ring-nmv2sexc5x">
      <div class="ldio-i1kf0rvo0b">
        <div></div>
        <div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes ldio-i1kf0rvo0b {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-i1kf0rvo0b div {
  box-sizing: border-box !important;
}
.ldio-i1kf0rvo0b > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #1d3f72 transparent #1d3f72 transparent;
  animation: ldio-i1kf0rvo0b 1s linear infinite;
}

.ldio-i1kf0rvo0b > div:nth-child(2),
.ldio-i1kf0rvo0b > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-i1kf0rvo0b 1s linear infinite reverse;
}
.ldio-i1kf0rvo0b > div:nth-child(2) {
  border-color: transparent #5699d2 transparent #5699d2;
}
.ldio-i1kf0rvo0b > div:nth-child(3) {
  border-color: transparent;
}
.ldio-i1kf0rvo0b > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-i1kf0rvo0b > div:nth-child(3) div:before,
.ldio-i1kf0rvo0b > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #1d3f72;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #1d3f72;
}
.ldio-i1kf0rvo0b > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #1d3f72;
}

.ldio-i1kf0rvo0b > div:nth-child(4) {
  border-color: transparent;
}
.ldio-i1kf0rvo0b > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-i1kf0rvo0b > div:nth-child(4) div:before,
.ldio-i1kf0rvo0b > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: #5699d2;
  border-radius: 50%;
  box-shadow: 0 46px 0 0 #5699d2;
}
.ldio-i1kf0rvo0b > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 #5699d2;
}
.loadingio-spinner-double-ring-nmv2sexc5x {
  width: 61px;
  height: 61px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-i1kf0rvo0b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.61);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-i1kf0rvo0b div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>
