import type { Item } from "@/store/items/interfaces/common";

export function getSearchResults<T extends Item>(
    items: T[],
    searchStr: string,
    searchField: keyof T
  ): T[] {
    if (!searchStr) return items; // Return all items if no search string is provided
  
    const lowercaseFilter = searchStr.toLowerCase();
  
    // Initialize priority lists
    const startsWithMatches: T[] = [];
    const wordStartsWithMatches: T[] = [];
    const includesMatches: T[] = [];
  
    // Iterate through the items once
    for (const item of items) {
      const fieldValue = String(item[searchField]).toLowerCase();
  
      if (fieldValue.startsWith(lowercaseFilter)) {
        // Exact start-of-field match
        startsWithMatches.push(item);
      } else if (
        fieldValue
          .split(/\s+/)
          .slice(1) // Ignore the first word
          .some((word) => word.startsWith(lowercaseFilter))
      ) {
        // Word-based match
        wordStartsWithMatches.push(item);
      } else if (fieldValue.includes(lowercaseFilter)) {
        // General substring match
        includesMatches.push(item);
      }
    }
  
    // Combine results in priority order
    return [...startsWithMatches, ...wordStartsWithMatches, ...includesMatches];
  }