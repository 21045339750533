<script setup lang="ts">
import { useAppointments } from '@/store/items/appointment'
import { computed, reactive } from 'vue'
import ApptBlock from './ApptBlock.vue'
import type { AppointmentItem } from '@/store/items/interfaces/appointment'
import { useProjects } from '@/store/items/project';

const props = defineProps<{
  date: Date
}>()

const emits = defineEmits<{
  (e: 'openForm'): void
}>()

const appts = useAppointments()
const projects = useProjects()

const timeArray = computed(() => {
  const intervals: Date[][] = [];

  // Convert `props.date` to a UTC-only date (strip the time and local timezone)
  const utcDate = new Date(Date.UTC(
    props.date.getFullYear(),
    props.date.getMonth(),
    props.date.getDate() // Only keep the day part, no time
  ));

  for (let hour = 5; hour < 24; hour++) {
    let pair: Date[] = [];

    for (let minutes = 0; minutes < 60; minutes += 30) {
      let startDate = new Date(utcDate);
      startDate.setUTCHours(hour, minutes, 0, 0); // Set UTC time

      let endDate = new Date(utcDate);
      endDate.setUTCHours(hour, minutes + 30, 0, 0); // Set UTC time for end

      pair.push(startDate);
      pair.push(endDate);
    }
    intervals.push(pair);
  }
  return intervals;
});

function getAppointments(dateObj: Date): AppointmentItem[] {
  return appts.appointmentsForDay(dateObj).filter(i => {
    const itemDate = new Date(i.begin);

    return (
      dateObj.getUTCHours() === itemDate.getUTCHours() &&
      Math.round(dateObj.getUTCMinutes() / 30) * 30 === Math.round(itemDate.getUTCMinutes() / 30) * 30
    );
  });
}

function getLabel(dateObj: Date): string {
  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' }
  return dateObj.toLocaleTimeString('en-US', options)
}

const getColor = (projectPK: number): string => {
    return projects.getItem(projectPK).color
}

function onDrop(evt: DragEvent, newDate: Date): void {
  const pk = parseInt(evt.dataTransfer!.getData('pk'))
  appts.proposed['id'] = pk
  appts.proposed.begin = newDate
  appts.update('begin')
}

const openBlankForm = (dateObj: Date): void => {
  appts.proposed = { ...appts.blank }
  appts.proposed.begin = dateObj
  emits('openForm')
}

const openEditForm = (pk: number): void => {
  appts.openForm(pk)
  emits('openForm')
}
</script>

<template>
<div class="h-full overflow-y-auto overflow-x-hidden">
    <div 
      v-for="pair in timeArray" 
      class="flex flex-row border-b border-gray-400 text-sm gap-2"  
    >
        <div class="w-12 text-xs p-1 font-light">{{ getLabel(pair[0]) }}</div>
        <div class="flex flex-col w-full">
            <div 
              v-if="getAppointments(pair[0]).length > 0"
              class="h-5 w-full border-b cursor-pointer"
            >
                <div 
                  v-for="(appointment, index) in getAppointments(pair[0])" 
                  :key="appointment.id" 
                  class="relative text-xs cursor-pointer"
                  @click="openEditForm(appointment.id)"
                >
                    <ApptBlock 
                        :appointment="appointment"
                        :position="index"
                        :color="getColor(appointment.category)"
                    />
                </div>
            </div>
            
            <div 
              v-else
              class="h-5 w-full border-b cursor-pointer hover:bg-gray-100"
              @click="openBlankForm(pair[0])" 
              @drop="onDrop($event, pair[0])" 
              @dragover.prevent
              @dragenter.prevent
            >
            </div>
            <div v-if="getAppointments(pair[1]).length > 0" class="h-5 w-full cursor-pointer">
                <div 
                    v-for="(appointment, index) in getAppointments(pair[1])" 
                    :key="appointment.id"  
                    class="relative text-xs cursor-pointer"
                    @click="openEditForm(appointment.id)"
                >
                    <ApptBlock
                        :appointment="appointment"
                        :position="index"
                        :color="getColor(appointment.category)"
                    />
                </div>
            </div>
            <div 
              v-else
              class="h-5 w-full cursor-pointer hover:bg-gray-100"
              @click="openBlankForm(pair[1])" 
              @drop="onDrop($event, pair[1])" 
              @dragover.prevent
              @dragenter.prevent
            >
             </div>
        </div>
    </div>
</div>

</template>
