<script setup lang="ts">

import CloseItem from '@/components/common/buttons/CloseItem.vue';

import { useContacts } from '@/store/items/contact';
import { useProjects } from '@/store/items/project';

const contacts = useContacts()
const projects = useProjects()

const emit = defineEmits(['setView'])

async function createContact() {
    if (projects.selected !== null) {
        contacts.proposed.category = [projects.selected]
    }
    const savedItem = await contacts.create()
    contacts.selected = savedItem['id']
    emit('setView', 'default')
}

</script>

<template>
<div class="bg-white rounded shadow p-5">
    <div class="flex flex-row justify-between border-b pb-3 mb-3">
        <div class="text-xl font-light">New Contact</div>
        <CloseItem @click="emit('setView', 'default')"></CloseItem>
    </div>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-0.5">
            <div class="form-label">
                Enter the Person's Name
            </div>
            <input autofocus v-model="contacts.proposed.name" type="text" class="form-field">
        </div>
        <div class="flex flex-row justify-end">
            <div @click="createContact()" class="btn-confirm">Continue</div>
        </div>
    </div>
</div>
</template>