<script setup lang="ts">

import ArrowSubmit from '@/components/common/buttons/ArrowSubmit.vue';
import type { ThreadParticipant } from '@/store/items/interfaces/thread';
import { useThreads } from '@/store/items/thread'

import { ref, computed } from 'vue'

const props = defineProps<{
    recipients: ThreadParticipant[]
    getRecipientClass: (email: string) => string;
}>();

const emit = defineEmits(['cycleAddress'])

const threads = useThreads()

const isEmailValid = computed(() => {
  return threads.isCustomRecipientValid()
});

function addEmail() {
    if (isEmailValid.value) {
        emit('cycleAddress', threads.customRecipient.value, undefined, threads.customRecipient.position)
    }
    threads.customRecipient.value = ""
}

const eligible = computed(() => {
    return props.recipients.filter(recipient => recipient.id === null);
});

</script>

<template>
<div class="flex flex-row gap-2">
    <div class="flex flex-col gap-2">
        <div class="flex flex-row gap-1 pt-2 select-none ">    

            <div
                :class="{ 'bg-blue-500 text-white ': threads.customRecipient.position === 'to' }"
                @click="threads.customRecipient.position = 'to'"
                class="place-self-end text-xs px-1 py-0.5 rounded mt-0.5 cursor-pointer border"
            >
                To
            </div>
            <div
                :class="{ 'bg-yellow-600 text-white': threads.customRecipient.position === 'cc' }"
                @click="threads.customRecipient.position = 'cc'"
                class="place-self-end text-xs px-1 py-0.5 rounded mt-0.5 cursor-pointer border"
            >
                CC
            </div>
            <div
                :class="{ 'bg-gray-600 text-white': threads.customRecipient.position === 'bcc' }"
                @click="threads.customRecipient.position = 'bcc'"
                class="place-self-end text-xs px-1 py-0.5 rounded mt-0.5 cursor-pointer border"
            >
                BCC
            </div>
            
        </div> 
        <div class="text-sm flex flex-row gap-2">
            <input @keyup.enter="addEmail" v-model="threads.customRecipient.value" class="form-field text-sm p-1" type="text" placeholder="New Email Address"> 
            <ArrowSubmit 
            :disabled="!isEmailValid"
            @click="addEmail"
            ></ArrowSubmit>
        </div>
    </div>
    <div class="flex flex-grow flex-wrap items-start pt-2">
        <div
            class="flex flex-row gap-1 cursor-pointer select-none"
            v-for="item in eligible"
            @click="$emit('cycleAddress', item.address)"
            :key="item.address"
        >
            <div
                class="flex flex-row select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs p-1"
                :class="getRecipientClass(item.address)"
            > {{ item.address }}
            </div>
        </div>
    </div>
</div>

</template>