<script setup lang="ts">

import { useRoutines } from '@/store/items/routine';
import { getSearchResults } from '@/utils/searchUtils';

import SearchBar from '@/components/common/search.vue';
import FilterBar from '@/components/common/filters.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';
import RoutineForm from './RoutineForm.vue';
import RoutineDetails from './RoutineDetails/RoutineDetails.vue';

import { ref, computed } from 'vue';

const store = useRoutines();

const formIsOpen = ref(false);

const emit = defineEmits(['toggleMode']);
const props = defineProps<{
  project: number;
}>();

const items = computed(() => {
    let forProject = store.forProject(props.project)
    return getSearchResults(forProject, searchStr.value, 'label')
})

const searchStr = ref("")
function setSearch(input: string): void {
    searchStr.value = input
}

function closeForm(): void {
    formIsOpen.value = false;
}

interface FilterOption {
  label: string;
  id: number;
}

const filterOptions: FilterOption[] = [
    {'label': "All Routines", id: 0},
    {'label': "Daily", id: 1},
    {'label': "Weekly", id: 7},
    {'label': "Monthly", id: 30},
    {'label': "Quarterly", id: 90},
];

const selectedFilter = ref(0);

function setFilter(input: number): void {
  selectedFilter.value = input;
}

function openForm(pk: 'new' | number): void {
  if (pk === 'new') {
    store.clearSelected();
    store.proposed.category = props.project;
  } else {
    store.proposed = store.getItem(pk);
  }
  formIsOpen.value = true;
}

</script>

<template>
    <div v-if="formIsOpen" class="w-full flex-col bg-white px-7 py-5 overflow-y-auto">
        <div class="flex flex-row border-b pb-2 text-sm justify-between text-gray-400 items-center">
            <div class=" text-gray-400">New Routine</div>
            <CloseItem @click="closeForm"></CloseItem>
        </div>
        <RoutineForm 
            :project="project"
            @close="closeForm"
        ></RoutineForm>
    </div>
    <div v-else class="w-full flex flex-col h-full bg-white">
        <div class="flex flex-row justify-between border-b items-center p-3">
            <SearchBar
                @runSearch="setSearch"
            ></SearchBar>
            <div 
                @click="emit('toggleMode')"
                class="text-xs select-none uppercase flex flex-row justify-end text-gray-100 border p-1 px-2 rounded cursor-pointer bg-teal-700 hover:bg-teal-600"
            >
                <span>Show Tasks</span>
            </div>
        </div>
        <div class="flex flex-row items-center text-sm gap-4 py-3 px-5 border-b justify-between">
            <FilterBar
                :items="filterOptions"
                :selected="selectedFilter"
                @setFilter="setFilter"
            ></FilterBar>
            <div class="">
                <div @click="openForm('new')" class="btn-bold">Add Routine</div>
            </div>
        </div>
        <div v-if="items.length" class="flex flex-col p-6 gap-2 overflow-y-auto">
            <RoutineDetails
                :project="project"
                :items="items"
                @openForm="openForm"
            />
        </div>
        <div v-else class="flex flex-col gap-3 p-6 items-center">
            <div class="text-gray-500 select-none">No Routines for Project</div>
        </div>
    
    </div>
</template>