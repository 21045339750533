import { useApiCourier } from "@/store/items/common/apiCourier";
import { mergeArrays } from "@/utils/arrayUtils";

export const crudActions = {

    async fetchThread(id) {
        const data = {'id':id, 'pk': id}
        const response = await useApiCourier().interaction(
          "get",
          "thread-data",
          data
        );
        if (response.success) {
          this.messages.data = response.message_data;
          this.participants = response.all_participants;
          this.current = id;
          return response
        } else {
          const error = response.error
          if (error === 'invalid_grant') {
            const account = this.getItem(id).account
            this.refreshAccountId = account
            this.authReference[account] = false
            return true
          } else {
            return false
          }
        }
      },
    async deleteBulk(idSet) {
      await this.bulkInteract("delete", idSet);
      this.removeItems(idSet);
    },
    async resolveBulk(idSet) {
      await this.bulkInteract("resolve", idSet);
      idSet.forEach(id => {
      const item = this.items.find(item => item.id === id);
        if (item) {
          item.status = 2;
        }
      });
    },
    async muteBulk(idSet) {
      await this.bulkInteract("mute", idSet);
      idSet.forEach(id => {
      const item = this.items.find(item => item.id === id);
        if (item) {
          item.status = 3;
        }
      });
    },
    setCategory(id, target)  {
      const item = this.getItem(id)
      item.category = target
    },
    resetFilterData() {
      this.filterData = { ...this.filterTemplate };
    },
    async sortMessages() {
      const category = this.filterData.category; // Target category
      const source = this.filterData.source; // Sender to filter by
    
      await useApiCourier().interaction('create', 'sort-messages', this.filterData);
    
      const senderMap = new Map();
      this.items.forEach((item) => {
        if (!senderMap.has(item.sender_address)) {
          senderMap.set(item.sender_address, []); 
        }
        senderMap.get(item.sender_address).push(item); 
      });
    
      // Process only items with the matching sender
      if (senderMap.has(source)) {
        const senderItems = senderMap.get(source);
    
        if (category) {
          // Update category for all matching items
          senderItems.forEach((item) => {
            this.setCategory(item.id, category);
          });
        } else {
          // Remove matching items from the main list
          this.items = this.items.filter((item) => item.sender_address !== source);
        }
      }
      this.resetFilterData();
    },
    async processDraft(data) {
      return await this.customInteract("create", "process_draft", data);
    },
    async clearUnread() {
    //   return await useCourier().post_operation('delete_all_unread')
    },
    async getFilters() {
    //   return await useCourier().interaction('get', 'filter_matches', {'rfcid': this.current})
    },
    async startMessageSync(account) {
      try {
        await useApiCourier().interaction('filter', 'start-thread-sync', { 'account': account });
        this.authReference[account] = true;
      } catch (error) {
        if (error?.response?.status === 401) {
          this.authReference[account] = false;
        } else {
          console.error('Error fetching triage messages:', error);
          throw error;
        }
      }
    },
    async fetchTriageMessages() {
      this.loadItems({ 'category': null });
    },

}