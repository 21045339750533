<script setup lang="ts">

import type { TaskItem } from '@/store/items/interfaces/task';
import { useTasks } from '@/store/items/task';

import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';

const tasks = useTasks();

const props = defineProps<{
    obj: TaskItem;
    // project: number;
}>();

const emit = defineEmits(['openForm', 'openArchive']);

function toggleStatus() {
    tasks.proposed = props.obj
    tasks.proposed.status = tasks.proposed.status === 1 ? 3 : 1
    tasks.update('status')
}

</script>

<template>
<div class="flex flex-row gap-6 w-full text-sm items-start justify-between">
    <div class=" text-lg text-teal-700">{{ obj.label }} </div>
    
    <div class="flex flex-row gap-4 items-center">

        <div @click="$emit('openForm')" class="cursor-pointer p-1 rounded text-gray-600  hover:text-gray-500 mb-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
        </div>
        <ArchiveItem @click="emit('openArchive')"/>
        <div @click="toggleStatus()" class="flex flex-row gap-3 uppercase w-32 items-center border p-1.5 cursor-pointer select-none rounded hover:bg-gray-200 ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <div class="flex flex-row flex-grow justify-around"> 
                <span v-if="obj.status === 1" class="text-emerald-700">Active</span>
                <span v-else class="text-red-700">Suspended</span>
            </div>
        </div>
    
    </div>

</div>

</template>