<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'

const props = defineProps<{
    currentTime: number
}>()

const emit = defineEmits<{
    (e: 'update', value: number): void
}>()

const minutes = computed<number>(() => {
    return Math.floor((props.currentTime / 1000 / 60) % 60)
})

const hours = computed<number>(() => {
    return Math.floor((props.currentTime / (1000 * 60 * 60)) % 24)
})

const customHrs = ref<number>(0)
const customMins = ref<number>(0)

const toggleCustomEntry = (): void => {
    emit('update', customHrs.value * 1000 * 60 * 60 + customMins.value * 1000 * 60)
}

onMounted(() => {
    customHrs.value = hours.value,
    customMins.value = minutes.value
})

</script>

<template>
    <div class="flex flex-row items-center justify-between px-4 w-72">
        <div class="flex flex-row gap-3">
            <div class="flex flex-row items-center gap-2">
                <label for="hours" class="text-xs">Hrs</label>
                <input v-model="customHrs" id="hours" min="0" type="number" class="w-12 px-1 border-white border-b-black p-0 focus:outline-none" placeholder="HH">
            </div>
            <div class="flex flex-row items-center gap-2">
                <label for="minutes" class="text-xs">Min</label>
                <input v-model="customMins" type="number" id="minutes" min="0" class="w-12 px-1 border-white border-b-black p-0 focus:outline-none" placeholder="MM">
            </div> 
        </div>
        <div class="text-teal-600 cursor-pointer hover:text-teal-700">
            <svg @click="toggleCustomEntry" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </div>
</template>