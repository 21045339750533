<script setup lang="ts">

import { useTasks } from '@/store/items/task';
import CloseItem from '../../buttons/CloseItem.vue';
import PhaseDisplay from '@/components/projects/TaskView/TaskPanel/PhaseDisplay/PhaseDisplay.vue';
import TaskForm from '../../forms/TaskForm.vue';

import { ref, computed } from 'vue';

const tasks = useTasks();

const props = defineProps<{
    topItemPK: number,
}>()

const topItem = ref(tasks.getItem(props.topItemPK));
const formIsOpen = ref(false);


const openedItemPK = computed(() => {
  const openedRoot = tasks.opened['root'];

  if (!Array.isArray(openedRoot) || openedRoot.length === 0) {
    // Return `topItemPK` if the array is empty
    return props.topItemPK;
  }

  // Return the last value of the array if not empty
  return openedRoot[openedRoot.length - 1];
});

function openForm(pk: 'new' | number, position?: number | null): void {
    tasks.proposed.category = topItem.value.category;
    formIsOpen.value = true;
}

function closeTask(value: number): void {
  const index = tasks.opened['root'].indexOf(value);
  tasks.opened['root'] = tasks.opened['root'].slice(0, index);
}
function closeAfter(pk: number) {
  const openedRoot = tasks.opened['root'];
  const index = openedRoot.indexOf(pk);

  if (index !== -1) {
    // Keep items up to and including the index of `pk`
    tasks.opened['root'] = openedRoot.slice(0, index + 1);
  } else {
    console.warn(`Task with pk ${pk} not found in opened['root']`);
  }
}

</script>

<template>
<div class="flex flex-col w-full m-4 divide-y border">
    <div class="flex flex-row justify-between p-3">
        <div @click="tasks.opened['root'] = []" class="cursor-pointer hover:text-teal-500">{{ topItem.label }}</div>
    </div>
    <div
      v-for="pk in tasks.opened['root']"
     class="flex flex-row justify-between p-3"
    >
        <div 
          @click="closeAfter(pk)"
          class="cursor-pointer hover:text-teal-500"
        >{{ tasks.getItem(pk).label }}</div>
        <CloseItem @click="closeTask(pk)" />
    </div>
    <div v-if="!formIsOpen" class="p-4">
        <PhaseDisplay 
          :pk="openedItemPK" 
          :goalID="'root'" 
          @openForm="openForm"
        />
    </div>

    <div v-else class="p-4">
        <TaskForm 
          :position="'root'"
          @close="formIsOpen = false"
        />
    </div>
</div>
</template>