<script setup lang="ts">

import Search from "@/components/common/search.vue"

import { ref } from 'vue'

const props = defineProps<{
    projectContacts: any[]
    contactFilter: string
}>();

const emit = defineEmits<{
  (e: 'setContactFilter', value: string): void
  (e: 'setSearchStr', value: string): void
}>();

const searchStr = ref<string>('')
function setSearchStr(str: string) {
    emit('setSearchStr', str)
}
</script>


<template>
<div class="flex flex-row pb-3 justify-between items-center">
    <div
        v-if="projectContacts.length"
        class="border select-none grid grid-cols-2 text-center text-xs font-bold"
    >
        <div
        @click="$emit('setContactFilter', 'individual')"
        :class="{ 'bg-gray-100': contactFilter === 'individual' }"
        class="p-2 hover:bg-gray-100 cursor-pointer"
        >
        Individual
        </div>
        <div
        @click="$emit('setContactFilter', 'group')"
        :class="{ 'bg-gray-100': contactFilter === 'group' }"
        class="p-2 hover:bg-gray-100 cursor-pointer"
        >
        Groups
        </div>
    </div>
    <Search @runSearch="setSearchStr"></Search>
</div>
<div class="flex flex-row gap-1 select-none">

    <div
        class="place-self-end text-xs bg-blue-500 text-white px-1 py-0.5 rounded mt-0.5"
    >
    To
    </div>
    <div
    class="place-self-end text-xs bg-yellow-600 text-white px-1 py-0.5 rounded mt-0.5"
    >
    CC
    </div>
    <div
    class="place-self-end text-xs bg-gray-600 text-white px-1 py-0.5 rounded mt-0.5"
    >
    BCC
    </div>
</div>
</template>