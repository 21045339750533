<script setup lang="ts">

import Search from "@/components/common/search.vue"

import type { ThreadParticipant } from '@/store/items/interfaces/thread';
import type { ContactItem } from '@/store/items/interfaces/contact'

import { useRecipientClass } from './useRecipientClass';
import { useGroups } from '@/store/items/group'

import { ref, computed } from 'vue'

const groups = useGroups()

const props = defineProps<{
    recipients: ThreadParticipant[]
    projectContacts: ContactItem[]
    searchStr: string
}>();

const emit = defineEmits(['toggleGroup'])

const { getRecipientClass } = useRecipientClass({
  recipients: props.recipients,
  projectContacts: props.projectContacts
});

const eligible = computed(() => groups.items.filter(group => 
    group.name.toLowerCase().includes(props.searchStr.toLowerCase())
))


</script>

<template>

 <div>
    <div
    v-if="groups.items.length"
    class="w-full flex flex-row py-2 flex-wrap"
    >
    <div v-for="group in eligible" :key="group.id">
        <div
        @click="$emit('toggleGroup', group)"
        :class="getRecipientClass(group)"
        class="p-1 select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs py-1.5 cursor-pointer"
        >
        {{ group.name }}
        </div>
    </div>
    </div>
    <div v-else class="italic py-4 px-1 text-sm">
        No contact groups exist for this project.
    </div>
</div>
    
</template>