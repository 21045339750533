<script setup lang="ts">

import editEntries from "./editEntries.vue"
import { removeObjectFromArray } from "@/utils/arrayUtils";

import { ref, onMounted, nextTick } from 'vue'

import { useAppointments } from '@/store/items/appointment'

import type { AgendaEntryItem, AgendaSectionItem } from "@/store/items/interfaces/appointment";

const props = defineProps<{
    appointment: number,
}>()

const emit = defineEmits(['close', 'save'])

const store = useAppointments()

const proposed = ref<AgendaSectionItem[]>([])

let isDraggingEntry = ref<boolean>(false)
function setDraggingEntry(value: boolean) {
    isDraggingEntry.value = value
}

let draggedIndex: number | null = null;

function resetOrder(): void {
    proposed.value.forEach((item, index) => {
        item.order = index + 1;
    })
}
function onDragStart(event: DragEvent, index: number): void {
    setDraggingEntry(false);
    draggedIndex = index;
    if (event.dataTransfer) {
        event.dataTransfer.effectAllowed = 'move';
        const dragImage = document.getElementById(`section-${index}`);
        if (dragImage) {
            const clone = dragImage.cloneNode(true) as HTMLElement;
            clone.style.position = 'absolute';
            clone.style.top = '-9999px';
            document.body.appendChild(clone);
            event.dataTransfer.setDragImage(dragImage, 0, 0);
            event.target?.addEventListener('dragend', () => {
                document.body.removeChild(clone);
            }, { once: true });
        }
    }
}

let placeholder: HTMLElement | null = null;

function onDragOver(event: DragEvent, index: number): void {
    event.preventDefault();
    if (isDraggingEntry.value) return;

    const sectionElement = document.getElementById(`section-${index}`);
    if (placeholder && placeholder.nextSibling === sectionElement) {
        return;
    }

    if (placeholder) placeholder.remove();

    placeholder = document.createElement('div');
    placeholder.className = 'placeholder';
    placeholder.style.height = '50px';
    placeholder.style.backgroundColor = '#e0e0e0';
    placeholder.style.margin = '10px 10px 0 10px';

    sectionElement?.parentNode?.insertBefore(placeholder, sectionElement);
}

function onDrop(event: DragEvent, index: number): void {
    if (draggedIndex === null || draggedIndex === index) {
        if (placeholder) placeholder.remove();
        return;
    }
    const draggedItem = proposed.value[draggedIndex];
    proposed.value.splice(draggedIndex, 1);
    proposed.value.splice(index, 0, draggedItem);

    draggedIndex = null;
    if (placeholder) placeholder.remove();

    resetOrder();
}


function onDragEnd(): void {
    if (placeholder) {
        placeholder.remove();
    }
    draggedIndex = null;
}
const newSectionLabel = ref('')
function addSection(): void {
    if (!newSectionLabel.value) return
    proposed.value.push({
        'id': null, 
        'label': newSectionLabel.value,
        'appointment': props.appointment,
        'order': proposed.value.length + 1,
        'agenda_entries': []
    });
}

function refocus(target: HTMLElement) {
    nextTick().then(() => {
        target.focus()
    })
}

function handleNewSectionKeyDown(event: KeyboardEvent) {
    const inputElement = event.target as HTMLElement;
    if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        addSection()
        newSectionLabel.value = "" 
        refocus(inputElement);
    }
}

function addEntry(index: number, text: string){
    const entries = proposed.value[index].agenda_entries;
    entries.push({    
        id: null,
        text: text,
        order: entries.length + 1
    })
}

function repositionEntry(entry: number, draggedIndex: number, sectionIndex: number) {
    const agendaEntries = proposed.value[sectionIndex].agenda_entries;
    const draggedItem = proposed.value[sectionIndex].agenda_entries[draggedIndex];
    agendaEntries.splice(draggedIndex, 1);
    agendaEntries.splice(entry, 0, draggedItem);
    agendaEntries.forEach((item, index) => {
        item.order = index + 1;
    })
}

async function deleteSection(pk: number): Promise<void> {
    const sectionIndex = proposed.value.findIndex(item => item.id === pk);
    if (!proposed.value[sectionIndex].agenda_entries.length || confirm('Delete this section and all of its topics?')) {
        await store.deleteAgendaSection(pk)
        proposed.value = removeObjectFromArray(proposed.value, pk);
        resetOrder()
    }
}

async function deleteEntry(sectionPk: number, pk: number): Promise<void> {
    await store.deleteAgendaEntry(sectionPk, pk)
}


onMounted(() => proposed.value = [...store.agenda])

</script>

<template>
<div class="flex flex-col overflow-auto mb-1">
    <div
        v-for="(section, index) in proposed" 
        @dragover.prevent="onDragOver($event, index)"
        @drop="onDrop($event, index)"
    >
        <div 
            :id="'section-' + index"
            class="flex flex-col px-2 pt-3"
        >
            <div class="flex flex-col p-3 border">
                <div class="flex flex-row">
                    <div 
                    :draggable="true" 
                    @dragstart="onDragStart($event, index)" 
                    @dragend="onDragEnd()" 
                    class="flex flex-col flex-grow mb-2 cursor-move drag-handle justify-around"
                    >
                        <div class="border-t border-slate-300 py-0.5"></div>
                        <div class="border-t border-slate-300 py-0.5"></div>
                        <div class="border-t border-slate-300 py-0.5"></div>
                        <div class="border-t border-slate-300 py-0.5"></div>
                    </div>
                    <div @click="deleteSection(section.id!)" class="flex flex-row mb-3 ml-2 text-gray-500 hover:text-red-700 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </div>
                </div>
                <input v-model="section.label" type="text" class="py-0.5 text-gray-800 border border-dashed px-1 border-gray-400 focus:ring-0 focus:border-teal-500">
                <editEntries
                    :sectionID="section.id"
                    :sectionIndex="index"
                    :items="section.agenda_entries"
                    :isDragging="isDraggingEntry"
                    @addItem="addEntry"
                    @reposition="repositionEntry"
                    @setDrag="setDraggingEntry"
                    @deleteEntry="deleteEntry"
                ></editEntries>
            </div>
        </div>
    </div>

    <div class="flex flex-col p-2">
        <div class="flex flex-col px-2 py-4 border">
            <input
                v-model="newSectionLabel"
                @keydown="event => handleNewSectionKeyDown(event)"
                type="text"
                placeholder="+ Add Section" 
                class="py-0.5 placeholder:text-gray-400 text-gray-800 border border-dashed px-1 border-gray-400 focus:ring-0 focus:border-teal-500"
            >
        </div>
    </div>
</div>
<div class="flex flex-row gap-4 justify-end p-4 border-t">
    <div @click="emit('close', true)" class="btn-caution">Cancel</div>
    <div @click="emit('save', proposed)" class="btn-confirm">Save</div>
</div>
</template>