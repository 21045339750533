import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./router";
import { createPinia } from "pinia";
import "./index.css";
import VueClickAway from "vue3-click-away";
import vue3GoogleLogin from "vue3-google-login";
import "v-calendar/dist/style.css";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createVuetify } from 'vuetify'
import { Vue3Mq } from "vue3-mq";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'hacktimer/HackTimer.js'


const isLoggedIn = (user) => {
  let result = false
  if (user && user !== 'null'){
    result = true
  }
  return result
}

const app = createApp(App);
app.use(createPinia());

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const publicRoutes = ['login', 'home', 'request-access'];

router.beforeEach((to, from, next)=>{
  let key = Math.random().toString(36);
  localStorage.setItem("preloadKey", key);
  if (isLoggedIn(localStorage.getItem('user'))) {
    // preloadEmails(key);
    if ( to.name === 'login' || to.name === 'request-access' || to.name === 'home') {
      next({
        path: '/today/',
        replace: true
    })
    } else {
      next()
    }
  } else if (!publicRoutes.includes(to.name)){
    console.log(to.name)
    next({
        path: '/',
        replace: true
    })
  } else {
    next() 
  }
})

app.use(router);

app.use(VueClickAway);

app.use(Vue3Mq, {
  preset: 'tailwind'
});

const toastOptions = {position: POSITION.BOTTOM_LEFT}
app.use(Toast, toastOptions)

app.use(vue3GoogleLogin, {
  clientId: "326869990964-gu4cepcdjv6mo0foepgpcda32e0s84a9.apps.googleusercontent.com",
  scopes: "https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/userinfo.email",
});
// const gAuthOptions = {
//   clientId:
//     "326869990964-gu4cepcdjv6mo0foepgpcda32e0s84a9.apps.googleusercontent.com",
//   scope: "https://mail.google.com/",
//   prompt: "consent",
//   fetch_basic_profile: false,
// };
// app.use(GAuth, gAuthOptions);

const vuetify = createVuetify({})

app.use(vuetify)

app.component('Datepicker', Datepicker);

app.directive('focus', {
  mounted(el) {
    el.focus()
  }
})

app.mount("#app");


