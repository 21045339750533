<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  options: { label: string; value: string }[];
  modelValue: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();

function selectOption(value: string) {
  emit("update:modelValue", value);
}
</script>

<template>
  <div class="flex gap-2 border-b p-3">
    <div
      v-for="option in options"
      :key="option.value"
      @click="selectOption(option.value)"
      :class="{
        'text-teal-600 border-b-2 border-teal-600': modelValue === option.value,
        'text-gray-500 cursor-pointer': modelValue !== option.value,
      }"
      class="cursor-pointer"
    >
      {{ option.label }}
    </div>
  </div>
</template>