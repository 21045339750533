import { createStore } from "@/store/items/common/index.js";

const template = { name: "" };

const customGetters = {
  getOtherProjects: (state) => (pk) => state.items.filter(item => item.id !== pk)
};

const customActions = {
  async loadItems() {
    const data = await this.interact("filter", { category__isnull: true });
    this.items = data.results;
  },
  updateOrder(items) {
    this.customInteract('order', 'update_category_order', items);
  }
};

export const useProjects = createStore({
  model: "project",
  template,
  customGetters,
  customActions,
});
