<script setup lang="ts">

import Search from '@/components/common/search.vue';
import SelectOptionInline from '@/components/common/buttons/SelectOptionInline.vue';

import { ref } from 'vue';

const options = [
    {'label': "Open",'value': 1},
    {'label': "Resolved", 'value': 2},
    {'label': "Draft", "value": 4},
    {'label': "Muted", 'value': 3}
]

const emit = defineEmits<{
  (e: 'setStatus', value: number): void,
  (e: 'setSearchTerm', value: string): void
  (e: 'setCompose'): void
}>()

const selected = ref<number>(1)

const setView = (value: number) => {
    selected.value = value
    emit('setStatus', value)
}

function runSearch(value: string) {
    emit('setSearchTerm', value)
}

</script>

<template>
    <div class="flex flex-row justify-between border-b items-center p-3">
        <div class="flex flex-row gap-4">
            <Search
              @runSearch="runSearch"
            ></Search>
            <SelectOptionInline
                :options="options"
                :selected="selected"
                @selectOption="setView"
            ></SelectOptionInline>
        </div>
        <div class="flex flex-row items-center">
            <div @click="emit('setCompose')" class="btn-bold">Compose</div>
        </div>
    </div>
</template>