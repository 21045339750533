
export function getTaskUrgencyClass(distance: number) {
    let output = "border-gray-300";
    if (distance <= 1) {
      output = "border-red-500";
    } else if (distance === 2) {
      output = "border-yellow-400";
    }
    return output;
}

export function getUrgencyRating(deadline: string | null, target?: string | null): number {
  const currentDate = target ? new Date(target) : new Date();

  if (!deadline) {
    return 5; // Default urgency level when no deadline exists
  }

  const differenceInDays = (new Date(deadline).getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24);

  if (differenceInDays < 1) return 1; // Less than 1 day
  if (differenceInDays <= 2) return 2; // Between 1 and 2 days
  if (differenceInDays <= 7) return 3; // Between 3 and 7 days
  return 4; // More than 7 days
}
