import { createStore } from "@/store/items/common/index.js"

import { mergeArrays } from "@/utils/arrayUtils";

const template = {
    model: 'message',
    project: null,
    rfcid: "new",
    subject: "",
    body: "",
    contacts: {
        primary: [],
        secondary: [],
        to: [],
        cc: [],
        bcc: []
    },
}

const customStore = {
  data: [],
  loaded: {
    task: [],
    appointment: []
  },
  transfer: {
    target: null,
    data: {
      'action': 'link_objects',
      'id': null,
      'model': null
    },
  }
}

const customActions = {
  async loadRelated(model, id) {
    const data = { model: model, id: id };
    const response = await this.customInteract(
      "filter",
      "related_messages",
      data
    );
    this.items = mergeArrays(this.items, response.data.items, "id");
    this.data = mergeArrays(this.data, response.data.data, "id");
    this.loaded[model].push(id)
  },
}

export const useMessages = createStore({
  model: "message",
  template,
  customStore,
  customActions
});
