<script setup lang="ts">

import type { PhaseItem, TaskItem } from '@/store/items/interfaces/task';

import GoalTrayHeader from './GoalTrayHeader.vue';
import DisplayGoalDetails from './DisplayGoalDetails.vue';
import Archive from '@/components/common/Archive.vue'

import {useTasks} from '@/store/items/task'
import { getDate } from '@/utils/dateUtils';

import { ref, computed, onMounted, watchEffect } from 'vue';
import FinishGoalForm from './FinishGoalForm.vue';

const tasks = useTasks()

const props = defineProps<{
    project: number;
    obj: TaskItem
}>();

const emit = defineEmits(['openGoalForm', 'closeForm'])

const showDelayForm = ref<boolean>(false)
const showArchiveForm = ref<boolean>(false)
function openArchiveForm() {
    tasks.setSelected(props.obj.id)
    showArchiveForm.value = true
}

// const obj = computed(() => taskStore.getItem(props.pk))
// const formLabel = computed(() => taskStore.formLabel)
const goalIsCompleted = ref(false)
const finalDeadline = computed(() =>
  props.obj.final_deadline ? getDate(props.obj.final_deadline) : "N/A"
)
function checkAllTasksStatus() {
  const items = tasks.forParent(props.obj.id);
  // Check if all items have a status of 5
  goalIsCompleted.value = items.length > 0 && items.every(item => item.status === 5);
}

watchEffect(() => {
  checkAllTasksStatus();
});
onMounted(() => {
  tasks.opened[`${props.obj.id}`] = []
  checkAllTasksStatus();
});


</script>

<template>
    <div class="flex flex-col h-full w-[650px] flex-shrink-0 border-r p-5">
        <div class="flex flex-col gap-4 flex-grow min-h-0">
            
            <GoalTrayHeader 
                :obj="obj"
                @openForm="emit('openGoalForm', obj.id)"
                @openArchive="openArchiveForm"
            />

            <Archive 
              v-if="showArchiveForm"
              :project="props.project"
              :store="tasks"
              @close="showArchiveForm = false"
            />
            <div v-else class="flex flex-col justify-between flex-grow min-h-0">

                <div class="flex flex-row justify-between text-gray-500 text-sm py-2">
                    <div>Final Deadline: {{ finalDeadline }}</div>
                </div>

                <FinishGoalForm 
                    v-if="goalIsCompleted"
                    :obj="obj"
                    @cancel="goalIsCompleted = false"
                    @openArchive="openArchiveForm"
                ></FinishGoalForm>

                <DisplayGoalDetails 
                    v-else
                    :project="project"
                    :obj="obj"
                />
            </div>
        </div>
    </div>        
</template>

<style>
.goalSelect {
     direction: rtl;
}
.disable
{
    background: repeating-linear-gradient(
        -55deg,
        #eaeaea,
        #ababab 3px
        );
    filter:alpha(opacity=50); /* IE */
    opacity: 0.5; /* Safari, Opera */
    -moz-opacity:0.50; /* FireFox */
    z-index: 20;
    background-repeat:no-repeat;
    background-position:center;
    top: 0px;
    left: 0px;
}
</style>
