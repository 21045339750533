import { ref } from "vue";

export function useGoogleCallback() {
  const isValid = ref(false);
  const responseData = ref<any>(null);
  const error = ref<string | null>(null);

  const processCallback = (response: any) => {  
    try {
      // Example: Extract and verify response properties
      if (response?.credential) {
        const payload = parseJwt(response.credential); // Decoding the JWT
        responseData.value = payload;
        // Add custom validation logic here
        isValid.value =
          !!payload.email &&
          !!payload.sub &&
          !!payload.iss &&
          payload.iss === "https://accounts.google.com";

        if (!isValid.value) {
          throw new Error("Invalid response data");
        }
      } else {
        throw new Error("No credential found in response");
      }
    } catch (err: any) {
      error.value = err.message || "Unknown error occurred";
      console.error(err);
    }
  };

  const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${c.charCodeAt(0).toString(16).padStart(2, "0")}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  return {
    isValid,
    responseData,
    error,
    processCallback,
  };
}