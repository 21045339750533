import { createStore } from "@/store/items/common/index.js";

import { useWorkdays } from "@/store/items/workday"
// update() with query "record" automatically updates task

const template = {
  task: null,
  workday: null,
  minutes_planned: 0,
  minutes_worked: 0,
  completed: false,
}

const customGetters = {
  forWorkday: (state) => (workdayPK) => state.items.filter((item) => item.workday === workdayPK),
  forTask: (state) => (taskPK) => state.items.filter((item) => item.task === taskPK),
  forWorkdayAndTask: (state) => (workdayPK, taskPK) => state.items.find((item) => Number(item.workday) === Number(workdayPK) && Number(item.task) === Number(taskPK)),
}

const customActions = {
  updateOrder(items) {
    this.customInteract('custom', 'update_record_order', items)
  },
  async postpone(date){
    const payload = { pk: this.selected, date: date }
    const result = await this.customInteract('custom', 'postpone_record', payload)
    console.log(result)
    this.setItem(result.object.id, result.object)
  },
  toggleCompleted(pk, taskStatus) {
    const record = this.getItem(pk)
    const isCompleted = !record.completed
    const data = {id: pk, completed: isCompleted, status_for_task: taskStatus};
    this.customInteract('update', 'record', data)
    record.completed = isCompleted
    const workday = useWorkdays().getItem(record.workday)
    workday.minutes_worked += record.minutes_worked > 0 ? record.minutes_worked : record.minutes_planned
  },
  async deleteForDate(workdayID) {
    const idSet = this.items.filter((item) => item.workday === workdayID).map((item) => item.id)
    const response = await this.bulkInteract('delete', idSet) 
    if (!response.error) {
      const deleted = this.items
      .filter(item =>
        item.workday === workdayID &&
        !item.completed &&
        item.minutes_worked === 0
      )
      .map(item => item.id);
      this.removeItems(deleted)
    }
}}

export const useRecords = createStore({
  model: "record",
  template,
  customGetters,
  customActions,
})