<script>
import { ref, computed, onBeforeMount} from "vue";
import { useRouter } from "vue-router";
import { useDirector } from "@/store/pages/director";
import { useProjects } from "@/store/items/project";
import { useThreads } from "@/store/items/thread";
import { MqResponsive } from "vue3-mq";
import axios from "axios";

export default {
  name: "Application",
  props: {
    msg: String,
  },
  components: {MqResponsive },
  setup() {
    const director = useDirector();
    const projects = useProjects();
    const threads = useThreads();
    const dataLoaded = ref(false);
    const router = useRouter();
    const projectItems = computed(() => projects.items)
    const path = computed(() => router.currentRoute.value.path)
    const publicRoutes = ['login', 'home', 'request-access'];
    const hasUnfilteredThreads = computed(() => threads.items.filter((item) => item.category === null).length)

    let loading = ref(false);
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const checkLogin = async () => {
      const accessToken = localStorage.getItem("access_token");
      let permitted = true;

      if (accessToken && accessToken !== "null") {
        const url = `${apiEndpoint}/check_auth/`;
        await axios
          .get(url, {
            headers: { Authorization: `Token ${accessToken}` },
          })
          .then(async (response) => {
            axios.defaults.headers.common = {
              Authorization: `Token ${accessToken}`,
            };
            director.user = response.data.user_id;
            director.accounts = response.data.accounts;
            const globalAccount = director.accounts.find((account) => !account.category)
            if (threads && !threads.items.length){
              threads.startMessageSync(globalAccount.id)
              threads.fetchTriageMessages();
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 401) {
              if (router.currentRoute.value.path !== "/login/") {
                permitted = false;
                clearStorage();
                // router.replace("/");
              }
            }
          });
      } else {
        permitted = false;
        clearStorage();
        // router.replace("/");
      }
      return permitted;
    };
    onBeforeMount(async () => {
      if (path !== "/login/") {
        const permitted = await checkLogin();
        if (permitted) {
          await projects.loadItems();
          dataLoaded.value = true;
          director.loginSignal = true
          if (path === "/") { 
            router.replace("/today/");
          }
        }
      }
      dataLoaded.value = true;
    });

    const clearStorage = () => {
      director.user = "";
      localStorage.setItem("access_token", null);
      localStorage.setItem("refresh_token", null);
      localStorage.setItem("user", null);
      axios.defaults.headers.common = {
        Authorization: null,
      };
      dataLoaded.value = true;
    };

    const logout = async () => {
      director.loginSignal = false
      const accessToken = localStorage.getItem("access_token");
      const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
      const url = `${apiEndpoint}/logout/`;
      await axios
          .post(url, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(async () => {
            clearStorage()
            router.go();
          })
    }

    const getHeaderClass = (pageName) => {
      let classString = ""
      if (router.currentRoute.value.name === pageName) {
        classString = "text-pink-700 hover:text-pink-700"
      }
      return classString
    }

    // const resubmitTimedTasks = async () => {
    //   await axios.post(`${apiEndpoint}/timed_tasks/`, { 'date': javascriptNonsenseForUserTime() });
    // };

    // watch(router.currentRoute, async (to, from) => {  
    //   const currentTime = getUserLocalTime()
    //   if (dataLoaded.value && currentTime.getDate() && currentTime.getDate() !== director.cachedTime.getDate()) {        
    //     await resubmitTimedTasks();
    //   }
    //   director.cachedTime = currentTime
    // });

    const displayClass = computed(() =>  !director.focusTask && !director.focusAppointment ? 'h-screen w-screen' : 'hidden')

    // const messageAlert = computed(() => threads.items.filter(item => !item.category).length)

    return {
      projects,
      director,
      router,
      paths: {},
      displayMenu: ref(false),
      getHeaderClass,
      displayClass,
      dataLoaded,
      projectItems,
      threads,
      hasUnfilteredThreads,
      // messageAlert,
      logout,
      publicRoutes,
      path
    };
  },
};
</script>

<template>

  <div class="overflow-hidden h-screen font-sofia">
    <MqResponsive target="sm-">
      <div class="flex flex-row justify-between p-4">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
        <div>On-It.pro</div>          
      </div>
    </MqResponsive>
    <div :class="displayClass" class="flex flex-col  bg-gray-100 ">
      <div class="flex flex-row p-4 text-lg bg-white shadow justify-between">
          <div>On-It.pro</div> 
          <div v-if="path !== '/' && !publicRoutes.includes(path)" class="flex flex-row gap-20 uppercase select-none">
            <div @click="router.replace('/today/');" :class="getHeaderClass('dashboard')" class="cursor-pointer hover:text-gray-600">Today</div>
            <div @click="router.replace('/projects/');" :class="getHeaderClass('projects')" class="cursor-pointer hover:text-gray-600">Projects</div>
            <div @click="router.replace('/agenda/current/');" :class="getHeaderClass('agenda')" class="cursor-pointer hover:text-gray-600">Agenda </div>
            <div @click="router.replace('/calendar/');" :class="getHeaderClass('calendar')" class="cursor-pointer hover:text-gray-600">Calendar </div>
          </div>
          <div class="flex flex-row gap-5">
            <div v-if="director.user" @click="router.replace('/feedback/');" class="cursor-pointer hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
              </svg>
            </div>
            <div v-if="director.user" class="relative inline-flex w-fit">
              <!-- <div v-if="messageAlert" class="absolute bottom-auto left-auto right-1 top-0.5 z-10 inline-block -translate-y-1/2 translate-x-2/4 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 rounded-full bg-pink-700 p-1.5 text-xs">
              </div> -->
               <div @click="router.replace('/inbox/');" class="cursor-pointer hover:text-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
                <span v-if="hasUnfilteredThreads" class="absolute top-0 right-0 w-3 h-3 bg-red-600 rounded-full"></span>
              </div>
            </div>
            <div v-if="director.user" @click="logout" class="cursor-pointer hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
              </svg>
            </div>
            <div v-else @click="router.replace('/login/')" class="cursor-pointer hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
              </svg>

            </div>
          </div>
        </div>
        <RouterView v-slot="{ Component }">
          <template v-if="Component && dataLoaded">
              <Suspense>
                <component :is="Component"></component>
                <template #fallback> Loading... </template>
              </Suspense>
          </template>
        </RouterView>
      </div>
  </div>
</template>

<style scoped>
.background {
  background-color: #E8F3F1;
}
</style>
