<script setup lang="ts">

import ListedTask from "@/components/common/listedItems/ListedTask.vue";

import { useAgenda } from "@/store/pages/agenda";
import { ref, computed, watchEffect, nextTick } from 'vue';

import type { TaskItem } from "@/store/items/interfaces/task"
import type { RecordItem } from "@/store/items/interfaces/record"
import type { CategoryItem } from "@/store/items/interfaces/project"
import type { WorkdayItem } from "@/store/items/interfaces/workday";
import { useTasks } from "@/store/items/task";
import { useWorkdays } from "@/store/items/workday"

const tasks = useTasks()
const workdays = useWorkdays()

const workdayItem = computed<WorkdayItem>(() =>   workdays.items.find((i: WorkdayItem) => i.id === props.workday))

const props = defineProps<{
    project: CategoryItem;
    workday: number | null;
    records: RecordItem[];
    minutesPlanned: number;
    statusFilter: number;
    dataLoaded: boolean;
}>();

const emit = defineEmits(['openTaskForm']);

const showTaskList = ref<boolean>(true);

const taskItems = computed<TaskItem[]>(() => {
    const projectTasks = tasks.forProject(props.project.id!).filter((item: TaskItem) => item.children.length === 0);

    // Obtain the currently active phase, which is the set with the lowest phase number where a task has a status other than Done.
    const lowestPhaseGroups = projectTasks.reduce((lowestGroups: Map<number, number>, task: TaskItem) => {
        if (task.parent) {
            const taskPhaseGroup = lowestGroups.get(task.parent) || Infinity;
            lowestGroups.set(task.parent, Math.min(taskPhaseGroup, task.phase!));
        }
        return lowestGroups;
    }, new Map<number, number>());

    return projectTasks.filter((task: TaskItem) => {
        const taskPhaseGroup = task.parent ? lowestPhaseGroups.get(task.parent) : null;
        let statusFilter = props.statusFilter;

        if (statusFilter === 1 && task.minutes_available <= 0) {
            return false;
        }

        if (statusFilter === 0) {
            if(task.minutes_available > 0) {
                return false;
            }
            statusFilter = 1
        }

        if (task.status !== statusFilter) {
            return false;
        }

        return (
            task.status === statusFilter &&
            ((task.status !== 5 || !task.parent) || (taskPhaseGroup === task.phase))
        );
    });
});

const addRecord = async (taskID: number): Promise<void> => {
    await workdays.autoAssignRecord(taskID, props.workday!);
};

watchEffect(() => {
    showTaskList.value = taskItems.value.length > 0;
});

</script>

<template>
    <div class="flex flex-row gap-2 p-2 items-center" :class="{'border-b': showTaskList === true}">
        <div @click="showTaskList=true" class="text-gray-500 cursor-pointer hover:text-gray-600" v-if="!showTaskList">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
        <div @click="showTaskList=false" class="text-gray-500 cursor-pointer hover:text-gray-600" v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
        <div>{{ project.name }}</div>
    </div>
    <div v-if="showTaskList" class="flex flex-col flex-grow max-h-40 text-sm overflow-y-auto gap-1 p-1 cursor-pointer">
        <ListedTask
            v-for="task in taskItems"
            :key="task.id!"
            :pk="task.id!"
            @actionA="emit('openTaskForm', task.id, project.id)"
            @actionB="addRecord"
            :dataLoaded="dataLoaded"
        >
        </ListedTask>
        <div @click="emit('openTaskForm', 'new', project.id)" class="text-gray-400 border p-2 cursor-pointer hover:bg-gray-100"> + Add Task </div>
    </div>
</template>