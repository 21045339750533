<script setup lang="ts">

</script>

<template>
    
    <div class="flex-col p-2 border w-full bg-white border-dashed cursor-pointer text-gray-500  hover:text-teal-500">
        <div class="text-sm py-4 text-center">+ New Notecard</div>
    </div>

</template>