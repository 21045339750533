<script setup lang="ts">

import type { AppointmentItem, AgendaSectionItem } from '@/store/items/interfaces/appointment'

import SectionList from './SectionList.vue'
import EntryList from './EntryList.vue'

import { useAppointments } from '@/store/items/appointment'

import {ref} from 'vue'
import { useSectionDragAndDrop } from './useSectionDragDrop';
import { useEntryDragAndDrop } from './useEntryDragDrop';

import ApptSelectRecurring from '../../formFields/ApptSelectRecurring.vue';

const props = defineProps<{
    appointment: number
    items: AgendaSectionItem[]
}>()

const emit = defineEmits<{
    (e: 'selectItem', sectionIndex: number, entryIndex: number): void;
}>()

const appts = useAppointments()

const newSectionLabel = ref('');
const newEntryText = ref<{ [key: number]: string }>({});

function addNewSection() {
  if (newSectionLabel.value.trim()) {
    const payload = {
        'id': null, 
        'label': newSectionLabel.value,
        'appointment': props.appointment,
        'order': props.items.length + 1,
        'agenda_entries': [],
        'note': []
    };
    appts.saveAgendaSection('create', payload)
    newSectionLabel.value = ''; 
  }
}

function addNewEntry(sectionIndex: number) {
  const text = newEntryText.value[sectionIndex]?.trim();
  if (text) {
    const payload = {
        'id': null, 
        'text': text,
        'order': props.items[sectionIndex].agenda_entries.length + 1
    };
    appts.saveAgendaEntry('create', props.items[sectionIndex].id!, payload)
    // emit('addEntry', sectionIndex, text);
    newEntryText.value[sectionIndex] = ''; // Clear input
  }
}


const {
  draggedSectionIndex,
  onSectionDragStart,
  onSectionDrop,
  onSectionDragOver,
} = useSectionDragAndDrop();

const {
  draggedEntry,
  onEntryDragStart,
  onEntryDrop,
  onEntryDragOver,
} = useEntryDragAndDrop();


</script>
<template>
  <div 
    v-for="(item, sectionIndex) in items" 
    @dragover.prevent="onSectionDragOver($event)"
    @drop="onSectionDrop($event, sectionIndex, item.id!, appointment)"
    class="flex flex-col" 
    :key="sectionIndex"
  >
    <!-- Editable Section Label -->
    <div
      class="border-b cursor-move flex flex-row items-center w-full"
      draggable="true"
      @dragstart="onSectionDragStart($event, sectionIndex)"
      @dragover.prevent="onSectionDragOver"
      @drop="onSectionDrop($event, sectionIndex, item.id!, appointment)"
    >
      <SectionList :index="sectionIndex" :item="item" />
    </div>

    <!-- Editable Agenda Entries -->
    <div class="flex flex-col gap-1 m-1">
      <!-- Add New Entry Input -->
      <div 
        v-for="(entry, entryIndex) in item.agenda_entries" 
        :key="entryIndex" 
        class="text-sm text-gray-600 cursor-pointer hover:text-teal-600"
      >
        <div
          class="border-b cursor-move flex flex-row items-center w-full"
          draggable="true"
          @dragstart="onEntryDragStart($event, entryIndex, entry.id!)"
          @dragover.prevent="onEntryDragOver"
          @drop="onEntryDrop($event, entryIndex, item.id!, entry.id! )"
        >
          <EntryList 
            :sectionIndex="sectionIndex" 
            :entryIndex="entryIndex"
            :section="item"
            :entry="entry" 
          />
        </div>
      </div>
      <div>
        <input
          v-model="newEntryText[sectionIndex]"
          placeholder="+ Add"
          class="rounded w-full mt-1 text-sm focus:p-1"
          @keyup.enter="addNewEntry(sectionIndex)"
        />
      </div>
    </div>
  </div>

  <!-- Add New Section Input -->
  <div class="mt-4">
    <input
      v-model="newSectionLabel"
      placeholder="Add new section..."
      class="border rounded p-1 w-full"
      @keyup.enter="addNewSection"
    />
  </div>
</template>