<script setup lang="ts">

import StatusField from '@/components/common/formFields/forTask/Status.vue';
import DeadlineField from '@/components/common/formFields/forTask/Deadline.vue';
import EnthusiasmField from '@/components/common/formFields/forTask/Enthusiasm.vue';
import DurationField from '@/components/common/formFields/forTask/Duration.vue';

import { useAppointments } from '@/store/items/appointment'
import { useTasks } from '@/store/items/task'

import { ref, computed } from 'vue'

import type { AgendaAssignmentItem } from '@/store/items/interfaces/appointment';
import type { TaskItem } from '@/store/items/interfaces/task'

const props = defineProps<{
    project: number,
    assignments: AgendaAssignmentItem[]
}>()

const emit = defineEmits(['update'])

const selected = ref(0)
const tasks = useTasks()

const form = computed(() => tasks.proposed)

const assignmentItem = computed(() => props.assignments[selected.value])

function setData() {
    if (assignmentItem.value) {
        const taskID = assignmentItem.value.task
        if (taskID) {
            tasks.proposed = {...tasks.getItem(taskID)}
        } else {
            tasks.proposed.label = assignmentItem.value.text
        }
    }
}

function updateField(data: { field: keyof TaskItem; value: any }): void {
  tasks.proposed[data.field] = data.value;
}

async function saveData() {
    tasks.proposed.category = props.project
    const taskData = await tasks.save()

    const assignmentData = {...assignmentItem.value}
    assignmentData['task'] = taskData.id
    emit('update', 'assignment', assignmentData)

    selected.value += 1
    setData()
}

function incrementTask() {
    selected.value +=1
    setData()
}

setData()

</script>

<template>
    <div class="px-6 border-b pb-4">
        <div v-if="!assignments.length" class="flex flex-col items-center py-12">
            <div class="text-lg pb-6 font-dm">No assignments established.</div>
            <div class="flex flex-row justify-around">
                <button class="bg-slate-700 text-white py-2 px-4 rounded hover:bg-slate-900 transition duration-200">
                    End Meeting
                </button>
            </div>
        </div>
        <div v-else-if="selected + 1 > assignments.length" class="flex flex-col items-center py-12">
            <div class="text-lg pb-6 font-dm">No assignments remaining.</div>
            <div class="flex flex-row justify-around">
                <button class="bg-slate-700 text-white py-2 px-4 rounded hover:bg-slate-900 transition duration-200">
                    End Meeting
                </button>
            </div>
        </div>
        <div v-else class="pt-4 flex flex-col gap-5">
            <div class="flex flex-row justify-around font-dm font-medium text-lg border-b pb-2">Create Tasks from Assignments</div>
            <div class="flex flex-col">         
                <div class="form-label">
                    Label
                </div>
                <input autofocus v-model="form.label" type="text" class="form-field">            
            </div>
   
            <StatusField
              :data="form.status"
               @updateField="updateField"
            ></StatusField>

            <DeadlineField
              :data="form.due_date"
              :isChild="false"
              @updateField="updateField"
            ></DeadlineField>

            <DurationField
              :data="form.minutes_allotted"
              :label="'Remaining Work'"
              @updateField="updateField"
            ></DurationField>

            <EnthusiasmField
              :data="form.enthusiasm"
              :classes="{label:''}"
              @updateField="updateField"
            ></EnthusiasmField>


            <div class="flex flex-row justify-end border-t pt-4 gap-2">
                <div @click="incrementTask" class="btn-dark">Skip This Task</div>
                <div @click="saveData" class="btn-bold">Save</div>
            </div>

        </div>
    </div>
</template>