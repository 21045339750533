<script setup lang="ts">

import DaysToInput from './DaysToInput.vue';
import SelectDate from '@/components/common/formFields/SelectDate.vue';

import { ref, onMounted } from 'vue';

const props = defineProps<{
    dueDate?: string | null;
    daysToDeadline?: number | null;
    label?: string;
    styleClass: string;
}>();

const emit = defineEmits<{
    (e: 'submit', payload: {days_to_deadline: number | null, due_date: string | null}): void;
    (e: 'close'): void;
}>();

const label = ref('');

function submitDays(value:number) {
    const payload = {label: label.value, days_to_deadline: value, due_date: null};
    emit('submit', payload);
}

function submitDueDate(value: string) {
    const payload = {label: label.value, days: null, due_date: value};
    emit('submit', payload);
}

function closeForm() {
    emit('close')
}

onMounted(() => {
    if(props.label) {
        label.value = props.label
    }
});

</script>

<template>
    <div class="flex flex-col pl-2">
        <div class="text text-sm flex flex-col pb-3">
            <div class="pb-1">Label</div>
            <input v-model="label" type="text" class="border border-gray-300 rounded-md p-1 text-sm"  placeholder="(Optional)"/> 
        </div>
        <DaysToInput 
            v-if="!dueDate"
            :styleClass="styleClass"
            :initial="daysToDeadline"
            @submit="submitDays"
            @close="closeForm"
        ></DaysToInput>

        <div v-else class="flex flex-col">
            <SelectDate
                :initial="dueDate"
                @submit="submitDueDate"
            ></SelectDate>  
        </div>
    </div>
</template>