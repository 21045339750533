<script setup lang="ts">
import type { MessageItem } from "@/store/items/interfaces/message";

const props = defineProps({
    message: {
      type: Object as () => MessageItem,
      required: true,
    }})

function formatAMPM(dateStr: string) {
  // Parse the RFC 2822–style date string
  const date = new Date(dateStr);
  
  // Return a readable string like "Wed, Mar 5, 2025, 10:55 AM"
  return date.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true, // Ensures we get AM/PM
  });
}
</script>

<template>
  <div class="cursor-pointer select-none p-4 hover:bg-gray-100 ">
    <div class="flex flex-row justify-between cursor-pointer">

      <div class="flex flex-row gap-2 items-center text-gray-500">
        <div v-if="message.sender.name" class="font-medium text-sm">
          {{ message.sender.name }} wrote:
        </div>
        <div v-else class="font-medium text-sm">
          {{ message.sender.address }} wrote:
        </div>
      </div>

      <div class="flex flex-row gap-3 items-center pr-5">
        <div class="text-xs text-gray-500">{{ formatAMPM(message.date) }}</div>
      </div>

    </div>

    <div 
      class="text-sm text-gray-900 truncate-1 pt-1"
      v-html="message.snippet"
    ></div>

  </div>
</template>

<style scoped></style>