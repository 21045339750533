import type { Item } from '@/store/items/interfaces/common'

export function mergeArrays<T extends Item>(oldArray: T[], newArray: T[], index: number): T[] {
  const output = oldArray.map(
    s => newArray.find(
      t => t.id === s.id
    ) || s
  ).concat(
    newArray.filter(
      s => !oldArray.find(t => t.id === s.id)
    )
  );
  return output;
}

export function removeObjectFromArray<T extends Item>(array: T[], pk: number | string): T[] {
  return array.filter(item => item.id !== pk);
}

export function addOrRemoveFromArray<T>(array: T[], item: T): T[] {
  const index = array.indexOf(item);
  if (index === -1) {
    return [...array, item]; // Add the item
  } else {
    return array.filter((_, i) => i !== index); // Remove the item
  }
}