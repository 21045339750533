<script setup lang="ts">

import type { NotecardItem } from '@/store/items/interfaces/notecard';
import Notecard from './Notecard.vue';
import NotecardForm from './NotecardForm.vue';
import NewCard from './NewCard.vue';
import NotecardHeader from './NotecardHeader.vue';

import { ref, computed, onMounted, watch } from 'vue';
import { useNotecards } from '@/store/items/notecard';
import { useRoute } from 'vue-router'; 

const route = useRoute();
const task = ref(parseInt(route.params.task as string, 10))

const notecards = useNotecards();

const selected = ref<number | null>(null);

const items = computed(() => notecards.getSet('task', task.value).filter(item => item.parent === selected.value));

function balanceColumns(items: NotecardItem[]): [NotecardItem[], NotecardItem[]] {

    const leftItems: NotecardItem[] = [];
    const rightItems: NotecardItem[] = [];
    let leftHeight = 0;
    let rightHeight = 0;

    for (const item of items) {
        if (leftHeight <= rightHeight) {
            leftItems.push(item);
            leftHeight += item.text.length;
        } else {
            rightItems.push(item);
            rightHeight += item.text.length;
        }
    }

    return [leftItems, rightItems];
}

const columnDistribution = computed(() => balanceColumns(items.value));

const leftItems = computed(() => columnDistribution.value[0]);
const rightItems = computed(() => columnDistribution.value[1]);

const shorterColumn = computed(() => {
    const leftHeight = leftItems.value.reduce((sum, item) => sum + item.text.length, 0);
    const rightHeight = rightItems.value.reduce((sum, item) => sum + item.text.length, 0);

    return leftHeight <= rightHeight ? 'left' : 'right';
});

const formOpened = ref(false)

function openForm(id: number | 'new') {
    if (id === 'new') {
        notecards.clearProposed();
        notecards.proposed.task = task.value;
        notecards.proposed.parent = selected.value;
    } else {
        notecards.setSelected(id);
        selected.value = id;
    }
    formOpened.value = true;
}

function deselect() {
    if (selected.value === null) return;
    selected.value = notecards.getItem(selected.value)?.parent ?? null;
    notecards.clearProposed();
    formOpened.value = false;
}

onMounted(() => {
    notecards.loadItems(task.value);
});

watch(task, () => {
    notecards.loadItems(task.value);
});

</script>

<template>

<div class="flex flex-col bg-gray-100 h-ful overflow-auto">
    <NotecardHeader 
      :selected="selected" 
      @deselect="deselect"
      @openForm="openForm"  
    />
    <div v-if="formOpened===true" class="border-t">
        <NotecardForm
          @close="formOpened=false"
        ></NotecardForm>
    </div>
    <div class="flex flex-row gap-4 p-6 overflow-y-auto">
        <div class="flex flex-col items-center gap-4 w-1/2">
            <Notecard
                v-for="item in leftItems"
                :key="item.id!"
                :item="item"
                @click="selected = item.id"
            />
            <NewCard
                v-if="shorterColumn === 'left'"
                @click="openForm('new')"
            ></NewCard>
        
        </div>
        <div class="flex flex-col items-center gap-4 w-1/2">
            <Notecard
                v-for="item in rightItems"
                :key="item.id!"
                :item="item"
                @click="selected = item.id"
            />
            <NewCard
                v-if="shorterColumn === 'right'"
                @click="openForm('new')"
            ></NewCard>
        </div>
    </div>
</div>
</template>