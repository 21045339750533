<script setup lang="ts">

import SelectContact from './SelectContact.vue';
import SortPanel from '@/components/inbox/DisplayThreads/SortPanel.vue';

import { useThreads } from '@/store/items/thread';

import { ref, computed } from 'vue'

const threads = useThreads()

const searchStr = ref<string>("");
function setSearch(str: string) {
    searchStr.value = str;
}

const props = defineProps<{
    filterAll: boolean
    useSubject: boolean
}>()

const emit = defineEmits(['setFilterAll', 'setUseSubject', 'submit'])


function setFilterAll (value: boolean) {
    emit('setFilterAll', value)
}

function setUseSubject(value: boolean) {
    emit('setUseSubject', value)
}

const eligibleForSubmission = computed(() => {
    return threads.filterData.category && (props.filterAll || threads.filterData.threadIDs.length);
})

function submitFilter() {
    if (eligibleForSubmission) {
        emit('submit')
    }
}

</script>

<template>
    <div class="flex flex-row w-full p-4 border-b justify-around" style="height:475px">
        <SortPanel
            :filterAll="filterAll"
            :useSubject="useSubject"
            @setFilterAll="setFilterAll"
            @setUseSubject="setUseSubject"
        ></SortPanel>
        <SelectContact></SelectContact>
    </div>
    <div class="flex flex-row justify-end p-2 border-b w-full"> 
        <div @click="submitFilter" :class="{ 'btn-confirm': eligibleForSubmission, 'btn-disabled': !eligibleForSubmission}">Apply</div>
    </div>

</template>