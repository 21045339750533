<script setup lang="ts">

import textEdit from '@/components/common/textEdit.vue'

import { ref, nextTick } from 'vue'

import type { AgendaAssignmentItem, AgendaNoteItem } from '@/store/items/interfaces/appointment';
import type { ContactItem } from '@/store/items/interfaces/contact'

const props = defineProps<{
    assignments: AgendaAssignmentItem[],
    note: AgendaNoteItem,
    sectionPK: number,
    entryPK: number,
    participants: ContactItem[], 
}>()

const emit = defineEmits(['create', 'update', 'delete'])

const newAssignment = ref<string>("")

const assignmentFields = ref<HTMLInputElement[] | null>(null)
const assignmentEditing = ref<number | null>(null)
const showDropdownFor = ref<number | null>(null)
const editingText = ref<string>('')

function openEditing(pk: number, text: string): void {
  if (assignmentEditing.value === pk) {
    assignmentEditing.value = null;
  } else {
    editingText.value = text;
    assignmentEditing.value = pk;
    nextTick(() => {
      if (assignmentFields.value && assignmentFields.value[0]) {
        assignmentFields.value[0].focus();
      }
    });
  }
}

function createAssignment() {
    if (newAssignment.value) {
        const data = {
            text: newAssignment.value,
            section: props.sectionPK,
            entry: props.entryPK
        }
        console.log(data)
        emit('create', 'assignment', data)
        newAssignment.value = ""
    }
}

function updateAssignment() {
    const data = {
        id: assignmentEditing.value,
        text: editingText.value
    }
    emit('update', 'assignment', data)
    assignmentEditing.value = null,
    editingText.value = ""
}

function deleteAssignment(pk: number) {
    emit('delete', 'assignment', pk, props.sectionPK, props.entryPK)
}

function createNote(text: string, sectionPK: number, entryPK: number) {
    const data = {
        text: text,
        entry: entryPK,
        section: sectionPK
    }
    emit('create', 'note', data)
}

function updateNote(pk: number, text: string) {
    const data = {
        id: pk,
        text: text,
    }
    emit('update', 'note', data)
}

function deleteNote(pk: number, sectionPK: number, entryPK: number) {
    emit('delete', 'note', pk, sectionPK, entryPK)
}

const toggleDropdown = async (pk: number | null) => {
    if (!showDropdownFor.value) {
        showDropdownFor.value = pk;
        setTimeout(() => {
            document.addEventListener('click', handleClickOutside);
        }, 100); // Short delay to allow the dropdown to open
    } else {
        showDropdownFor.value = null;
        document.removeEventListener('click', handleClickOutside);
    }
}


function setParticipantForAssignment(contactPK: number | 'self', assignmentPK: number): void {
  const data: Partial<AgendaAssignmentItem> = { id: assignmentPK };
  if (contactPK === 'self') {
    data.assigned_to_self = true;
    data.contact = null;
  } else {
    data.assigned_to_self = false;
    data.contact = contactPK;
  }
  emit('update', 'assignment', data);
  toggleDropdown(assignmentPK);
}


function handleClickOutside(event: MouseEvent): void {
  if (!event.target || !(event.target as HTMLElement).closest('.absolute')) {
    toggleDropdown(null);
  }
}

function getAssignedName(contactPK: number | 'self') {
    if (contactPK === 'self') {
        return 'msyelf'
    } else {
        const contact = props.participants.find(item => item.id === contactPK)
        return contact!.name
    }
}


</script>

<template>
    <div class="flex flex-col p-4 gap-6 overflow-auto flex-grow">
        <div class="flex flex-col">
            <div class="font-light">
                Actionable Items
            </div>
            <div class="flex flex-col w-full gap-1 divide-y border-b">
                <div v-for="assignment in assignments" class="flex flex-col p-2">
                    <div class="flex flex-row justify-between">
                        <div v-if="assignmentEditing !== assignment.id" class="">{{ assignment.text }}</div>
                        <div v-else><input @keydown.enter="updateAssignment()" class="border-gray-300 p-1 my-1.5" v-model="editingText" ref="assignmentFields" type="text" /></div>
                        <div class="text-gray-400 flex flex-row gap-1">
                            <div @click="openEditing(assignment.id!, assignment.text)" class="cursor-pointer hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" /></svg></div>
                            <div @click="deleteAssignment(assignment.id!)" class="cursor-pointer hover:text-red-500"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg></div>
                        </div>
                    </div>
                    <div v-if="participants.length && assignmentEditing !== assignment.id" class="flex flex-row gap-6 text-sm text-gray-600">
                        
                        <div v-if="assignment.contact" class="flex flex-row gap-2 text-sm items-end">
                            <div class="text-gray-600">Assigned to: <span class="font-medium text-pink-700">{{ getAssignedName(assignment.contact) }}</span></div>
                            <div @click="toggleDropdown(assignment.id)" class=" text-xs border-b border-dashed select-none border-teal-600 text-teal-600 cursor-pointer hover:border-teal-700 hover:text-teal-700">Change</div>
                        </div>
                        <div v-else-if="assignment.assigned_to_self" class="flex flex-row gap-2 text-sm items-end">
                            <div class="text-gray-600">Assigned to: <span class="font-medium text-pink-700">Me</span></div>
                            <div @click="toggleDropdown(assignment.id)" class=" text-xs border-b border-dashed select-none border-teal-600 text-teal-600 cursor-pointer hover:border-teal-700 hover:text-teal-700">Change</div>
                        </div>
                        <div v-else @click="toggleDropdown(assignment.id)" class="border-b border-dashed select-none border-teal-600 text-teal-600 cursor-pointer hover:border-teal-700 hover:text-teal-700">Assign to Participant</div>
                        <div v-if="showDropdownFor === assignment.id" class="absolute bg-white shadow-lg border p-2 mt-2 z-50" @click.stop>    
                            <ul>
                                <li @click="setParticipantForAssignment('self', assignment.id!)" class="cursor-pointer p-2 hover:bg-gray-100"> 
                                    <div class="text-lg">Myself</div>
                                </li>
                                <li v-for="participant in participants" :key="participant.id!" @click="setParticipantForAssignment(participant.id!, assignment.id)" class="cursor-pointer p-2 hover:bg-gray-100">
                                    <div class="text-lg">{{ participant.name }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row gap-2 p-2 w-full items-center">
                    <div class="flex flex-grow">
                        <input @keydown.enter="createAssignment" v-model="newAssignment" class="w-full" type="text">
                    </div>
                    <div @click="createAssignment" class="p-1 cursor-pointer hover:bg-gray-100">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                    </div>
                </div>
                
            </div>
            
        </div>

        <div class="flex flex-col flex-grow">
            <div class="font-light mb-2">
                Notes
            </div>
            <textEdit       
                :id="note ? note.id : null"
                :data="note ? note.text : ''"
                :sectionPK="sectionPK"
                :entryPK="entryPK"
                @createItem="createNote"
                @updateItem="updateNote"
                @deleteItem="deleteNote"
            />
        </div>
        
    </div>
</template>