<script setup lang="ts">
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import { ref, onMounted, defineProps, defineEmits } from "vue";

const props = defineProps<{
  initial: string; // Initial value for the date field
}>();

const emit = defineEmits<{
  (e: "submit", value: string): void;
}>();

const proposed = ref<string>(props.initial); // Holds the date value
const input = ref<HTMLInputElement | null>(null); // Ref for the input field

onMounted(() => {
  input.value?.focus(); // Focus the input on mount
});
</script>

<template>
  <div class="flex flex-col">
    <label class="form-label">Select Date</label>
    <div class="flex flex-row gap-2 items-center justify-between">
      <!-- Date Input Field -->
      <input
        type="date"
        v-model="proposed"
        ref="input"
        class="form-field w-full text-sm border rounded px-2 py-1"
      />
      
        <div class="btn-outline">Cancel</div>
        <SaveButton
            @click="emit('submit', proposed)"
        />
    </div>
  </div>
</template>

<style scoped></style>