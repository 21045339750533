<script setup lang="ts">

import ViewItems from './viewItems.vue'
import AssignTasks from './assignTasks.vue'

import { useAppointments } from '@/store/items/appointment'
import { useContacts } from '@/store/items/contact'

import { formatDistance } from 'date-fns';
import { computed } from 'vue'
import { parseToLocal } from '@/utils/dateUtils';

import type { AppointmentItem, AgendaSectionItem, AgendaEntryItem, AgendaAssignmentItem } from '@/store/items/interfaces/appointment'

const store = useAppointments()
const contacts = useContacts()

const props = defineProps<{
    appointment: AppointmentItem,
    sectionIndex: number,
    sectionItem: AgendaSectionItem,
    sectionCount: number, 
    entryIndex: number, 
    entryItem: AgendaEntryItem,
    availableTasks: AgendaAssignmentItem[],
}>()

const emit = defineEmits(['step'])

const participantItems = computed(() => {
    const forProject = contacts.forProject(props.appointment.category);
    return forProject.filter(item => props.appointment.participants.includes(item.id!));
});

const timeStatus = computed(() => {
  const now = new Date(); // Current local datetime

  const start = parseToLocal(props.appointment.begin as string);
  const end = parseToLocal(props.appointment.end as string);
  console.log(start, end, now);
  if (now < start) {
    return `Scheduled to start ${formatDistance(start, now, { addSuffix: true })}.`; 
  } else if (now >= start && now <= end) {
    return "This meeting has started.";
  } else {
    return `This meeting ended ${formatDistance(end, now, { addSuffix: true })}.`;
  }
})

function getTargetForContent(sectionID: number, entryID?: number): AgendaSectionItem | AgendaEntryItem {
  const targetSection = store.agenda.find((item: AgendaSectionItem) => item.id === sectionID);
  if (!targetSection) {
    throw new Error(`Section with ID ${sectionID} not found.`);
  }
  if (entryID) {
    const targetEntry = targetSection.agenda_entries.find(item => item.id === entryID);
    if (!targetEntry) {
      throw new Error(`Entry with ID ${entryID} not found in section ${sectionID}.`);
    }
    return targetEntry;
  }
  return targetSection;
}

async function saveNewContent(type: 'assignment' | 'note', data: any): Promise<void> {
  const results = await store.saveContent(type, data);
  const target = getTargetForContent(results.section, results.entry);

  if (type === 'assignment') {
    (target as AgendaEntryItem)['assignments']!.push(results);
  } else if (type === 'note') {
    if (results.entry) {
      (target as AgendaEntryItem).note = results;
    } else {
      (target as AgendaSectionItem).agenda_entries.push(results);
    }
  }
}

async function updateContent(type: 'assignment' | 'note', data: any): Promise<void> {
  const results = await store.saveContent(type, data);
  const target = getTargetForContent(results.section, results.entry);

  if (type === 'assignment') {
    const index = (target as AgendaEntryItem).assignments!.findIndex(item => item.id === data.id);
    (target as AgendaEntryItem).assignments![index] = results;
  } else if (type === 'note') {
    if (results.entry) {
      (target as AgendaEntryItem).note = results;
    } else {
      const index = (target as AgendaSectionItem).agenda_entries.findIndex(item => item.id === data.id);
      (target as AgendaSectionItem).agenda_entries[index] = results;
    }
  }
}

async function deleteContent(
  type: 'assignment' | 'note',
  id: number,
  section: number,
  entry?: number
): Promise<void> {
  await store.deleteContent(type, id);
  const target = getTargetForContent(section, entry);

  if (type === 'assignment') {
    const index = (target as AgendaEntryItem)['assignments']!.findIndex(item => item.id === id);
    (target as AgendaEntryItem)['assignments']!.splice(index, 1);
  } else if (type === 'note') {
    if (entry) {
      (target as AgendaEntryItem).note = null;
    } else {
      (target as AgendaSectionItem).agenda_entries = [];
    }
  }
}
</script>

<template>
<div v-if="sectionIndex === -1" class="flex flex-col pt-56 items-center">
    <div class="text-xl text-gray-600 mb-6 font-dm">{{ timeStatus }}</div>
    <button v-if="store.agenda.length" @click="emit('step', true)" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200">
      Proceed to First Agenda Item 
    </button>
    <div v-else>No Agenda Established for this Meeting.</div>
</div>

<div v-else-if="sectionCount > sectionIndex" class="flex flex-col min-h-0 h-full">
    <div class="grid grid-cols-7 items-center py-4 bg-gray-100">
        <div class="flex justify-around">
            <div v-if="sectionIndex > 0 || entryIndex > 0" @click="emit('step', false)" class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>
            </div>
        </div>
        <div class="flex flex-col items-center col-span-5">
            <div class="text-xl font-medium">{{ sectionItem.label }}</div>
            <div v-if="entryItem" class="text-gray-600">{{ entryItem.text }}</div>
        </div>
        <div class="flex justify-around">
            <div @click="emit('step', true)" class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>
            </div>
        </div>
    </div>

    <ViewItems
        :assignments="entryItem ? entryItem.assignments : sectionItem.assignments"
        :note="entryItem ? entryItem.note : sectionItem.note.filter(item => !item.entry)[0]"
        :sectionPK="sectionItem.id"
        :entryPK="entryItem ? entryItem.id: null"
        :participants="participantItems"
        @create="saveNewContent"
        @update="updateContent"
        @delete="deleteContent"
    ></ViewItems> 

</div>

<div v-else-if="store.agenda.length && sectionCount === sectionIndex" class="flex flex-col">
    <div class="grid grid-cols-7 items-center py-4 bg-gray-100">
        <div class="flex justify-around">
            <div v-if="sectionIndex > 0 || entryIndex > 0" @click="emit('step', false)" class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>
            </div>
        </div>
        <div class="flex flex-col items-center col-span-5">
            <div class="text-xl font-medium">Next Steps</div>
        </div>
    </div>
    <div class="flex flex-row justify-around py-12 border">
        <button v-if="store.agenda.length" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200">
        Send Summary Email
        </button>
    </div>
    <AssignTasks
        :project="appointment.category"
        :assignments="availableTasks"
        @update="updateContent"
    ></AssignTasks>
</div>
</template>