<script setup lang="ts">
import { getUrgencyRating } from '@/utils/taskUtils.js';
import { convertMinutesIntoHrsMinutesVerbose } from "@/utils/timeUtils.js";
import { useRecords } from '@/store/items/record';
import { useTasks } from '@/store/items/task';
import { ref, computed, defineProps, defineEmits } from 'vue';

import type { RecordItem } from '@/store/items/interfaces/record';
import type { TaskItem } from '@/store/items/interfaces/task';

const store = useRecords();
const taskStore = useTasks();
const records = useRecords();

const props = defineProps<{
    obj: RecordItem;
    task: TaskItem;
    menuDisplayedFor: number | null;
}>();

const emit = defineEmits([
    'actionA', 'actionB', 'openTask'
]);

const urgencyClassesForCircle: Record<number, string> = {
    1: 'border-red-700',
    2: 'border-amber-600',
    3: 'border-yellow-600',
    4: 'border-blue-600',
    5: 'border-gray-600'
};

function getUrgencyClass(): string {
    const urgencyRating = getUrgencyRating(props.task.due_date, props.obj.date);
    return urgencyClassesForCircle[urgencyRating];
}

function getTimeRemaining(): string {
    let minutes = props.obj.completed ? props.obj.minutes_worked : props.obj.minutes_planned
    return convertMinutesIntoHrsMinutesVerbose(minutes);
}

const doubleClickPending = ref(false);
const timerId = ref<ReturnType<typeof setTimeout> | null>(null);

// Single click handler
const handleClick = (): void => {
    if (timerId.value) clearTimeout(timerId.value);
    timerId.value = setTimeout(() => {
        if (!doubleClickPending.value) {
            emit('actionA', props.obj);
        }
        doubleClickPending.value = false;
    }, 200);
};

// Double click handler
const handleDoubleClick = (): void => {
    doubleClickPending.value = true;
    emit('actionB', props.obj);
};

const strikeSelected = ref(false);

async function strike(taskIsCompleted: boolean): Promise<void> {
    const status_for_task = taskIsCompleted ? 5 : 1;
    records.toggleCompleted(props.obj.id!, status_for_task);
    strikeSelected.value = false;
    const task = taskStore.getItem(props.task.id);
    task.status = status_for_task;
    task.minutes_remaining -= props.obj.minutes_planned;
    emit('actionA', { id: props.obj.id });
}

function setRecordStrike(): void {
    if (props.obj.completed) {
        strike(false);
        const task = taskStore.getItem(props.task.id);
        task.minutes_remaining += props.obj.minutes_planned;
    } else {
        strikeSelected.value = true;
    }
}

function openTask(): void {
    store.selectTask(props.task);
}

function deleteTask(): void {
    taskStore.getItem(props.task.id).minutes_available += props.obj.minutes_planned;
    store.del(props.obj.id)
}
</script>

<template>
    <div class="flex flex-row" ref="taskElement" :class="{'line-through bg-gray-200': obj && obj.completed}">
        <div class="flex flex-row items-center cursor-pointer hover:bg-gray-100 w-full text-sm">
            <div v-if="task" :class="getUrgencyClass()" class="border-l-4 flex flex-row items-center w-full">
                <div class="flex flex-row justify-between items-center h-full w-full border">
                    <div
                        @click="handleClick"
                        @dblclick="handleDoubleClick"
                        class="truncate w-full p-2"
                    >
                        {{ task.label }}
                    </div> 
                    <div class="flex flex-row text-gray-600 gap-2 pr-3" v-if="strikeSelected">
                        <div class="whitespace-nowrap pl-2 border-l">Task is completed?</div>
                        <div @click="strike(true)" class="hover:text-teal-600 border-x px-2 shadow">Yes</div>
                        <div @click="strike(false)" class="hover:text-teal-600 border-x px-2 shadow border-r">No</div>
                        <div @click="strikeSelected=false">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 pl-2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                    <div class="flex flex-row text-gray-600" v-else-if="menuDisplayedFor === obj.id">

                        <!-- Strike -->
                        <div @click="setRecordStrike()" class="border-l p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                            </svg>
                        </div>

                        <!-- Open -->
                        <div @click="$emit('openTask', task.id)" class="border-l p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />
                            </svg>
                        </div>

                        <!-- Trash -->
                        <div @click="deleteTask(obj.id)" class="border-l p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                        </div>

                        <!-- Close -->
                        <div @click="$emit('actionA', {id: null})" class="border-l flex flex-row items-center p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 p-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>

                    <div 
                    v-else
                    @click="handleClick"
                    @dblclick="handleDoubleClick"
                    class="text-gray-500 text-xs w-28 flex flex-row justify-end p-2"
                    >{{ getTimeRemaining() }}</div>
                </div>                
            </div>
            <div v-else class="border-l-4 flex flex-row items-center  w-full">
                <div class="flex flex-row justify-between items-center h-full w-full border p-2">
                    Loading task...
                </div>
            </div>
        </div>        
    </div>
</template>
