import { useRecords } from "@/store/items/record/index.js";
import { useTasks } from "@/store/items/task/index.js";

const crudActions = {

      removeRelatedObjects(pk) {
        const tasks = useTasks()
        const records = useRecords()
        const relatedTasks = tasks.items
          .filter(item => item.routine === pk)
          .map(item => item.id);  
        const relatedRecords = records.items
          .filter(item => relatedTasks.includes(item.task))
          .map(item => item.id);
        records.removeItems(relatedRecords)
        tasks.removeItems(relatedTasks)
      },
      async create() {
        const result = await this.interact('create', this.proposed)
        this.items.push(result.object)
        useTasks().mergeItems(result.tasks)
        useRecords().mergeItems(result.records)
        this.clearSelected()
        return result
      },
      async overwrite() {
        const result = await this.interact('overwrite', this.proposed)
        this.setItem(result.object.id, result.object);
        this.removeRelatedObjects(result.object.id)
        useTasks().mergeItems(result.tasks)
        useRecords().mergeItems(result.records)
        return result
      },
      async updateStatusForItems(pks, status) {
        const result = await this.bulkInteract('set_status', pks, status)
        this.modifyItems(pks, 'status', status)
        if (status === 1) {
          useTasks().mergeItems(result.tasks)
          useRecords().mergeItems(result.records)
        } else {
          pks.forEach(pk => {
            this.removeRelatedObjects(pk);
          });
        }
      },
      async resetItems(items) {
        const result = await this.bulkInteract('reset', items)
        items.forEach(item => {
          this.removeRelatedObjects(item);
        });
        useTasks().mergeItems(result.tasks)
        useRecords().mergeItems(result.records)
      }
    
}

export { crudActions };