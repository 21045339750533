<script setup lang="ts">
import { QuillEditor } from '@vueup/vue-quill';
import SaveButton from '@/components/common/buttons/SaveButton.vue'
import CancelButton from '@/components/common/buttons/CancelButton.vue';

import { useNotecards } from '@/store/items/notecard';
import { useRoute } from 'vue-router'; 

import { ref, watch } from 'vue';
const quillKey = ref(0); // Used to force re-render of the QuillEditor

const notecards = useNotecards();

const emit = defineEmits(['close']);

watch(() => notecards.proposed.id, () => {
  quillKey.value++;  // Increment key to force re-render of QuillEditor
});

async function save(): Promise<void> {
  await notecards.save();
  emit('close');
}

const modules ={ toolbar: false }

</script>

<template>
  <div class="p-6 bg-white h-72 pb-24">
    
    <input
      type="text"
      class="w-full"
      placeholder="Title (Optional)"
      maxlength="24"
      v-model="notecards.proposed.title"
    />
    
    <QuillEditor
      ref="quill"
      :key="quillKey"
      v-model:content="notecards.proposed.text"
      theme="snow"
      contentType="html"
      toolbar="#my-toolbar"
    />
    <div id="my-toolbar">    
      <span class="ql-formats">
                      <button class="ql-bold" ngbPopover="Bold" triggers="mouseenter:mouseleave"></button>
                      <button class="ql-italic" ngbPopover="Italic" triggers="mouseenter:mouseleave"></button>
                      <button class="ql-underline" ngbPopover="Underline" triggers="mouseenter:mouseleave"></button>
                      <button class="ql-strike" ngbPopover="Strikethrough" triggers="mouseenter:mouseleave"></button>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-blockquote" ngbPopover="Blockquote" triggers="mouseenter:mouseleave"></button>
                      <button class="ql-code-block" ngbPopover="Code block" triggers="mouseenter:mouseleave"></button>
                    </span>

                    <span class="ql-formats">
                      <button class="ql-list" value="ordered" ngbPopover="Ordered list" triggers="mouseenter:mouseleave"></button>
                      <button class="ql-list" value="bullet" ngbPopover="Bulleted list" triggers="mouseenter:mouseleave"></button>
                    </span>
                    <span class="ql-formats" ngbPopover="Text color" triggers="mouseenter:mouseleave">
                      <select class="ql-color">
                        <option selected></option>
                        <option value="#e60000"></option>
                        <option value="#ff9900"></option>
                        <option value="#ffff00"></option>
                        <option value="#008a00"></option>
                        <option value="#0066cc"></option>
                        <option value="#9933ff"></option>
                        <option value="#ffffff"></option>
                        <option value="#facccc"></option>
                        <option value="#ffebcc"></option>
                        <option value="#ffffcc"></option>
                        <option value="#cce8cc"></option>
                        <option value="#cce0f5"></option>
                        <option value="#ebd6ff"></option>
                        <option value="#bbbbbb"></option>
                        <option value="#f06666"></option>
                        <option value="#ffc266"></option>
                        <option value="#ffff66"></option>
                        <option value="#66b966"></option>
                        <option value="#66a3e0"></option>
                        <option value="#c285ff"></option>
                        <option value="#888888"></option>
                        <option value="#a10000"></option>
                        <option value="#b26b00"></option>
                        <option value="#b2b200"></option>
                        <option value="#006100"></option>
                        <option value="#0047b2"></option>
                        <option value="#6b24b2"></option>
                        <option value="#444444"></option>
                        <option value="#5c0000"></option>
                        <option value="#663d00"></option>
                        <option value="#666600"></option>
                        <option value="#003700"></option>
                        <option value="#002966"></option>
                        <option value="#3d1466"></option>
                      </select>
                    </span>
    </div>
    
    <div class="pt-2 flex flex-row justify-end gap-2">
      <CancelButton @click="emit('close')" />
      <SaveButton @click="save" />
    </div>
  </div>
</template>
