<script setup lang="ts">
import SearchBar from "@/components/common/search.vue";
import ContactList from "./ContactList.vue";
import GroupList from "./GroupList.vue";
import TabToggle from "@/components/common/buttons/TabToggle.vue";
import { ref, computed } from "vue";
import { getSearchResults } from "@/utils/searchUtils";
import { useContacts } from "@/store/items/contact";
import { useGroups } from "@/store/items/group";
import type { ContactItem } from "@/store/items/interfaces/contact";
import type { GroupItem } from "@/store/items/interfaces/group";

const props = defineProps<{
  project: number;
  selected: {
    contacts: number[];
    groups: number[];
  }
}>();

const emit = defineEmits<{
  (event: "selectContact", pk: number): void;
  (event: "selectGroup", pk: number): void;
}>();

const contacts = useContacts();
const groups = useGroups();

const searchStr = ref<string>("");

function setSearchStr(str: string) {
  searchStr.value = str;
}

const contactItems = computed<ContactItem[]>(() => {
  const results = contacts.forProject(props.project);
  return getSearchResults(results, searchStr.value, "name");
});

const groupItems = computed<GroupItem[]>(() => {
  return groups.forProject(props.project);
});

// Reactive display mode
const displayMode = ref<"contact" | "group">("contact");

function handleContactSelect(pk: number) {
  emit("selectContact", pk);
}

function handleGroupSelect(pk: number) {
  emit("selectGroup", pk);
}

</script>

<template>
  <div class="flex flex-col w-full flex-1">
    <div class="form-label flex-none">Contacts</div>

    <div class="text-sm flex flex-col border rounded border-gray-300">
      <TabToggle
        :options="[
          { label: 'People', value: 'contact' },
          { label: 'Groups', value: 'group' }
        ]"
        v-model="displayMode"
      />
      <div v-if="displayMode === 'contact'" class="flex flex-col gap-2 p-2">
        <SearchBar @runSearch="setSearchStr"/>
        <ContactList 
          :contacts="contactItems" 
          :selected="selected.contacts"
          @select="handleContactSelect"
        />
      </div>
      <div v-else class="flex flex-col gap-2 p-2">
        <GroupList 
          :groups="groupItems" 
          :selected="selected.groups"
          @select="handleGroupSelect"
        />
      </div>
    </div>
  </div>
</template>