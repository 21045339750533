<script setup lang="ts">

import { ref, computed } from 'vue'
import { addOrRemoveFromArray } from '@/utils/arrayUtils'
import { getSearchResults } from '@/utils/searchUtils'
import { useRoutines } from '@/store/items/routine'

import type { RoutineItem } from '@/store/items/interfaces/routine'
import SelectableRoutine from '@/components/common/listedItems/SelectableRoutine.vue'
import InteractionControls from './InteractionControls.vue'

const store = useRoutines()
const props = defineProps<{
    project: number
    items: RoutineItem[]
}>()

const emit = defineEmits(['openForm'])

const searchStr = ref<string>("")
function setSearch(input: string) {
    searchStr.value = input
}

const selected = ref<number[]>([])

function setSelected(pk: number) {
    selected.value = addOrRemoveFromArray(selected.value, pk)
}
function clearSelected() {
    selected.value = []
}

function selectAll() {
    if (selected.value.length > 0) {
        selected.value = []
    } else {
        selected.value = props.items.map(item => item.id)
    }   
}
function openEditForm() {
    emit('openForm', selected.value[0])
}

</script>

<template>
    <div class="flex flex-row justify-between pb-4">
        <InteractionControls
            :selected="selected"
            @selectAll="selectAll"
            @openForm="openEditForm"
            @clearSelected="clearSelected"
        />
    </div>
    <SelectableRoutine
        v-for="item in items"
        :pk="item.id"
        :selected="selected"
        @select="setSelected"                     
    ></SelectableRoutine>
</template>