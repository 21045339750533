import { createStore } from "@/store/items/common/index.js";

import { filterActions } from "./filterActions";
import { crudActions } from "./crudActions";
import { customActions } from "./customActions"

const template = {
  category: null,
  rfcid: "new",
  subject: "",
  body: "",
  page: 0,
  loginRequired: false,
  contacts: {
    primary: [],
    secondary: [],
    to: [],
    cc: [],
    bcc: [],
  },
};

const filterTemplate = {
  source: "",
  category: "",
  subject: "",
  threadIDs: [],
  createFilter: true,
  saveContact: false,
  contactInfo: {
    'id': 'temp',
    'name': "",
    'email_addresses': [{'id': 'temp0', 'label': 'Primary', 'value': '' }]
  }
}

const customState = {
  authReference: {},
  messages: {
    objects: [],
    data: [],
  },
  participants: [],
  rfcid: null,
  resultsPage: 0,
  selectors: [],
  batch: [],
  associatedMessages: [],
  filters: {
    items: [],
    loadedProjectPKs: [],
    senderBatch: [],
  },
  customRecipient: {
     value: "",
     position: "to"
  },
  senderEnabled: false,
  filterTemplate: { ...filterTemplate},
  filterData: {...filterTemplate},
}

const customGetters = {
  getParentMessage: (state) => (state.items.find((id) => item.id === id).messages[0])
}

export const useThreads = createStore({
  model: "thread",
  template: template,
  customState: customState,
  customGetters: customGetters,
  customActions: {
    ...crudActions,
    ...customActions,
    ...filterActions,
  }
})
