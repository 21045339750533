export function calculateDaysUntil(dueDate: string): number {
  const [year, month, day] = dueDate.split('-').map(Number);
  const dueDateUTC = Date.UTC(year, month - 1, day); // Note: month is 0-indexed.
  const now = new Date();
  const todayUTC = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.round((dueDateUTC - todayUTC) / MS_PER_DAY);
}

export function dueDateVerbose(dueDate: string): string {
  const distance = calculateDaysUntil(dueDate);
  let output: string;

  switch (distance) {
    case 0:
      output = "today";
      break;
    case 1:
      output = "tomorrow";
      break;
    case -1:
      output = "yesterday";
      break;
    default:
      if (distance > 0) {
        output = `in ${distance} days`;
      } else {
        output = `${Math.abs(distance)} days ago`;
      }
  }

  return output;
}

export function getDate(str: string, options?: Intl.DateTimeFormatOptions): string {
  const [year, month, day] = str.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  if (!options) {
    options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
  }
  return new Intl.DateTimeFormat("default", options).format(date);
}
export function formatDateForBackend(toFormat: string | Date): string {
  const date = typeof toFormat === "string" ? new Date(toFormat) : toFormat;

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export function getDateAndTime(str: string, options?: Intl.DateTimeFormatOptions): string {
  const date = new Date(str);
  if (!options) {
    options = { dateStyle: "full", timeStyle: "short" };
  }
  return new Intl.DateTimeFormat("default", options).format(date);
}

export function daysUntilVerbose(daysUntil: number): string {
  const x = daysUntil;
  if (x < 7) {
    return x === 1 ? '1 day' : `${x} days`;
  } else {
    const weeks = x / 7;
    if (Number.isInteger(weeks)) {
      return weeks === 1 ? '1 week' : `${weeks} weeks`;
    } else {
      const roundedWeeks = Math.round(weeks);
      return roundedWeeks === 1 ? 'About 1 week' : `About ${roundedWeeks} weeks`;
    }
  }
}

export function getDateSlugFromObj(date: Date): string {
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  if (month.length < 2) {
    month = `0${month}`;
  }
  let day = date.getDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
}

export function getDateSlugFromStr(dateString: string): string {
  const date = new Date(Date.parse(dateString + "T00:00"));
  return getDateSlugFromObj(date)
}

export function parseToLocal(dateString: string) {
  const [year, month, day, hours, minutes, seconds] = dateString
    .replace("Z", "") // Remove the "Z" to stop interpretation as UTC
    .split(/[-T:.]/) // Split by date-time separators
    .map((part) => parseInt(part, 10));

  return new Date(year, month - 1, day, hours, minutes, seconds); // Local Date object
};

export function getDateLabels(inputDate: Date | string): { 
  weekday: { full: string; short: string }; 
  month: string; 
  date: string; 
} {
  const date = typeof inputDate === "string" ? new Date(inputDate) : inputDate;

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date input");
  }

  const weekdays = [
    { full: "Sunday", short: "Sun" },
    { full: "Monday", short: "Mon" },
    { full: "Tuesday", short: "Tue" },
    { full: "Wednesday", short: "Wed" },
    { full: "Thursday", short: "Thu" },
    { full: "Friday", short: "Fri" },
    { full: "Saturday", short: "Sat" }
  ];

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  return {
    weekday: weekdays[date.getUTCDay()], // Use UTC methods
    month: months[date.getUTCMonth()],
    date: date.getUTCDate().toString(),
  };
}