<script setup lang="ts">
import { ref, computed,  onMounted } from 'vue';
import ArrowSubmit from '@/components/common/buttons/ArrowSubmit.vue';
import { getUniqueDefaultColor, calculateSwatchMap } from '@/utils/colorUtils';
import { useProjects } from '@/store/items/project';

const emit= defineEmits<{
  loadProject: (projectId: number) => void;
}>();

const inputRef = ref<HTMLInputElement | null>(null);
const projects = useProjects();
const isColorPickerVisible = ref(false); // Controls visibility of the color picker
const usedColors = computed(() => projects.items.map((item) => item.color))
const currentColor = ref<string>(getUniqueDefaultColor(usedColors.value)); // Stores the random color

const updateColor = (value: string): void => {
  currentColor.value = value;
  projects.proposed.color = value;
};

const toggleColorPicker = (): void => {
  isColorPickerVisible.value = !isColorPickerVisible.value;
};

const formValid = computed(() => {
  return projects.proposed.name.trim() !== '';
});

async function saveProject(): Promise<void> {
  if (formValid.value) {
    const newProj = await projects.save();
    emit('loadProject', newProj.id);
  }
}

// Focus the input field on component mount
onMounted(() => {
  projects.clearProposed();
  projects.proposed.color = currentColor.value; // Set initial proposed color
  inputRef.value?.focus();
});
</script>

<template>
  <div class="flex flex-row items-center justify-around w-full">
    <div class="relative flex flex-col bg-white p-12 gap-6">
      <div class="text-lg border-b">New Project</div>
      <div class="flex flex-row gap-4 items-center">
        <input 
          v-model="projects.proposed.name"
          placeholder="Project Name"
          ref="inputRef" 
          type="text" 
          class="border rounded px-2 py-1 w-36" 
        />
        <!-- Trigger for Color Picker -->
        <div 
          class="w-8 h-8 border rounded cursor-pointer"
          :style="{ backgroundColor: currentColor }"
          @click="toggleColorPicker"
        ></div>
        <!-- Floating Color Picker -->
        <div 
          v-if="isColorPickerVisible" 
          class="absolute z-10 bg-white shadow-lg p-4 top-8"
          style="width: 100%; left: 50%; transform: translate(-50%, 112px);"
        >
        <v-color-picker
            dot-size="15"
            hide-canvas
            hide-inputs
            hide-sliders
            mode="hsla"
            show-swatches
            swatches-max-height="244"
            :swatches="calculateSwatchMap(usedColors)"
            :value="projects.proposed.color"
            @update:modelValue="updateColor"
        ></v-color-picker>
        </div>
        <ArrowSubmit
          :disabled = !formValid
          @click="saveProject()"
        />
      </div>
    </div>
  </div>
</template>
