<script setup lang="ts">

import SaveButton from '@/components/common/buttons/SaveButton.vue';

import SelectOptionInline from '@/components/common/buttons/SelectOptionInline.vue';
import ArrowSubmit from '@/components/common/buttons/ArrowSubmit.vue';

import { ref, onMounted } from 'vue';

const props = defineProps<{
    initial: number | null | undefined;
    styleClass: string;
}>();

const emit = defineEmits<{
    (e: 'submit', value: number): void;
    (e: 'close'): void
}>();

const input = ref<HTMLInputElement | null>(null);
const days = ref<number>(0);
const mult = ref<number>(1);

const multOpts = [
    { label: 'Days', value: 1 },
    { label: 'Weeks', value: 7 },
];

function submitForm() {
    if (days.value <= 0) {
        alert('Please enter a valid number of days.');
        return;
    }
    const totalDays = days.value * mult.value
    emit('submit', totalDays);
}

onMounted(() => {
    input.value?.focus();
    if(props.initial) {
        if (props.initial % 7 === 0) {
            days.value = props.initial / 7;
            mult.value = 7;
        } else {
            days.value = props.initial;
            mult.value = 1;
        }
    }
});

</script>

<template>
    <label class="text-sm">To Be Completed Within:</label>
    <div class="flex flex-row justify-between items-center">
        
        <div class="flex flex-row gap-6 items-center">
            <!-- Days Input -->
            <input 
                ref="input"
                v-model="days" 
                class="w-16 border rounded px-2 py-1 text-sm"
                :class="styleClass" 
                type="number" 
                min="1"
                placeholder="0"
            />

            <!-- Multiplier Selector -->
            <SelectOptionInline 
                :options="multOpts"
                :selected="mult"
                @selectOption="mult = $event" 
            />
        </div>
        <div class="flex flex-row gap-2">
            <div 
              class="btn-outline" 
              @click="emit('close')"
            >Cancel</div>
            <!-- Submit Button -->
            <SaveButton 
                @click="submitForm" 
            />
            
        </div>
    </div>
</template>