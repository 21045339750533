<script lang="ts">
import { ref, computed, defineComponent } from "vue";
import { getDateAndTime } from "@/utils/dateUtils";

import type { MessageItem } from '@/store/items/interfaces/message';
import type { ContactItem } from '@/store/items/interfaces/contact';

import DOMPurify from 'dompurify'

export default defineComponent({
  name: "ReadMessage",
  props: {
    message: {
      type: Object as () => MessageItem,
      required: true,
    },
    showFilterOpts: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array as () => ContactItem[],
      required: true,
    },
  },
  emits: ["setReply", "convertToTask"],
  setup(props) {
    const fullMessage = ref<MessageItem | null>(null);

    const getContact = (id: number): ContactItem | undefined => {
      return props.contacts.find((item) => item.id === id);
    };

    const recipients = computed((): string => {
      return props.message.to
        .map((obj) => (obj.name.length ? obj.name : obj.address))
        .join(", ");
    });

    const displayDate = (str: string): string => {
      try {
        return getDateAndTime(str);
      } catch (e) {
        if (e instanceof TypeError || e instanceof RangeError) {
          return "Date Error";
        }
        throw e;
      }
    };
    const sanitizedHtml = computed(() => {
      return DOMPurify.sanitize(props.message.body) // or wherever your HTML string is
    })

    return { recipients, displayDate, sanitizedHtml };
  },
});
</script>

<template>  
  <div class="w-full h-full min-h-0">
    <div class="flex flex-row justify-between">
      <div class="flex flex-col gap-1">
        <div class="flex flex-row gap-3">
          <div class="text-xs mt-1 truncate-3">from: {{ message.sender_name }}, to: {{ recipients }}</div>
          <div class="flex flex-row gap-2"></div>
        </div>

        <div class="flex flex-row gap-3 items-center">
          <div class="text-xs text-gray-500">{{ displayDate(message.date) }}</div>
        </div>
      </div>
      <div class="flex flex-row gap-2 justify-end pt-5 mb-2">
        
      </div>
    </div>
    <div class="mt-4 pb-20 border-b text-sm flex flex-col h-full min-h-0">
      <div class="flex-grow overflow-y-auto" v-html="sanitizedHtml"></div>

      <!-- <div v-if="message.has_attachment" class="border flex flex-row mt-4">
        <div
          v-for="item in message.attachments"
          :key="item.attachment_id"
          class="border flex flex-row gap-2 py-1.5 px-2 m-2 rounded hover:bg-gray-100"
        >
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <div class="font-medium text-blue-500">
            {{ item.filename }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.icon {
  color: #d4d4d4;
  margin-bottom: 1px;
}
.icon:hover,
.icon.selected {
  color: #5f84b1;
}
</style>