<script setup lang="ts">
import { useRoutines } from '@/store/items/routine';
import { computed } from 'vue';

const store = useRoutines();

const props = defineProps<{
  selected: number[];
}>();

const emit = defineEmits<{
  (e: 'selectAll'): void;
  (e: 'openForm'): void;
  (e: 'clearSelected'): void;
}>();


const selectedStatus = computed((): number => {
  let status = 0;
  const selectedItems = store.items.filter(item => props.selected.includes(item.id));
  if (selectedItems.length > 0) {
    const firstStatus = selectedItems[0].status;
    const allSameStatus = selectedItems.every(item => item.status === firstStatus);
    if (allSameStatus) {
      status = firstStatus;
    }
  }
  return status;
});

function setStatus(): void {
  if (selectedStatus.value > 0) {
    const newStatus = selectedStatus.value === 1 ? 2 : 1;
    store.updateStatusForItems(props.selected, newStatus);
    emit('clearSelected');
  }
}

function resetItems(): void {
  if (selectedStatus.value === 1) {
    store.resetItems(props.selected);
    emit('clearSelected');
  }
}

function deleteSelected(): void {
  if (props.selected.length > 0) {
    let confirmText = `Delete these ${props.selected.length} items?`;
    if (props.selected.length === 1) {
      confirmText = 'Delete the selected item?';
    }
    if (confirm(confirmText)) {
      store.bulkInteract('delete', props.selected);
      store.removeItems(props.selected);
      emit('clearSelected');
    }
  }
}
</script>

<template>
    <div class="flex flex-row w-full justify-between">
        <div 
            @click="emit('selectAll')" 
            class="btn-special p-1 px-3 text-sm font-medium"
        >
            {{ selected.length > 0 ? 'Deselect' : "Select All" }}
        </div>
        <div class="flex flex-row gap-2">
            <div 
                @click="emit('openForm')" 
                :class="selected.length === 1 ? 'btn-dark cursor-pointer': 'btn-disabled'" 
                class="p-1 px-3 text-sm font-medium"
                >
                Edit
            </div>
            <div 
                @click="setStatus()" 
                :class="selectedStatus > 0 ? 'btn-dark cursor-pointer' : 'btn-disabled'" 
                class="p-1 px-3 text-sm font-medium"
            >
                <span v-if="selectedStatus === 2">Restore</span>
                <span v-else>Suspend</span>
            </div>

            <div 
                @click="resetItems()" 
                :class="selectedStatus === 1 ? 'btn-dark cursor-pointer' : 'btn-disabled'" 
                class="p-1 px-3 text-sm font-medium"
            >
                <span>Reset</span>
            </div>
            <div 
                @click="deleteSelected()" 
                :class="selected.length > 0 ? 'btn-caution cursor-pointer' : 'btn-disabled'" 
                class="p-1 px-3 text-sm font-medium"
            >
                Delete
            </div>                                                              
        </div>
    </div>
</template>