<script setup lang="ts">
import { ref, computed, defineProps } from 'vue';
import type { PropType, Component } from 'vue';

// Define the structure of the `tabs` array
const props = defineProps({
  tabs: {
    type: Array as PropType<
      {
        label: string;
        component: Component;
        props?: Record<string, any>;
      }[]
    >,
    required: true,
  },
});

const selected = ref(0);

const selectedTabContent = computed(() => {
  const tab = props.tabs[selected.value];
  return tab ? { component: tab.component, props: tab.props || {} } : null;
});

const gridClass = computed(() => `grid grid-cols-${props.tabs.length} divide-x select-none`);
</script>

<template>
  <div :class="gridClass">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      @click="selected = index"
      class="flex flex-row justify-around p-2 font-light uppercase cursor-pointer h-full"
      :class="{
        'hover:bg-gray-100 border-b': selected !== index,
        'text-pink-600': selected === index,
      }"
    >
      {{ tab.label }}
    </div>
  </div>
  <div class="flex flex-1 min-h-0">
    <!-- Render the dynamic component with props -->
    <component
      v-if="selectedTabContent"
      :is="selectedTabContent.component"
      v-bind="selectedTabContent.props"
    />
  </div>
</template>