<script setup lang="ts">
import { useAppointments } from '@/store/items/appointment';
import type { AgendaSectionItem, AgendaEntryItem } from '@/store/items/interfaces/appointment';

import { ref } from 'vue';

const appts = useAppointments()

const props = defineProps<{
    sectionIndex: number
    entryIndex: number
    section: AgendaSectionItem
    entry: AgendaEntryItem
}>()

const editingEntry = ref<{ entryIndex: number } | null>(null);

function startEditing(entryIndex: number) {
  editingEntry.value = { entryIndex };
}

function finishEditing(entryIndex: number) {
  const entry = props.section.agenda_entries[entryIndex];
  const payload = {
    'id': entry.id,
    'text': entry.text,
  }
  appts.saveAgendaEntry('update', props.section.id!, payload)
  editingEntry.value = null; // Stop editing
}

function isEditing(entryIndex: number): boolean {
  return editingEntry.value?.entryIndex === entryIndex;
}

</script>

<template>
    
    <template v-if="isEditing(entryIndex)">
        <input
            v-model="entry.text"
            @keyup.enter="() => finishEditing(entryIndex)"
            @blur="finishEditing(entryIndex)"
            class="border rounded p-1 w-full"
            type="text"
        />
    </template>
    
    <template v-else>
        <div @click="startEditing(entryIndex)">&bull; {{ entry.text }}</div>
    </template>

</template>