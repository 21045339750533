<script setup lang="ts">

import { useProjects } from '@/store/items/project'
import { useContacts } from '@/store/items/contact'
import { useThreads } from '@/store/items/thread'

import { ref, computed } from 'vue'

import ContactOptions from './ContactOptions.vue'
import FromParticipants from './FromParticipants.vue'
import IndividualContacts from './IndividualContacts.vue'
import CustomRecipient from './CustomRecipient.vue'

import type { ThreadParticipant } from '@/store/items/interfaces/thread'
import type { ContactItem } from '@/store/items/interfaces/contact'
import type { GroupItem } from '@/store/items/interfaces/group'
import ContactGroups from './ContactGroups.vue'

const projects = useProjects()
const contacts = useContacts()
const threads = useThreads()

const props = defineProps<{
    recipients: ThreadParticipant[]   
}>()

const emit = defineEmits(['updateRecipient', 'addRecipient'])

const contactFilter = ref("individual");

const projectContacts = computed(() => {
    return contacts.forProject(projects.selected!)
});

const showAllContacts = ref(false);
const showEmailForm = ref(false)

const contactSearchStr = ref<string>('')
function setContactSearchStr(str:string) {
    contactSearchStr.value = str
}

const getRecipientClass = (contactOrEmail: ContactItem | string): string => {
  // Normalize to an array of emails
  const emails = typeof contactOrEmail === "string"
    ? [contactOrEmail]  // If raw email, wrap in an array
    : contactOrEmail.email_addresses.map(e => e.value);  // Extract emails from ContactItem

  // Find matching recipients in `props.recipients`
  const positions = emails
    .map(email => props.recipients.find(i => i.address === email)?.position)
    .filter(Boolean) as ("to" | "cc" | "bcc")[];

  // Prioritize 'to' > 'cc' > 'bcc'
  if (positions.includes("to")) {
    return "bg-blue-500 text-white hover:bg-blue-400";
  } else if (positions.includes("cc")) {
    return "bg-yellow-600 text-white hover:bg-yellow-500";
  } else if (positions.includes("bcc")) {
    return "bg-gray-600 text-white hover:bg-gray-500";
  }
  return "";
};

function cyclePosition(index:number) {
  const currentRole = props.recipients[index].position
  let newPosition: 'to' | 'cc' | 'bcc' | 'disabled'
  if (currentRole === 'to') {
    newPosition = 'cc'
  } else if (currentRole === 'cc') {
    newPosition = 'bcc'
  } else if (currentRole === 'bcc') {
    newPosition = 'disabled'
  } else {
    newPosition = 'to'
  }
  emit('updateRecipient', index, newPosition)
}

function addNewRecipient(pk: number) {
    const contactItem = contacts.getItem(pk)
    const newRecipient = threads.constructRecipientFromContact(contactItem, projects.selected!)
    emit('addRecipient', newRecipient)
}

const cycleContact = (pk: number): void => {
    const alreadyAdded = props.recipients.some((i) => i.id === pk)
    if (alreadyAdded) {
        const index = props.recipients.findIndex((i) => i.id === pk)
        cyclePosition(index) 
    } else {
        addNewRecipient(pk)
    }
};

const cycleAddress = (value: string, pk?: number, position: "to" | "cc" | "bcc" = "to"): void => {
    const alreadyAdded = props.recipients.some((i) => i.address === value)
    if (alreadyAdded){
        const index = props.recipients.findIndex((i) => i.address === value)
        cyclePosition(index)
    } else {
        const newRecipient = {
            address: value,
            position: position,
            id: pk ?? null
        }
        emit('addRecipient', newRecipient)
    }   
}

const selectGroup = (group: GroupItem) => {
  // Retrieve all contacts in the group
  const memberContacts = group.members
    .map(memberId => projectContacts.value.find(contact => contact.id === memberId))
    .filter(Boolean) as ContactItem[]; // Remove undefined values

  // Check which emails are already in recipients (ignoring 'disabled' ones)
  const allMembersPresent = memberContacts.every(contact =>
    contact.email_addresses.some(emailObj =>
      props.recipients
        .filter(recipient => recipient.position !== "disabled") // Ignore disabled recipients
        .some(recipient => recipient.address === emailObj.value)
    )
  );

  if (allMembersPresent) {
    // 🔹 All members are present → CYCLE them
    memberContacts.forEach(contact => {
      contact.email_addresses.forEach(emailObj => {
        props.recipients.forEach((recipient, index) => {
          if (recipient.address === emailObj.value) {
            cyclePosition(index); // Cycle recipient positions
          }
        });
      });
    });
  } else {
    // 🔹 Some members are missing → ADD only missing ones OR UPDATE 'disabled' to 'to'
    const processedContacts = new Set(); // Prevent duplicates

    memberContacts.forEach(contact => {
      if (processedContacts.has(contact.id)) return; // Skip if already processed
      processedContacts.add(contact.id);

      const hasMatchingEmail = contact.email_addresses.some(emailObj =>
        props.recipients.some(recipient => recipient.address === emailObj.value)
      );

      if (hasMatchingEmail) {
        contact.email_addresses.forEach(emailObj => {
          const recipientIndex = props.recipients.findIndex(recipient => recipient.address === emailObj.value);

          if (recipientIndex !== -1 && props.recipients[recipientIndex].position === "disabled") {
            emit('updateRecipient', recipientIndex, "to")// Convert disabled to "to"
          }
        });
      } else {
        // Add only if no email exists in recipients
        addNewRecipient(contact.id);
      }
    });
  }
};

const contactRecipients = computed(() => {
    return props.recipients.filter((i) => i.id !== null && i.position !== 'disabled')
})
const emailRecipients = computed(() => {
    return props.recipients.filter((i) => i.id === null && i.position !== 'disabled')
})

function getContactName(id: number): string {
    return contacts.getItem(id).name
}

</script>

<template>


<div class="flex flex-col border">

    <div class="flex flex-col">
        <div class="flex flex-col border-b">
            <div class="flex flex-row gap-2 items-center p-4">
                <div class="text-sm pt-1">Select from your contacts:</div>
                <div class="flex flex-row mt-1 items-center cursor-pointer gap-4">
                  <div @click="showAllContacts = !showAllContacts">
                    <svg v-if="!showAllContacts" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                    </svg>
                  </div>
                    <div v-if="!showAllContacts" class="flex flex-row gap-1">
                      <div
                        class="flex flex-row gap-1 cursor-pointer select-none"
                        v-for="item in contactRecipients"
                        @click="cycleAddress(item.address)"
                        :key="item.address"
                      > 
                        <div
                            class="flex flex-row select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs p-1"
                            :class="getRecipientClass(item.address)"
                        > {{ getContactName(item.id) }}
                        </div>
                      </div>
                    </div>
                </div>  
            </div>
            <div class="px-4" v-if="showAllContacts">
                <ContactOptions
                    :projectContacts="projectContacts"
                    :contactFilter="contactFilter"
                    @setContactFilter="contactFilter = $event"
                    @setSearchStr="setContactSearchStr"
                ></ContactOptions>
                <IndividualContacts 
                    v-if="contactFilter === 'individual'"
                    :recipients="recipients"
                    :projectContacts="projectContacts"
                    :getRecipientClass="getRecipientClass"
                    :searchStr="contactSearchStr"
                    @cycleContact="cycleContact"
                    @cycleAddress="cycleAddress"
                >    
                </IndividualContacts>
                <ContactGroups
                    v-if="contactFilter === 'group'"
                    :recipients="recipients"
                    :projectContacts="projectContacts"
                    :searchStr="contactSearchStr"
                    @toggleGroup="selectGroup"
                ></ContactGroups>
            </div>
        </div>
    </div>

    <div class="flex flex-col justify-between w-full">
    <div class="flex flex-col p-4 w-full">
        <div class="flex flex-row gap-2">
        <div class="text-sm">Or enter an email address:</div>
        <div @click="showEmailForm = !showEmailForm" class="items-center cursor-pointer">
            <svg v-if="!showEmailForm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
        </div> 
        <div v-if="!showEmailForm" class="flex flex-row gap-1">
            <div
              class="flex flex-row gap-1 cursor-pointer select-none"
              v-for="item in emailRecipients"
              @click="cycleAddress(item.address)"
              :key="item.address"
            > 
              <div
                  class="flex flex-row select-none col-span-1 border border-gray-200 rounded self-center text-center text-xs p-1"
                  :class="getRecipientClass(item.address)"
              > {{ item.address }}
              </div>
            </div>
          </div>
        </div>
       <CustomRecipient
            v-if="showEmailForm"
            :recipients="recipients"
            :getRecipientClass="getRecipientClass"
            @cycleAddress="cycleAddress"
       ></CustomRecipient>
    </div>
    </div>
</div>
</template>