<script setup>

import { useTasks } from '@/store/items/task'

import { computed } from 'vue'

const props = defineProps({
    project: Number,
    participants: Array
});


const tasks = useTasks()

const goals = computed(() => tasks.goalsForProject(props.project))

</script>

<template>
    <div class="flex flex-col min-h-0 border m-4 overflow-auto w-full">
        <div v-for="goal in goals">{{ goal.label }}</div>
    </div>
</template>