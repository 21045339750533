<script setup lang="ts">

import { useRouter } from 'vue-router';

const router = useRouter()

const props = defineProps<{
    pk: number
}>()

function setFocus() {
    router.push(`/focus/${props.pk}`); 
}

</script>

<template>
    <div @click="setFocus" class="text-white bg-fuchsia-700 p-1 rounded cursor-pointer hover:bg-fuchsia-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z" />
        </svg>
    </div>
</template>