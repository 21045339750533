<script setup lang="ts">

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from "axios";

const router = useRouter()
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const isAdam = ref<boolean>(false)
const feedback = ref([])
const applications = ref([])

const userSubmission = ref<string>('')
const userComplete = ref<boolean>(false)

function submitFeedback() {
    axios.post(`${apiEndpoint}/feedback/`, {
        feedback: userSubmission.value
    })
    userSubmission.value = ''
    userComplete.value = true
}

onMounted(async() => {
    const response = await axios.get(`${apiEndpoint}/feedback/`)
    if (response.data.permissions === 'admin') {
        feedback.value = response.data.feedback
        applications.value = response.data.applications
        isAdam.value = true
    }
})

</script>

<template>

    <div v-if="isAdam" class="flex flex-row gap-2">
        <div class="max-w-xl mx-auto bg-white rounded-lg shadow-md p-8 space-y-6 mt-12 w-full">
            <h1 class="text-3xl font-bold text-center text-teal-700 border-b pb-2">
                Feedback
            </h1>
            <div v-for="item in feedback" :key="item.id" >
                <section class="space-y-2">
                    <h2 class="font-semibold">{{ item.username }}</h2>
                    <div class="flex flex-col gap-2">
                        <p class="text-xs text-gray-700">
                            {{ item.time }}
                        </p>
                        <p class="text-sm text-gray-700">
                            {{ item.text }}
                        </p>
                    </div>
                </section>
            </div>
        </div>
        <div class="max-w-xl mx-auto bg-white rounded-lg shadow-md p-8 space-y-6 mt-12 w-full">
            <h1 class="text-3xl font-bold text-center text-teal-700 border-b pb-2">
                Applications
            </h1>  
            <div v-for="item in applications" :key="item.id" >
                <section class="space-y-2">
                    <h2 class="font-semibold">{{ item.email }}</h2>
                </section>
            </div>
        </div>
    </div>

    <div v-else-if="!userComplete" class="max-w-xl mx-auto bg-white rounded-lg shadow-md p-8 space-y-6 mt-12">
        <h1 class="text-3xl font-bold text-center text-teal-700 border-b pb-2">
            Feedback
        </h1>

        <section class="space-y-2">
            <h2 class="text-xl font-semibold text-teal-700">Thank You!</h2>
            <p class="text-gray-700">
                I'm grateful that you tried this out and I’d really love to hear what you think. Does the user interface make sense? Was it easy to use? Were any features confusing? What could we improve? Anything you can share is extremely helpful.
            </p>
        </section>
        <section class="space-y-2 flex flex-col">
            <textarea v-model="userSubmission" type="text" class="w-full h-64"/>
            <button @click="submitFeedback" class="btn btn-confirm ml-2">Submit</button>
        </section>
    </div>

    <div v-else class="max-w-xl mx-auto bg-white rounded-lg shadow-md p-8 space-y-6 mt-12">
        <h1 class="text-3xl font-bold text-center text-teal-700 border-b pb-2">
            Feedback
        </h1>

        <section class="space-y-2">
            <h2 class="text-xl font-semibold text-teal-700">Thank You!</h2>
            <p class="text-gray-700">
                I really appreciate everyone who takes the time to help me make improvements! If you know someone who might find this application useful, please pass it along to them, as I would love their input as well.
            </p>
        </section>
        <section class="flex flex-row gap-2 justify-between">
            <button class="btn-confirm" @click="userComplete = false">Say Something Else</button>
            <button class="btn-outline" @click="router.push('/dashboard/')">Back to App</button>
        </section>

    </div>
</template>