import { createStore } from "@/store/items/common/index.js";

const template = {
  'name': "",
  'project': ""
}

export const useBinders = createStore({
  model: "binder", 
  template, 
});
