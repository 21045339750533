<script setup lang="ts">
import type { TaskItem } from '@/store/items/interfaces/task';

import NotesField from '@/components/common/formFields/forTask/Notes.vue';
import StatusField from '@/components/common/formFields/forTask/Status.vue';
import DeadlineField from '@/components/common/formFields/forTask/Deadline.vue';
import EnthusiasmField from '@/components/common/formFields/forTask/Enthusiasm.vue';
import DurationField from '@/components/common/formFields/forTask/Duration.vue';
import SelectProject from '@/components/common/formFields/SelectProject.vue';

import { useTasks } from '@/store/items/task';
import { useWorkdays } from '@/store/items/workday'

import { ref, reactive, onMounted } from 'vue';

const props = defineProps<{
  position: string;
  workdayID?: number
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const store = useTasks();
const workdays = useWorkdays();
const form = reactive(store.proposed);

function updateField<K extends keyof TaskItem>(data: { field: K; value: TaskItem[K] }): void {
  form[data.field] = data.value;
}

async function saveData(): Promise<void> {
  const isUpdating = Boolean(form.id);
  const isDoneStatus = form.status === 5;
  let taskItem
  if (isUpdating) {
    taskItem = await store.save();
    if (isDoneStatus) {
      closeTask();
    }
  } else {
    taskItem = await store.create();
  }
  if (props.workdayID) {
    workdays.autoAssignRecord(taskItem.id, props.workdayID)
  }

  emits('close');
}

const showSelectProject = ref<boolean>(false)

onMounted(() => {
  if (!form.category) {
    showSelectProject.value = true
  }
})

function closeTask(): void {
  if (props.position in store.opened) {
    store.opened[props.position].pop();
  }
}
</script>
<template>
    <div class="pt-4 flex flex-col gap-5">
        <SelectProject 
          v-if="showSelectProject" 
          :value="form.category || null"
          @updateField="updateField"
        ></SelectProject>

        <div class="flex flex-col">         
            <div class="form-label">
                Label
            </div>
            <input autofocus v-model="form.label" type="text" class="form-field">            
        </div>

        <template v-if="!form.children || !form.children.length">
        <StatusField
            v-if="!form.parent"
            :data="form.status"
            @updateField="updateField"
        ></StatusField>
 
        <DeadlineField
            v-if="!form.routine && !form.parent"
            :data="form.due_date"
            :isChild="false"
            @updateField="updateField"
        ></DeadlineField>

        <DurationField
          :data="form.minutes_allotted"
          :label="'Remaining Work'"
          @updateField="updateField"
        ></DurationField>

        <EnthusiasmField
            :data="form.enthusiasm"
            :classes="{label:''}"
            @updateField="updateField"
        ></EnthusiasmField>

        <NotesField
            v-if="!form.id"
            :data="form.details"
            :classes="{label:''}"
            @updateField="updateField"
        ></NotesField>
        </template>

        <div class="flex flex-row justify-end border-t pt-4">
            <div @click="saveData" class="btn-confirm">Save</div>
        </div>

    </div>
</template>