<script setup lang="ts">

import { useNotecards } from '@/store/items/notecard';
import { computed } from 'vue';

import ArrowBack from '@/components/common/buttons/ArrowBack.vue';
import EditItem from '@/components/common/buttons/EditItem.vue';
import DeleteItem from '@/components/common/buttons/DeleteItem.vue';

const props = defineProps<{
    selected: number | null;
}>();

const emit = defineEmits(['openForm', 'deselect']);

const notecards = useNotecards();

const headerText = computed(() => {
    if (!props.selected) {
        return 'All Notecards';
    } else {
        return notecards.getItem(props.selected).title;
    }
});

function handleOpenForm(id: number){ 
    emit('openForm', id)
}

function handleDelete(){
    notecards.del(props.selected!)
    emit('deselect')
}

function handleDeselect(){
    emit('deselect')
}

</script>

<template>

    <div class="flex flex-col bg-white p-6 gap-2">
        <div class="flex flex-row justify-between">
            <div class="flex flex-row gap-2">
                <ArrowBack 
                  v-if="props.selected" 
                  @click=handleDeselect
                />
            </div>
            <div class="flex flex-row gap-2">
                <EditItem 
                  v-if="props.selected" 
                  :pk="props.selected"
                  :onClick="handleOpenForm"  
                />
                <DeleteItem 
                  v-if="props.selected" 
                  :pk="props.selected"
                  :onClick="handleDelete"
                />

            </div>
        </div>
        <div class="flex flex-row w-full justify-between">
            <div class="text-xl">{{headerText}}</div>
            <div class=""></div>
        </div>
        <div 
          v-if="selected"
          v-html="notecards.getItem(selected).text"
        ></div>
    </div>

</template>