<script>
import { computed, ref } from "vue";
import Slider from "@vueform/slider";

export default {
  name: "Duration",
  props: {
    data: Number, // Initial data in minutes
    label: String, // Field Label
  },
  emits: ["updateField"],
  components: { Slider },
  setup(props, context) {
    const minutesPicker = ref(null);
    const hoursPicker = ref(null);

    const splitMinutesAllotted = computed(() => {
      let minutes = props.data;
      let output = { hours: 0, minutes: minutes };
      if (minutes > 0) {
        if (minutes > 59) {
          output["hours"] = Math.floor(minutes / 60);
        }
        output["minutes"] = minutes % 60;
      }
      return output;
    });

    function combineMinutesWithHours($event) {
      let minutes = $event;
      let hours = parseInt(hoursPicker.value.modelValue);
      context.emit("updateField", {
        field: "minutes_allotted",
        value: minutes + hours * 60,
      });
    }

    function combineHoursWithMinutes($event) {
      context.emit("updateField", {
        field: "minutes_allotted",
        value: $event * 60,
      });
    }

    return {
      splitMinutesAllotted,
      combineMinutesWithHours,
      combineHoursWithMinutes,
      minutesPicker,
      hoursPicker,
    };
  },
};
</script>

<template>
  <div class="flex flex-col">
    <label class="form-label">
      <span>{{ label }}</span>
    </label>

    <div class="flex flex-col gap-5 ">
      <div class="flex flex-col gap-1 mt-2">
        <Slider
          ref="hoursPicker"
          :max="24"
          v-model="splitMinutesAllotted.hours"
          @change="combineHoursWithMinutes($event)"
        />
        <span class="text-xs font-bold select-none">Hours</span>
      </div>
      <div class="flex flex-col gap-1">
        <Slider
          ref="minutesPicker"
          :max="59"
          :step="5"
          v-model="splitMinutesAllotted.minutes"
          @change="combineMinutesWithHours($event)"
        />
        <span class="text-xs font-bold select-none">Minutes</span>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/slider/themes/default.css"></style>
