<script setup lang="ts">

import SelectWeek from './SelectWeek.vue';
import SelectWorkday from './SelectWorkday.vue';

import { getDateSlugFromObj } from '@/utils/dateUtils';
import { useRouter } from 'vue-router';
import { useAgenda } from '@/store/pages/agenda';

const router = useRouter();
const store = useAgenda();

const props = defineProps<{
    selected: {
        workday: number | null;
    };
    dataLoaded: boolean
}>();

const emit = defineEmits(['selectItem', 'setView']);

function handleSelectWorkday(pk: number| null) {
    emit('selectItem', 'workday', pk)
}

function setAutoSchedule() {
    emit('setView', 'center', 'autoSch')
}

const scrollWeek = async (direction: string) => {
    let thisDate = Date.parse(store.weekStr) ? new Date(Date.parse(store.weekStr + "T00:00")) : new Date()
    let next = (direction === 'f') ? thisDate.setDate(thisDate.getDate() + 7) : thisDate.setDate(thisDate.getDate() - 7)
    const newSlug = getDateSlugFromObj(new Date(next)) 
    router.push({ name: 'agenda', params: { slug: newSlug } })
}

</script>

<template>

<div class="flex flex-row border-b bg-white select-none">
          
    <SelectWeek
        @scrollWeek="scrollWeek"
        @setAutoSchedule="setAutoSchedule"
    ></SelectWeek>

    <SelectWorkday 
      v-if="dataLoaded"
      :selected="selected.workday"
      @selectWorkday="handleSelectWorkday"
    ></SelectWorkday>
    
    <div></div>
</div>

</template>