<script setup lang="ts">

import Timer from './Timer.vue'
import TimerForm from './TimerForm.vue'
import type { TaskItem } from '@/store/items/interfaces/task'
import type { WorkdayItem } from '@/store/items/interfaces/workday'
import { useRecords } from '@/store/items/record'
import { useApiCourier } from '@/store/items/common/apiCourier'
import Status from '@/components/common/formFields/forTask/Status.vue'
import { useRouter } from 'vue-router'

import { ref, computed, onMounted } from 'vue'
import type { RecordItem } from '@/store/items/interfaces/record'

const records = useRecords()
const router = useRouter()

const props = defineProps<{
    taskObj: TaskItem
}>()

const workday = ref<WorkdayItem | null>(null)
const record = computed(() => {
    if (!workday.value) {
        return records.proposed 
    }
    const existingRecord = records.forWorkdayAndTask(workday.value.id, props.taskObj.id)
    return existingRecord ? existingRecord : records.proposed
})

const showRecordForm = ref(false)

const workedInSession = ref<number>(0)
const timerLock = ref(true)
function updateTaskStatus(input: {field: string, value: number}) {
    records.proposed.status_for_task = input.value
}

function manualTimeEntry(value: number) {
    updateWorked(value)
    updateRecord()
}

function updateWorked(value: number) {
    if (timerLock.value) return;
    workedInSession.value = value
    if (showTimerForm.value) {
        toggleTimerForm()
    }
}

async function getWorkday() {
    const data = {date: new Date().toISOString().substring(0, 10)}
    const response = await useApiCourier().interaction('filter', 'workday', data)
    workday.value = response.results[0]
}

function updateRecord() {
    records.proposed.minutes_worked = Math.round(workedInSession.value / 1000 / 60)
    records.save()
}

onMounted(async () => {
    await getWorkday()
    await records.loadItems({task:props.taskObj.id, workday:workday.value.id})
    records.proposed = record.value
    if(!record.value.id) {
        records.proposed.task = props.taskObj.id
        records.proposed.workday = workday.value.id
    }
    workedInSession.value = record.value.minutes_worked * 1000 * 60
    setTimeout(() => {
        timerLock.value = false;
    }, 100);
})
const adjustedMinutesWorked = ref<number | null>(null); // User input for manual adjustments

function openRecordForm() {
    adjustedMinutesWorked.value = Math.round(workedInSession.value / 1000 / 60)
    showRecordForm.value = true
}
async function submitRecordForm() {
  records.proposed.minutes_worked = adjustedMinutesWorked.value;
  records.proposed.completed = true
  records.proposed.id = record.value.id
  await records.save();
  router.replace('/today/');
}

function cancelRecordForm() {
  showRecordForm.value = false; // Hide the form without saving
  adjustedMinutesWorked.value = null; // Reset adjustment
}

const showTimerForm = ref<boolean>(false)
function toggleTimerForm() {
    showTimerForm.value = !showTimerForm.value
}

</script>

<template>
    <div class="flex flex-row py-2">
        <div
          v-if="!showTimerForm" 
          class="w-72 flex flex-row items-center"
        >
            <Timer
            :currentTime="workedInSession"
            :targetValue="record.minutes_planned"
            @toggleForm="toggleTimerForm()"
            @updateValue="updateWorked"
            @saveValue="updateRecord"
            ></Timer>
        </div>

        <TimerForm
          v-else
          :currentTime="workedInSession"
          @update="manualTimeEntry"
        >

        </TimerForm>

        <div class="flex flex-row items-center gap-2 border-l p-2">
            {{ taskObj.label }}
        </div>
    </div>
    <div v-if=!showRecordForm @click="openRecordForm" class="btn-bold m-3 text-sm items-center flex flex-row">
        Work Complete
    </div>
    <div v-else class="flex flex-row gap-4 py-1 px-3 border rounded bg-gray-50 text-xs items-center">
        <div class="flex flex-row w-96">
            <Status
            :data="records.proposed.status_for_task || taskObj.status"
            labelClass="hidden"
            @updateField="updateTaskStatus"
            />
        </div>

        <div class="flex flex-row gap-2 items-center">
            <!-- Cancel Button -->
            <button
            @click="cancelRecordForm"
            class="border border-gray-300 text-gray-600 rounded px-4 py-2 hover:bg-gray-100"
            >
            Cancel
            </button>

            <!-- Submit Button -->
            <button
            @click="submitRecordForm"
            class="bg-teal-600 text-white rounded px-4 py-2 hover:bg-teal-700"
            >
            Save
            </button>
            
        </div>
    </div>
</template>