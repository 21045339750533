<script setup lang="ts">

import SearchBar from '@/components/common/search.vue';

import { useThreads } from "@/store/items/thread";
import { useProjects } from '@/store/items/project';
import { ref, computed } from 'vue';
import type { ThreadItem, FilterItem } from '@/store/items/interfaces/thread';;

const store = useThreads();
const projectStore = useProjects();

const searchStr = ref<string>("");
const expanded = ref<number | null>(null);
const applyToExisting = ref<boolean>(false);

const proposed = ref<{
    category: number | null;
    subject: string | null;
}>({
    category: null,
    subject: null,
});

const emit = defineEmits<{
    (e: 'close'): void;
}>();

function expand(item: FilterItem): void {
    proposed.value.category = item.category;
    proposed.value.subject = item.subject;
    expanded.value = item.id;
}

function runSearch(str: string): void {
    searchStr.value = str;
}

const filterList = computed<FilterItem[]>(() => {
    if (searchStr.value.length) {
        return store.filters.items.filter(
            (item: FilterItem) =>
                (item.source && item.source.includes(searchStr.value)) ||
                (item.subject && item.subject.includes(searchStr.value))
        );
    } else {
        return store.filters.items;
    }
});

async function saveFilter(pk: number): Promise<void> {
    const data = { ...proposed.value, id: pk, applyToExisting: applyToExisting.value };
    const response = await store.interactFilter('update', data);

    // Update the filters in the store
    const targetIndex = store.filters.items.findIndex((item: FilterItem) => item.id === response.data.filter.id);
    if (targetIndex !== -1) {
        store.filters.items[targetIndex] = response.data.filter;
    }

    // Update threads if applicable
    if (response.data.threads) {
        const updatedThreadPKs: number[] = response.data.threads;
        store.items.forEach((obj: ThreadItem) => {
            if (updatedThreadPKs.includes(obj.id!)) {
                obj.category = proposed.value.category;
            }
        });
    }

    expanded.value = null;
}


</script>


<template>
<div class="flex flex-col m-4 mt-0 bg-white rounded shadow py-3 h-full select-none" style="width:800px">
    <div class="flex flex-row justify-between px-3 pb-3">
        <div class="text-xl ">Filter Settings</div>
        <div @click="emit('close')" class="cursor-pointer hover:text-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
    <div class="flex flex-row justify-end items-center w-full border-y p-2 pr-3">         
        <SearchBar
            @runSearch="runSearch"
        ></SearchBar>
    </div>
    <div class="flex flex-col h-full overflow-scroll scrollbar-thin p-4">
        <div v-for="item in filterList" class="border-b">
            <div v-if="expanded === item.id" class="flex flex-col gap-2 p-2 ">
                <div class="flex flex0row justify-between">
                    <div>Sorting messages from:</div>
                    <div @click="expanded = null" class="cursor-pointer hover:text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>                
                <div class="font-bold">{{ item.source }}</div>
                <div class="flex flex-row gap-4">
                    <div class="flex flex-col">
                        <label for="selectCategory" class="text-sm">Into:</label>
                        <select id="selectCategory" v-model="proposed.category">
                            <option :value="null">Trash</option>
                            <option v-for="item in projectStore.items" :value="item.id">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="flex flex-col flex-grow">
                        <label for="subject" class="text-sm">Subject:</label>
                        <input v-model="proposed.subject" type="text" class="form-field" />
                    </div>
                </div>
                <div class="flex flex-row w-full justify-between items-center ">
                    <div class="flex flex-row gap-1 items-center text-sm">
                        <input
                            v-model="applyToExisting"
                            class="form-checkbox"
                            id="applyToExisting"
                            type="checkbox"
                        /><label for="applyToExisting"></label>
                        <div>Apply to Existing Messages</div>
                    </div>
                    <div class="flex flex-row gap-2">
                        <div @click="store.deleteFilter(item.id)" class="text-white bg-red-700 p-1 rounded cursor-pointer hover:bg-red-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg>
                        </div>
                        <div @click="saveFilter(item.id!)" class="text-white  p-1 rounded cursor-pointer bg-teal-600 hover:bg-teal-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>
                        </div>
                    </div>
                </div>
                
            </div>
            <div v-else @click="expand(item)"  class="flex flex-col gap-2 hover:bg-gray-100 p-2  cursor-pointer">
                <div>{{ item.source }}</div>
                <div class="text-gray-500 italic" v-if="item.subject" >"{{ item.subject }}"</div>
            </div>
        </div>
    </div>
</div>

</template>