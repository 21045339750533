import { calculateDaysUntil } from "@/utils/dateUtils";

const displayActions = {
    getMinutesRemaining(pk) {
        let remaining = 0
        const obj = this.getItem(pk)
  
        if (obj.children.length) {
          obj.children.forEach(
            (item) => (remaining += this.getMinutesRemaining(item))
          );
        } else {
          remaining += obj.minutes_remaining
        }
        return remaining
      },
      returnTopAncestorPK(pk){
        let parent = pk
        let task = this.getItem(pk)
        if (task.parent) {
            parent = this.returnTopAncestorPK(task.parent)
        }
        return parent
      },
      getUrgencyClass(pk) {
        const dueDate = this.getItem.due_date
        if (!dueDate) {
          return ""
        }
        const distance = calculateDaysUntil(dueDate)
        let output = "text-gray-600";
        if (distance <= 1) {
          output = "text-red-700";
        } else if (distance === 2) {
          output = "text-yellow-700";
        }
        return output;
    },
}
export { displayActions}