import { useToast } from "vue-toastification";

const dragDropActions = {
  onDrop(event, thisID) {
    const toast = useToast();
    this.transfer.target = thisID;
    this.transfer.data = {
      id: event.dataTransfer.getData("id"),
      model: event.dataTransfer.getData("model"),
    };
    const message =
      this.transfer.alerts[this.transfer.data.model] + " (Click to Undo)";
    if (
      this.transfer.model !== this.model &&
      thisID.toString() !== this.transfer.data.id
    ) {
      toast.success(message, {
        onClick: this.toastClick,
        onClose: this.toastClose,
      });
    }
  },
  startDrag(event, id) {
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("id", id);
    event.dataTransfer.setData("model", this.model);
  },
  toastClick(closeToast) {
    this.transfer.confirm = false;
    closeToast();
  },
  toastClose() {
    if (this.transfer.confirm) {
      this.commitTransfer();
    } else {
      this.transfer.confirm = true;
    }
  },
};

export { dragDropActions };