<script setup lang="ts">

import type { TaskItem } from '@/store/items/interfaces/task';
import PhaseDisplay from '../../TaskPanel/PhaseDisplay/PhaseDisplay.vue';
import TaskForm from '@/components/common/forms/TaskForm.vue';
import TaskDetails from '../../TaskPanel/TaskDetails/TaskDetails.vue';

import { useTasks } from '@/store/items/task';
import { ref, computed, watch } from 'vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';

const tasks = useTasks();

const props = defineProps<{
    project: number;
    obj: TaskItem;
}>();

const showTaskForm = ref<boolean>(false)
const formPosition = ref<number | null>(null)
const formPhaseID = ref<any>(null)

function openTaskForm(pk: 'new' | number, position: number | null): void {
    if (position) {
        formPosition.value = position;
    }
    formPhaseID.value = tasks.proposed.phase
    if (pk === 'new') {   
        tasks.proposed.category = props.project;
    } else {
        tasks.proposed = tasks.getItem(pk);
    }
    showTaskForm.value = true;
}

const formLabel = computed(() => {
  let label = formPosition.value ? `New Task for Phase ${formPosition.value}` : 'New Task';
  if (tasks.proposed.parent) {
    const parentTask = tasks.getItem(tasks.proposed.parent);
    label += ` of: ${parentTask.label}`;
  }
  return label;
});

function closeForm(): void {
    showTaskForm.value = false;
}

watch(
  () => tasks.formOwner,
  (newOwner) => {
    if (showTaskForm.value && formPhaseID.value && newOwner !== formPhaseID.value) {
      closeForm();
    }
  }
);

</script>

<template>

<div class="w-full flex-col flex-grow  overflow-y-auto">
    <div class="flex flex-col flex-grow gap-4 border-b p-6 bg-white"> 
        <div v-if="showTaskForm" class="w-full flex-col bg-white ">
            <div class="flex flex-row border-b pb-2 text-sm justify-between text-gray-400 items-center">
                <div class=" text-gray-400">{{ formLabel }}</div>
                <CloseItem @click="closeForm()" />
            </div>
            <TaskForm
                :position="`${obj.id}`"
                @close="closeForm()"
            ></TaskForm>

        </div>
        <div v-else-if="tasks.opened[`${obj.id}`] && tasks.opened[`${obj.id}`].length" class="flex flex-col gap-3">
            <TaskDetails
                :pk="tasks.opened[`${obj.id}`][tasks.opened[`${obj.id}`].length - 1]"
                :goalID="`${obj.id}`"
                :parentLocation="formPhaseID"
                @openForm="openTaskForm"
            ></TaskDetails>
        </div>
        <PhaseDisplay
            v-else
            :pk="obj.id"
            :goalID="`${obj.id}`"
            :taskStatus="obj.status"
            @openForm="openTaskForm"    
        />
    </div>
</div> 
</template>