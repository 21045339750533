<script setup lang="ts">
  import { getDate } from '@/utils/dateUtils.js';
  import { getHrsMinutes } from '@/utils/timeUtils';

  import type { WorkdayItem } from '@/store/items/interfaces/workday';

  import { computed } from 'vue'

  const props = defineProps<{
    obj: WorkdayItem
  }>()

  const time_in_meetings = computed(() => getHrsMinutes(props.obj.minutes_in_meetings))
  const minutes_planned = computed(() => getHrsMinutes(props.obj.minutes_planned))
  const minutes_worked = computed(() => getHrsMinutes(props.obj.minutes_worked))

  const today = getDate(props.obj.date)
</script>

<template>          

    <div class="flex flex-row gap-2">
      <!-- <svg class="cursor-pointer w-7 h-7 p-0.5 border hover:bg-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" /></svg>
      <svg class="cursor-pointer w-7 h-7 p-0.5 border hover:bg-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" /></svg> -->
      <div class="p-0.5">{{ today }}</div>
    </div>
    <div class="flex flex-row gap-12 text-sm">
      <div class="flex flex-col gap-1">
        <div>Time in Meetings:</div>
        <div class="text-teal-600 text-lg"> {{ time_in_meetings }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div>Work Planned: </div>
        <div class="text-teal-600 text-lg">{{  minutes_planned }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div>Work Completed: </div>
        <div class="text-teal-600 text-lg">{{ minutes_worked }}</div>
      </div>
    </div>
    <div class="flex flex-row gap-2">
      <svg class="cursor-pointer w-8 h-8 border p-0.5 hover:bg-gray-100 rounded" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" /></svg>
      <svg class="cursor-pointer w-8 h-8 border p-0.5 hover:bg-gray-100 rounded" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" ><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" /></svg>
    </div>

</template>