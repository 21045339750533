<script setup lang="ts">

</script>

<template>

<div class="flex flex-col bg-white min-h-0 flex-grow">
    <div class="flex flex-col h-full">

        <div class="flex flex-row border justify-between w-full">
            <slot name="header"></slot>
        </div>
        
        <div class="grid grid-cols-3 divide-x-2 min-h-0 flex-grow">

            <div class="flex flex-col h-full min-h-0">
                <slot name="left"></slot>
            </div>

            <div class="flex flex-col h-full min-h-0">
                <slot name="center"></slot>
            </div>

            <div class="flex flex-col h-full min-h-0">
                <slot name="right"></slot>
            </div> 

        </div>
    </div>
</div>

</template>