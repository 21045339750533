<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useAgenda } from "@/store/pages/agenda";

import SelectStatus from "@/components/common/formFields/forTask/Status.vue";
import SelectDuration from "@/components/common/formFields/forTask/Duration.vue";
import CloseItem from "@/components/common/buttons/CloseItem.vue";

import { convertMinutesIntoHrsMinutesVerbose } from "@/utils/timeUtils";
import { getDateLabels } from "@/utils/dateUtils";
import { useTasks } from '@/store/items/task';
import { useRecords } from '@/store/items/record';
import { useWorkdays } from '@/store/items/workday';

const store = useAgenda();
const records = useRecords()
const workdays = useWorkdays()

const props = defineProps<{
  pk: number;
  workday: number;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();

const tasks = useTasks();

const obj = computed(() => tasks.getItem(props.pk));
const status = computed(() => obj.value.status);

const recordItems = computed(() => records.forTask(props.pk));

const selectedWorkday = ref(props.workday);

async function updateStatus(data: { value: number }) {
  tasks.setSelected(props.pk);
  if (obj.value.status !== data.value) {
    tasks.proposed.status = data.value;
    await tasks.save();
    tasks.clearSelected();
  }
}

function setSelected(workdayPK: number) {
  const selected = recordItems.value.find((record) => record.workday === workdayPK);
  if (selected) {
    records.proposed = selected;
  } else {
    records.clearProposed();
    records.proposed.workday = workdayPK;
  }
  selectedWorkday.value = workdayPK;
}

async function updatePlan(data: { value: number }) {
  const proposed = records.proposed;
  const workday = proposed.workday;

  const previousMinutesPlanned = proposed.minutes_planned!;

  records.proposed.minutes_planned = data.value;
  records.proposed.task = props.pk;

  if (proposed.hasOwnProperty("id") && Number.isInteger(proposed.id)) {
    if (data.value <= 0 && proposed.minutes_worked <= 0) {
      // Delete if data is 0 and work has not been logged for this record.
      await records.del(proposed.id!);
      records.proposed.id = null;
    } else {
      // Otherwise update.
      await records.update("minutes_planned");
    }
  } else {
    // Create new record.
    if (data.value > 0) {
      const newRecord = await records.create();
      records.setSelected(newRecord.id);
    }
  }
  const taskItem = tasks.getItem(props.pk);
  const difference = data.value - previousMinutesPlanned;
  taskItem.minutes_available -= difference; 
}

const isWorkdayRecord = (currentWorkdayId: number): boolean => {
  return recordItems.value.some((record) => record.workday === currentWorkdayId);
};

const workdayClasses = computed(() => {
  return workdays.items.reduce<Record<number, string>>((classes, workday) => {
    let classStr = "text-xs text-center border py-1 cursor-pointer hover:bg-gray-100";
    if (selectedWorkday.value === workday.id) {
      classes[workday.id] = `${classStr} border-b-white text-emerald-600 hover:bg-white`;
    } else if (isWorkdayRecord(workday.id)) {
      classes[workday.id] = `${classStr} text-pink-600`;
    } else {
      classes[workday.id] = classStr;
    }
    return classes;
  }, {});
});

const workdayStats = computed(() => {
  return workdays.items.reduce<Record<number, { scheduled: string; difference: string }>>(
    (summary, workday) => {
      // Filter records associated with the current workday
      const relatedRecords = records.items.filter(
        (record) => record.workday === workday.id
      );

      // Calculate the total scheduled time in minutes
      const totalScheduledMinutes = relatedRecords.reduce(
        (sum, record) => sum + (record.minutes_planned || 0), // Ensure no null/undefined values
        0
      );

      // Convert scheduled and difference into human-readable format
      const difference = convertMinutesIntoHrsMinutesVerbose(
        workday.duration - totalScheduledMinutes
      );
      const scheduled = convertMinutesIntoHrsMinutesVerbose(totalScheduledMinutes);

      // Add stats for the current workday
      summary[workday.id] = { scheduled, difference };

      return summary;
    },
    {} // Initial value for reduce
  );
});

onMounted(() => {
  setSelected(props.workday);
});

watch(
  () => props.pk,
  () => {
    setSelected(props.workday);
  }
);
</script>

<template>
  <div class="flex flex-col justify-between p-2">
    <div class="flex flex-row justify-between border-b text-gray-600 pb-4 ">
      <div class="text-xl ">{{ obj.label }}</div> 

      <!-- Close -->
      <CloseItem @click="emit('close')"></CloseItem>
    </div>
    <div class="m-2 p-4 shadow">
      <SelectStatus
        :data="status"
        labelClass="hidden"
        @updateField="updateStatus"
      ></SelectStatus>
    </div>

    <div class="shadow m-2 p-4">
      <div class="grid grid-cols-7 justify-around">
        <div v-for="workday in workdays.items" :key="workday.id">
          <div
            @click="setSelected(workday.id)"
            :class="workdayClasses[workday.id]"
          >
          {{ getDateLabels(workday.date).weekday.short }}
          </div>
        </div>
      </div>
      <div v-if="workday" class="flex flex-row justify-around py-6">
        <div class="flex flex-col text-center">
          <div class="border-b border-pink-300 uppercase text-sm">Scheduled</div>
          <div class="text-sm">{{ workdayStats[workday].scheduled }}</div>
        </div>
        <div class="text-sm flex flex-row items-center">-- This Day --</div>
        <div class="flex flex-col text-center">
          <div class="border-b border-pink-300 uppercase text-sm">Available</div>
          <div class="text-sm">{{ workdayStats[workday].difference }}</div>
        </div>
      </div>
      <SelectDuration
        :data="records.proposed.minutes_planned"
        @updateField="updatePlan"
      ></SelectDuration>
    </div>
  </div>
</template>
