<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'
import { useResources } from '@/store/items/resource';

import EditItem from '@/components/common/buttons/EditItem.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';
import DeleteItem from '@/components/common/buttons/DeleteItem.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';
import Archive from '@/components/common/Archive.vue';

const store = useResources()

const props = defineProps<{
    project: number,
    category: number
}>()

const items = computed(() => store.forProject(props.category))

const selected = computed(() => store.getSelected())

const editing = ref<boolean>(false)
function toggleEditing() {
    store.proposed = selected.value
    editing.value = !editing.value
}

function update() {
    store.save()
    store.clearProposed()
    editing.value = false
}

const showArchive = ref<boolean>(false)
function closeArchive(isArchived: boolean) {
    if (isArchived) {
        store.selected = null
    }
    showArchive.value = false
}

function deleteSelected() {
    store.del(selected.value.id)
}
onMounted(() => {
    store.loadItems({'category': props.category})
})

</script>

<template>
{{ store.selected }}
<div v-if="!store.selected && !items.length" class="italic text-center text-gray-400">
    No Resources Found
</div>
<div v-if="!store.selected" class="flex flex-col flex-grow">
    <div 
      v-for="item in items"
      class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
      @click="store.selected = item.id"
    >
        {{ item.title }}
    </div>
</div>

<div v-else class="flex flex-col gap-2">
    <div class="flex flex-row justify-between w-full">
        <div>{{ selected.title }}</div>
        <CloseItem @click="store.clearSelected()" />
    </div>

    <div v-if="!showArchive" class="flex flex-row gap-2 items-center">
        <EditItem :pk="selected.id" :onClick="toggleEditing"/>
        <input
          v-if="editing" 
          @keyup.enter="update()"
          type="text" 
          class="form-field w-full" 
          v-model="store.proposed.url"   
        >
        <a v-else href="selected.url" target="_blank" class="italic text-sm text-blue-500"> 
            {{ selected.url }}
        </a>
    </div>
    <div v-if="!showArchive"  class="flex flex-row justify-end gap-2">
        <ArchiveItem 
            @click="showArchive = true"
        />
        <DeleteItem 
            :pk="selected.id"
            :onClick="deleteSelected"
        />
    </div>
    <Archive 
      v-else
      :project="project"
      :store="store"
      @close="closeArchive"
    />
</div>

</template>