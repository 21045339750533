import type { ContactItem } from "@/store/items/interfaces/contact";

export interface FieldSet {
    type: keyof Pick<
        ContactItem,
        | "email_addresses"
        | "phone_set"
        | "date_set"
        | "url_set"
        | "address_set"
        | "note_set"
    >;
    label: string;
    proposed: Record<string, any>;
    defaultProposed: Record<string, any>;
  }
  
  export const fieldSets: FieldSet[] = [
    { type: 'email_addresses', label: "Email Addresses", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
    { type: 'phone_set', label: "Phone Numbers", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
    { type: 'date_set', label: "Important Dates", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
    { type: 'url_set', label: "Websites", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
    { type: 'address_set', label: "Addresses", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
    { type: 'note_set', label: "Personal Notes", proposed: { label: "", value: "" }, defaultProposed: { label: "", value: "" } },
  ];