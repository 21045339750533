<script setup lang="ts">

import { ref, computed, onMounted, watch } from 'vue';

import {panelConfig} from '@/components/projects/config/panelConfig'

import Loader from '@/components/common/Loader.vue';
import SelectProject from '@/components/projects/SelectProject/SelectProject.vue';
import ProjectNav from '@/components/projects/ProjectNav.vue';
import ProjectsLayout from '@/layouts/ProjectsLayout.vue';
import TaskView from '@/components/projects/TaskView/TaskView.vue';
import ContactsView from '@/components/projects/ContactsView/ContactsView.vue';
import AppointmentView from '@/components/projects/AppointmentView/AppointmentView.vue';
import ResourceView from '@/components/projects/ResourceView/ResourceView.vue';
import BindersView from '@/components/projects/BindersView/BindersView.vue';
import SettingsView from '@/components/projects/SettingsView/SettingsView.vue';
import NotesView from '@/components/projects/NotesView/NotesView.vue';
import ProjectForm from '@/components/projects/ProjectForm.vue';
import MessageView from '@/components/projects/MessageView/MessageView.vue';


// import MessagesView from '@/components/projects/MessagesView/MessagesView.vue';

import { useProjects } from '@/store/items/project';
import { useDirector } from '@/store/pages/director';
// import { useThreads } from '@/store/items/thread';

import type { DirectorExtensions } from '@/store/pages/interfaces/director';

// State stores
const store = useProjects();
const director = useDirector();
// const threadStore = useThreads();

// State variables
const selectedPanelLabel = ref<keyof DirectorExtensions>('task'); // Tracks the currently selected panel
const settingsViewIsOpen = ref<boolean>(false); // Tracks whether the settings view is open
const selected = computed(() => store.selected)
const panelSignal = computed(() => director.panelSignal)
const loaded = computed(() => director.loadedForProjects)

// Toggles the settings view
const toggleSettingsView = (): void => {
  settingsViewIsOpen.value = !settingsViewIsOpen.value;
};

// Loads objects related to a project
async function loadObjectsForProject(projectPK: number): Promise<void> {
  try {
    store.selected = projectPK; // Update the selected project in the store
    await director.loadItemsForProject(selectedPanelLabel.value, projectPK); // Load items for the selected panel
    // await threadStore.fetchProjectMessages(projectPK); // Fetch messages for the project
  } catch (error) {
    console.error(`Failed to load objects for project ${projectPK}:`, error);
  }
}

// Sets the selected panel and loads related objects
function setPanel(target: keyof DirectorExtensions): void {
  selectedPanelLabel.value = target; // Update the selected panel label
  if (store.selected) {
    loadObjectsForProject(store.selected); // Load objects if a project is selected
  }
  director.panelSignal = null
}

const formOpened = ref<boolean>(false);
function openForm(): void {
  store.selected = null
  formOpened.value = true;
}

onMounted(() => {
  const firstPK = store.items[0].id;
  if (firstPK){
    store.setSelected(firstPK);
    loadObjectsForProject(firstPK);}
});

watch(selected, () => {
  if(store.selected){
    formOpened.value = false;
  }
});
watch(panelSignal, () => {
  if (panelSignal.value){
    setPanel(panelSignal.value)
  }
})

</script>

<template>
    <ProjectsLayout
        :settingsViewIsOpen="settingsViewIsOpen"
    >
        <!-- <template #settings>
            <SettingsView
                @close="toggleSettingsView"
            />
        </template> -->
        
        <template #select-project>
          <SelectProject                
              :toggleSettingsView="toggleSettingsView"
              @loadProject="loadObjectsForProject"
              @openForm="openForm"
          />
        </template>

        <template #project-nav>
            <ProjectNav
                :selectedPanelLabel="selectedPanelLabel"
                @setPanel="setPanel"
            />
        </template>

        <template #panel>
          <template v-if="formOpened">
            <ProjectForm
              @close="formOpened = false"
              @loadProject="loadObjectsForProject"
            ></ProjectForm>
          </template>
          <template v-else-if="selected">

            <MessageView
                v-if="selectedPanelLabel==='thread'"
                :project="selected"
                :loaded="loaded['thread']"
            />
            
            <TaskView 
              v-if="selectedPanelLabel==='task'"
              :project="selected"
              :loaded="loaded['task']"
            ></TaskView>

            <ContactsView v-else-if="selectedPanelLabel==='contact'"
              :project="selected"
              :loadedContacts="loaded['contact']"
              :loadedGroups="loaded['group']"
            ></ContactsView>

            <AppointmentView v-else-if="selectedPanelLabel==='appointment'"
              :project="selected"
              :loaded="loaded['appointment']"
            ></AppointmentView>        

            <ResourceView v-else-if=" selectedPanelLabel==='resource'"
              :project="selected"
              :loaded="loaded['resource']"
            ></ResourceView>
      
            <NotesView v-else-if="selectedPanelLabel==='note'"
            :project="selected"
            :loaded="loaded['note']"
            ></NotesView>  
            
            <BindersView v-else-if="selectedPanelLabel==='binder'"
            :project="selected"
            :loaded="loaded['binder']"
            ></BindersView>

            <SettingsView v-else-if="selectedPanelLabel==='settings'"
              :project="selected"
            ></SettingsView>
          </template>
          
          <Loader v-else></Loader> 
        </template>  

    </ProjectsLayout>

</template>