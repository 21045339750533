<script setup lang="ts">

import { useTasks } from '@/store/items/task';

import { ref, computed } from 'vue';

import Archive from '@/components/common/Archive.vue';
import CloseItem from '@/components/common/buttons/CloseItem.vue';
import DetailsDisplay from './DetailsDisplay.vue';
import InteractionControls from './InteractionControls.vue';
import PhaseDisplay from '../PhaseDisplay/PhaseDisplay.vue';

const store = useTasks()

const props = defineProps<{
  pk: number;
  goalID: string;
  parentLocation: string | number;
}>();

const emit = defineEmits<{
  (e: 'displayTask'): void;
  (e: 'close'): void;
  (e: 'openForm', pk: 'new' | number, position: number | null): void;
}>();

const obj = computed(() => store.getItem(props.pk));

const showArchiveMenu = ref<boolean>(false)
function openArchiveMenu() {
    store.selected = props.pk
    showArchiveMenu.value = true
}
function closeArchive(isArchived: boolean) {
    showArchiveMenu.value = false
    if (isArchived) {
        emit('close')
        store.opened['root'].length = 0;
    }
}

function closeTask(): void {
  store.opened[props.goalID].splice(-1);
}

function openTaskForm(pk: 'new' | number, position: number | null): void {
    store.formOwner = props.parentLocation;
    emit('openForm', pk, position)
}

</script>

<template>
    <div class="flex flex-col gap-1 w-full">
        <div class="flex flex-col mx-6 pt-2">
            <div class="flex flex-row items-center justify-between">
                <div>{{ obj.label }}</div> 
                <CloseItem @click="closeTask" />
            </div>

            <div class="flex flex-row text-sm justify-between items-center border-y py-2 select-none">
                <DetailsDisplay
                    :obj="obj"
                />
                <InteractionControls
                    :obj="obj"
                    @openForm="openTaskForm"
                    @closeTask="closeTask"
                    @showArchiveMenu="openArchiveMenu"
                />
            </div>

            <div class="flex flex-col gap-4 border-b py-2">
                <Archive
                    v-if="showArchiveMenu"
                    :project="obj.category!"
                    :store="store"
                    @close="closeArchive"
                ></Archive>  
                <PhaseDisplay 
                    v-else
                    :pk="pk"
                    :goalID="goalID"
                    :taskStatus="obj.status"
                    @openForm="openTaskForm"
                />
            </div>
        </div>
    </div>
</template>