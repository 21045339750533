import { createStore } from "@/store/items/common/index.js";

const template = {
  category: null,
  text: ""
}

const customStore = {
  editorIsOpen: false
}

const customActions = {
  async save(action, data) {
    const result = await this.interact(action, data);
    if (action === "create") {
      this.items.push(result);
    } else {
      // Don't use default setCurrent() because this.selected might have changed.
      const targetIndex = this.items.findIndex((item) => item.id === data.id);
      this.items[targetIndex] = result;
    }
    return result;
  },
  async del(pk, project = null) {
    try {
      let url = pk;
      if (project) url += `?project=${project}`;
      await this.interact("delete", url);
      this.items = this.items.filter((item) => item.id !== pk);
    } catch (error) {
      console.error(`Error deleting item with ID ${pk}:`, error);
      throw error;
    }
},    
}


export const useNotes = createStore({
  model: "note",
  template,
  customStore,
  customActions,
})
