<script setup lang="ts">
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import NavigationHeader from '@/components/dashboard/NavigationHeader.vue';
import Loader from '@/components/common/Loader.vue';
import { defineAsyncComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAppointments } from '@/store/items/appointment/index';
import { useDirector } from '@/store/pages/director';
import { useProjects } from '@/store/items/project/index';
import { useRecords } from '@/store/items/record/index';
import { useTasks } from '@/store/items/task/index';
import { useWorkdays } from "@/store/items/workday"
import { formatDateForBackend } from '@/utils/dateUtils';

import type { RecordItem } from '@/store/items/interfaces/record';
import type { WorkdayItem } from '@/store/items/interfaces/workday';
import type { TaskItem } from '@/store/items/interfaces/task';
import type { AppointmentItem } from '@/store/items/interfaces/appointment';
import type { CategoryItem } from '@/store/items/interfaces/project';

const AppointmentSection = defineAsyncComponent(() =>
  import('@/components/dashboard/AppointmentSection/AppointmentSection.vue')
);
const ProjectSection = defineAsyncComponent(() =>
  import('@/components/dashboard/ProjectSection/ProjectSection.vue')
);
const RecordSection = defineAsyncComponent(() =>
  import('@/components/dashboard/RecordSection/RecordSection.vue')
);

interface DashboardData {
  workday: WorkdayItem | null;
  records: RecordItem[];
  tasks: TaskItem[];
  appointments: AppointmentItem[];
  categories: CategoryItem[];
}

const director = useDirector();
const recordStore = useRecords();
const projectStore = useProjects();
const taskStore = useTasks();
const workdayStore = useWorkdays()
const appointmentStore = useAppointments();
const router = useRouter();

const workday = ref<WorkdayItem | null>(null);
const records = computed<RecordItem[]>(() => {
  return workday.value?.id ? recordStore.getSet('workday', workday.value.id) : [];
});

async function getData(): Promise<void> {
  try {
    const formattedDate = formatDateForBackend(new Date());
    const response = await director.interact('filter', 'dashboard', { date: formattedDate });
    const data: DashboardData = response as DashboardData;
    workday.value = data.workday
    workdayStore.items.push(workday.value);
    recordStore.mergeItems(data.records);
    taskStore.mergeItems(data.tasks);
    appointmentStore.mergeItems(data.appointments);
    projectStore.mergeItems(data.categories);
  } catch (error) {
    console.error('Failed to fetch dashboard data:', error);
  }
}

function focusOnTask(taskPK: number): void {
  director.focus.taskPK = taskPK;
}

function navToProject(pk: number): void {
  projectStore.selected = pk;
  router.replace('/projects/');
}

getData();
</script>

<template>
  <DashboardLayout v-if="workday">
    <template #header>
      <NavigationHeader :obj="workday" />
    </template>

    <template #appointment>
      <AppointmentSection />
    </template>

    <template #records>
      <RecordSection 
        :workdayID="workday.id"
        :focusOnTask="focusOnTask" 
        :records="records"
      />
    </template>

    <template #projects>
      <ProjectSection :navToProject="navToProject" />
    </template>
  </DashboardLayout>
  <Loader v-else />
</template>