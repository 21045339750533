export function findSpaceIndexAfterNWords(text: string, n: number): number {
    const words = text.split(/\s+/);
    let wordCount = 0;
    for (let i = 0; i < words.length; i++) {
        wordCount++;
        if (wordCount === n && i + 1 < words.length) {
            return text.indexOf(words[i + 1]);
        }
    }
    return -1;
}

export function truncateText(text: string, maxLength: number): string {
    const cleanedText = text.replace(/<[^>]*>|&nbsp;/g, '');
    return cleanedText.length > maxLength ? cleanedText.slice(0, maxLength) + '...' : cleanedText;
}

export function splitText(text: string): [string, string] {
    if (typeof text !== 'string') {
        return ['Error', 'Improper Data Submitted'];
    }
    const separatorIndex = text.indexOf('</p>');
    const thirdWordSpaceIndex = findSpaceIndexAfterNWords(text, 5);
    let index;

    if (separatorIndex !== -1 && (thirdWordSpaceIndex === -1 || separatorIndex < thirdWordSpaceIndex)) {
        index = separatorIndex;
    } else if (thirdWordSpaceIndex !== -1) {
        index = thirdWordSpaceIndex;
    } else {
        return [text, ''];
    }

    const beforeSeparator = text.slice(0, index + 4); // Include '</p>'
    const afterSeparator = text.slice(index + 4);

    const result: [string, string] = [
        truncateText(beforeSeparator, 30),
        truncateText(afterSeparator, 65)
    ];

    return result;
}