<script setup lang="ts">

import { ref, computed } from 'vue'

import Loader from '@/components/common/Loader.vue'
import ListedResource from '@/components/common/listedItems/ListedResource.vue'
import ResourceForm from '@/components/common/forms/ResourceForm.vue'
import SearchBar from '@/components/common/search.vue'
import Archive from '@/components/common/Archive.vue'

import { useResources } from '@/store/items/resource'
import { getSearchResults } from '@/utils/searchUtils'

import type { ResourceItem } from '@/store/items/interfaces/resource'

const resourceStore = useResources()

// Define the props with correct types
const props = defineProps<{
  project: number;
  loaded: number[];  // assuming loaded is an array of project ids
}>()

const searchStr = ref<string>("")

// Define type for the search setter function
const setSearchStr = (val: string): void => {
  searchStr.value = val
}

// Define computed items based on the project and search string
const items = computed<ResourceItem[]>(() => {
  let results = resourceStore.forProject(props.project)
  return getSearchResults(results, searchStr.value, 'title')
})

const formIsOpen = ref<boolean>(false)

// Define functions with type annotations
function openNewForm(): void {
  resourceStore.clearProposed()
  formIsOpen.value = true
}

function openFormForItem(obj: ResourceItem): void {
  resourceStore.proposed = obj
  formIsOpen.value = true
}

function closeForm(): void {
  formIsOpen.value = false
}

const archiveIsOpen = ref<boolean>(false)
function openArchive(pk:number) {
  resourceStore.selected = pk
  archiveIsOpen.value = true
}
</script>

<template>
  <div class="h-full w-full flex flex-row">
    <div class="flex flex-col w-1/2 border-r p-5">
      <div class="flex flex-row gap-6 w-full text-sm items-center mb-5">
        <div class="text-xl text-teal-700">Resources</div>
      </div>
      <div class="bg-white rounded shadow flex flex-col overflow-y-auto">
        <div v-if="formIsOpen">
          <ResourceForm
            :project="project"
            @closeForm="closeForm"
          ></ResourceForm>
        </div>

        <div class="flex flex-col p-4 gap-4" v-else-if="archiveIsOpen">
          <div class="uppercase text-sm">Select binder for <span class='text-teal-600'>{{ resourceStore.getSelected().title }}</span></div>
          <Archive
           :project="project"
           :store="resourceStore"
           @close="archiveIsOpen = false"
          ></Archive>
        </div>

        <div v-else class="flex flex-col flex-grow min-h-0">
          <div class="flex flex-row justify-between border-b items-center pl-3">
            <SearchBar
              @runSearch="setSearchStr"
            ></SearchBar>
            <div class="p-3">
              <div @click="openNewForm()" class="btn-bold">Add Resource</div>
            </div>
          </div>
          <div v-if="!loaded.includes(project)">
            <Loader></Loader>
          </div>
          <div v-else class="flex flex-col p-4 flex-grow overflow-y-auto">
            <div v-if="items.length" class="flex flex-col gap-2">
              <div v-for="item in items" :key="item.id!" class="flex flex-row items-center border rounded-2xl text-sm divide-x group">
                <ListedResource
                  :item="item"
                  :starred="false"
                  @openForm="openFormForItem"
                  @showArchive="openArchive"
                  @del="resourceStore.del"
                />
              </div>
            </div>
            <div v-else class="flex flex-col gap-3 p-1 items-center">
              <div class="text-gray-500 select-none">
                No Resources For Project
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-1/2 m-5">
      <div class="bg-white rounded shadow"></div>
    </div>
  </div>
</template>
