import { mergeArrays, removeObjectFromArray } from '@/utils/arrayUtils'

const agendaActions = {

    async agendaInteract(action, type, data = {}, page = null) {
        return await this.customInteract(action, 'agenda-' + type, data, page)
    },
    async loadAgendaItems(apptPk, page=0) {
        this.agenda = []
        const data = {
          'appointment': apptPk
        }
        const sectionResult = await this.agendaInteract('filter', 'section', data, page);
        this.agenda = sectionResult.results
        if (sectionResult.next) {
          this.loadAgendaItems(apptPk, page + 1)
        }
      },
      async saveAgendaSection(action, data) {
        if (data.id === null) {
          delete data.id
        }
        const response = await this.agendaInteract(action, 'section', data);
        if (action === 'create') {
          this.agenda.push(response)
        }
      },
      async saveAgendaEntry(action, sectionPk, entryData) {
        if (entryData.id === null || entryData.id === undefined) {
          delete entryData.id;  // Ensure `id` is not included for new entries
        }
      
        const response = await this.agendaInteract(action, 'entry', { ...entryData, section: sectionPk });
      
        // Find the section where the entry belongs
        const sectionIndex = this.agenda.findIndex((section) => section.id === sectionPk);
        if (sectionIndex === -1) {
          throw new Error(`Section with id ${sectionPk} not found.`);
        }
      
        // If updating an entry, replace it. If creating, push the new entry.
        if (action === 'update') {
          const entryIndex = this.agenda[sectionIndex].agenda_entries.findIndex((entry) => entry.id === entryData.id);
          if (entryIndex !== -1) {
            this.agenda[sectionIndex].agenda_entries.splice(entryIndex, 1, response); // Replace entry
          } else {
            throw new Error(`Entry with id ${entryData.id} not found in section ${sectionPk}.`);
          }
        } else if (action === 'create') {
          this.agenda[sectionIndex].agenda_entries.push(response); // Add new entry
        }
      
        return response;  // Return the saved/created entry for further use
      },
      async updateAgendaOrder(oldIndex, newIndex, section, appointment) {
        this.saveContent('section', {'order': newIndex + 1, id: section, appointment: appointment})
        const movedItem = this.agenda.splice(oldIndex, 1)[0];
        this.agenda.splice(newIndex, 0, movedItem);
      },
      async updateEntryOrder(oldIndex, newIndex, section, entry) {
        this.saveContent('entry', {'order': newIndex + 1, id: entry, section: section})
        const movedItem = this.agenda.find(item => item.id === section).agenda_entries.splice(oldIndex, 1)[0];
        this.agenda.find(item => item.id === section).agenda_entries.splice(newIndex, 0, movedItem);
      },
      async deleteAgendaSection(pk) {
        await this.agendaInteract('delete', 'section', pk);
        this.agenda = removeObjectFromArray(this.agenda, pk)
      },

      async deleteAgendaEntry(sectionPk, pk) {
        await this.agendaInteract('delete', 'entry', pk);
        const sectionIndex = this.agenda.findIndex(item => item.id === sectionPk);
        this.agenda[sectionIndex].agenda_entries = removeObjectFromArray(this.agenda[sectionIndex].agenda_entries, pk)  
      },
  
      async pasteAgenda(targetPk) {
        if (targetPk !== this.clipboard) {
          const data = {
            'origin': this.clipboard,
            'target': targetPk
          }
          const response = await this.customInteract('custom', 'copy_appointment_agenda', data);
          this.agenda = response.data.agenda
        }
      },
  
      async saveContent(type, data) {
        const mode = data['id'] ? 'update' : 'create'
        const response = await this.agendaInteract(mode, type, data)
        return response
      },
  
      async deleteContent(type, pk) {
        await this.agendaInteract('delete', type, pk)
      },
}

export { agendaActions };