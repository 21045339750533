<script setup lang="ts">

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import MessageContents from './MessageContents.vue';
import TaskContents from './TaskContents/TaskContents.vue';
import ContactContents from './ContactContents.vue';
import AppointmentContents from './AppointmentContents.vue';
import ResourceContents from './ResourceContents.vue';
import NotesContents from './NotesContents.vue';

import { ref, computed } from 'vue';

const props = defineProps<{
  project: number,
  category: number,
}>()

/**
 * For each binder "app", store a label (human-readable) and
 * a unique name to identify it. You can also directly
 * import components and store references here if preferred.
 */
const options = [
  { label: 'Messages', name: 'messages' },
  { label: 'Tasks', name: 'tasks' },
  { label: 'Contacts', name: 'contacts' },
  { label: 'Meetings', name: 'meetings' },
  { label: 'Resources', name: 'resources' },
  { label: 'Notes', name: 'notes' },
];

// This ref holds the name of the currently selected "app."
// If empty, nothing is displayed below the grid.
const selectedApp = ref<string>('');

function openApp(appName: string) {
  selectedApp.value = appName;
}

/**
 * Determine which child component to display based on selectedApp.
 * In a real-world scenario, you'd import actual components
 * (e.g., import Messages from '@/components/Messages.vue')
 * and return those references instead of placeholder strings.
 */

const currentAppLabel = computed(() => {
  const found = options.find(o => o.name === selectedApp.value);
  return found ? found.label : '';
});

const currentAppComponent = computed(() => {
  switch (selectedApp.value) {
    case 'messages':
      return MessageContents;
    case 'tasks':
      return TaskContents;
    case 'contacts':
      return ContactContents;
    case 'meetings':
      return AppointmentContents;
    case 'resources':
      return ResourceContents;
    case 'notes':
      return NotesContents;
    default:
      return null;
  }
});
</script>
<template>
    <div class="h-full w-full p-4">
      <div v-if="!currentAppComponent" class="grid grid-cols-2 grid-rows-3 gap-4 h-full">
        <div
          v-for="option in options"
          :key="option.name"
          class="flex flex-row text-2xl font-thin items-center justify-around rounded bg-teal-800 p-4 border cursor-pointer hover:bg-teal-700"
          @click="openApp(option.name)"
        >
          <span class="text-xl font-medium uppercase text-white">{{ option.label }}</span>
        </div>
      </div>
  
      <!-- Display the "selected app" area -->
      <div class="flex flex-col h-full" v-if="currentAppComponent">
        <div class="flex flex-row justify-between border rounded p-4 bg-white shadow">
          <div>
            Viewing {{ currentAppLabel }}
          </div>
      
          <div>
              <CloseItem @click="selectedApp = ''" />
          </div>
        </div>
        <div class="p-4 bg-white border flex flex-col flex-grow overflow-auto">
          <component 
            :is="currentAppComponent" 
            :category="category"
            :project="project"
          />
        </div>
      </div>
    </div>
  </template>