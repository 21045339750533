<script setup lang="ts">

import { useRouter, useRoute } from "vue-router";

import Loader from '@/components/common/Loader.vue'
import Agenda from '@/components/meeting/agenda/AgendaSection.vue'
import Minutes from '@/components/meeting/minutes/MinutesSection.vue'
import References from '@/components/meeting/references/ReferencesSection.vue'
import Search from '@/components/common/search.vue'
import Header from '@/components/meeting/header.vue'
import ThreeTrayLayout from "@/layouts/ThreeTrayLayout.vue";
import { useDirector } from '@/store/pages/director'
import { useAppointments } from '@/store/items/appointment'

import { ref, computed, onBeforeMount, nextTick } from 'vue'

import type { AppointmentItem, AgendaEntryItem, AgendaSectionItem, AgendaAssignmentItem } from '@/store/items/interfaces/appointment'

const store = useAppointments()
const director = useDirector()
const route = useRoute()
const router = useRouter()

const pk = ref<number | null>(null)
const item = computed<AppointmentItem | null>(() => pk.value !== null ? store.getItem(pk.value) : null);
const showDropdown = ref<boolean>(false)
const projectsLoaded = ref<number[]>([])

const sectionIndex = ref<number>(-1)
const entryIndex = ref<number>(0)

const availableTasks = computed<AgendaAssignmentItem[]>(() => {
    const allTasks: AgendaAssignmentItem[] = []
    store.agenda.forEach((section: AgendaSectionItem) => {
        section.assignments!.forEach((item: AgendaAssignmentItem) => {
            if (!item.contact || item.assigned_to_self) {
                allTasks.push(item)
            }
        });
        section.agenda_entries.forEach((entry: AgendaEntryItem) => {
            entry.assignments!.forEach((item: AgendaAssignmentItem) => {
            if (!item.contact || item.assigned_to_self) {
                allTasks.push(item)
            }})
        })
    });
    return allTasks.sort((a, b) => Number(b.assigned_to_self) - Number(a.assigned_to_self))
})


const toggleDropdown = async () => {
    if (!showDropdown.value) {
        if (item.value && !projectsLoaded.value.includes(item.value.category)) {
            const data = {'project': item.value.category }
            await store.loadItems(data);
            projectsLoaded.value.push(item.value.category)
        }
        showDropdown.value = true;
        setTimeout(() => {
            document.addEventListener('click', handleClickOutside);
        }, 100); // Short delay to allow the dropdown to open
    } else {
        showDropdown.value = false;
        document.removeEventListener('click', handleClickOutside);
    }
}

const selectAppointment = (appointment: AppointmentItem) => {
  showDropdown.value = false;
  sectionIndex.value = -1
  entryIndex.value = 0
  router.replace('/meeting/' + appointment.id)
  pk.value = appointment.id
  searchStr.value = ""
}

const searchStr = ref<string>("")
const setSearchStr = (str: string) => {
    searchStr.value = str
}

const filteredAppointments = computed<AppointmentItem[]>(() => {
  if (!item.value) {
    return []
  }
  if (!searchStr.value) {
    return store.forProject(item.value.category);
  } else {
  return store.forProject(item.value.category).filter(appointment => {
    return (
      appointment.label.toLowerCase().includes(searchStr.value.toLowerCase()) ||
      appointment.participant_names.includes(searchStr.value.toLowerCase())
    );
  });
  }
})



const dataLoaded = ref(false);

onBeforeMount(async () => {
  const urlSlug = Array.isArray(route.params.slug) ? route.params.slug[0] : route.params.slug

  const result = await store.fetchItemByID(parseInt(urlSlug))
  if (!result) {
    router.replace("/dashboard/");
  } else {
    const project = result.category
    await director.loadItemsForProject('appointment', project)
    await director.loadItemsForProject('contact', project)
    await director.loadItemsForProject('task', project)
    await director.loadItemsForProject('resource', project)
    pk.value = result.id
    dataLoaded.value = true;
  }
});

const handleClickOutside = (event: MouseEvent) => {
  const target = event.target as HTMLElement; // Cast to HTMLElement
  if (!target.closest('.absolute')) {
    showDropdown.value = false;
    searchStr.value = ""
    document.removeEventListener('click', handleClickOutside);
  }
}

const sectionItem = computed<AgendaSectionItem | undefined>(() => store.agenda[sectionIndex.value])
    const entryItem = computed(() => {
    if (!sectionItem.value || !sectionItem.value.agenda_entries) return undefined;
    return { ...sectionItem.value.agenda_entries[entryIndex.value] }; // Ensure reactivity
});

const sectionCount = computed<number>(() => store.agenda.length)
const entryCount = computed<number>(() => sectionItem.value ? sectionItem.value.agenda_entries.length : 0)

function incrementSection(forward: boolean) {
    if (forward) {
        if (entryCount.value === 0 || entryIndex.value === entryCount.value - 1) {
            sectionIndex.value += 1
            entryIndex.value = 0
        } else {
            entryIndex.value += 1
        }
    } else {
        if (entryIndex.value === 0) {
            sectionIndex.value -= 1
            nextTick(() => {
                if (sectionCount.value > -1 && entryCount.value > 0) {
                    entryIndex.value = entryCount.value - 1
                } else {
                    entryIndex.value = 0
                }
            })
        } else {
            entryIndex.value -= 1
        }
    }
}

function setDisplay(section: number, entry: number) {
    sectionIndex.value = section
    entryIndex.value = entry
}

</script>


<template>
    <ThreeTrayLayout>

        <template #header>
            <Loader v-if="!item"></Loader>
            <Header
                v-else
                :item="item"
                @toggleDropdown="toggleDropdown"
            ></Header>
        </template>

        <template #left>
            <Agenda
                :pk="pk!"
                :sectionIndex="sectionIndex"
                @setDisplay="setDisplay"
            ></Agenda>
        </template>
        <template #center>
            <Loader v-if="!dataLoaded"></Loader>
            <Minutes
                v-else
                :sectionIndex="sectionIndex"
                :sectionItem="sectionItem!"
                :sectionCount="sectionCount"
                :entryIndex="entryIndex"
                :entryItem="entryItem!"
                :appointment="item!"
                :availableTasks="availableTasks"
                @step="incrementSection"
            ></Minutes>
        </template>
        <template #right>
            <Loader v-if="!dataLoaded"></Loader>
            <References
            v-else
            :project="item!.category"
            :participants="item!.participants"
            :appointmentPK="item!.id"
            ></References>
        </template>
        

</ThreeTrayLayout>

<!-- Dropdown Menu -->
<div v-if="showDropdown" class="absolute bg-white shadow-lg border p-4 mt-2 z-50" @click.stop>
    <Search
        @runSearch="setSearchStr"
    ></Search>
    <ul>
        <li v-for="appointment in filteredAppointments" :key="appointment!.id!" @click="selectAppointment(appointment)" class="cursor-pointer p-2 hover:bg-gray-100">
            <div class="text-lg">{{ appointment.label }}</div>
            <div class="text-gray-400 text-sm">{{ appointment.participant_names }}</div>
            <div class="text-gray-400 text-sm">Display Schedule Here</div>
        </li>
    </ul>
</div>

</template>