import { mergeArrays } from "@/utils/arrayUtils";

const itemActions = {
  setItem(id, obj) {
    const index = this.items.findIndex((item) => item.id === id);
    this.items[index] = obj;
  },
  mergeItems(items) {
    this.items = mergeArrays(this.items, items, "id");
  },
  removeItems(pks) {
    if (!Array.isArray(pks)) pks = [pks];
    this.items = this.items.filter((item) => !pks.includes(item.id));
  },
  modifyItems(pks, label, value) {
    if (!Array.isArray(pks)) pks = [pks];
    this.items = this.items.map((item) =>
      pks.includes(item.id) ? { ...item, [label]: value } : item
    );
  },
  openForm(pk) {
    this.proposed = {...this.getItem(pk)}
  },
  setSelected(pk) {
    this.selected = pk;
    this.proposed = { ...this.getSelected() };
  },
  clearProposed() {
    this.proposed = { ...this.blank };
  },
  clearSelected() {
    this.selected = null;
    this.clearProposed()
  },
  async deleteSelected() {
    this.del(this.selected);
    this.clearSelected()
  },

};

export { itemActions };