<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useThreads } from "@/store/items/thread";
import { useContacts } from "@/store/items/contact";
import Search from "@/components/common/search.vue";
import SelectOption from "@/components/common/buttons/SelectOption.vue";

const threads = useThreads();
const contacts = useContacts()

const enabled = ref<boolean>(false);

const options = [
    {value: "add", label: "Add Contact"},
    {value: 'search', label: "Search Contact"},
]
const action = ref<"add" | "search" | null>(null);
function setAction(value: "add" | "search") {
    if (value === "add") {
        threads.filterData.contactInfo.id = "temp";
        threads.filterData.contactInfo.name = "";
        confirmed.value = false;
    }
    action.value = value;
}

const searchStr = ref<string>("");
function setSearchStr(str: string) {
    searchStr.value = str;
}   
const filteredContacts = computed(() => {
    if (searchStr.value.length < 3) {
        return [];
    }
    return contacts.items.filter((contact: any) => 
        contact.name.toLowerCase().includes(searchStr.value.toLowerCase())
    );
});

function handleSelectContact(contact: any) {
    threads.filterData.contactInfo.id = contact.id;
    threads.filterData.contactInfo.name = contact.name;
    threads.filterData.saveContact = true;
};

const confirmed = ref<boolean>(false);
function toggleConfirm() {
    if (threads.filterData.contactInfo.name.length >= 3) {
        confirmed.value = !confirmed.value;
    } else {
        confirmed.value = false;
    }
    threads.filterData.saveContact = confirmed.value;
}

contacts.loadItems();

watch(() => enabled.value, (newValue) => {
    if (!newValue) {
      threads.filterData.saveContact = false;
    }
  }
);

</script>

<template>
<div class="w-1/2 flex flex-col gap-4 border border-l-0 p-4">
  <div class="flex flex-col gap-4 w-full">
    <!-- Toggle Option -->
    <div class="flex items-center gap-2 border-b ">
      <input 
        type="checkbox" 
        id="link-contact"
        v-model="enabled" 
        class="w-4 h-4 accent-blue-500 cursor-pointer mb-0.5"
      />
      <label for="link-contact" class="cursor-pointer">Link Email to Contact:</label>
    </div>

    <!-- Action Buttons -->
    <div v-if="enabled" class="flex flex-row gap-4 justify-center select-none">
      <SelectOption
        :options="options"
        :selected="action"
        @select="setAction"
      ></SelectOption>
    </div>
    <!-- Add Contact Form -->
    <div v-if="enabled && action === 'add'">
        <label class="block border-b mb-4">New Contact Name:</label>
        <div v-if="!confirmed" class="flex flex-col">
            <input 
                type="text" 
                v-model="threads.filterData.contactInfo.name" 
                placeholder="Enter contact name" 
                class="my-1 block w-full px-3 py-2 border focus:ring focus:ring-blue-200"
            />
            <div class="mt-3 flex flex-row justify-end w-full">    
            <button 
                :class="{ 'btn-confirm': threads.filterData.contactInfo.name.length >= 3, 'btn-disabled': threads.filterData.contactInfo.name.length < 3}"
                @click="toggleConfirm()"
            >Confirm & Link
            </button>
            </div>
            </div>
        <div v-else class="flex flex-row justify-around cursor-pointer w-full" @click="toggleConfirm()">
            <div 
                class="flex py-2 px-3 flex-row items-center gap-2 bg-teal-800 text-white w-full mt-1"
            >
                {{ threads.filterData.contactInfo.name }} 
            </div>
       </div>
    </div>

    <!-- Search Contact -->
    <div v-if="enabled && action === 'search'">
        <label class="block border-b">Find Contact:</label>
        <div class="flex flex-row border-b my-2 pb-2 justify-around" >
            <Search @runSearch="setSearchStr" />
        </div>
        <div class="flex flex-col gap-2">
            <div v-for="contact in filteredContacts" :key="contact.id" @click="handleSelectContact(contact)" class="cursor-pointer">
                <div 
                  class="flex p-2 flex-row items-center gap-2"
                  :class="{ 'bg-teal-800 text-white': threads.filterData.contactInfo.id === contact.id }"
                >
                    <div>{{ contact.name }}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
</template>