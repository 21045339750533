import { createStore } from "@/store/items/common/index.js";
import { mergeArrays } from "@/utils/arrayUtils";


const template = {
    id: null,
    title: "",
    text: "",
};

const customActions = {
    async loadItems(task) {
      const data = await this.interact("filter", { task: task });
      if (data.results.length) {
        this.items = mergeArrays(this.items, data.results, "id");
      }
      return data.results;
    }
  };

export const useNotecards = createStore({
  model: "notecard",
  template,
  customActions
})
