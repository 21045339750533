<script setup lang="ts">

import TabbedSections from '@/layouts/TabbedSections.vue'
import ResourcesView from './ResourcesView.vue';
import NotesView from './NotesView.vue';
import phases from '@/components/common/lists/phases/phases.vue';

const props = defineProps<{
    project: number,
    task: number,
}>()

// Define the tabs with their labels and associated components
const tabs = [
    {
        label: 'Resources',
        component: ResourcesView,
        props: { project: props.project },
    },
    {
        label: 'Task',
        component: phases,
        props: { topItemPK: props.task },
    },
    {
        label: 'Notes',
        component: NotesView,
        props: { project: props.project },
    },
];

</script>

<template>
    <TabbedSections :tabs="tabs" />
</template>