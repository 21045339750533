<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'
import { useAppointments } from '@/store/items/appointment';
import { makeReadable } from '@/utils/timeUtils';

import { useRouter } from 'vue-router';

import CloseItem from '@/components/common/buttons/CloseItem.vue';

const router = useRouter()
const store = useAppointments()

const props = defineProps<{
    category: number
}>()

const items = computed(() => store.forProject(props.category))

const selected = ref<number | null>(null)
function closeSelected() {
    selected.value = null
}

onMounted(() => {
    store.loadItems({'category': props.category})
})

</script>

<template>

<div v-if="!selected" class="flex flex-col flex-grow">
    <div 
      v-for="item in items"
      class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
      @click="router.replace('/meeting/' + item.id)"
    >
        <div>{{ item.label }}</div>
        <div>{{  makeReadable(item.begin) }}</div>
    </div>
</div>

<div v-else>
    <CloseItem @click="closeSelected" />
    
</div>


</template>