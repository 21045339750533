<script setup lang="ts">

import SelectOption from './SelectOption.vue'
import SortOptions from './SortOptions.vue'

import { useThreads } from '@/store/items/thread'

import { watch, ref, computed } from 'vue'

import type { FilterPayload } from '@/store/items/interfaces/thread'
import type { ThreadItem } from '@/store/items/interfaces/thread'

const threads = useThreads()

const selection = ref('')
function selectOption(option: string) {
    selection.value = option
}

const filterAll = ref(true)
function setFilterAll(value: boolean) {
    filterAll.value = value
}

const useSubject = ref<boolean>(false)
function setUseSubject(value: boolean) {
    useSubject.value = value
}

const unfilteredThreads = computed(() =>
  threads.items.filter((item) => item.category === null)
);

const groupedBySender = computed(() => {
  const groups = new Map<string, ThreadItem[]>();

  // Group items by sender_address
  unfilteredThreads.value.forEach((item) => {
    const sender = item.sender_address;
    if (!groups.has(sender)) {
      groups.set(sender, []);
    }
    groups.get(sender)!.push(item);
  });
  return Array.from(groups.values()).sort((a, b) => b.length - a.length);
});

const currentGroup = ref<ThreadItem[]>([])
const currentSource = ref<string>('')

function setCurrentGroup() {
  currentGroup.value = groupedBySender.value[0]
  currentSource.value = currentGroup.value[0]?.sender_address
  threads.filterData.source = currentSource.value
  if (!currentSource.value) {
    saveOmitFilter()
  }
  
}

function truncateSubject(str: string) {
    if (str.length > 110) {
    return str.slice(0, 110 - 3) + '...';
  }
  return str;
}

const formattedEmail = computed((): string => {
  const address = currentSource.value
  if (!address) {
    return "<span class='text-red-500'>Warning! Email Address Invalid</span>";
  }

  const parts = address.split('@');
  let output = '';

  if (parts.length > 1) {
    const domainParts = parts[1].split('.');
    if (domainParts.length > 1) {
      const tld = domainParts.pop(); // Extract top-level domain
      const domain = domainParts.pop(); // Extract domain
      const extraDomain = domainParts.join('.'); // Join any remaining domain parts

      output = `${parts[0]}@${extraDomain ? extraDomain + '.' : ''}<span class="text-pink-500">${domain}.${tld}</span>`;
    }
  }

  if (!output) {
    output = "<span class='text-red-500'>Warning! URL Invalid</span>";
  }

  return output;
});

async function submitSort() {
  await threads.sortMessages()
  setCurrentGroup()
}

async function saveOmitFilter() {
    threads.filterData.createFilter = true
    threads.filterData.category = null
    await submitSort()
}

function toggleAllThreadsFromSender(evt: Event) {
  const target = evt.target as HTMLInputElement;

  if (target.checked) {
    currentGroup.value.forEach((obj) => {
      if (!threads.filterData.threadIDs.includes(obj.id)) {
        threads.filterData.threadIDs.push(obj.id);
      }
    });
  } else {
    threads.filterData.threadIDs = [];
  }
}

async function trashSelected() {
    const pks = threads.filterData.threadIDs
    await threads.deleteBulk(pks)   
    currentGroup.value = currentGroup.value.filter((item) => !pks.includes(item.id));
}

watch(
  () => threads.items, 
  (newItems: ThreadItem[]) => {
    if (currentGroup.value.length === 0 && newItems.length > 0) {
      setCurrentGroup();
    }
  }
);

  
setCurrentGroup()


</script>

<template>

<div class="flex flex-col items-center m-4 mt-0 bg-white rounded shadow py-3 overflow-y-scroll" style="width:800px">

  <div class="text-sm uppercase pb-2">Showing messages sent by:</div>
  <SelectOption
    :name="currentGroup[0]?.sender_name"
    :email="formattedEmail"
    :selection="selection"
    @select="selectOption"
    @saveOmitFilter="saveOmitFilter"
  ></SelectOption>

  <SortOptions 
    v-if="selection === 'yes'" 
    :filterAll="filterAll"
    :useSubject="useSubject"
    @setUseSubject="setUseSubject"
    @setFilterAll="setFilterAll"
    @submit="submitSort"
  ></SortOptions>
  <div class="flex flex-col flex-grow gap-1 p-6 select-none h-full" style="width: 800px">
      <div class="flex flex-row justify-between">
          <div class="flex flex-row gap-2">
              <input
                  v-if="!filterAll"
                  @change="toggleAllThreadsFromSender($event)"
                  ref="selectAll"
                  class="form-checkbox ml-2"
                  type="checkbox"
              />
              <div class="text-sm pb-4 ml-2">{{ currentGroup.length }} messages from sender:</div>
          </div>
          <svg v-if="!filterAll" @click="trashSelected()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer hover:text-red-700">
              <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
          </svg>

      </div>         
      <div class="flex flex-col divide-y border-y min-h-0 overflow-y-auto">           
          <div v-for="thread in currentGroup" class="flex flex-row p-2 gap-4 items-center">
              <input
                  v-if="!filterAll"
                  v-model="threads.filterData.threadIDs"
                  :value="thread.id"
                  class="form-checkbox"
                  type="checkbox"
              />
              <div v-html="truncateSubject(thread.messages[0].subject)" class="font-medium text-black whitespace-nowrap">
                  
              </div>
              <div v-html="thread.messages[0].snippet" class="text-gray-600 truncate">
                  
              </div>
          </div>
      </div>
  </div>
</div>
</template>