<script lang="ts" setup>
  import { computed, watch } from 'vue';
  
  const props = defineProps<{
    leadTime: number;
    distance: string;
    interval: number;
  }>();
  
  const emit = defineEmits<{
    (event: 'update:leadTime', value: number): void;
  }>();
  
  const leadTimeMapping = {
    DAILY: 1,
    WEEKLY: 7,
    MONTHLY: 30,
    QUARTERLY: 90,
  } as const;
  
  const maxLeadTime = computed(() => {
    const distanceValue = leadTimeMapping[props.distance as keyof typeof leadTimeMapping] || 0;
    return distanceValue * props.interval - 1;
  });
  
  function handleInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = Number(target.value);
    emit('update:leadTime', Math.min(value, maxLeadTime.value));
  }

  watch(
  () => props.leadTime,
  (newVal) => {
    if (newVal > maxLeadTime.value) {
      emit('update:leadTime', maxLeadTime.value);
    }
  }
);

</script>


  
<template>
    <div class="flex flex-row gap-3 items-center">
        <input
          type="number"
          :min="0"
          :max="maxLeadTime"
          :value="leadTime"
            @input="(event) => handleInput(event)"
          class="text-sm w-16 border-x-0 border-t-0 border-dotted focus:ring-0"
        />
        <div>Days</div>
    </div>
</template>
