const setterActions = {
    async loadDetail(pk) {
        const results = await this.interactCustom("appointment_detail", pk);
        return results.data;
    },
    setDefaultName(label) {
        this.current = null;
        this.trigger.label = label;
    },
};

export { setterActions };