<script setup lang="ts">

import { useDirector } from "@/store/pages/director"
import Resources from '@/components/common/lists/resources.vue';
import { ref, onMounted } from "vue"

const director = useDirector()

const props = defineProps<{
    project: number;
}>()

const loaded = director.loadedForProjects['resource']

onMounted( async () => {
    await director.loadItemsForProject('resource', props.project)
});

</script>

<template>

    <Resources
        :loaded="loaded"
        :project="project"
    />

</template>