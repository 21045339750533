import { useApiCourier } from "@/store/items/common/apiCourier";
import { mergeArrays } from "@/utils/arrayUtils";



export const filterActions = {
    async interactFilter(action, data, page) {
        return await useApiCourier().interaction(action, "filter", data, page);
    },    
    async deleteFilter(pk) {
      await this.interactFilter('delete', pk)
      const targetIndex = this.filters.items.findIndex((item) => item.id === pk)
      this.filters.items.splice(targetIndex, 1);
    },
    async loadFilters(query=null, page=1) {
        let currentPage = page
        let response
        if (!query) {
          response = await this.interactFilter("fetch", {}, currentPage);
          this.filters.items = mergeArrays(this.filters.items, response.results, 'id');
        } else if (!this.filters.loadedProjectPKs.includes(query.id)) {
          response = await this.interactFilter("filter", query, currentPage);
          this.filters.items = mergeArrays(this.filters.items, response.results, 'id');
          this.filters.loadedProjectPKs.push(query.category);
        }
        if (response && response.next && response.next.length) {
          currentPage += 1
          this.loadFilters(query, currentPage)
        }
      },

}