<script setup lang="ts">

import editSections from './editSections.vue'
import Loader from '@/components/common/Loader.vue'

import { ref, computed, watch } from 'vue'

import { useAppointments } from '@/store/items/appointment'

import type { AgendaEntryItem, AgendaSectionItem } from '@/store/items/interfaces/appointment'

const props = defineProps<{
    pk: number,
}>()

const emit = defineEmits(['setDisplay'])

const store = useAppointments()

const loading = ref<boolean>(true)
const editMode = ref<boolean>(false)

const items = computed(() => store.agenda.filter(item => item.appointment === props.pk))

function closeEditMode(): void {
    if (confirm("Close Editor Without Saving Changes?")) {
        editMode.value = false
    }
}

function cleanData(data: AgendaSectionItem[]): AgendaSectionItem[] {
    return data.map(section => {
        // Filter out agenda entries with a blank text
        section.agenda_entries = section.agenda_entries.filter(entry => entry.text.trim() !== "");

        // Handle section label
        if (section.label.trim() === "") {
        if (section.agenda_entries.length > 0) {
            section.label = "Misc. Topics"; // Assign a placeholder label
        } else {
            return null; // Remove section if it has no label and no agenda entries
        }
        }
        return section;
    })
    .filter(section => section !== null); // Remove null sections
};

async function saveItems(data: AgendaSectionItem[]): Promise<void> {
    editMode.value = false
    loading.value = true
    store.agenda = []
    let cleanedData = cleanData(data)
    for (let section of cleanedData) {
        const action = section.id ? "update" : 'create'
        await store.saveAgendaSection(action, section)
    }
    loading.value = false
}

function loadItems(): void {
    store.agenda = []
    store.loadAgendaItems(props.pk)
    loading.value = false
}

function itemSelection(sectionIndex: number, entryIndex: number): void {
    emit('setDisplay', sectionIndex, entryIndex)
}

loadItems()

watch(() => props.pk, () => {
    loadItems()
})

</script>


<template>

<div class="flex flex-col h-full">
    <div v-if="!editMode" class="grid grid-cols-3 items-center justify-around py-4 border-b">
        <div class="flex flex-row px-3 gap-2">
            <div class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" /></svg>
            </div>
        </div>
        <div class="flex flex-row justify-around text-2xl font-thin uppercase">Agenda</div>
        <div class="flex flex-row justify-end px-3 gap-2">
            <div v-if="store.clipboard" @click="store.pasteAgenda(pk)" class="border p-1 cursor-pointer bg-orange-600 text-orange-200 border-orange-500 hover:bg-orange-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" /></svg>
            </div>
            <div @click="store.clipboard=pk" class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" /></svg>
            </div>
            <div @click="editMode = true" class="border p-1 cursor-pointer hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" /></svg>
            </div>
        </div>
    </div>

    <div v-else class="grid grid-cols-3 items-center justify-around py-4 border-b">
        <div></div>
        <div class="flex flex-row justify-around text-lg uppercase">Agenda Builder</div>
        <div class="flex flex-row justify-end mr-4 text-gray-500 cursor-pointer">
            <div @click="closeEditMode()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 qw-5"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>
            </div>
        </div>
    </div>

    <div v-if="loading"><Loader></Loader></div>

    <div v-else-if="!editMode" class="flex flex-col m-6 mb-1">
        <div v-for="(item, sectionIndex) in items" class="flex flex-col">
            <div @click="itemSelection(sectionIndex, 0 )" class=" text-gray-800 border-b cursor-pointer hover:text-teal-600">
                {{ item.label }}
            </div>
            <div class="flex flex-col gap-1 m-1 ">
                <div v-for="(entry, entryIndex) in item.agenda_entries" @click="itemSelection(sectionIndex, entryIndex)" class="text-sm text-gray-600 cursor-pointer hover:text-teal-600">&bull; {{ entry.text }}</div>
            </div>
        </div>
    </div>

    <editSections 
        v-else 
        :appointment="pk"
        @close="closeEditMode"
        @save="saveItems"  
    />

</div>


</template>