<script setup lang="ts">
import Search from '@/components/common/search.vue';
import ResourceForm from '../forms/ResourceForm.vue';

import { useResources } from '@/store/items/resource';
import { useContacts } from '@/store/items/contact';
import { ref, computed } from 'vue';

import type { ContactItem } from "@/store/items/interfaces/contact";
import type { ResourceItem } from "@/store/items/interfaces/resource";

const props = defineProps<{
  project: number,
  participants?: ContactItem[]
}>();

const resources = useResources();
const contacts = useContacts();

const availableContacts = computed<ContactItem[]>(() => 
  props.participants?.length
    ? contacts.forProject(props.project).filter(item => props.participants?.includes(item.id))
    : [] // No available contacts if participants are not provided
);

const searchStr = ref<string>('');
function setSearchStr(text: string) {
  searchStr.value = text;
}

const items = computed<ResourceItem[]>(() =>
  resources.forProject(props.project).filter(item =>
    item.title.toLowerCase().includes(searchStr.value.toLowerCase())
  )
);

const selectedItems = ref<ResourceItem[]>([]);
const selectedContacts = ref<ContactItem[]>([]);
const showContacts = ref<boolean>(false);
const showForm = ref<boolean>(false);

// Functions
function toggleForm(pk?: number){
  if (pk) {
    resources.setSelected(pk)
  } else {
    resources.clearProposed()
  }
  showForm.value = !showForm.value
}

function toggleItemSelection(item: ResourceItem): void {
  if (selectedItems.value.includes(item)) {
    selectedItems.value = selectedItems.value.filter(i => i !== item);
  } else {
    selectedItems.value.push(item);
  }
}

function toggleContactSelection(contact: ContactItem): void {
  if (selectedContacts.value.includes(contact)) {
    selectedContacts.value = selectedContacts.value.filter(c => c !== contact);
  } else {
    selectedContacts.value.push(contact);
  }
}

function toggleAllContacts(): void {
  if (availableContacts.value.length === selectedContacts.value.length) {
    selectedContacts.value = [];
  } else {
    selectedContacts.value = [...availableContacts.value];
  }
}

function quickSend(): void {
  if (selectedItems.value.length && selectedContacts.value.length) {
    console.log('Sending items', selectedItems.value, 'to contacts', selectedContacts.value);
  } else {
    alert('Please select both items and contacts');
  }
}

function toggleQuickSend(): void {
  if (selectedItems.value.length && availableContacts.value.length) {
    showContacts.value = !showContacts.value;
  } else {
    showContacts.value = false;
    selectedContacts.value = [];
    selectedItems.value = [];
  }
}
</script>

<template>
  <div class="flex flex-col min-h-0 border m-4 w-full">
    <div class="flex flex-row justify-between items-center p-3 border-b">
      <Search @runSearch="setSearchStr" />
      <button
        @click="toggleQuickSend()"
        :disabled="!availableContacts.length" 
        :class="selectedItems.length && availableContacts.length ? 'btn-bold' : 'hidden'"
        class="focus:ring-0 focus:outline-none"
      >
        Quick Share
      </button>
      <button @click="toggleForm()" class="text-xl text-gray-600">+</button>
    </div>
    <!-- Contacts Section -->
    <div v-if="showContacts" id="contacts" class="p-4">
      <div class="flex flex-row justify-between items-center">
        <h3 class="text-sm mb-2">Send To Participants:</h3>
        <div v-if="availableContacts.length" @click="toggleAllContacts" class="btn btn-confirm text-xs mr-4 mb-4">Select All</div>
      </div>
      <ul v-if="availableContacts.length" class="list-disc px-4">
        <li 
          v-for="contact in availableContacts" 
          @click="toggleContactSelection(contact)"
          :key="contact.id" 
          :class="{'bg-teal-600 text-white': selectedContacts.includes(contact)}"
          class="mb-1 flex flex-row items-center border p-2 rounded cursor-pointer"
        >
          {{ contact.name }}
        </li>
      </ul>
      <div v-else class="text-gray-600 italic">No participants available for quick sharing.</div>
        <div class="flex flex-row gap-2 justify-end m-4">
          <button 
            @click="showContacts = false"
            class="btn-dark">
            Cancel
          </button>
          <button 
            @click="quickSend" 
            class="btn-special"
            :disabled="!selectedItems.length || !selectedContacts.length"
          >
            Send
          </button>
        </div>
    </div>
    <div v-else-if="showForm">
      <ResourceForm 
        :project="project"
        @closeForm="toggleForm"
      />
    </div>
    <!-- Resources Section -->
    <div v-else id="resources" class="select-none overflow-auto">
      <ul v-if="items.length" class="list-disc px-5 my-4">
        <li v-for="item in items" :key="item.id" class="mb-1 flex flex-row items-center border rounded">
          <div 
          v-if="participants"
            @click="toggleItemSelection(item)" 
            class="flex flex-row p-2 border-r h-10 w-10 items-center justify-around rounded-l cursor-pointer"
            :class="{'bg-teal-600 text-white': selectedItems.includes(item)}"
          >
            <svg v-if="selectedItems.includes(item)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" /></svg>
          </div>
          <a 
            :href="item.url" 
            target="_blank" class="flex flex-row p-2 hover:bg-gray-100 cursor-pointer w-full items-center">
            <span class="text-blue-500 hover:underline">
              {{ item.title }}
            </span>
          </a>
        </li>
      </ul>
      <div v-else class="flex flex-row justify-around pt-9 text-lg text-gray-600 italic">
        No Resources Available
      </div>
    </div>
  </div>
</template>