<script setup lang="ts">

import SearchBar from '@/components/common/search.vue';

import { useDirector } from '@/store/pages/director';
import { useProjects } from '@/store/items/project';
import { useContacts } from '@/store/items/contact';

import { computed, ref } from 'vue'
import { getSearchResults } from '@/utils/searchUtils';

const director = useDirector()
const projects = useProjects()
const contacts = useContacts()

const project = ref(projects.selected)

const searchStr = ref<string>('')
const emit = defineEmits(['setView'])

const setSearchStr = (val: string) => {
    searchStr.value = val
}

const items = computed(() => {
    let results = project.value ? contacts.forProject(project.value) : []
    return getSearchResults(results, searchStr.value, 'name')
})

function selectContact(pk: number) {
    contacts.selected = pk
    emit('setView', 'manage')
}

</script>

<template>

<div class="flex flex-col min-h-0 bg-white rounded shadow">
    <div class="flex flex-row justify-between items-center border-b p-3">
        <SearchBar
            @runSearch="setSearchStr"
        ></SearchBar>
        <div class="">
            <div @click="emit('setView', 'new')" class="btn-bold">Add Contact</div>
        </div>
    </div>
    <div v-if="!project || !director.loadedForProjects['contact'].includes(project)">
        <Loader></Loader>
    </div>
    <div v-else-if="!items.length" class="flex flex-col gap-3 p-6 items-center">
        <div class="text-gray-500 select-none">No Contacts for Project</div>
    </div>
    <div v-else class="flex flex-col divide-y border-b overflow-y-auto">
        <div v-for="contact in items" class="flex flex-row justify-between divide-x"> 
            <div @click="selectContact(contact.id)" class="flex flex-col p-4 cursor-pointer w-full hover:bg-gray-100">
                {{ contact.name }}
            </div>
            <div class='flex flex-row divide-x'>
            <div class="p-4 cursor-pointer hover:bg-gray-100 text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
            </div>
            <div class="p-4 cursor-pointer hover:bg-gray-100 text-gray-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                </svg>
            </div>
            </div>
        </div>
    </div>
</div>
</template>