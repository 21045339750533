import { useApiCourier } from "@/store/items/common/apiCourier";
import { mergeArrays } from "@/utils/arrayUtils";

const crudActions = {
    async interact(action, data = {}, page = null) {
        try {
            return await useApiCourier().interaction(action, this.model, data, page);
        } catch (error) {
            console.error(`Error during ${action} action:`, error);
            throw error;
        }
    },
    async customInteract(action, query, data, page = null) {
        try {
            return await useApiCourier().interaction(action, query, data, page);
        } catch (error) {
            console.error(`Error during ${action} action:`, error);
            throw error;
        }
    },
    async bulkInteract(action, idSet, value = null) {
        const payload = {
          action,
          id_set: Array.isArray(idSet) ? idSet : [idSet],
          value,
        };
        return this.interact("batch", payload);
    },
    async loadItems(query = {}, page = 0) {
        try {
            const data = await this.interact("filter", query, page);
            const { results, next } = data;

            if (results?.length) {
                this.items = mergeArrays(this.items, results, "id");
            }

            if (next) {
                await this.loadItems(query, page + 1); // Recursive load
            }
            
            return data.results;
        } catch (error) {
            console.error("Error loading items:", error);
            throw error;
        }
    },
    async fetchItemByID(pk) {
        try {
          const data = await this.interact("get", { id: pk });
          const item = data;
    
          const existingIndex = this.items.findIndex((i) => i.id === pk);
          if (existingIndex >= 0) {
            this.items[existingIndex] = item;
          } else {
            this.items.push(item);
          }
    
          return item;
        } catch (error) {
            console.error(`Error fetching item with ID ${pk}:`, error);
            throw error;
        }
      },
    async create() {
        try {
            const data = await this.interact("create", this.proposed);
            this.items.push(data);
            this.clearProposed();
            return data;
        } catch (error) {
            console.error("Error creating item:", error);
            throw error;
        }
    },
    async overwrite() {
        try {
            const data = await this.interact("overwrite", this.proposed);
            this.setItem(data.id, data);
            return data;
        } catch (error) {
            console.error("Error overwriting item:", error);
            throw error;
        }
    },
    async update(field) {
        try {
            const payload = { id: this.proposed.id, [field]: this.proposed[field] };
            const data = await this.interact("update", payload);
            this.setItem(data.id, data);
            return data;
        } catch (error) {
            console.error(`Error updating ${field} field:`, error);
            throw error;
        }
    },
    async save() {
        return this.proposed.id ? await this.overwrite() : await this.create();
    },
    async del(pk, project = null) {
        try {
          let url = pk;
          if (project) url += `?project=${project}`;
    
          if (window.confirm(`Permanently delete this ${this.model}?`)) {
            await this.interact("delete", url);
            this.items = this.items.filter((item) => item.id !== pk);
          }
        } catch (error) {
          console.error(`Error deleting item with ID ${pk}:`, error);
          throw error;
        }
    },
    async updateCategory(categoryID) {
        const data = {'id': this.selected, 'category': categoryID}
        this.interact('update', data)
        this.getItem(this.selected).category = categoryID
    },          
};

export { crudActions };