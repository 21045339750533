<script setup lang="ts">

import PhaseTasks from './PhaseTasks.vue'
import { useTasks } from '@/store/items/task'
import PhaseForm from './PhaseForm/PhaseForm.vue';
import { ref, computed, watch } from 'vue'

const store = useTasks()

const props = defineProps<{
    pk: number;
    goalID: string;
    taskStatus: number;
}>();

const emit = defineEmits<{
    (e: 'openForm', pk: 'new' | number, position: number): void;
}>();

const task = computed(() => store.getItem(props.pk))
const phases = computed(() => {
    return task.value.phases.slice().sort((a: { order: number }, b: { order: number }) => {
        return a.order - b.order;
    });
});

const newPhase = ref(false)
const deadlineClass = ref("")

const newPhaseValue = computed(() => phases.value.length + 1)
function openTaskForm(pk: 'new' | number, position:number){
    emit('openForm', pk, position)
}

async function createPhase(payload: {label: string, days_to_deadline: number | null; due_date: string | null }): Promise<void> {
    if (!payload.days_to_deadline) {
        deadlineClass.value = "border-red-500"
    } else {
        deadlineClass.value = ""
        const data = {
            parent: props.pk,
            label: payload.label,
            days_to_deadline: payload.days_to_deadline,
            order: newPhaseValue.value,
        }
        store.createPhase(data)
        newPhase.value = false
    }
}

async function onDrop(evt: DragEvent, phase: number) {
    const pk = parseInt(evt.dataTransfer?.getData('pk') || '0')
    store.proposed.id = pk
    store.proposed.phase = phase
    await store.update('phase')
    store.proposed = {...store.blank}
}

function closePhaseForm() {
    newPhase.value = false

}

watch(task, () =>  {
    newPhase.value = false
})

</script>

<template>
<div class="flex flex-col gap-4">
    <div v-for="(phase, index) in phases" :key="phase.id" class="flex flex-col flex-grow gap-2 " @drop.prevent="onDrop($event, phase.id)" @dragenter.prevent @dragover.prevent>
        <PhaseTasks 
            :index="index"
            :phase="phase"
            :taskStatus="taskStatus"
            :goalID="goalID"
            @openForm="openTaskForm"
        />
    </div> 
    <div v-if="!newPhase" @click="newPhase=true" class="text-sm bg-gray-100 p-1 text-gray-500 hover:text-gray-600 cursor-pointer hover:bg-gray-100 pl-2">
        + Add Phase
    </div> 
    <div class="flex flex-col gap-3" v-else>
        <div 
            class="text-sm bg-gray-100 p-1 text-gray-500 hover:text-gray-600 cursor-pointer hover:bg-gray-100 pl-2"
            @click="closePhaseForm"
        >
            Phase {{ newPhaseValue }}
        </div>
        <PhaseForm 
            :styleClass="deadlineClass"
            @submit="createPhase"
            @close="closePhaseForm"
        />
    </div>
</div>
</template>