<script setup lang="ts">

import { computed, reactive } from 'vue';
import { useTasks } from '@/store/items/task'
import { useProjects } from '@/store/items/project';
import CloseItem from '@/components/common/buttons/CloseItem.vue';


const tasks = useTasks()
const projects = useProjects().items

const form = reactive(tasks.proposed)
const emit = defineEmits(['close'])

function saveItem(): void {
    tasks.saveAsGoal()
    emit('close')
}

const title = computed(() => form.id ? 'Edit Goal' : 'Establish New Goal')

</script>

<template>

<div class="flex flex-col justify-between">
    <div class="flex flex-row gap-6 w-full text-sm items-center justify-between">
        <div class="text-xl text-teal-700 ">{{ title }}</div>
        <CloseItem
            @click="emit('close')"
        />
    </div>
</div>

<div class="w-full flex flex-col gap-5 bg-white p-5 ">
    <div class="border-b">Set an effective goal by ensuring that it is:</div>
    <div class="flex flex-col gap-2">
        <div><span class="font-black text-amber-700">Specific</span> - Make it clear exactly what should be accomplished.</div> 
        <div><span class="font-black text-cyan-700">Measureable</span> - How would you definitively demonstrate your success?</div> 
        <div><span class="font-black text-pink-700">Actionable</span> - Focus on outcomes that you are able to control or influence.</div> 
        <div><span class="font-black text-emerald-700">Relevant</span> - Be sure your efforts are in alignment with the mission of the larger project.</div> 
        <div><span class="font-black text-violet-700">Time-Bound</span> - Establish an end point to keep things moving forward.</div> 
    </div>
    
    <div class="flex flex-col gap-1">         
        <div class="form-label ml-0">
            My goal is to:
        </div>
        <textarea autofocus v-model="form.label" type="text" class="form-field"> </textarea>           
    </div>
    
    <div v-if="form.id" class="flex flex-col gap-1">  
        <div class="form-label ml-0">
            Project:
        </div>
        <select v-model="form.category">
            <option v-for="project in projects" :value="project.id">{{ project.name }}</option>
        </select>           
    </div>
    
    <div class="flex flex-row justify-end gap-2">
        <div v-if="form.id" @click="tasks.del(form.id)" class="btn-caution">Delete</div>
        <div @click="saveItem()" class="btn-confirm">Save</div>
    </div>
</div>

</template>