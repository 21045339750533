<script setup lang="ts">

import MessageInline from './MessageInline.vue'

import { useThreads } from '@/store/items/thread'
import { useDirector } from '@/store/pages/director'

import type { ThreadItem } from '@/store/items/interfaces/thread'

import { ref, onMounted, onBeforeUnmount } from 'vue'

const threads = useThreads()
const director = useDirector()

const props = defineProps<{
  items: ThreadItem[],
  searchTerm: String,
  status: number
  project: number,
  loadingPrevious: boolean
}>()

const emit = defineEmits(['openThread', 'setLoadingPrevious'])

const sentinel = ref<HTMLElement | null>(null);
const messageListRef = ref<HTMLElement | null>(null);
const hasMoreToLoad = ref(true);

const observer = new IntersectionObserver(async (entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting && !props.loadingPrevious && hasMoreToLoad.value) {
        emit('setLoadingPrevious', true);
        const oldCount = props.items.length;
        await director.loadItemsForProject('thread', props.project);
        emit('setLoadingPrevious', false);
        const newCount = props.items.length;
        if (newCount === oldCount) {
            hasMoreToLoad.value = false;
        }
        emit('setLoadingPrevious', false);
    }
  }
}, { root: messageListRef.value, rootMargin: '0px', threshold: 0.1 });

function openThread(id: number) {
  emit('openThread', id);
};

onMounted(() => {
  if (sentinel.value) {
  observer.observe(sentinel.value);
  }
});

onBeforeUnmount(() => {
  observer.disconnect();
});


</script>

<template>
  <div v-if="!threads.selected" ref="messageListRef" class="border-b border-gray-200 items-center flex flex-col justify-between group message divide-y overflow-auto">
      <MessageInline  
        v-for="item in items" 
        :item="item"
        @openThread="openThread"
      ></MessageInline>
      <div ref="sentinel" class="sentinel"></div>
  </div>
</template>