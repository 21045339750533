<script setup lang="ts">
import { getHrsMinutes } from '@/utils/timeUtils';
import { useRoutines } from '@/store/items/routine';
import { ref, computed } from 'vue';

// Using the store
const store = useRoutines();

// Define props with types
const props = defineProps<{
  pk: number;
  selected: number[];
}>();

// Define emits with types
const emit = defineEmits<{
  (e: 'select', pk: number): void;
}>();

// Computed property with proper typing
const item = computed(() => store.getItem(props.pk));
</script>

<template>
    <div
      @click="emit('select', item?.id)" 
      :class="{
        'bg-blue-100 border-blue-400': selected.includes(item?.id),
        'bg-gray-300': item?.status === 2
      }"
      class="flex flex-col p-4 gap-2 border hover:bg-blue-100 cursor-pointer select-none"
    >
      <div class="flex flex-row text-sm justify-between">
        <div class="uppercase text-gray-500">
          {{ item?.schedule }}:
        </div>
        <div>
          <div 
            v-if="item?.status !== 1"
            class="text-gray-600 uppercase"
          >
            Suspended
          </div>
        </div>
      </div>
  
      <div class="flex flex-col gap-1">
        <div class="flex flex-row">
          {{ item?.label }}
        </div>
        <div class="flex flex-row justify-between text-sm">
          <div class="flex flex-row gap-1">
            <div class="text-gray-500">Work Time:</div>
            <div class="text-black">{{ getHrsMinutes(item?.minutes_allotted) }}</div>
          </div>
          <div class="flex flex-row gap-1">
            <div class="text-gray-500">Next Active:</div>
            <div class="text-black">{{ item?.when_active }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  