import { createStore } from "@/store/items/common/index.js";
import { crudActions } from "./crudActions";


const template =  {
    id: null, // Unique identifier for the workday
    date: "", // ISO 8601 formatted date (e.g., "2024-01-01")
    duration: 0, // Duration of the workday in minutes
    is_holiday: false, // Whether the workday is a holiday
  }

export const useWorkdays = createStore({
    model: 'workday',
    template,
    customActions: {
        ...crudActions
    }
})