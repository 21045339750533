<script>
import { ref, onMounted, nextTick } from "vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "Notes",
  props: {
    classes: Object,
    data: String,
  },
  components: { Editor },
  emits: ["updateField"],
  setup(props, context) {
    function update(data) {
      context.emit("updateField", { field: data.field, value: data.value });
    }

    return { update };
  },
};
</script>
<template>

  <div class="flex flex-col" :class="classes.container">
    <label :class="classes.label">Notes:</label>
    <textarea
      @keyup="update({ field: 'details', value: $event.target.value })"
    ></textarea>
  </div>
</template>
