import { javascriptNonsenseForUserTime } from "@/utils/timeUtils";
import { mergeArrays } from "@/utils/arrayUtils";

import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useApiCourier } from "@/store/items/common/apiCourier";
import { useProjects } from "@/store/items/project";
import { useTasks } from "@/store/items/task";
import { useAppointments } from "@/store/items/appointment";
import { useRecords } from "@/store/items/record";
import { useWorkdays } from "@/store/items/workday";

export const useAgenda = defineStore("agenda", () => {
  const loadedWeeks = ref([]);
  const filterOptions = {
    task: [
      [1, "Actionable"],
      [2, "Awaiting Response"],
      [3, "On Hold"],
      [5, "Done"],
    ],
  };

  const interact = (action, model, data) => {
    return useApiCourier().interaction(action, model, data);
  };

  const projects = useProjects();
  const tasks = useTasks();
  const appointments = useAppointments();
  const records = useRecords();
  const workdays = useWorkdays();

  const weekStr = ref("");
  const dataLoaded = ref(false)

  const tasksLoaded = ref(false)

  const loadTasks = async () => {
    if (!tasksLoaded.value) {
      const data = {date: javascriptNonsenseForUserTime()}
      let response = await tasks.loadItems(data);
      tasksLoaded.value = true
    }
  };

  const loadData = async (slug) => {
    const payload = { slug: slug };
    const result = await interact("filter", "agenda", payload);
    appointments.items = mergeArrays(
      appointments.items,
      result.appointments,
      "id"
    );
    records.items = mergeArrays(records.items, result.records, "id");
    workdays.items = result.workdays;
    tasks.items = mergeArrays(tasks.items, result.tasks, "id")
    loadedWeeks.value.push(new Date(result.index));
    weekStr.value = result.index;
  };

  const autoSchedule = async (data) => {
    const result = await interact("create", "agenda", data);
    records.items = []
    records.items = mergeArrays(records.items, result.records);
    workdays.items = mergeArrays(workdays.items, result.workdays)
  };

  const updateDuration = async (pk, value) => {
    const workday = workdays.items.find((item) => item.id === pk)
    const payload = {'id': pk, duration: value, date: workday.date, 'partial': true }
    const result = await useApiCourier().interaction('update', 'workday', payload);
    const targetIndex = workdays.items.findIndex((item) => item.id === pk);
    workdays.items[targetIndex] = result;
  }

  const moveRecord = async (data) => {
    return await records.updateItem(data);
  };

  const getWorkdayStats = (pk) => {
    const workday = workdays.items.find((item) => item.id = pk)
    return workday
  }

  const selectedTask = computed(() => tasks.getSelected())

  return {
    filterOptions, tasks, appointments, weekStr, dataLoaded, selectedTask,
    loadData, autoSchedule, moveRecord, interact, loadTasks, getWorkdayStats, updateDuration,
  };
});
