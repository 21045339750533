<script setup lang="ts">

import { ref, computed, onMounted } from 'vue'
import { useGroups } from '@/store/items/group'
import { useContacts } from '@/store/items/contact'

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import ManageContactView from '../../ContactsView/ContactsPanel/ManageContacts/ManageContactView.vue';
import ManageGroupView from '../../ContactsView/GroupsPanel/ManageGroups/ManageGroupView.vue';

const groups = useGroups()
const contacts = useContacts()

const props = defineProps<{
    category: number
}>()

const groupItems = computed(() => groups.forProject(props.category))
const contactItems = computed(() => contacts.forProject(props.category))

const selected = ref<number | null>(null)
function closeSelected() {
    contacts.selected = null
    groups.selected = null
}

onMounted(() => {
    contacts.loadItems({'category': props.category})
    groups.loadItems({'category': props.category})
})

</script>

<template>

<div v-if="contacts.selected">
    <ManageContactView @setView="closeSelected" />
</div>

<div v-else-if="groups.selected">
    <ManageGroupView @setView="closeSelected" />
</div>

<div v-else class="flex flex-col flex-grow gap-4">
    <div v-if="groupItems.length" class="flex flex-col">
        <div class="text-sm border-b text-gray-600">Groups</div>
        <div 
        v-for="item in groupItems"
        class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
        @click="groups.selected = item.id"
        >
            {{ item.name }}
        </div>
    </div>
    <div v-if="contactItems.length" class="flex flex-col">
        <div class="text-sm border-b text-gray-600">Contacts</div>
        <div 
        v-for="item in contactItems"
        class="flex flex-row justify-between p-2 my-1 border text-sm cursor-pointer hover:bg-gray-100"
        @click="contacts.selected = item.id"
        >
            {{ item.name }}
        </div>
    </div>
</div>




</template>