<script setup lang="ts">
import type { AppointmentItem } from '@/store/items/interfaces/appointment';

import { formatInTimeZone } from "date-fns-tz";
import { parseToLocal } from "@/utils/dateUtils";
import { convertToLocalTime } from "@/utils/timeUtils";
import { computed } from 'vue';

const props = defineProps<{
    item: AppointmentItem
}>();

const userTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

function formatDate(
    dateValue: string | number | Date | undefined,
    formatString: string,
    timeZone: string,
    fallback: string = "Unknown"
): string {
    if (!dateValue) return fallback;

    try {
        const date = new Date(dateValue);
        const zonedDate = convertToLocalTime(date); // Convert to time zone
        return formatInTimeZone(zonedDate, timeZone, formatString); // Format with the provided string
    } catch (error) {
        console.error("Error formatting date:", error);
        return fallback;
    }
}

// Reactive computed properties
const startDay = computed<string>(() =>
  formatDate(parseToLocal(props.item.begin as string), "eee", userTimeZone) // Now treated as local
);

const startDate = computed<string>(() =>
  formatDate(parseToLocal(props.item.begin as string), "MMMM d", userTimeZone) // Now treated as local
);

const startTime = computed<string>(() =>
  formatDate(parseToLocal(props.item.begin as string), "'from' h:mmaaa", userTimeZone) // Now treated as local
);

const endTime = computed<string>(() =>
  formatDate(parseToLocal(props.item.end as string), "h:mmaaa", userTimeZone) // Now treated as local
);

</script>

<template>

    <div class="text-lg text-gray-800">{{ item.label }}</div>
    <div v-if="item.participant_names" class="text-gray-400 text-sm mt-0.5">with {{ item.participant_names }}</div>
    <div
        class="text-sm text-gray-600 mt-0.5"
    >
        {{ startDay }}, <span class="text-pink-600">{{ startDate }}</span> {{ startTime }} - {{ endTime }}
    </div>

</template>