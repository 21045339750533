<script setup lang="ts">  

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';

import { useGroups } from '@/store/items/group';
import { useContacts } from '@/store/items/contact';
import { computed } from 'vue';


const groups = useGroups()
const contacts = useContacts()

const selected = computed(() => groups.getSelected());
const relatedContacts = computed(() => contacts.forGroup(selected.value!.members));

const emit = defineEmits(['setView'])

async function deleteGroup() {
    await groups.del(selected.value!.id, selected.value!.category);
}

</script>

<template>

<div class="bg-white rounded shadow p-5 flex flex-col min-h-0">
    <div class="flex flex-row justify-between border-b pb-3 mb-3">
        <div class="text-xl font-light">{{ selected!.name }}</div>
        <CloseItem @click="emit('setView', 'default')"></CloseItem>
    </div>

    <div class="flex flex-col min-h-0 overflow-auto">
        <div class="uppercase text-sm text-gray-500 border-b py-2">Members</div>     
        <div v-if="!relatedContacts.length" class="py-4 italic text-gray-500"> No Members Added </div>
        <div v-else class="flex flex-col divide-y overflow-y-auto">
            <div v-for="contact in relatedContacts">
                <div class="flex flex-row items-center justify-between py-2">
                    <div class="text-gray-600">{{ contact.name }}</div>
                    <div class="text-red-600 hover:text-red-700 cursor-pointer" @click="groups.toggleContactForGroup(contact.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-row justify-between border-t pt-4 items-center">
      <ArchiveItem @click="emit('setView', 'archive')" />
      <div class="flex flex-row gap-2">
        <button @click="deleteGroup()" class="btn-caution">Delete</button>
        <div @click="$emit('setView', 'default')" class="btn-confirm">Finish</div>
      </div>
    </div>

</div>                

</template>