import type { DirectorExtensions } from '@/store/pages/interfaces/director';

interface PanelConfig {
  key: keyof DirectorExtensions;
  label: string;
}

export const panelConfig: { panels: PanelConfig[] } = {
  panels: [
    { key: 'thread', label: 'Messages' },
    { key: 'task', label: 'Tasks' },
    { key: 'contact', label: 'Contacts' },
    { key: 'appointment', label: 'Meetings' },
    { key: 'resource', label: 'Resources' },
    { key: 'note', label: 'Notes' },
    { key: 'binder', label: 'Binders' },
  ],
};