// Added: 3/15/2025

<script setup lang="ts">

import type { WorkdayItem } from "@/store/items/interfaces/workday";

import { getDate } from "@/utils/dateUtils";

import { useAgenda } from "@/store/pages/agenda";
import { useRecords } from "@/store/items/record";
import ApptSelectRecurring from "../common/formFields/ApptSelectRecurring.vue";

const store = useAgenda()
const records = useRecords()

const props = defineProps<{
  workday: WorkdayItem;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();

const clearSchedule = () => {
  records.deleteForDate(props.workday.id)
  emit("close")
}

</script>

<template>
<div class="flex flex-col items-center mt-36 min-h-0 flex-grow">
    <div class="flex flex-col w-68 gap-10">

        <div class="flex flex-col gap-4">
            <div class="text-gray-800 text-center">
                Are you sure you want to delete all scheduled items for this day?
            </div>
            <div class="text-center text-2xl text-teal-700">{{ getDate(workday.date) }}</div>
            <div class="text-sm text-gray-700 text-center">
                Note: Items marked as completed or with recorded work will not be deleted.
            </div>
        </div>
        <div class="flex items-center justify-center select-none">
            <button
              @click="clearSchedule()"
              class="btn-caution"
            >
              Clear Schedule
            </button>
        </div>
    </div>
</div>
</template>