<script setup lang="ts">

import CloseItem from '@/components/common/buttons/CloseItem.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';
import { useContacts } from '@/store/items/contact';
import { computed } from 'vue';
import FieldSetDisplay from './FieldSetDisplay.vue';

import { fieldSets, type FieldSet } from '@/components/common/formFields/forContact/FieldGroups';

import { ref, reactive } from 'vue';
import ContactDetailForm from './ContactDetailForm.vue';

const contacts = useContacts()

const emit = defineEmits(['setView'])

const obj = computed(() => {
  if (!contacts.selected) throw new Error("This component should not be loaded if selected is null");
  return contacts.getItem(contacts.selected);
});

const opened = ref<number | null>(null);

function deleteContact() {
  contacts.del(obj.value.id)
  emit('setView', 'default')
}

const active = reactive<{
  input: FieldSet['type'] | null;
  objID: number | null;
}>({
  input: null,
  objID: null
});

function setOpenedField(objID: number, input: FieldSet['type']) {
  if (objID) {
    const fieldSet = fieldSets.find(f => f.type === input);
    if (fieldSet) {
      fieldSet.proposed = contacts.getDetailItem(obj.value.id, input, objID);
    }
  }
  active.input = input;
  active.objID = objID;
}

function clearOpenedField() {
  fieldSets.forEach(fieldSet => {
    fieldSet.proposed = { ...fieldSet.defaultProposed };
  });
  active.input = null;
  active.objID = null;
}

</script>

<template>

<div class="bg-white rounded shadow p-5 flex flex-col min-h-0">
    <div class="flex flex-row justify-between border-b pb-3 mb-3">
        <div class="text-xl font-light">{{ obj.name }}</div>
        <CloseItem @click="emit('setView', 'default')"></CloseItem>
    </div>
    <div class="flex flex-col gap-4 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-white">
        <div 
          v-for="fieldSet in fieldSets" 
          :key="fieldSet.type" 
          class="flex flex-col"
        >
            <FieldSetDisplay 
                v-if="active.input !== fieldSet.type"
                :fieldSet="fieldSet"
                :obj="obj"
                :opened="opened"
                @open="setOpenedField"
            ></FieldSetDisplay>
            <ContactDetailForm
                :fieldSet="fieldSet"
                :activeInput="active.input"
                :objID="active.objID"
                :contact="obj.id"
                @setOpenedField="setOpenedField"
                @clearOpenedField="clearOpenedField"
            ></ContactDetailForm>
        </div>
    </div>
    <div class="flex flex-row justify-between border-t pt-4 items-center">
      <ArchiveItem @click="emit('setView', 'archive')" />
      <div class="flex flex-row gap-2">
        <button @click="deleteContact()" class="btn-caution">Delete</button>
        <div @click="emit('setView', 'default')" class="btn-confirm">Finish</div>
      </div>
    </div>
</div>
</template>