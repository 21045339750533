<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

import Loader from "@/components/common/Loader.vue";
import ThreeTrayLayout from '@/layouts/ThreeTrayLayout.vue';
import Header from '@/components/focus/Header/Header.vue';
import ActionsPanel from '@/components/focus/ActionsPanel/ActionsPanel.vue';
import JournalPanel from '@/components/focus/JournalPanel/JournalPanel.vue';
import ReferencesPanel from '@/components/focus/References/ReferencesPanel.vue';

import { useTasks } from '@/store/items/task';

const route = useRoute();
const tasks = useTasks();

const taskPK = ref<number | null>(null);
const projectPK = ref<number | null>(null);

// Async function to fetch task and set relevant data
async function setTask(id: number) {
    await tasks.fetchItemByID(id); // Fetch task by ID
    await tasks.getChildren(id); // Fetch children of task
    taskPK.value = id;
    const taskItem = tasks.getItem(taskPK.value);
    projectPK.value = taskItem ? taskItem.category : null; // Assign category (or null if not found)
}

// Run once when component mounts
onMounted(async () => {
    await setTask(Number(route.params.task));
});

// Watch for changes in route params and fetch new task
watch(() => route.params.task, async (id) => {
    await setTask(Number(id));
});
</script>


<template>

<ThreeTrayLayout>

    <template #header>
        <Header
            v-if="taskPK"
            :taskObj=tasks.getItem(taskPK)
        ></Header>
    </template>

    <template #left>
        <JournalPanel></JournalPanel>
    </template>

    <template #center>
        <ActionsPanel
          v-if="taskPK && projectPK"
          :project=projectPK
          :task="taskPK"
        ></ActionsPanel>
        <Loader v-else />
    </template>
    
    <template #right>
        <ReferencesPanel
          v-if="taskPK && projectPK"
          :project=projectPK
          :task="taskPK"
        ></ReferencesPanel>
        <Loader v-else />
    </template>

</ThreeTrayLayout>

</template>