<script setup lang="ts">
import { getHrsMinutes } from "@/utils/timeUtils";
import { getUrgencyRating } from "@/utils/taskUtils";

import { useTasks } from '@/store/items/task';
import { ref, computed, watch } from 'vue';

// Store instance
const taskStore = useTasks();

// Props definition
const props = defineProps<{
  pk: number;
  location?: string;
  dataLoaded?: boolean;
  active?: boolean;
}>();

// Emit events
const emit = defineEmits<{
  (event: 'actionA', pk: number): void;
  (event: 'actionB', pk: number): void;
}>();

// Computed property for the task object
const obj = computed(() => taskStore.getItem(props.pk));

// Urgency class mapping
const urgencyClassesForCircle: Record<number, string> = {
  1: 'border-red-700',
  2: 'border-amber-600',
  3: 'border-yellow-600',
  4: 'border-blue-600',
  5: 'border-gray-600',
};

// Get urgency class
const urgencyClass = computed(() => {
if (obj.value.status !== 5) {
    const urgencyRating = getUrgencyRating(obj.value.due_date || null);
    return urgencyClassesForCircle[urgencyRating] || '';
  }
  return '';
});

// Get time remaining
const timeRemaining = computed(() => {
  if (obj.value.status === 5) {
    return 'COMPLETED';
  } else if (obj.value.id) {
    const minutesRemaining = obj.value.minutes_available;
    return getHrsMinutes(minutesRemaining);
  }
  return '';
});

// Double-click handling
const doubleClickPending = ref(false);
const timerId = ref<ReturnType<typeof setTimeout> | null>(null);

const handleClick = (): void => {
  if (timerId.value) clearTimeout(timerId.value);
  timerId.value = setTimeout(() => {
    if (!doubleClickPending.value) {
      emit('actionA', props.pk);
    }
    doubleClickPending.value = false;
  }, 200);
};

const handleDoubleClick = (): void => {
  doubleClickPending.value = true;
  emit('actionB', props.pk);
};

// Check if task is completed for goal
const objCompletedForGoal = (): boolean => {
  return !!props.location && props.location !== 'root' && obj.value.status === 5;
};

</script>

<template>
  <div class="flex flex-row">
    <div class="flex flex-row items-center cursor-pointer hover:bg-gray-100 w-full text-sm">
      <div
        @click="handleClick"
        @dblclick="handleDoubleClick"
        :class="urgencyClass"
        class="border-l-4 flex flex-row items-center w-full"
      >
        <div class="flex flex-row justify-between items-center h-full w-full border p-2">
          <div
            class="truncate w-full"
            :class="{ 'text-gray-500 line-through': objCompletedForGoal() }"
          >
            {{ obj.label }}
          </div> 
          <div
            v-if="objCompletedForGoal()"
            class="text-gray-500 text-xs w-24 flex flex-row justify-end uppercase"
          >
            completed
          </div>
          <div
            v-else-if="dataLoaded"
            @click="handleClick"
            @dblclick="handleDoubleClick"
            class="text-gray-500 text-xs w-24 flex flex-row justify-end"
          >
            {{ timeRemaining }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>