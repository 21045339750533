
<script setup lang="ts">

import { ref, onMounted } from 'vue'
import { useThreads } from '@/store/items/thread'

import { MqResponsive } from "vue3-mq";

import Header from '@/components/inbox/Header.vue'
import FilterSettings from '@/components/inbox/FilterSettings.vue'
import DisplayThreads from '@/components/inbox/DisplayThreads/DisplayThreads.vue'

// Threads are preloaded in App.vue

const threads = useThreads()

const filterPanelIsOpen = ref(false)
function toggleFilterPanel() {
    filterPanelIsOpen.value = !filterPanelIsOpen.value
}
threads.fetchTriageMessages();
threads.loadFilters()

</script>

<template>
    <MqResponsive target="md+" class="w-full h-full text-gray-600 pb-14">
      <div class="flex flex-col w-full h-full items-center">

        <Header 
          @toggleFilterPanel="toggleFilterPanel"
        ></Header>
        
        <FilterSettings 
          v-if="filterPanelIsOpen" 
          @close="toggleFilterPanel"
        ></FilterSettings>

        <DisplayThreads
           v-else-if="threads.items.length"
        ></DisplayThreads>

      </div>
    </MqResponsive>  
</template>