
<script setup lang="ts">
import { googleSdkLoaded } from "vue3-google-login"
import axios from "axios"
import { removeObjectFromArray } from "@/utils/arrayUtils"
import { useProjects } from '@/store/items/project'
import { useApiCourier } from '@/store/items/common/apiCourier'
import {ref, inject, watch} from 'vue'

const authInstance = inject("Vue3GoogleOauth");
const projects = useProjects()

const props = defineProps<{
  project: number
}>()

interface EmailAccount {
  id: string;
  address: string;
}

const emailAccounts = ref<EmailAccount[]>([])


function archive(): void {
    projects.proposed.is_active = !projects.proposed.is_active
    projects.update('is_active')
}

async function addEmailAccount(authCode: string): Promise<EmailAccount> {
    try {
        if (!authCode) {
            return null;
        }
        const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
        const url = `${apiEndpoint}/email_auth/`;
        const data = { code: authCode, project:projects.proposed.id };
        const response = await axios.post(url, data); 
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

const auth = async (): Promise<void> => {
  googleSdkLoaded((google) => {
    google.accounts.oauth2.initCodeClient({
      client_id: "326869990964-gu4cepcdjv6mo0foepgpcda32e0s84a9.apps.googleusercontent.com",
      scope: 'https://mail.google.com/',
      callback: async (remoteResponse) => {
        const serverResponse = await addEmailAccount(remoteResponse.code)
        if (serverResponse.message === 'success') {
            emailAccounts.value.push(serverResponse.data.results)
        }
      }
    }).requestCode()
  })
}

async function deleteAccount(objID: string) {
    const answer = confirm("Stop receiving emails from this email account in this project?")
    const data = {'category': projects.proposed.id, 'id': objID}
    const results = await useApiCourier().interaction('delete', 'email_accounts', objID)
    emailAccounts.value = removeObjectFromArray(emailAccounts.value, objID)
}

async function loadEmailAccounts(): Promise<void> {
    const data = {'category': projects.proposed.id}
    const response = await useApiCourier().interaction('filter', 'email_accounts', data)
    emailAccounts.value = response.results
}

projects.setSelected(props.project)
loadEmailAccounts()

watch(() => projects.selected, () => {
    projects.setSelected(props.project)
    loadEmailAccounts()
})

</script>

<template>

    <div class="flex flex-col justify-between h-full">

        <div class="flex flex-col gap-6 p-6">
            <div class="flex flex-row gap-2  w-full">

                <div class="flex flex-col flex-grow">
                    <div class="form-label">
                        Project Name
                    </div>
                    <input v-model="projects.proposed.name" type="text" class="form-field">  
                </div>
                <div class="pt-7 pl-2">
                    <input v-model="projects.proposed.color" class="h-9 w-8 cursor-pointer" type="color" />
                </div>

            </div>

            <div class="flex flex-col gap-1">
                <div class="form-label">
                    Email Accounts
                </div>
                <div
                  v-for="account in emailAccounts"
                  class="flex flex-row border divide-x"
                >
                    <!-- <div @click="deleteAccount(account.id)"  class="p-1 pt-1.5 bg-gray-100">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 hover:text-red-700 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </div>
                    <div class="p-1 pt-1.5 pl-1.5 text-gray-600">{{ account.address }}</div> -->
                </div>
                <div @click="auth()" class="text-blue-500 text-sm cursor-pointer select-none">+ Add Account</div>
            
            </div>

        </div>

        <div class="flex flex-row w-full justify-between p-4 border-t">
            <div @click="archive()" class="btn-caution">
                <span v-if="projects.proposed.is_active">Archive</span>
                <span v-else>Restore</span>
            </div>
            <div @click="projects.save()" class="btn-confirm">
                <span v-if="projects.proposed.id">Update</span>
                <span v-else>Create</span>
            </div>
        </div>
    </div>

</template>