
const dateTimeActions = {
    displayDate(pk) {
        const obj = this.getItem(pk);
        if (isNaN(new Date(obj.begin).getTime())) {
            return "Invalid Date";
        }
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'UTC',  // Force UTC timezone formatting
            weekday: 'short',
            month: 'short',
            day: 'numeric'
        });
        return formatter.format(new Date(obj.begin));
    },
    displayTime(pk) {
        const obj = this.getItem(pk);
        if (isNaN(new Date(obj.begin).getTime()) || isNaN(new Date(obj.end).getTime())) {
            return "Invalid Date";
        }
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'UTC',  // Force UTC timezone formatting
            hour: 'numeric',
            minute: 'numeric'
        });
        const beginTime = formatter.format(new Date(obj.begin));
        const endTime = formatter.format(new Date(obj.end));
        return `${beginTime} - ${endTime}`;
    },
    isRepeatingOnWeekDay(item, targetDate) {
        return item.recurrence_pattern === 'x' && item.recurrence_days.includes(targetDate.getUTCDay());
    },
    
    isRepeatingOnMonthDay(item, targetDate) {
        const targetPosition = Math.ceil(targetDate.getUTCDate() / 7); // Use UTC day of the month
        return item.recurrence_pattern === targetPosition.toString() && item.recurrence_days.includes(targetDate.getUTCDay());
    },
    isMatchingDate(item, targetDate) {
        const itemDate = new Date(item.begin);
        return (
            targetDate.getUTCFullYear() === itemDate.getUTCFullYear() &&
            targetDate.getUTCMonth() === itemDate.getUTCMonth() &&
            targetDate.getUTCDate() === itemDate.getUTCDate()
        );
    },
    isBeginDateValid(item, targetDate) {
        const itemBeginDate = new Date(item.begin);
        return itemBeginDate.getTime() <= targetDate.getTime();
    },
    appointmentsForDay(date) {
        return this.items.filter(item => {
            const targetDate = new Date(date); // Make sure `targetDate` is treated as a Date object.
            
            return this.isMatchingDate(item, targetDate) || 
                (this.isBeginDateValid(item, targetDate) && (
                    (item.recurrence_pattern && this.isRepeatingOnWeekDay(item, targetDate)) ||
                    (item.recurrence_pattern && this.isRepeatingOnMonthDay(item, targetDate))
                ));
        });
    },
};

export { dateTimeActions };