<script setup lang="ts">
import { ref, reactive, computed } from "vue";
import { useGoogleCallback } from "@/composables/auth";
import { useProjects } from "@/store/items/project";
import { useDirector } from "@/store/pages/director";
import axios from "axios";

// State
const form = ref<"signUp" | "logIn">("signUp");
const email = ref("");
const password = ref("");
const password2 = ref("");

// Validation errors
const errors = reactive({
  email: false,
  password: false,
  password2: false,
});

// Google Callback Logic
const { isValid, responseData, error, processCallback } = useGoogleCallback();

// Computed: Get access token from localStorage
const accessToken = computed(() => localStorage.getItem("access_token"));

// Methods
const isValidEmail = (email: string): boolean => {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

const setUserData = async (data: { token: string; user: object }) => {
  localStorage.setItem("access_token", data.token);
  localStorage.setItem("user", JSON.stringify(data.user));
  axios.defaults.headers.common = { Authorization: `Token ${data.token}` };
  await useProjects().loadItems();
  useDirector().loginSignal = true;
  window.location.href = "/today/";
};

const handleSiteSignUp = async () => {
  if (!isValidEmail(email.value)) {
    errors.email = true;
    return;
  } else {
    errors.email = false;
  }

  if (password.value.length < 8) {
    errors.password = true;
    return;
  } else {
    errors.password = false;
  }

  if (password.value !== password2.value) {
    errors.password2 = true;
    return;
  } else {
    errors.password2 = false;
  }

  try {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const response = await axios.post(`${apiEndpoint}/create_user/`, {
      username: email.value,
      email: email.value,
      password: password.value,
    });
    await setUserData(response.data);
  } catch (error) {
    console.error("Sign up error:", error);
  }
};

const handleGoogleSignIn = async (response: any) => {
  
  const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
  try {
    const res = await axios.post(`${apiEndpoint}/auth_google/`, {
      code: response.code,
    });
    await setUserData(res.data);
  } catch (err) {
    console.error("Google sign-in error:", err);
  }

};

// Logout
const handleSignOut = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
  useDirector().loginSignal = false;
  window.location.href = "/";
};
</script>

<template>
  <div class="flex flex-row">
    <div
      class="flex flex-col gap-2 items-center justify-center bg-gray-300 opacity-80"
      style="height: 100vh; position: absolute; bottom: 0vh; width: 100vw"
    >
      <div
        class="flex flex-col gap-4 items-center justify-center bg-white p-16 border border-gray-400 shadow-lg select-none"
        style="height: 80vh; width: 450px; max-height: 700px;"
      >
        <div class="flex flex-col items-center gap-2">
          <div class="font-medium text-3xl text-cyan-700">
            Tule|v|Reed
          </div>
          <div class="text-sm text-gray-700 border-b pb-3 mb-2">
            Project Organizer
          </div>
        </div>
        <GoogleLogin :callback="handleGoogleSignIn" :authMode="'code'"/>

        <div class="flex flex-row gap-2 items-center">
          <div> -- </div>
          <div>or</div>
          <div> -- </div>
        </div>
        <div class="flex flex-col w-full border">
          <div class="flex flex-row w-full">
            <div @click="form='signUp'" class="border w-1/2 p-2 text-center cursor-pointer" :class="{'bg-teal-700 text-white' : form==='signUp'}">Sign Up</div>
            <div @click="form='logIn'" class="border w-1/2 p-2 text-center cursor-pointer" :class="{'bg-teal-700 text-white' : form==='logIn'}">Log In</div>
          </div>
          <form ref="signUpForm" v-if="form==='signUp'">
            <div class="flex flex-col p-4 gap-4">
              <div class="flex flex-col">
                <div class="flex flex-row gap-2 items-center">
                  <label for="signupEmail">Email Address</label> <span v-if="errors.email" class="text-sm text-red-500 uppercase">Invalid!</span>
                </div>
                <input id="signupEmail" :class="{'border-red-500': errors.email}" v-model="email" type="email" required />
              </div>
              <div class="flex flex-col">
                <div class="flex flex-row gap-2 items-center">
                  <label for="password1">Password</label><span v-if="errors.password" class="text-sm text-red-500 uppercase">Must be 8+ characters!</span>
                </div>
                <input v-model="password" :class="{'border-red-500': errors.password}"  id="password1" type="password" required />
              </div>
              <div  class="flex flex-col" >
                <div class="flex flex-row gap-2 items-center">
                  <label for="password1">Password</label><span v-if="errors.password2" class="text-sm text-red-500 uppercase">Passwords must match.</span>
                </div>
                <input v-model="password2" :class="{'border-red-500': errors.password2}" id="password2" type="password" required />
              </div>
              <div @click="handleSiteSignUp()" class="bg-blue-700 p-2 text-center cursor-pointer hover:bg-blue-600 text-white">Submit</div>
            </div>
          </form>
          <form ref="logInForm" v-else-if="form==='logIn'">
            <div class="flex flex-col p-4 gap-4">
              <div class="flex flex-col">
                <label for="email">Email Address</label>
                <input id="loginEmail" v-model="email" type="email" />
              </div>
              <div class="flex flex-col">
                <label for="password">Password</label>
                <input is="password" type="password" />
              </div>
              <div @click="handleSiteSignUp()" class="bg-blue-700 p-2 text-center cursor-pointer hover:bg-blue-600 text-white">Submit</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
