<script setup lang="ts">

import ApptForm from '@/components/common/forms/AppointmentForm/AppointmentForm.vue'
import MeetingLabel from '@/components/common/labels/MeetingLabel.vue'
import EditItem from '@/components/common/buttons/EditItem.vue'
import MeetingAgenda from '@/components/common/lists/MeetingAgenda/MeetingAgenda.vue'
import ItemScroll from '@/components/common/ItemScroll.vue'

import { useDirector } from '@/store/pages/director'
import { useAppointments } from '@/store/items/appointment'

import { ref, computed, watch, onMounted } from 'vue'

const director = useDirector()
const appts = useAppointments()

const props = defineProps<{
    task: number,
    project: number
}>()

const index = ref(0)
const items = computed(() => appts.forProject(props.project).filter(item => item.task === props.task))
const item = computed(() => {
  if (!items.value || items.value.length === 0 || index.value < 0 || index.value >= items.value.length) {
    return null; // Return null if `items` array is empty or index is out of bounds
  }
  return items.value[index.value]; // Return the item at the specified index
});
const formIsOpen = ref(false)

const agenda = computed(() => {
  if (!item.value || item.value === null) {
    return null; // Return null if `items` array is empty or index is out of bounds
  }
  return appts.agenda.filter(i => i.appointment === item.value!.id); // Return the item at the specified index
});

function scrollVisible(direction: string) {
    if (direction === 'left') {
        if (index.value > 0) {
            index.value--
        }
    } else {
        if (index.value < items.value.length - 1) {
            index.value++
        }
    }
}

function toggleForm(pk?: number | "new") {
    formIsOpen.value = !formIsOpen.value
    if (pk) {
        if (pk !== 'new') {
            appts.proposed = appts.getItem(pk)
        } else {
            appts.clearSelected()
            appts.proposed.task = props.task
        }
    }
}

function loadAgenda(): void {
    appts.agenda = []
    appts.loadAgendaItems(item.value.id)
}

// function itemSelection(sectionIndex: number, entryIndex: number): void {
//     emit('setDisplay', sectionIndex, entryIndex)
// }

onMounted(async() => {
    await director.loadItemsForProject('appointment', props.project)
    if (items.value.length > 0) {
        loadAgenda()
    }
})
watch(() => props.task, async () => {
    await director.loadItemsForProject('appointment', props.project)
    loadAgenda()
})

watch(() => index.value, async () => {
    loadAgenda()
})
</script>

<template>
    <div class="flex flex-col p-4 w-full h-full">
        <div v-if="formIsOpen">
            <ApptForm :project="project" @close="toggleForm" />
        </div>
        
        <div
            v-else
            class="flex flex-col text-gray-500 w-full h-full items-center justify-between"
        >
            <div v-if="item" class="flex flex-col border p-2 flex-auto w-full">
                <div class="flex border-b pb-3 flex-row justify-between">
                    <div class="flex flex-col">
                        <MeetingLabel
                            :key="index"
                            :item="item"
                        />
                    </div>
                    <EditItem :pk="item.id" @click="toggleForm" />
                </div>
                <MeetingAgenda
                  :appointment="item.id"
                  :items="agenda!"
                />
            </div>

            
            <div class="flex flex-col w-full">
                <!-- Scroller Tool --> 
                <ItemScroll
                  :items="items"
                  :currentIndex="index"
                  @scroll="scrollVisible"
                ></ItemScroll>
                <div @click="toggleForm('new')" class="flex pt-3 w-full cursor-pointer">
                    <div class="flex justify-center items-center p-2 w-full bg-teal-600 text-white hover:bg-teal-700 rounded">
                        Schedule A Meeting
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
