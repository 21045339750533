<script setup lang="ts">
import { useResources } from '@/store/items/resource'
import { cleanURL} from '@/utils/urlUtils'  

const store = useResources()

// Define the types for props
const props = defineProps<{
  project: number;
}>()

// Define the emit type for closeForm event
const emit = defineEmits<{
  (e: 'closeForm'): void;
}>()

function validate(): boolean {
  return !!store.proposed.title && !!cleanURL(store.proposed.url!)
}

function save(): void {
  if (!validate()) {
    return
  }
  store.proposed.url = cleanURL(store.proposed.url!)
  if (!store.proposed.id) {
    store.proposed.category = props.project
    store.create()
  } else {
    store.overwrite()
  }
  emit('closeForm')
}
</script>

<template>
  <div class="flex flex-col gap-2 p-5">
    <div class="flex flex-row justify-end">
      <div @click="$emit('closeForm')" class="flex flex-row gap-2 items-center select-none cursor-pointer hover:text-gray-600"> 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">         
        <div class="form-label">
          Title
        </div>
        <input autofocus v-model="store.proposed.title" type="text" class="form-field">            
      </div>
      <div class="flex flex-col">
        <div class="form-label">
          URL
        </div>
        <input v-model="store.proposed.url" type="url" class="form-field">            
      </div>
      <div class="flex flex-row justify-end">
        <div @click="save()" :class="['btn-confirm', { 'btn-disabled': !validate() }]">Save</div>
      </div>
    </div>
  </div>
</template>
