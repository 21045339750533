<script setup lang="ts">

import type { FieldSet } from '@/components/common/formFields/forContact/FieldGroups';

import { useContacts } from '@/store/items/contact';

const contacts = useContacts();

const props = defineProps<{
  fieldSet: FieldSet,
  activeInput: FieldSet['type'] | null;
  objID: number | null,
  contact: number,
}>();

const emit = defineEmits(['setOpenedField', 'clearOpenedField']);

async function deleteData() {
    if (props.objID && props.activeInput) {
        await contacts.delDetail(props.objID, props.activeInput)
        emit('clearOpenedField')
    }
}

async function saveDetail() {
    if (props.activeInput) {
        const data = {...props.fieldSet.proposed, contact: props.contact}
        await contacts.saveDetail(props.activeInput, data)
        emit('clearOpenedField')
    }
}

</script>

<template>

<div 
  v-if="activeInput !== fieldSet.type" 
  @click=" emit('setOpenedField', objID, fieldSet.type)" 
  class="form-label select-none text-blue-500 cursor-pointer hover:text-blue-600"
    > + Add
</div>
<div v-if="activeInput === fieldSet.type" class="flex flex-col gap-2 p-4 border m-2 border-dashed">
    <div class="flex flex-row gap-2">
        <div class="flex flex-col text-sm relative w-1/3">
            <label class="absolute bg-white bottom-8 left-3 px-0.5">Label</label>
            <input type="text" v-focus v-model="fieldSet.proposed.label" class="form-field flex">
        </div>
        <div class="flex flex-col text-sm relative flex-grow">
            <label class="absolute bg-white bottom-8 left-3 px-0.5">Value</label>
            <input type="text" v-model="fieldSet.proposed.value" class="form-field flex flex-grow">
        </div>
    </div>
    <div class="flex flex-row justify-end gap-2">
        <div @click="emit('clearOpenedField')" class="btn-secondary text-xs">Cancel</div>
        <div v-if="objID !== null && activeInput" @click="deleteData" class="btn-caution text-xs">Delete</div>
        <div @click="saveDetail()" class="btn-confirm text-xs">Save</div>
    </div>
</div>
</template>