import type { ContactItem } from "@/store/items/interfaces/contact";
import type { GroupItem } from "@/store/items/interfaces/group"; 

export function useRecipientClass(props: { recipients: any[], projectContacts: ContactItem[] }) {
  
  // 🎯 Determines the CSS class based on recipient's position
  const getClassByPosition = (position?: "to" | "cc" | "bcc"): string => {
    if (position === "to") return "bg-blue-500 text-white hover:bg-blue-400";
    if (position === "cc") return "bg-yellow-600 text-white hover:bg-yellow-500";
    if (position === "bcc") return "bg-gray-600 text-white hover:bg-gray-500";
    return "";
  };

  // 🔹 Handles a single email string
  const getClassForEmail = (email: string): string => {
    const position = props.recipients.find(i => i.address === email)?.position;
    return getClassByPosition(position);
  };

  // 🔹 Handles a ContactItem (checking all its email addresses)
  const getClassForContact = (contact: ContactItem): string => {
    const positions = contact.email_addresses
      .map(emailObj => props.recipients.find(i => i.address === emailObj.value)?.position)
      .filter(Boolean) as ("to" | "cc" | "bcc")[];

    return getClassByPosition(positions.includes("to") ? "to" : positions.includes("cc") ? "cc" : positions.includes("bcc") ? "bcc" : undefined);
  };

  // 🔹 Handles a GroupItem (checking all members)
  const getClassForGroup = (group: GroupItem): string => {
    const memberContacts = group.members
      .map(memberId => props.projectContacts.find(contact => contact.id === memberId))
      .filter(Boolean) as ContactItem[];

    // Collect all member emails
    const allMemberEmails = memberContacts.flatMap(contact => contact.email_addresses.map(e => e.value));

    // Get positions from matching emails
    const positions = allMemberEmails
      .map(email => props.recipients.find(i => i.address === email)?.position)
      .filter(Boolean) as ("to" | "cc" | "bcc")[];

    // Ensure all members are present in recipients
    const allMembersPresent = memberContacts.every(contact =>
      contact.email_addresses.some(emailObj =>
        props.recipients
        .filter(recipient => recipient.position !== "disabled")
        .some(recipient => recipient.address === emailObj.value)
      )
    );

    if (!allMembersPresent) return "";

    return getClassByPosition(positions.includes("to") ? "to" : positions.includes("cc") ? "cc" : positions.includes("bcc") ? "bcc" : undefined);
  };

  // 🎯 Main function that delegates based on input type
  const getRecipientClass = (contactOrGroupOrEmail: ContactItem | GroupItem | string): string => {
    if (typeof contactOrGroupOrEmail === "string") return getClassForEmail(contactOrGroupOrEmail);
    if ("email_addresses" in contactOrGroupOrEmail) return getClassForContact(contactOrGroupOrEmail);
    if ("members" in contactOrGroupOrEmail) return getClassForGroup(contactOrGroupOrEmail);
    return "";
  };

  return {
    getRecipientClass
  };
}