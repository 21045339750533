<script setup lang="ts">

import ReadThread from '@/components/common/email/ReadThread.vue'
import ComposeMessage from '@/components/common/email/ComposeMessage/ComposeMessage.vue'
import Loader from '@/components/common/Loader.vue'
import ThreadList from './ThreadList/ThreadList.vue'
import LoginPrompt from './LoginPrompt.vue'
import Header from './Header.vue'

import { useThreads } from "@/store/items/thread"
import { useProjects } from '@/store/items/project'
import { useDirector } from '@/store/pages/director'
import { computed, ref, onMounted } from 'vue'

import type { EmailAccount } from '@/store/pages/interfaces/director'
import type { ComposeInitial } from '@/components/common/email/ComposeMessage/ComposeMessage.vue'

const threads = useThreads()
const projects = useProjects()
const director = useDirector()

const props = defineProps({
    project: Number,
    loaded: Array
})

const status = ref<number>(1)
const setStatus = (value: number) => {
    status.value = value
}

const searchTerm = ref<string>('')
function setSearchTerm(value: string) {
    searchTerm.value = value
}

const forProject = computed(() => {
  return threads.items.filter(item => 
    item.category === projects.selected &&
    (status.value === 1 ? item.status === 1 || item.status === 0 : item.status === status.value) &&
    item.messages[0].subject.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
});
const itemCount = computed(() => Object.keys(forProject.value).length)

const loadingPrevious = ref(false)
function setLoadingPrevious(value: boolean) {
    loadingPrevious.value = value
}

async function openThread(id: number) {
    await threads.fetchThread(id)
    threads.selected = id
}

function closeThread() {
    threads.selected = null
}

function trashThread() {
    closeThread()
    threads.del(threads.selected!);
    threads.removeItems([threads.selected!]);
}

function resolveThread() {
    const threadID = threads.selected
    threads.resolveBulk([threadID!])
    closeThread()
}

const defaultComposeInitial: ComposeInitial = {
  id: null,
  account: 0,
  subject: "",
  body: "",
  category: 0,
};

const composeEnabled = ref<boolean>(false);
const composeInitial = ref<ComposeInitial>({ ...defaultComposeInitial });

const defaultAccount = computed<EmailAccount | undefined>(() => {
    const accounts = director.accounts
    const match = accounts.find(
    (account) => account.category === projects.selected
    )
    if (match) {
    return match
    }
    // 2. If none found, return the first account with no category
    return accounts.find((account) => !account.category)
})

function setComposeThread() {
    const selectedThread = threads.getSelected();

    // Get thread data if `id` is provided
    if (selectedThread) {
        composeInitial.value = {
            id: selectedThread.id,
            account: defaultAccount.value!.id,
            subject: selectedThread.messages[0].subject ?? "",
            body: "",
            category: projects.selected!,
        };
    } else {
        // Fallback to default if thread not found
        composeInitial.value = { ...defaultComposeInitial };
    }
    composeEnabled.value = true;
}

onMounted(() => {
    director.loadItemsForProject('contact', projects.selected!)
})

</script>


<template>
<div class="flex flex-col w-full bg-white min-h-0">
    <Header
        v-if="!threads.selected && !composeEnabled"
        @setStatus="setStatus"
        @setSearchTerm="setSearchTerm"
        @setCompose="setComposeThread"
    ></Header>
    <div v-if="(!threads.selected && !composeEnabled && !(itemCount > 0) && !loaded!.includes(project)) ">
        <Loader></Loader>
    </div>
    <ComposeMessage
        v-if="composeEnabled"
        :initial="composeInitial"
    ></ComposeMessage>
    <ThreadList
        v-else-if="!threads.selected"
        :items="forProject"
        :searchTerm="searchTerm"
        :status="status"
        :project="projects.selected!"
        :loadingPrevious="loadingPrevious"
        @setLoadingPrevious="setLoadingPrevious"
        @openThread="openThread"
    ></ThreadList>
    <LoginPrompt
        v-else-if="!threads.authReference[threads.getSelected()!.account]"
        @clearSelected="threads.selected = null"
    >
    </LoginPrompt>
    <ReadThread
        v-else
        :showFilterOpts="true"
        @closeThread="closeThread"
        @trashThread="trashThread"
        @resolveThread="resolveThread"
        @setComposeThread="setComposeThread"
    ></ReadThread>
    <div v-if="loadingPrevious && !threads.selected && !composeEnabled">
        <Loader></Loader>
    </div>
    <div v-else-if="itemCount === 0 && loaded!.includes(project)" class="flex flex-col gap-3 p-6 items-center border-b">
        <div class="text-gray-500 select-none">No Current Messages</div>
    </div>
</div>
</template>