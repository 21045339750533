<script setup lang="ts">

import AppointmentIndex from "./AppointmentIndex/AppointmentIndex.vue"
import CloseItem from "@/components/common/buttons/CloseItem.vue";
import AppointmentForm from "@/components/common/forms/AppointmentForm/AppointmentForm.vue";
import Archive from "@/components/common/Archive.vue"

import Schedule from "@/components/common/Schedule/Schedule.vue"
import DateSelect from "./DateSelect.vue";

import { ref, watch, onMounted, nextTick } from 'vue'
import { useAppointments } from '@/store/items/appointment'

const appts = useAppointments()

const props = defineProps<{
    project: number,
    loaded: number[]
}>()

const scheduleDate = ref(new Date())

const formIsOpened = ref(false)

const loadItemsForDay = async () => {
    await appts.loadItems({
        date: scheduleDate.value.toISOString().slice(0, 10)
    });
}

function openForm() {
    formIsOpened.value = false
    nextTick(() => {formIsOpened.value = true})
}

const archiveIsOpened = ref(false)
function openArchive(pk: number) {
    appts.selected = pk
    archiveIsOpened.value = true
}

loadItemsForDay()

watch(scheduleDate, (n,o) => {
    loadItemsForDay()
})

onMounted(() => {
    if (appts.labelPipe) {
        appts.clearProposed()
        openForm()
        appts.proposed.label = appts.labelPipe
        appts.labelPipe = ""
    }
})

</script>

<template>

<div class="h-full w-full flex flex-row">

    <div class="flex flex-col w-1/2 border-r p-5 h-full flex-shrink-0">
        <div class="flex flex-row gap-6 w-full text-sm items-center mb-5">
            <div class="text-xl text-teal-700">Meetings</div>
        </div>
        <div v-if="formIsOpened" class="flex flex-col min-h-0">               
            <div class="bg-white flex flex-col rounded shadow p-5 gap-4 min-h-0">
                <div class="flex flex-row border-b pb-2 text-sm justify-between text-gray-400 items-center">
                    <div class="text-gray-400"></div>
                    <CloseItem @click="formIsOpened = false" />
                </div>
                <div class="flex flex-col overflow-y-auto p-4 flex-grow">
                    <AppointmentForm
                        :project="project"
                        @close="formIsOpened = false"   
                    ></AppointmentForm>
                </div>
            </div> 
        </div>

        <div class="flex flex-col p-4 gap-4" v-else-if="archiveIsOpened">
            <div class="uppercase text-sm">Select binder for <span class='text-teal-600'>{{ appts.getSelected()?.label }}</span></div>
            <Archive 
                :project="project"
                :store="appts"
                @close="archiveIsOpened = false"
            />
        </div>

        <div v-else class="bg-white rounded shadow flex flex-col min-h-0 flex-grow">
            <AppointmentIndex
                :project="project"
                :loaded="loaded"
                @openForm="openForm"
                @openArchive="openArchive"
            ></AppointmentIndex>
        </div>

    </div>


    <div class="flex flex-col w-1/2 flex-shrink-0 ml-5 p-5 pl-0 h-full gap-2">       
        <div class="flex flex-row justify-around">
            <div class="bg-white rounded shadow">
                <DateSelect
                    v-model:selected="scheduleDate"
                ></DateSelect>
            </div>
        </div> 
        <div class="p-4 bg-white rounded shadow overflow-y-auto">
            <Schedule
                :date="scheduleDate"
                @openForm="openForm"
            ></Schedule>
        </div>

    </div>
</div>

</template>