<script lang="ts" setup>

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import Loader from '@/components/common/Loader.vue';
import ArchiveItem from '@/components/common/buttons/ArchiveItem.vue';
import Archive from '@/components/common/Archive.vue';
import type { DirectorExtensions } from '@/store/pages/interfaces/director';
import { useNotes } from '@/store/items/note';
import { useDirector } from '@/store/pages/director';
import type { NoteItem } from '@/store/items/interfaces/note';

import { ref, watch, onBeforeUnmount } from 'vue';

interface Props {
    project: number;
    showActions: boolean;
}

const store = useNotes();
const director = useDirector();

const props = defineProps<Props>();

// Use local values to make sure saves occur before any data is switched.
const id = ref<number | null>(null);
const data = ref<string>("");
const backup = ref<string>("");

const saving = ref<boolean>(false);
const selectedText = ref<string>("");
let polling: ReturnType<typeof setInterval> | null = null;

const quill = ref<InstanceType<typeof QuillEditor> | null>(null);

const clearData = (): void => {
    data.value = "";
    id.value = null;
    backup.value = "";
};

const setSelection = (data: { range: { index: number; length: number } | null }): void => {
    const text = quill.value?.getText() || "";
    const range = data.range;
    if (range) {
        selectedText.value = text.substring(range.index, range.index + range.length);
    }
};

const checkForBackspace = (event: KeyboardEvent): void => {
    selectedText.value = "";
};

const onTextChange = (data:{
  delta: any, 
  oldDelta: any, 
  source: string
}): void => {
  if (data.source === 'user') {
    // Clear the selected text when a user makes a text change.
    selectedText.value = "";
  }
};

const showArchive = ref<boolean>(false)
function closeArchive(isArchived: boolean) {
    if (isArchived){
        store.selected = null
    }
    showArchive.value = false
}

type KeysWithLabelPipe = {
    [K in keyof DirectorExtensions]: 'labelPipe' extends keyof DirectorExtensions[K] ? K : never;
}[keyof DirectorExtensions];

function toForm(model: KeysWithLabelPipe): void {
    if (selectedText.value) {
        director.extensions[model].labelPipe = selectedText.value;
        director.panelSignal = model;
    }
}

const setData = (): void => {
    if (store.selected) {
        const text = store.getSelected()!.text;
        id.value = store.selected;
        if (backup.value !== text) { 
            data.value = text;
            backup.value = text;
        }
    } else {
        id.value = null;
        data.value = "\n";
        backup.value = "\n";
    }
};

function containsOnlyTags(htmlString: string): boolean {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const textContent = doc.body.textContent?.trim() || "";
    return textContent === '';
}

const saveNote = async (): Promise<number | null> => {
    if (id.value && (!data.value || containsOnlyTags(data.value)) && !saving.value) {
        saving.value = true;
        if (id.value) {
            await store.del(id.value, props.project);
        }
        clearData();
        store.clearSelected();
        saving.value = false;
        return id.value;
    } else if (
        !saving.value &&
        (!data.value || !containsOnlyTags(data.value)) &&
        data.value !== backup.value
    ) {
        let result: NoteItem | null = null;
        saving.value = true;
        store.proposed.category = props.project;
        store.proposed.text = data.value;
        if (id.value) {
            store.proposed.id = id.value;
            result = await store.save('update', store.proposed);
        } else {
            store.proposed.id = null;
            result = await store.save('create', store.proposed);
            id.value = result.id;
            store.selected = result.id;
        }
        backup.value = data.value;
        saving.value = false;
        return result?.id || null;
    } else {
        return id.value;
    }
};

polling = setInterval(async () => {
    const result = await saveNote();
    if (result && !store.selected) {
        store.selected = result;
    }
    if (result !== store.selected || !store.editorIsOpen) {
        clearInterval(polling!);
    }
}, 7000);

onBeforeUnmount(async () => {
    clearInterval(polling!);
    await saveNote();
    store.editorIsOpen = false;
});

watch(() => store.selected, async (newSelected, oldSelected) => {
    if (!saving.value && oldSelected && newSelected !== oldSelected) {
        await saveNote();
        setData();
    }
});

watch(() => props.project, async () => {
    store.selected = null;
});

setData();

</script>

<template>
<div class="flex flex-col p-4 gap-4" v-if="showArchive">
    <div class="uppercase text-sm">Select binder for this note.</div>
    <Archive 
        :project="project"
        :store="store"
        @close="closeArchive"
    />
</div>
<div v-else class="flex flex-col border-r p-5 min-h-0 flex-grow">
    <div v-if="showActions" class="flex flex-row w-full text-sm items-center justify-between mb-5">
        <div class="flex flex-row gap-6 ">
            <div @click="toForm('task')" :class="{'btn-confirm': selectedText, 'btn-disabled' : !selectedText}">Add Task</div>
            <div @click="toForm('appointment')" :class="{'btn-confirm': selectedText, 'btn-disabled' : !selectedText}">Add Appointment</div>
        </div>
        <ArchiveItem @click="showArchive = true"></ArchiveItem>
    </div>
    <div class="bg-white rounded shadow flex flex-col min-h-0 flex-grow">
        <QuillEditor       
            ref="quill"
            v-model:content="data"
            theme="snow"
            contentType="html"
            toolbar="minimal"
            @selectionChange="setSelection"
            @keyup.delete="checkForBackspace"
            @textChange="onTextChange"
        />
    </div>
</div>
</template>

<style>
.ql-container {
    height: calc(100% - 42px);
}
</style>
