<script setup lang="ts">
import Loader from "@/components/common/Loader.vue";
import ListNotes from "@/components/projects/NotesView/ListNotes.vue"
import DisplayNote from "@/components/projects/NotesView/DisplayNote.vue";
import { useNotes } from "@/store/items/note"
import { useDirector } from "@/store/pages/director";

import { ref } from "vue";
import CloseItem from "@/components/common/buttons/CloseItem.vue";

const props = defineProps<{
    project: number
}>()

const director = useDirector()
const notes = useNotes()

const editorIsOpen = ref(false)

function openEditForm(noteId: number) {
    notes.setSelected(noteId)
    editorIsOpen.value = true
}
function openNewForm() {
    notes.clearSelected()
    editorIsOpen.value = true
}

director.loadItemsForProject('note', props.project)



</script>

<template>
<Loader v-if="!director.loadedForProjects['note'].includes(project)" />

<div v-else-if="!editorIsOpen" class="pl-3">
    <ListNotes 
      :project="project" 
      :loaded="director.loadedForProjects['note']" 
      @openEditForm="openEditForm"
      @createNewNote="openNewForm"
    />
</div>

<div v-else class="flex flex-col h-full min-h-0 flex-grow">
    <DisplayNote :project="project" :showActions="false"/>
    <div class="flex flex-row justify-end pr-5 pb-4">
        <div @click="editorIsOpen = false" class="border p-2 hover:text-teal-500 cursor-pointer">Save & Close</div>
    </div>
</div>
</template>