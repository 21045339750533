<script setup lang="ts">

import NewContactView from './ContactsPanel/NewContactView.vue';
import AddToGroupView from './ContactsPanel/AddToGroupView.vue';
import ManageContactView from './ContactsPanel/ManageContacts/ManageContactView.vue';
import DisplayContacts from './ContactsPanel/DisplayContacts.vue';
import NewGroupView from './GroupsPanel/NewGroupView.vue';
import ManageGroupView from './GroupsPanel/ManageGroups/ManageGroupView.vue';
import Archive from '@/components/common/Archive.vue';
import DisplayGroups from './GroupsPanel/DisplayGroups.vue';

import { useProjects } from '@/store/items/project';
import { useContacts } from '@/store/items/contact';
import { useGroups } from '@/store/items/group'
import { ref, computed } from 'vue';
import { right } from '@popperjs/core';

const projects = useProjects()

const leftView = ref<'new' | 'add' | 'manage' | 'archive' | 'default'>('default');

type ViewMapLeft = {
    new: typeof NewContactView;
    add: typeof AddToGroupView;
    manage: typeof ManageContactView;
    archive: typeof Archive;
    default: typeof DisplayContacts;
};

const viewMapLeft: ViewMapLeft = {
  new: NewContactView,
  add: AddToGroupView,
  manage: ManageContactView,
  archive: Archive,
  default: DisplayContacts,
};

function setLeftView(mode: keyof ViewMapLeft) {
    leftView.value = mode
    if (mode === 'manage') {
        rightView.value = 'default'
    }
}

const LeftColumn = computed(() => viewMapLeft[leftView.value]);

const rightView = ref<'new' | 'manage' | 'archive' | 'default'>('default');

type ViewMapRight = {
    new: typeof NewGroupView;
    manage: typeof ManageGroupView;
    archive: typeof Archive;
    default: typeof DisplayGroups;
};

const viewMapRight: ViewMapRight = {
  new: NewGroupView,
  manage: ManageGroupView,
  archive: Archive,
  default: DisplayGroups,
};

function setRightView(mode: keyof ViewMapRight) {
    rightView.value = mode
    if (mode === 'manage') {
        leftView.value = 'add'
    } else if (leftView.value === 'add') {
        leftView.value = 'default'
    }
}

const RightColumn = computed(() => viewMapRight[rightView.value]);

const archiveLeftProps = computed(() => {
  if (leftView.value === 'archive') {
    return {
      project: projects.selected,
      store: useContacts()
    };
  }
  return {};
});

const archiveRightProps = computed(() => {
  if (rightView.value === 'archive') {
    return {
      project: projects.selected,
      store: useGroups()
    };
  }
  return {};
});

</script>


<template>
    <div class="h-full w-full flex flex-row">
        <div class="flex flex-col w-1/2 grow-0 border-r p-5">
            <div class="flex flex-row gap-6 w-full text-sm items-center mb-5">
                <div class="text-xl text-teal-700">Contacts</div>
            </div>
            
            <component :is="LeftColumn" v-bind="archiveLeftProps" @setView="setLeftView" @close="setLeftView('default')"/>
            
        </div> 
        <div class="flex flex-col shrink-0 w-1/2 m-5">
            <div class="flex flex-row gap-6 w-full text-sm items-center mb-5">
                <div class="text-xl text-teal-700">Groups</div>
            </div>
            <component :is="RightColumn" v-bind="archiveRightProps" @setView="setRightView" @close="setRightView('default')"/>
        </div>
    </div>
</template>