<script setup lang="ts">
import { Sortable } from "sortablejs-vue3";
import { useAgenda } from "@/store/pages/agenda";
import { ref, computed } from "vue";
import { convertMinutesIntoHrsMinutesVerbose } from "@/utils/timeUtils";
import { getDateLabels } from "@/utils/dateUtils";
import ListedRecord from "@/components/common/listedItems/ListedRecord.vue";
import Duration from "@/components/common/formFields/forTask/Duration.vue";

import type { RecordItem } from "@/store/items/interfaces/record";
import type { CategoryItem } from "@/store/items/interfaces/project";
import type { WorkdayItem } from "@/store/items/interfaces/workday"
import type { AppointmentItem } from "@/store/items/interfaces/appointment"
import { useTasks } from "@/store/items/task";
import { useRecords } from "@/store/items/record";
import { useProjects } from "@/store/items/project";
import { useWorkdays } from "@/store/items/workday";

import type { ViewName, Selections } from "@/store/pages/interfaces/agenda";

const props = defineProps<{
  workdayID: number | null;
  recordItems: RecordItem[];
  minutesPlanned: number;
}>();

const emit = defineEmits<{
  (event: "selectItem", item: keyof Selections, pk: number): void;
  (event: "setView", position:"center" | "right", label: ViewName): void;
  (event: "updateOrder", records: RecordItem[]): void;
}>();

// Store
const store = useAgenda();
const tasks = useTasks();
const records = useRecords();
const projects = useProjects();
const workdays = useWorkdays();

// Reactive Variables
const grouped = ref(false);
const menuDisplayedFor = ref<number | null>(null);
const hrsMinutesPlanned = computed(() => getHrsMins(props.minutesPlanned));
const hrsMinutesTarget = computed(() => getHrsMins(workday.value?.duration || 0))

// Computed Workday
const workday = computed(() => workdays.items.find((i: WorkdayItem) => i.id === props.workdayID));

function getHrsMins(mins: number) {
  return convertMinutesIntoHrsMinutesVerbose(mins);
}

// Sortable Options
const options = {
  animation: 350,
  easing: "cubic-bezier(0, 0, 0, 2)",
  dragClass: "opacity-0",
};

// Update Order
const updateOrder = (evt: any) => {
  const recordItems = [...props.recordItems];
  const record = recordItems.splice(evt.oldIndex, 1)[0];
  recordItems.splice(evt.newIndex, 0, record);
  records.updateOrder(recordItems);
};

const projectGroups = computed(() =>
  props.recordItems.reduce<Map<number, RecordItem[]>>((groups, item) => {
    const project = tasks.getItem(item.task).category!;
    if (!groups.has(project)) {
      groups.set(project, []);
    }
    groups.get(project)!.push(item);
    return groups;
  }, new Map<number, RecordItem[]>())
);

function getProject(pk: number) {
  return projects.getItem(pk)
}

function displayTaskMenu(obj: RecordItem) {
  menuDisplayedFor.value = obj.id;
}

function displayTask(pk: number) {
  emit('selectItem', 'task', pk)
  emit('setView', 'center', 'viewTask')
}

async function strikeRecord(pk: number) {
    records.clearSelected()
    menuDisplayedFor.value = null;
}

const showTargetForm = ref(false);

function updateWorkdayDuration(duration: {field: string, value: number}) {
  if (workday.value) {
    store.updateDuration(workday.value.id, duration.value,);
  }
}

</script>

<template>
  <div class="flex flex-row justify-between border-b p-2">
    <div v-if="workday" class="flex flex-col font-thin text-gray-500">
      <div class="text-3xl uppercase">{{ getDateLabels(workday.date).weekday.full }}</div>
      <div class="text-3xl">
        {{ getDateLabels(workday.date).month }} {{ getDateLabels(workday.date).date }}
      </div>
    </div>
    <div class="flex flex-col items-end justify-between gap-2">
      <div class="text-2xl">&#128512;</div>
      <div @click="showTargetForm = true" class="flex flex-col">
        <div class="flex flex-row justify-between font-semibold text-xs gap-3">
          <div>Planned: </div>
          <button class="hover:text-blue-400">{{ hrsMinutesPlanned }}</button>
        </div>
        <div class="flex flex-row justify-between font-semibold text-xs gap-3">
          <div>Target: </div>
          <button class="hover:text-blue-400">{{ hrsMinutesTarget }}</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showTargetForm" class="p-4 m-4 border flex flex-col">
    <Duration 
      :data="workday?.duration || 0"
      label="Daily Work Goal:"
      @updateField="updateWorkdayDuration"
    />
    <div class="flex flex-row justify-end">
      <button @click="showTargetForm = false" class="btn-confirm">Finished</button>
    </div>
  </div>
  <div class="h-full overflow-y-auto scrollbar-custom">
    <div
      v-if="!recordItems.length"
      class="flex flex-row items-center justify-around text-center italic text-gray-500 border-b p-2"
    >
      <div>-- Double-click a task to add it to your planner. --</div>
    </div>

    <div v-else-if="grouped" class="pt-2 flex flex-col gap-3">
      <div
        v-for="([project, records], index) in Array.from(projectGroups)"
        :key="index"
        class=""
      >
        <div class="text-xs font-medium mb-1 flex flex-row">
          <div
            class="w-24"
            :style="'border-bottom: solid 3px; border-color:' + (getProject(project)?.color || '')"
          >
            {{ getProject(project).name }}
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <template v-for="element in records">
            <div
              v-if="element.minutes_planned || element.minutes_worked"
              @click="$emit('select', 'task', element.task)"
              class=""
            >
              <ListedRecord
                :key="element.id"
                :obj="element"
                :task="tasks.getItem(element.task)"
                :menuDisplayedFor="menuDisplayedFor"
                @actionA="displayTaskMenu"
                @actionB="strikeRecord"
                @openTask="displayTask"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-else class="pt-2 flex flex-col gap-2">
      <Sortable
        :list="recordItems"
        :options="options"
        @end="updateOrder"
        tag="div"
        itemKey="id"
        class="flex flex-col gap-1"
      >
        <template
          #item="{ element, index }"
          @click="$emit('select', 'task', element.task)"
        >
          <ListedRecord
            :key="element.id"
            :obj="element"
            :task="tasks.getItem(element.task)"
            :menuDisplayedFor="menuDisplayedFor"
            @actionA="displayTaskMenu"
            @actionB="strikeRecord"
            @openTask="displayTask"
          />
        </template>
      </Sortable>
    </div>
  </div>

  <div class="flex flex-row justify-between border-t items-center h-12">
    <div class="flex flex-row items-center gap-2 p-2">
      <v-sheet class="text-sm lg:text-base pt-2">
        <v-switch hide-details color="success" density="compact" v-model="grouped">
          <template v-slot:label>
            <span class="text-sm lg:text-base">Group Tasks by Project</span>
          </template>
        </v-switch>
      </v-sheet>
    </div>

    <div 
      @click="$emit('setView', 'center', 'clearSchedule')"
      class="flex items-end text-sm hover:text-red-600 cursor-pointer text-gray-500 m-2 mt-4"
    >Clear Schedule</div>

    <!-- <button
      class="text-white font-bold h-full bg-green-600 hover:bg-green-700 px-3 xl:hidden text-sm lg:text-base hidden"
      @click=""
    >
      View Appointments
    </button> -->
  </div>
</template>