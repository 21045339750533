import { useTasks } from "@/store/items/task";
import { useRecords } from "@/store/items/record";

export const crudActions = {
    async autoAssignRecord(taskID, workdayID) {
        const tasks = useTasks()
        const records = useRecords()
        const task = tasks.getItem(taskID);
        console.log(records.items.find((item) => item.task === taskID && item.workday === workdayID))
        if (workdayID && !records.items.find((item) => item.task === taskID && item.workday === workdayID)) {
            console.log(this.items)
            const workday = this.items.find((item) => item.id === workdayID)
            console.log(workday)
            let proposedAllocation = task.minutes_remaining || 0;
    
            const minutesRemaining = workday.duration - workday.minutes_planned;
            if (proposedAllocation > minutesRemaining) {
                proposedAllocation = minutesRemaining;
            }
            if (proposedAllocation > 180) {
                proposedAllocation = 180;
            } else if (proposedAllocation === 0) {
                proposedAllocation = 5;
            }
            const data = {
                task: taskID,
                workday: workdayID,
                minutes_planned: proposedAllocation,
            };
            records.proposed = data
            records.save()
            task.minutes_available -= proposedAllocation; 
            workday.minutes_planned += proposedAllocation
        }
    }
}